import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import useParams to get the venue ID from the URL.
import { axiosInstance } from '../Axios';
import { toast, Toaster } from 'react-hot-toast';
import SocietySidebar from '../components/SocietySidebar';
import SocNav from '../components/SocNav';
import SocietySideInnerSM from '../components/SocietySideInnerSM';

const EditVenue = () => {
  const { venueId } = useParams(); // Get the venue ID from the URL.
console.log("ID"+venueId )
  const [venueData, setVenueData] = useState({
    name: '',
    status: '',
    type: '',
  });

  const [isSuccess, setIsSuccess] = useState(false);

  // Fetch the venue details when the component mounts.
  useEffect(() => {
    axiosInstance.get(`venue/${venueId}/`) // Replace with your API endpoint
      .then((response) => {
        setVenueData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching venue details:', error);
      });
  }, [venueId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenueData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .put(`venue/${venueId}/`, venueData) // Replace with your API endpoint
      .then((response) => {
        setIsSuccess(true);
        toast.success('Venue Updated', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      })
      .catch((error) => {
        console.error('Error updating venue:', error);
        setIsSuccess(false);
      });
  };
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  return (
    <div>







<div>
      <div>
        <div>
          <div class="absolute w-full bg-primary  min-h-75"></div>
          <SocietySidebar />
          {isSidebarVisible && <SocietySideInnerSM />}
          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
            <SocNav toggleSidebar={toggleSidebar} />
            <div className="w-full px-6 py-6 mx-auto">



      <div className='bg-white rounded-lg p-6 h-screen'>
        <div>
          <h1 className='text-2xl'>Edit Venue</h1>
          <form onSubmit={handleSubmit} className="mt-4">
            <div className='flex flex-row items-center justify-between gap-2'>
              <div className="mb-4 w-full">
                <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                <input
                  type="text"
                  name="name"
                  value={venueData.name}
                  onChange={handleChange}
                  placeholder='eg: Block A-4'
                  className="w-full input input-bordered"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-gray-700 text-sm font-bold mb-2">Status</label>
                <div className="form-control w-full ">
                  <select className="select select-bordered" name="status" value={venueData.status} onChange={handleChange}>
                    <option selected>Pick one</option>
                    <option value='Available'>Available</option>
                    <option value='Unavailable'>Unavailable</option>
                  </select>
                </div>
              </div>
              <div className="mb-4 w-full">
                <label className="block text-gray-700 text-sm font-bold mb-2">Type</label>
                <div className="form-control w-full ">
                  <select className="select select-bordered" name="type" value={venueData.type} onChange={handleChange}>
                    <option selected>Pick one</option>
                    <option value='Indoor'>Indoor</option>
                    <option value='Outdoor'>Outdoor</option>
                  </select>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white rounded py-2 px-4"
            >
              Update Venue
            </button>
          </form>
        </div>
      </div>
      <div style={{ zIndex: 999999999999999}}>
        <Toaster position="bottom-center" />
      </div>
              
              
            </div>
          </main>
        </div>
      </div>

      <div style={{ zIndex: 999999999999999}}>
        <Toaster position="bottom-center" />
      </div>

    </div>



    </div>
  );
};

export default EditVenue;
