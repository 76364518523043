import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../Axios";
import { toast, Toaster } from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const AutoApprovalPage = () => {
  const { type, eventId, otherId } = useParams();
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [status, setStatus] = useState("");
  const [events, setEvents] = useState([]);

  const [guest, setGuest] = useState([]);
  const [venues, setVenues] = useState([]);
  const [auditorium, setAuditorium] = useState([]);
  const [specialPurchases, setSpecialPurchases] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`event/${eventId}/`)
      .then((response) => {
        const data = response.data;
        setEvents(data);
        console.log(data);
        const guestbyId = data?.all_guests?.find(
          (guest) => guest.id === parseInt(otherId)
        );
        const purchasebyId = data?.all_purchases?.find(
          (pur) => pur.id === parseInt(otherId)
        );
        setGuest(guestbyId);
        const venueById = data?.venues[otherId];
        setVenues(venueById);
        setAuditorium(data?.auditoriums[otherId]);
        setSpecialPurchases(purchasebyId);
      })
      .catch((error) => {
        console.error("Error fetching society profile:", error);
      });
  }, [eventId, otherId]);

  useEffect(() => {
    console.log("Guest--", guest);
  }, [guest]);
  useEffect(() => {
    console.log("sp--", specialPurchases);
  }, [specialPurchases]);

  const handleApproval = () => {
    axiosInstance
      .post(`requestApproval/${type}/${eventId}/${otherId}/`, {
        approvalStatus: status,
        feedback: feedback,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Purchase status changed successfully!");
          let statusTemp = status;
          axiosInstance.post("post_notification_esclate_update_req/", {
            statusTemp,
          });
          toast.success("Approval Status Changed", {
            icon: "✅",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
          setApprovalStatus(status);
          console.log("Status: ", status);
        } else {
          console.error("Error changing purchase status.");
          toast.error("Error occurred", {
            icon: "☠️",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-2xl p-8 w-8/12">
        {approvalStatus === null ? (
          <div className="mt-4 w-full flex flex-col justify-center">
            <div className="form-control">
              <p className="text-center">Change status with one click 👆</p>
              {type === "guest" ? (
                <div>
                  <br></br>
                  <br></br>
                  <br></br>
                  <h3>
                    <b>{guest.name}</b>
                  </h3>
                  <p>Event Name: {events.event_name}</p>
                  <p>Introduction: {guest.introduction}</p>
                  <p>
                    Twitter:{" "}
                    <a className="link link-primary" href={guest.twitter_link}>
                      {" "}
                      Link{" "}
                    </a>{" "}
                  </p>
                  <p>
                    Instagram:{" "}
                    <a
                      className="link link-primary"
                      href={guest.instagram_link}
                    >
                      {" "}
                      Link{" "}
                    </a>
                  </p>
                  <p>
                    Facebook:{" "}
                    <a className="link link-primary" href={guest.facebook_link}>
                      {" "}
                      Link{" "}
                    </a>
                  </p>
                  <p>Org: {guest.org}</p>
                  <p>Role: {guest.role}</p>
                  <p>Research: {guest.research}</p>
                  <p>Outcome: {guest.outcome}</p>
                </div>
              ) : type === "venue" ? (
                <div>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Event Name: {events?.event_name}
                  </p>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Venue: {venues?.venue}
                  </p>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Capacity: {venues?.capacity}
                  </p>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Date: {venues?.date}
                  </p>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Time: {venues?.time}
                  </p>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Activity: {venues?.activity}
                  </p>
                </div>
              ) : type === "Auditorium" ? (
                <div>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Event Name: {events?.event_name}
                  </p>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Venue: {auditorium?.venue}
                  </p>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Capacity: {auditorium?.capacity}
                  </p>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Date: {auditorium?.date}
                  </p>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Time: {auditorium?.time}
                  </p>
                  <p className="px-6 py-4 whitespace-nowrap">
                    Status: {venues?.status}
                  </p>
                </div>
              ) : (
                <div>
                  <p>Event Name: {events?.event_name}</p>
                  <p>Purchase Category: {specialPurchases?.category}</p>
                  <p>Due Date: {specialPurchases?.due_date}</p>
                  <p>Name: {specialPurchases?.details}</p>
                  {specialPurchases?.file && (
                    <a
                      className="link link-primary"
                      href={`http://localhost:3000/${specialPurchases?.file}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View File
                    </a>
                  )}
                </div>
              )}
              <label className="label cursor-pointer">
                <span className="label-text">Approve</span>
                <input
                  type="radio"
                  name="radio-10"
                  className="radio checked:bg-green-500"
                  checked={status === "a"}
                  onChange={(e) => setStatus("a")}
                />
              </label>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Reject</span>
                <input
                  type="radio"
                  name="radio-10"
                  className="radio checked:bg-red-500"
                  checked={status === "r"}
                  onChange={(e) => setStatus("r")}
                />
              </label>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Conditional Approve</span>
                <input
                  type="radio"
                  name="radio-10"
                  className="radio checked:bg-yellow-500"
                  checked={status === "c"}
                  onChange={(e) => setStatus("c")}
                />
              </label>
            </div>
            <br />
            <p>Feedback</p>
            <textarea
              type="text"
              placeholder="Type here"
              className="input input-bordered w-full h-32 p-2"
              onChange={(e) => setFeedback(e.target.value)}
            ></textarea>
            <br />
            <br />
            <button
              className="btn btn-primary btn-block"
              onClick={handleApproval}
            >
              Submit
            </button>
          </div>
        ) : (
          // <p>Approval Status: {approvalStatus ? "Approved" : "Rejected"}</p>
          <p>Thanks for feedback</p>
        )}
      </div>
    </div>
  );
};

export default AutoApprovalPage;