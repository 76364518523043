import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Home from "./pages/Home";

import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import Profile from "./pages/Profile";
import SocietyDashboard from "./pages/SocietyDashboard";
import Write from "./pages/Write";
import Organisation from "./pages/Organisation";
import SocietyEvents from "./components/SocietyEvents";
import AllEvents from "./components/AllEvents";
import Repository from "./components/Repository";
import Test from "./pages/Test";
import Calendar from "./components/Calendar";
import SocietyProfile from "./pages/SocietyProfile";
import CCADashboard from "./pages/CCADashboard";
import Protected from "./components/Protected";
import ManagerProtected from "./components/ManagerProtected";
import AdminProtected from "./components/AdminProtected";
import ManagerDashboard from "./pages/ManagerDashboard";
import RequestEvent from "./pages/RequestEvent";
import EventDetails from "./pages/EventDetails";
import Chat from "./pages/Chat";
import AllSocietyDetails from "./components/AllSocietyDetails";
import AddSociety from "./components/AddSociety";
import AddManager from "./components/AddManager";
import EventReqForm from "./pages/EventReqForm";
import EditSocietyMember from "./components/EditSocietyMember";
import PdcDashboard from "./pages/PdcDashboard";
import PdcProtected from "./components/PdcProtected";
import Dashboard from "./sandbox/Dashboard";
import GetAdmins from "./components/GetAdmins";
import ChangePasswordForm from "./pages/ChangePasswordForm";
import AdminProfile from "./components/AdminProfile";
import UpdatePasswordPage from "./pages/UpdatePasswordPage";
import GraphMain from "./pages/GraphMain";
import AddAdmin from "./components/AddAdmin";
import EventApproval from "./pages/EventApproval";
import AutoApprovalPage from "./pages/AutoApprovalPage";
import ExportDetails from "./components/ExportDetails";
import VenueList from "./pages/VenueList";
import AddVenue from "./pages/AddVenue";
import EditVenue from "./pages/EditVenue";
import ManagerRegistration from "./pages/ManagerRegistration";
import FeedbackList from "./pages/FeedbackList";
import VenueApproval from "./pages/VenueApproval";
import Repo from "./components/Repo";
import CreateRepo from "./components/CreateRepo";
import EditRepo from "./components/EditRepo";
import DetailsRepo from "./components/RepoDetails";
import Announcements from "./components/Announcements";
import AnnouncementNew from "./components/AnnouncementNew";
import AnnouncementEdit from "./components/AnnouncementEdit";
import AnnouncementDetail from "./components/AnnouncementDetails";
import TUICalendar from "./pages/TUICalendar";
import Schedule from "./pages/Schedule";
import AddScheduleForm from "./components/AddScheduleForm";
function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/home" element={<Home />} />
            <Route path="/society" element={<SocietyDashboard />} exact />
            <Route path="/profile/:id" element={<Profile />} exact />
            <Route path="/request/:id" element={<Write />} exact />
            <Route path="/organisation" element={<Organisation />} exact />
            <Route path="/events_self/:id" element={<SocietyEvents />} exact />
            <Route path="/events_all" element={<AllEvents />} exact />
            <Route path="/repository/:id" element={<Repository />} exact />
            <Route path="/test" element={<TUICalendar />} exact />
            <Route path="/calendar" element={<Calendar />} exact />
            <Route path="/society_profile" element={<SocietyProfile />} exact />
            <Route path="/request_event" element={<RequestEvent />} exact />
            <Route path="/event_details/:id" element={<EventDetails />} exact />
            <Route path="/chats" element={<Chat />}></Route>
            <Route path="/event_form" element={<EventReqForm />} exact />
            <Route path="/edit_members" element={<EditSocietyMember />} exact />
            <Route path="/create_repo" element={<CreateRepo />} exact />
            <Route path="/edit_repo/:id" element={<EditRepo />} exact />
            <Route path="/det_repo/:id" element={<DetailsRepo />} exact />
            <Route path="/schedule" element={<Schedule />} exact />
            <Route path="/repo" element={<Repo />} exact />
            <Route path="/add-schedule" element={<AddScheduleForm />} exact />
            <Route path="/announcements" element={<Announcements />} exact />
            <Route
              path="/new_announcement"
              element={<AnnouncementNew />}
              exact
            />
            <Route
              path="/edit_announcement/:id"
              element={<AnnouncementEdit />}
              exact
            />
            <Route
              path="/announcement_detail/:id"
              element={<AnnouncementDetail />}
              exact
            />

            <Route
              path="/event/4789/approve/:type/:eventId/:otherId"
              element={<AutoApprovalPage />}
              exact
            />
            <Route
              path="/event/4788/approve/:type/:venue/:id"
              element={<VenueApproval />}
              exact
            />

            <Route path="/export" element={<ExportDetails />} exact />
            <Route path="/venues" element={<VenueList />} exact />
            <Route path="/add_venue" element={<AddVenue />} exact />
            <Route path="/edit_venue/:venueId" element={<EditVenue />} exact />
            <Route path="/sandbox" element={<Dashboard />} exact />
            <Route path="/set_proxy" element={<GetAdmins />} exact />
            <Route path="/pass_reset" element={<ChangePasswordForm />} exact />
            <Route path="/admin_profile" element={<AdminProfile />} exact />
            <Route
              path="/new_pass/user/2959094878/:id"
              element={<UpdatePasswordPage />}
              exact
            />
            <Route
              path="/remote_request/:event_id"
              element={<EventApproval />}
              exact
            />
            <Route
              path="startupearly/danger_route/create_manager"
              element={<ManagerRegistration />}
              exact
            />

            <Route path="/feedbacks" element={<FeedbackList />} exact />
            <Route path="/add_feedback" element={<AddVenue />} exact />
            <Route
              path="/edit_feedback/:feedback_id"
              element={<EditVenue />}
              exact
            />

            <Route
              path="/all_society_details"
              element={<AllSocietyDetails />}
              exact
            />
            <Route path="/network" element={<GraphMain />} exact />
            <Route
              path="/admin"
              element={
                <Protected>
                  <CCADashboard />
                </Protected>
              }
              exact
            />
            <Route
              path="/manager"
              element={
                <ManagerProtected>
                  <ManagerDashboard />
                </ManagerProtected>
              }
              exact
            />
            <Route
              path="/add_manager"
              element={
                <ManagerProtected>
                  <AddManager />
                </ManagerProtected>
              }
              exact
            />
            <Route
              path="/add_society"
              element={
                <AdminProtected>
                  <AddSociety />
                </AdminProtected>
              }
              exact
            />

            <Route
              path="/add_admin"
              element={
                <AdminProtected>
                  <AddAdmin />
                </AdminProtected>
              }
              exact
            />
            <Route
              path="/pdc_dashboard"
              element={
                <PdcProtected>
                  <PdcDashboard />
                </PdcProtected>
              }
              exact
            />
            <Route element={<PrivateRoute />}></Route>
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
