import React, { Fragment, useState, useEffect } from "react";
import randomcolor from "randomcolor";
import { axiosInstance } from "../Axios";
import "./Map.css";
import { toast, Toaster } from 'react-hot-toast';
const Card = ({ data }) => {
  const levelColor = randomcolor();
  //console.log("in card data", data);

  return (
    <ul>
      {data.map((item, index) => (
        <Fragment key={item.member.id}>
          <li>
            <div className="card">
              <div className="image">
                <img
                  src={"https://cca.startupearly.com/api" + item.member.image}
                  alt="Profile"
                  style={{ borderColor: levelColor }}
                />
              </div>
              <div className="card-body">
                <h4>{item?.member.name}</h4>
                <p>{item?.member.designation}</p>
              </div>
              <div
                className="card-footer"
                style={{ background: levelColor }}
              ></div>
              <div></div>
            </div>
            {item.children?.length && <Card data={item.children} />}
          </li>
        </Fragment>
      ))}
    </ul>
  );
};

const Chart = ({ data }) => {
  return (
    <div className="org-tree">
      <Card data={data} />
    </div>
  );
};


function HierarchyBuilder({ setCurrentOrder, fetchedMemberList }) {
  const [members, setMembers] = useState([]);
  const [list, setList] = useState(fetchedMemberList);
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedRoot, setSelectedRoot] = useState("");

  const handleAddChild = (node, level) => {
    if (selectedMember[node.member.id]) {
      node.children.push({
        member: selectedMember[node.member.id],
        children: [],
      });
      setMembers([...members]);
      // Remove the selected member from the list of available members
      setList(
        list.filter((member) => member.id !== selectedMember[node.member.id].id)
      );
      setSelectedMember({ ...selectedMember, [node.member.id]: "" });
      //console.log(members);
    }
  };

  const renderNode = (node, level = 0) => {
    const handleSelect = (e) => {
      const selected = list.find((member) => member.name === e.target.value);
      setSelectedMember({ ...selectedMember, [node.member.id]: selected });
    };

    return (
      <div  key={node.member.id} style={{ marginLeft: 20 * level }}>
        <h3>{node.member.name}</h3>
        <select
          className="select select-bordered"
          value={selectedMember[node.member.id]?.name || ""}
          onChange={handleSelect}
        >
          <option value="">Select a member</option>
          {list.map((member) => (
            <option key={member.id} value={member.name}>
              {member.name}
            </option>
          ))}
        </select>
        <button className="btn btn-sm btn-outlined" onClick={() => handleAddChild(node, level)}>Add Child</button>
        {node.children.map((child) => renderNode(child, level + 1))}
      </div>
    );
  };

  const handleSelectRoot = (e) => {
    const selected = list.find(
      (member) => member.id === parseInt(e.target.value)
    );
    setSelectedRoot(selected);
  };
  const addRoot = () => {
    if (selectedRoot) {
      setMembers([...members, { member: selectedRoot, children: [] }]);
      // Remove the selected root from the list of available members
      setList(list.filter((member) => member.id !== selectedRoot.id));
      setSelectedRoot("");
    }
  };

  const handleSubmit = () => {
    // setCurrentOrder(members);
    //console.log("final", members);
    let new_members = JSON.stringify(members);
    axiosInstance
      .put("society_hierarchy/", { new_members })
      .then((response) => {
        toast.success('Hierarchy Updated Successfully', {
          icon: '🎄',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        axiosInstance.get("society_hierarchy/").then((response) => {
          let hierarchy = response.data.hierarchy;
          if (hierarchy) {
            hierarchy = JSON.parse(hierarchy);
            if (hierarchy.length > 0) {
              setCurrentOrder(hierarchy);
            }
          }
        });
      });
  };

  return (
    <div className="bg-white p-6 rounded-md ">
      {members.map((root) => renderNode(root))}
      
      {members.length === 0 && (
        <div className="flex space-x-4 items-center">
          <select className="bg-gray-100 p-2 rounded-md" value={selectedRoot?.id || ""} onChange={handleSelectRoot}>
            <option value="">Select Root</option>
            {list.map((member) => (
              <option key={member.id} value={member.id}>
                {member.name}
              </option>
            ))}
          </select>
          <button className="bg-blue-500 text-white p-2 rounded-md" onClick={addRoot}>Add Root</button>
        </div>
      )}
      <button className="bg-green-500 text-white p-2 rounded-md mt-4" onClick={handleSubmit}>Submit</button>
    </div>
  );
}


// export default HierarchyBuilder;
//will be better to move hierarchy builder to separate page
const HierarchyMap = ({ fetchedMemberList, members }) => {
  const [hierarchy, setHierarchy] = useState([]);
  const [local_members, setMembers] = useState(members);
  const [showBuilder, setShowBuilder] = useState(false);

  useEffect(() => {
    // Fetch the requests from the backend
    axiosInstance
      .get("society_hierarchy/")
      .then((response) => {
        let hierarchy = response.data.hierarchy;
        if (hierarchy) {
          hierarchy = JSON.parse(hierarchy);
          if (hierarchy.length > 0) {
            setHierarchy(hierarchy);
          }
        }
      })
      .catch((error) => {
        alert(error.response.data);
      });
  }, []);

  const toggleBuilder = () => {
    setShowBuilder(!showBuilder);
  };
  //console.log(members);

  return (
    <div className="p-4 space-y-4">
    {hierarchy.length > 0 && (
      <Chart data={hierarchy} fetchedMemberList={fetchedMemberList} />
    )}
    
    <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={toggleBuilder}>
      {showBuilder ? "Hide Builder" : "Show Builder"}
    </button>

    {members && showBuilder && (
      <div className="mt-4">
        <HierarchyBuilder
          setCurrentOrder={setHierarchy}
          fetchedMemberList={members}
        />
      </div>
    )}

    <div style={{ zIndex: 999999999999999}}>
      <Toaster position="bottom-center" />
    </div>
  </div>
  );
};

export default HierarchyMap;
