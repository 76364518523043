import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { axiosInstance } from "../Axios";

function PassWarn() {

    useEffect(() => {
        const modal = document.getElementById("my_modal_5");
        if (modal) {
          modal.showModal();
      
          // Prevent closing on ESC
          window.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
              event.preventDefault();
              // Optionally, inform the user that ESC won't close the dialog
              console.log('Pressing ESC will not close the dialog.');
            }
          });
      
          // Cleanup listener when component unmounts or modal gets closed
          return () => window.removeEventListener('keydown', this);
        }
      }, []);
    
      const { user } = useContext(AuthContext);
      const userID = user["user_id"];
    
      let endpoint = '';
    
      if (user['role'] === 'society') {
        endpoint = `society/profile/${userID}/`;
      } else if (user['role'] === 'ccaAdmin') {
        endpoint = `admin/profile/${userID}/`;
      } else if (user['role'] === 'manager') {
        endpoint = `manager/profile/${userID}/`;
      }
    
      const [profileData, setProfileData] = useState(null);
    
      useEffect(() => {
        if (endpoint) {
          axiosInstance.get(endpoint)
            .then((response) => {
              setProfileData(response.data);
            })
            .catch((error) => {
              console.error('Error fetching profile:', error);
            });
        }
      }, [userID, endpoint]);
    
      let updateEndpoint = ''; // Define updateEndpoint here
    
      const handleSetNewPassword = () => {
        const data = { is_newlogin: 0 };
    
        if (user['role'] === 'society') {
          updateEndpoint = `society/profile/${userID}/update/`;
        } else if (user['role'] === 'ccaAdmin') {
          updateEndpoint = `admin/profile/${userID}/update/`;
        } else if (user['role'] === 'manager') {
          updateEndpoint = `manager/profile/${userID}/update/`;
        }
    
        if (updateEndpoint) {
          axiosInstance.post(updateEndpoint, data)
            .then((response) => {
              console.log('Profile updated successfully:', response.data);
              // Optionally, you can perform any other actions here (e.g., redirect to a different page)
            })
            .catch((error) => {
              console.error('Error updating profile:', error);
            });
        } else {
          console.error('Invalid user role or update endpoint');
        }
      };
    
      console.log('EP: ' + endpoint);
      console.log('Update EP: ' + updateEndpoint);
    

  return (
    <div>
      {/* <button className="btn" onClick={() => document.getElementById('my_modal_5').showModal()}>Open Modal</button> */}
      <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="text-red-600 w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              />
            </svg>
            Set a new password to continue
          </h3>
          <p className="py-4">
            You're using a temporary password. To continue using your account, set a new
            password
          </p>
          <div className="modal-action">
            <Link className="btn btn-primary bg-primary text-white"  to={`/new_pass/user/2959094878/${userID}`} onClick={handleSetNewPassword}>
              {" "}
              Set New Password{" "}
            </Link>
          </div>
        </div>
      </dialog>
    </div>
  );
}

export default PassWarn;
