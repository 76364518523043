import React, {
  useState,
  useContext,
  useRef,
  Component,
  useEffect,
} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useNavigate, Link } from "react-router-dom";
import moment from "moment";
import AuthContext from "../context/AuthContext";
import { Editor } from "@tinymce/tinymce-react";

import Papa from "papaparse";
const Write = () => {
  const state = useLocation().state;
  const [value, setValue] = useState(state?.descr || "");
  const [title, setTitle] = useState(state?.title || "");

  const [rtf, set_RTF] = useState(state?.rtf || "");

  const [opacity, setOpacity] = useState(0);

  const [splash, setSplash] = useState(true);
  const [isShownBASIC, setIsShownBASIC] = useState(false);
  const [isShownInfo, setIsShownInfo] = useState(false);
  const [isShownSuccess, setIsShownSuccess] = useState(false);
  const [isShownSchedule_event, setIisShownSchedule_event] = useState(false);
  const [isShownEventAgenda, setIisShownEventAgenda] = useState(false);
  const [isShownVigilence, setIisShownVigilence] = useState(false);
  const [isShownGuest, setIisShownGuest] = useState(false);
  const [isShownAuditorium, setIisShownAuditorium] = useState(false);
  const [isShownOutside, setIisShownOutside] = useState(false);
  const [isShownPurchase, setIisShownPurchase] = useState(false);
  const [isShownGroundService, setIisShownGroundService] = useState(false);

  const [isShownMeeting, setIisShownMeeting] = useState(false);
  const [isShownEvent, setisShownEvent] = useState(false);
  const [isShownMeeting_Venue, setIisShownMeeting_Venue] = useState(false);

  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        setParsedData(results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(valuesArray);
      },
    });
  };

  const handleSplash = (event) => {
    // 👇️ toggle shown state
    setSplash((current) => !current);

    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIsVisible_Applicant(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
  };

  const handleBasic = (event) => {
    // 👇️ toggle shown state
    setIsShownBASIC((current) => !current);
    setIsVisible_Carpenter(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);
    setIsVisible_Labour(false);
    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setIsVisible_Applicant(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
  };

  const handleInfo = (event) => {
    // 👇️ toggle shown state
    setIsShownInfo((current) => !current);
    setIsShownBASIC(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const handleSchedule_event = (event) => {
    // 👇️ toggle shown state
    setIisShownSchedule_event((current) => !current);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);

    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const handleEventAgenda = (event) => {
    // 👇️ toggle shown state
    setIisShownEventAgenda((current) => !current);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);

    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const handleVigilance = (event) => {
    // 👇️ toggle shown state
    setIisShownVigilence((current) => !current);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);

    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const handleGuest = (event) => {
    // 👇️ toggle shown state
    setIisShownGuest((current) => !current);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);

    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const handleAuditorium = (event) => {
    // 👇️ toggle shown state
    setIisShownAuditorium((current) => !current);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);

    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const handleOutside = (event) => {
    // 👇️ toggle shown state
    setIisShownOutside((current) => !current);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);

    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const handlePurchase = (event) => {
    // 👇️ toggle shown state
    setIisShownPurchase((current) => !current);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);

    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
  };

  const handleGroundServices = (event) => {
    // 👇️ toggle shown state
    setIisShownGroundService((current) => !current);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
  };

  const handleMeeting = (event) => {
    // 👇️ toggle shown state
    setIisShownMeeting((current) => !current);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const handleEvent = (event) => {
    // 👇️ toggle shown state
    setisShownEvent((current) => !current);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setEventHeadConfirm(false);
  };

  const [isExrernalMeeting, setisExrernalMeeting] = useState(false);
  const handleExternalMeeting = (event) => {
    // 👇️ toggle shown state
    setisExrernalMeeting((current) => !current);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const handleMeeting_Venue = (event) => {
    // 👇️ toggle shown state
    setIisShownMeeting_Venue((current) => !current);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setShowOutsideMeetingVenue(false);
  };

  const handleSuccess = (event) => {
    // 👇️ toggle shown state
    setIsShownSuccess((current) => !current);
    setIsShownBASIC(false);
    setIsVisible_Carpenter(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIsVisible_Labour(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);
    setIsVisible_Labour(false);
    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setCarpenter(false);
  };

  const [eventName, setEventName] = useState(state?.eventName || "");
  const [eventHead, setEventHead] = useState(state?.eventHead || "");
  const [eventHead_email, setEventHeadEmail] = useState(
    state?.eventHead_email || ""
  );
  const [eventHead_contact, setEventHeadContact] = useState(
    state?.eventHead_contact || ""
  );
  const [purpose, setEventPurpose] = useState(state?.purpose || "");
  const [vigilenceDetails, setVigilence] = useState(
    state?.vigilenceDetails || ""
  );
  const [ageGroup, setAgeGroup] = useState(state?.ageGroup || "");
  const [portalLink, setPortalLink] = useState(state?.portalLink || "");
  const [audit_date, setAuditDate] = useState(state?.audit_date || "");
  const [book_time, setAuditBookTime] = useState(state?.book_time || "");
  const [activity_time, setAuditActivityTime] = useState(
    state?.activity_time || ""
  );
  const [audit_cap, setAuditCap] = useState(state?.audit_cap || "");
  const [audit_cap2, setAuditCap2] = useState(state?.audit_cap2 || "");
  const [audit_cap3, setAuditCap3] = useState(state?.audit_cap3 || "");
  const [audit_cap4, setAuditCap4] = useState(state?.audit_cap4 || "");
  const [audit_cap5, setAuditCap5] = useState(state?.audit_cap5w || "");

  const [prefAudit, setPrefAudit] = useState(state?.prefAudit || "");

  const [activity_time2, setAuditActivityTime2] = useState(
    state?.activity_time2 || ""
  );
  const [activity_time3, setAuditActivityTime3] = useState(
    state?.activity_time3 || ""
  );
  const [activity_time4, setAuditActivityTime4] = useState(
    state?.activity_time4 || ""
  );
  const [activity_time5, setAuditActivityTime5] = useState(
    state?.activity_time5 || ""
  );

  const [book_time2, setAuditBookTime2] = useState(state?.book_time2 || "");
  const [book_time3, setAuditBookTime3] = useState(state?.book_time3 || "");
  const [book_time4, setAuditBookTime4] = useState(state?.book_time4 || "");
  const [book_time5, setAuditBookTime5] = useState(state?.book_time5 || "");

  const [start_date2, setStartDate2] = useState(state?.start_date2 || "");
  const [end_date2, setEndDate2] = useState(state?.end_date2 || "");
  const [start_date3, setStartDate3] = useState(state?.start_date3 || "");
  const [end_date3, setEndDate3] = useState(state?.end_date3 || "");
  const [start_date4, setStartDate4] = useState(state?.start_date4 || "");
  const [end_date4, setEndDate4] = useState(state?.end_date4 || "");
  const [start_date5, setStartDate5] = useState(state?.start_date5 || "");
  const [end_date5, setEndDate5] = useState(state?.end_date5 || "");

  const [im_title, setIM_title] = useState(state?.im_title || "");
  const [im_poc_name, setIM_poc_name] = useState(state?.im_poc_name || "");
  const [im_poc_mail, setIM_poc_mail] = useState(state?.im_poc_mail || "");
  const [im_poc_contact, setIM_poc_contact] = useState(
    state?.im_poc_contact || ""
  );
  const [im_purpose, setIM_purpose] = useState(state?.im_purpose || "");
  const [im_audit_date, setIM_AuditDate] = useState(state?.im_audit_date || "");
  const [im_book_time, setIM_AuditBookTime] = useState(
    state?.im_book_time || ""
  );
  const [im_activity_time, setIM_AuditActivityTime] = useState(
    state?.im_activity_time || ""
  );
  const [im_audit_cap, setIM_AuditCap] = useState(state?.im_audit_cap || "");
  const [im_prefAudit, setIM_PrefAudit] = useState(state?.im_prefAudit || "");
  const [im_prefAudit2, setIM_PrefAudit2] = useState(
    state?.im_prefAudit2 || ""
  );
  const [im_prefAudit3, setIM_PrefAudit3] = useState(
    state?.im_prefAudit3 || ""
  );
  const [im_prefAudit4, setIM_PrefAudit4] = useState(
    state?.im_prefAudit4 || ""
  );
  const [im_prefAudit5, setIM_PrefAudit5] = useState(
    state?.im_prefAudit5 || ""
  );
  const [im_otherDetails, setIM_OtherDetails] = useState(
    state?.im_otherDetails || ""
  );

  const [ext_title, setExt_title] = useState(state?.ext_title || "");
  const [ext_poc_name, setExt_poc_name] = useState(state?.ext_poc_name || "");
  const [ext_poc_mail, setExt_poc_mail] = useState(state?.ext_poc_mail || "");
  const [ext_poc_contact, setExt_poc_contact] = useState(
    state?.ext_poc_contact || ""
  );
  const [ext_purpose, setExt_purpose] = useState(state?.ext_purpose || "");
  const [ext_audit_date, setExt_AuditDate] = useState(
    state?.ext_audit_date || ""
  );
  const [ext_book_time, setExt_AuditBookTime] = useState(
    state?.ext_book_time || ""
  );
  const [ext_activity_time, setExt_AuditActivityTime] = useState(
    state?.ext_activity_time || ""
  );
  const [ext_audit_cap, setExt_AuditCap] = useState(state?.ext_audit_cap || "");
  const [ext_prefAudit, setExt_PrefAudit] = useState(
    state?.ext_prefAudit || ""
  );
  const [ext_otherDetails, setExt_OtherDetails] = useState(
    state?.ext_otherDetails || ""
  );
  const [ext_vigilance, setExt_Vigilance] = useState(
    state?.ext_vigilance || ""
  );

  const [outsideActivity, setOutsideActivity] = useState(
    state?.outsideActivity || ""
  );
  const [outsideDate, setOutsideDate] = useState(state?.outsideDate || "");
  const [outsideTime, setOutsideTime] = useState(state?.outsideTime || "");

  const [externalConst, setexternalConst] = useState(
    state?.externalConst || ""
  );
  const [outsideCap, setOutsideCap] = useState(state?.outsideCap || "");
  const [outsideVenue, setOutsideVenue] = useState(state?.outsideVenue || "");
  const [alt_outsideVenue, setAltOutsideVenue] = useState(
    state?.alt_outsideVenue || ""
  );
  const [janitorialService, setJanitorialService] = useState(
    state?.janitorialService || ""
  );

  const [auditMass, setauditMass] = useState(state?.auditMass || "");

  const [research, setResearch] = useState(state?.research || "");
  const [riskass, setRAO] = useState(state?.riskass || "");

  const [guestName, setGuestName] = useState(state?.guestName || "");
  const [guestProfile, setGuestProfile] = useState(state?.guestProfile || "");
  const [guestSocial, setGuestSocial] = useState(state?.guestSocial || "");

  const [purchaseType, setPurchaseType] = useState(state?.Purchase || "");
  const [purchaseDetails, setPurchaseDetails] = useState(
    state?.purchaseDetails || ""
  );
  const [purchaseDate, setPurchaseDate] = useState(state?.purchaseDate || "");

  const [electricals, setElectrical] = useState(state?.electricals || "");
  const [plumbers, setPlumber] = useState(state?.plumbers || "");
  const [welders, setWelder] = useState(state?.welders || "");
  const [carpenters, setCarpenter] = useState(state?.carpenters || "");
  const [labours, setLabour] = useState(state?.labours || "");
  const [security, setSecurity] = useState(state?.security || "");

  const [talkpoints, setTalkpoints] = useState(state?.talkpoints || "");
  const [talksummary, setTalksummary] = useState(state?.talksummary || "");

  const [applicant_name, set_ApplicantName] = useState(
    state?.applicant_name || ""
  );
  const [applicant_email, set_ApplicantEmail] = useState(
    state?.applicant_email || ""
  );
  const [applicant_contact, set_ApplicantContact] = useState(
    state?.applicant_contact || ""
  );
  const [nature, setNature] = useState(state?.nature || "");
  const [start_date, setStartDate] = useState(state?.start_date || "");
  const [end_date, setEndDate] = useState(state?.end_date || "");
  const [start_time, setStartTime] = useState(state?.start_time || "");
  const [end_time, setEndTime] = useState(state?.end_time || "");
  const [budget, setBudget] = useState(state?.budget || "");
  const [venue, setVenue] = useState(state?.venue || "");
  const [post_username, setUsername] = useState(state?.post_username || "");
  const [post_email, setEmail] = useState(state?.post_email || "");
  const [project, setProject] = useState(state?.project || "");
  const [file, setFile] = useState(state?.file || "");
  const [cat, setCat] = useState(state?.cat || "");
  const [status, setStatus] = useState(state?.status || "");
  const [comment, setComment] = useState(state?.comment || "");
  const [MOU, setMOU] = useState(state?.MOU || "");
  //all states
  /*console.log(
    title,
    applicant_name,
    applicant_email,
    applicant_contact,
    eventName,
    eventHead,
    eventHead_email,
    eventHead_contact,
    purpose,
    //rtf,
    rtf,
    auditMass,
    research,
    riskass,
    talkpoints,
    talksummary,
    externalConst,
    vigilenceDetails,
    purchaseType,
    purchaseDetails,
    purchaseDate,
    ageGroup,
    portalLink,
    audit_date,
    book_time,
    activity_time,
    audit_cap,
    prefAudit,
    outsideActivity,
    outsideDate,
    outsideTime,
    outsideCap,
    outsideVenue,
    alt_outsideVenue,
    purchaseDetails,
    janitorialService,
    security,
    electricals,
    plumbers,
    welders,
    labours,
    carpenters,
    guestName,
    guestProfile,
    guestSocial,
    start_date,
    end_date,
    start_time,
    end_time,
    nature,
    venue,
    budget,
    post_username,
    post_email,
    project,
    value,
    im_title,
    im_poc_name,
    im_poc_mail,
    im_poc_contact,
    im_purpose,
    im_audit_date,
    im_book_time,
    im_activity_time,
    im_audit_cap,
    im_prefAudit,
    im_otherDetails,
    ext_title,
    ext_poc_name,
    ext_poc_mail,
    ext_poc_contact,
    ext_purpose,
    ext_audit_date,
    ext_book_time,
    ext_activity_time,
    ext_audit_cap,
    ext_prefAudit,
    ext_otherDetails,
    ext_vigilance,
    cat,
    status,
    comment
  );*/
  //const [rtf, set_RTF] = useState(state?.rtf || "");
  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();

  //const full_name = currentUser.first_name+' '+currentUser.last_name;
  //const requestee = currentUser.username;

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios.post("/upload", formData);
      return res.data;
    } catch (err) {
      //console.log(err);
    }
  };

  const handleClick = async (e) => {
    //console.log("clicked");
    /*console.log(
      "heree",
      title,
      applicant_name,
      applicant_email,
      applicant_contact,
      eventName,
      eventHead,
      eventHead_email,
      eventHead_contact,
      purpose,
      //rtf,
      rtf,
      auditMass,
      research,
      riskass,
      talkpoints,
      talksummary,
      externalConst,
      vigilenceDetails,
      purchaseType,
      purchaseDetails,
      purchaseDate,
      ageGroup,
      portalLink,
      audit_date,
      book_time,
      activity_time,
      audit_cap,
      prefAudit,
      outsideActivity,
      outsideDate,
      outsideTime,
      outsideCap,
      outsideVenue,
      alt_outsideVenue,
      purchaseDetails,
      janitorialService,
      security,
      electricals,
      plumbers,
      welders,
      labours,
      carpenters,
      guestName,
      guestProfile,
      guestSocial,
      start_date,
      end_date,
      start_time,
      end_time,
      nature,
      venue,
      budget,
      post_username,
      post_email,
      project,
      value,
      im_title,
      im_poc_name,
      im_poc_mail,
      im_poc_contact,
      im_purpose,
      im_audit_date,
      im_book_time,
      im_activity_time,
      im_audit_cap,
      im_prefAudit,
      im_otherDetails,
      ext_title,
      ext_poc_name,
      ext_poc_mail,
      ext_poc_contact,
      ext_purpose,
      ext_audit_date,
      ext_book_time,
      ext_activity_time,
      ext_audit_cap,
      ext_prefAudit,
      ext_otherDetails,
      ext_vigilance,
      cat,
      status,
      comment
    );*/
    e.preventDefault();
    const imgUrl = await upload();
    //const imgUrl2 = await upload();
    try {
      state
        ? await axios.put(`/posts/${state.id}`, {
            title,
            applicant_name,
            applicant_email,
            applicant_contact,
            eventName,
            eventHead,
            eventHead_email,
            eventHead_contact,
            purpose,
            //rtf,
            rtf: rtf,
            auditMass,
            research,
            riskass,
            talkpoints,
            talksummary,
            externalConst,
            vigilenceDetails,
            purchaseType,
            purchaseDetails,
            purchaseDate,
            ageGroup,
            portalLink,
            audit_date,
            book_time,
            activity_time,
            audit_cap,
            prefAudit,
            outsideActivity,
            outsideDate,
            outsideTime,
            outsideCap,
            outsideVenue,
            alt_outsideVenue,
            purchaseDetails,
            janitorialService,
            security,
            electricals,
            plumbers,
            welders,
            labours,
            carpenters,
            guestName,
            guestProfile,
            guestSocial,
            start_date,
            end_date,
            start_time,
            end_time,
            nature,
            venue,
            budget,
            post_username,
            post_email,
            project,
            descr: value,
            im_title,
            im_poc_name,
            im_poc_mail,
            im_poc_contact,
            im_purpose,
            im_audit_date,
            im_book_time,
            im_activity_time,
            im_audit_cap,
            im_prefAudit,
            im_otherDetails,
            ext_title,
            ext_poc_name,
            ext_poc_mail,
            ext_poc_contact,
            ext_purpose,
            ext_audit_date,
            ext_book_time,
            ext_activity_time,
            ext_audit_cap,
            ext_prefAudit,
            ext_otherDetails,
            ext_vigilance,
            cat,
            status,
            comment,
            img: file ? imgUrl : "",
            MOU: MOU ? imgUrl : "",
          })
        : await axios.post(`/posts/`, {
            title,
            applicant_name,
            applicant_email,
            applicant_contact,
            eventName,
            eventHead,
            eventHead_email,
            eventHead_contact,
            purpose,
            rtf: rtf,
            auditMass,
            research,
            riskass,
            talkpoints,
            talksummary,
            externalConst,
            vigilenceDetails,
            purchaseType,
            purchaseDetails,
            purchaseDate,
            ageGroup,
            portalLink,
            audit_date,
            book_time,
            activity_time,
            audit_cap,
            prefAudit,
            outsideActivity,
            outsideDate,
            outsideTime,
            outsideCap,
            outsideVenue,
            alt_outsideVenue,
            purchaseDetails,
            janitorialService,
            security,
            electricals,
            plumbers,
            welders,
            labours,
            carpenters,
            guestName,
            guestProfile,
            guestSocial,
            start_date,
            end_date,
            start_time,
            end_time,
            nature,
            venue,
            budget,
            post_username,
            post_email,
            project,
            descr: value,
            im_title,
            im_poc_name,
            im_poc_mail,
            im_poc_contact,
            im_purpose,
            im_audit_date,
            im_book_time,
            im_activity_time,
            im_audit_cap,
            im_prefAudit,
            im_otherDetails,
            ext_title,
            ext_poc_name,
            ext_poc_mail,
            ext_poc_contact,
            ext_purpose,
            ext_audit_date,
            ext_book_time,
            ext_activity_time,
            ext_audit_cap,
            ext_prefAudit,
            ext_otherDetails,
            ext_vigilance,
            cat,
            status,
            comment,
            img: file ? imgUrl : "",
            MOU: MOU ? imgUrl : "",
            date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
          });
      navigate("/");
    } catch (err) {
      //console.log(err);
    }
  };

  const [isVisible_Event, setIsVisible_Event] = useState(false);
  const [isVisible_Meeting, setIsVisible_Meeting] = useState(false);
  const [isVisible_Meeting_Ext, setIsVisible_Meeting_Ext] = useState(false);
  const [isVisible_Vig, setIsVisible_Vig] = useState(false);

  const [isVisible_Guest_High, setIsVisible_Guest_High] = useState(false);
  const [isVisible_Guest_Med, setIsVisible_Guest_Med] = useState(false);
  const [isVisible_Guest_Low, setIsVisible_Guest_Low] = useState(false);

  const [isVisible_Auditorium, setIsVisible_Auditorium] = useState(false);
  const [isVisible_OutsideVenue, setIsVisible_OutsideVenue] = useState(false);

  const [isVisible_IM_Auditorium, setIsVisible_IMAuditorium] = useState(false);
  const [isVisible_ext_Auditorium, setIsVisible_ExtAuditorium] =
    useState(false);

  const [isVisible_Purchase, setIsVisible_Purchase] = useState(false);

  const [isVisible_Procure, setIsVisible_Procure] = useState(false);
  const [isVisible_Janitor, setIsVisible_Janitor] = useState(false);
  const [isVisible_Electrical, setIsVisible_Electrical] = useState(false);
  const [isVisible_Plumber, setIsVisible_Plumber] = useState(false);
  const [isVisible_Welder, setIsVisible_Welder] = useState(false);
  const [isVisible_Labour, setIsVisible_Labour] = useState(false);
  const [isVisible_Carpenter, setIsVisible_Carpenter] = useState(false);
  const [isVisible_Security, setIsVisible_Security] = useState(false);

  const [isVisibleGuest, setIsVisibleGuest] = useState(false);

  const [isVisibleMeetingVigilance, setisVisibleMeetingVigilance] =
    useState(false);

  const showSecurity = (e) => {
    setIsVisible_Security(true);
    setisVisible_SecurityConf(false);
    setIsVisible_Janitor(false);
    setisVisible_ElectricalConf(false);
  };

  const [isVisible_SecurityConf, setisVisible_SecurityConf] = useState(false);
  const showSecurityConf = (e) => {
    setisVisible_SecurityConf(true);
    setIsVisible_Security(false);
    setIsVisible_Janitor(false);
    setIsVisible_Electrical(false);
    setisVisible_JanitorConf(false);
  };

  const showCarpenter = (e) => {
    setIsVisible_Carpenter(true);
    setisVisible_CarpenterConf(false);
    setIsVisible_Labour(false);
  };

  const [isVisible_CarpenterConf, setisVisible_CarpenterConf] = useState(false);
  const showCarpentersConf = (e) => {
    setisVisible_CarpenterConf(true);
    setIsVisible_Carpenter(false);
    setIsVisible_Labour(false);
    setisVisible_LaboursConf(false);
  };

  const showLabours = (e) => {
    setIsVisible_Labour(true);
    setisVisible_LaboursConf(false);
    setisVisible_CarpenterConf(false);
    setIsVisible_Welder(false);
    setisVisible_WelderConf(false);
    setisVisible_PlumberConf(false);
    setIsVisible_Plumber(false);
    setisVisible_ElectricalConf(false);
    setIsVisible_Electrical(false);
    setIsVisible_Security(false);
    setisVisible_SecurityConf(false);
    setIsVisible_Janitor(false);
    setisVisible_JanitorConf(false);
  };

  const [isVisible_LaboursConf, setisVisible_LaboursConf] = useState(false);
  const showLaboursConf = (e) => {
    setisVisible_LaboursConf(true);
    setIsVisible_Labour(false);

    setIsVisible_Welder(false);
    setisVisible_WelderConf(false);
    setisVisible_PlumberConf(false);
    setIsVisible_Plumber(false);
    setisVisible_ElectricalConf(false);
    setIsVisible_Electrical(false);
    setIsVisible_Security(false);
    setisVisible_SecurityConf(false);
    setIsVisible_Janitor(false);
    setisVisible_JanitorConf(false);
  };

  const showWelder = (e) => {
    setIsVisible_Welder(true);
    setisVisible_WelderConf(false);
    setisVisible_PlumberConf(false);
    setIsVisible_Plumber(false);
    setisVisible_ElectricalConf(false);
    setIsVisible_Electrical(false);
    setIsVisible_Security(false);
    setisVisible_SecurityConf(false);
    setIsVisible_Janitor(false);
    setisVisible_JanitorConf(false);
    setisVisible_LaboursConf(false);
  };

  const [isVisible_WelderConf, setisVisible_WelderConf] = useState(false);
  const showWelderConf = (e) => {
    setisVisible_WelderConf(true);
    setIsVisible_Welder(false);

    setisVisible_PlumberConf(false);
    setIsVisible_Plumber(false);
    setisVisible_ElectricalConf(false);
    setIsVisible_Electrical(false);
    setIsVisible_Security(false);
    setisVisible_SecurityConf(false);
    setIsVisible_Janitor(false);
    setisVisible_JanitorConf(false);
    setisVisible_CarpenterConf(false);
  };

  const showPlumber = (e) => {
    setIsVisible_Plumber(true);
    setisVisible_WelderConf(false);
    setisVisible_PlumberConf(false);
    setisVisible_ElectricalConf(false);
    setIsVisible_Electrical(false);
    setIsVisible_Security(false);
    setisVisible_SecurityConf(false);
    setIsVisible_Janitor(false);
    setisVisible_JanitorConf(false);
  };

  const [isVisible_PlumberConf, setisVisible_PlumberConf] = useState(false);
  const showPlumberConf = (e) => {
    setisVisible_PlumberConf(true);
    setIsVisible_Plumber(false);
    setisVisible_WelderConf(false);
    setisVisible_ElectricalConf(false);
    setIsVisible_Electrical(false);
    setIsVisible_Security(false);
    setisVisible_SecurityConf(false);
    setIsVisible_Janitor(false);
    setisVisible_JanitorConf(false);
  };

  const showJanitor = (e) => {
    setIsVisible_Janitor(true);
    setPDHDetails(false);
    setPurchaseConf(false);
    setIsVisible_Purchase(false);
    setisVisible_JanitorConf(false);
    setisVisible_SecurityConf(false);
  };

  const [isVisible_JanitorConf, setisVisible_JanitorConf] = useState(false);
  const showJanitorConf = (e) => {
    setisVisible_JanitorConf(true);
    setIsVisible_Janitor(false);
    setPDHDetails(false);
    setPurchaseConf(false);
    setIsVisible_Purchase(false);
    setPDHinfo(false);
    setSecurity(false);
    setisVisible_ElectricalConf(false);
    setisVisible_SecurityConf(false);
    setSecurity(false);
  };

  const showElectrical = (e) => {
    setIsVisible_Electrical(true);
    setisVisible_ElectricalConf(false);
    setIsVisible_Security(false);
    setisVisible_SecurityConf(false);
    setIsVisible_Janitor(false);
    setisVisible_JanitorConf(false);
    setisVisible_PlumberConf(false);
  };

  const [isVisible_ElectricalConf, setisVisible_ElectricalConf] =
    useState(false);
  const showElectricalConf = (e) => {
    setisVisible_ElectricalConf(true);
    setIsVisible_Electrical(false);
    setIsVisible_Security(false);
    setisVisible_SecurityConf(false);
    setIsVisible_Janitor(false);
    setisVisible_JanitorConf(false);
    setisVisible_PlumberConf(false);
  };

  const showProcure = (e) => {
    if (e.target.value === "Yes") {
      setIsVisible_Procure(true);
    } else {
      setIsVisible_Procure(false);
    }
  };

  const showIM_audit = (e) => {
    if (e.target.value === "Yes") {
      setIsVisible_IMAuditorium(true);
    } else {
      setIsVisible_IMAuditorium(false);
    }
  };

  const showext_audit = (e) => {
    if (e.target.value === "Yes") {
      setIsVisible_ExtAuditorium(true);
    } else {
      setIsVisible_ExtAuditorium(false);
    }
  };

  const showMeetingVigilance = (e) => {
    setisVisibleMeetingVigilance(true);
    setIsVisible_Category(false);
    setisExrernalMeeting(false);
  };

  const showRelevent = (e) => {
    if (e.target.value === "Event") {
      setIsVisible_Event(true);
      setIsVisible_Meeting(false);
      setIsVisible_Meeting_Ext(false);
    } else if (e.target.value === "Meeting") {
      setIsVisible_Meeting(true);
      setIsVisible_Event(false);
      setIsVisible_Meeting_Ext(false);
    } else if (e.target.value === "Meeting_Ext") {
      setIsVisible_Meeting_Ext(true);
      setIsVisible_Event(false);
      setIsVisible_Meeting(false);
      //setisVisibleMeetingVigilance(true);
    } else {
      setIsVisible_Event(false);
      setIsVisible_Meeting(false);
      setIsVisible_Meeting_Ext(false);
    }
  };

  const [isVisible_Applicant, setIsVisible_Applicant] = useState(false);

  const showApplicant = (e) => {
    setIsVisible_Applicant(true);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_Category(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);

    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isVisible_ApplicantMobile, setIsVisible_ApplicantMobile] =
    useState(false);

  const showApplicantMobile = (e) => {
    setIsVisible_ApplicantMobile(true);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_Category(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);

    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isVisible_ApplicantEmail, setIsVisible_ApplicantEmail] =
    useState(false);

  const showApplicantEmail = (e) => {
    setIsVisible_ApplicantEmail(true);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_Category(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);

    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isVisible_Category, setIsVisible_Category] = useState(false);

  const showCategory = (e) => {
    setIsVisible_Category(true);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);

    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
    setisShownEvent(false);
  };

  const [isShownMeetingPurpose, setIsVisible_MeetingPurpose] = useState(false);

  const showMeetingPurpose = (e) => {
    setIsVisible_MeetingPurpose(true);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);

    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isShownMeetingPOC, setIsVisible_MeetingPOC] = useState(false);

  const showMeetingPOC = (e) => {
    setIsVisible_MeetingPOC(true);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);

    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isShownMeetingPOCDetails, setIsVisible_MeetingPOCDetails] =
    useState(false);

  const showMeetingPOCDetails = (e) => {
    setIsVisible_MeetingPOCDetails(true);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);

    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);

    setIsVisible_MeetingVenue2(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isShownMeetingVenueDate, setIsVisible_MeetingVenueDate] =
    useState(false);

  const ShowMeetingVenueDate = (e) => {
    setIsVisible_MeetingVenueDate(true);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);

    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
    setShowOutsideMeetingVenue(false);
  };

  const [isShownMeetingVenueTime, setIsVisible_MeetingVenueTime] =
    useState(false);

  const ShowMeetingVenueTime = (e) => {
    setIsVisible_MeetingVenueTime(true);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isShownMeetingVenueCap, setIsVisible_MeetingVenueCap] =
    useState(false);

  const ShowMeetingVenueCap = (e) => {
    setIsVisible_MeetingVenueCap(true);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isShownMeetingVenueName, setIsVisible_MeetingVenueName] =
    useState(false);

  const ShowMeetingVenueName = (e) => {
    setIsVisible_MeetingVenueName(true);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isShownMeeting_Venue2, setIsVisible_MeetingVenue2] = useState(false);

  const handleMeeting_Venue2 = (e) => {
    setIsVisible_MeetingVenue2(true);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
    setIsVisible_OutsideVenue(false);
  };

  const showOutsideVenue = (e) => {
    setIsVisible_OutsideVenue(true);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);

    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isShowOutsideMeetingVenue, setShowOutsideMeetingVenue] =
    useState(false);
  const showOutsideMeetingVenue = (e) => {
    setShowOutsideMeetingVenue(true);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isVisible_OutsideVenueDate, setIsVisible_OutsideVenueDate] =
    useState(false);

  const showOutsideVenueDate = (e) => {
    setIsVisible_OutsideVenueDate(true);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);

    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);

    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isVisible_OutsideVenueTime, setIsVisible_OutsideVenueTime] =
    useState(false);

  const showOutsideVenueTime = (e) => {
    setIsVisible_OutsideVenueTime(true);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isVisible_OutsideVenueCap, setIsVisible_OutsideVenueCap] =
    useState(false);

  const showOutsideVenueCap = (e) => {
    setIsVisible_OutsideVenueCap(true);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isVisible_OutsideVenueName, setIsVisible_OutsideVenueName] =
    useState(false);

  const showOutsideVenueName = (e) => {
    setIsVisible_OutsideVenueName(true);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isVisible_OutsideVenueActivitiess, setIsVisible_OutsideVenueActivity] =
    useState(false);

  const showOutsideVenueActivity = (e) => {
    setIsVisible_OutsideVenueActivity(true);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    showOutsideVenue(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isVisible_OutsideMeetingActivities, setOutsideMeetingActivities] =
    useState(false);

  const showOutsideVenueActivities = (e) => {
    setOutsideMeetingActivities(true);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setOutsideVenue(false);
    setShowOutsideMeetingVenue(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isShownOutside_Venue2, setIsVisible_OutsideVenue2] = useState(false);

  const showOutsideVenue2 = (e) => {
    setIsVisible_OutsideVenue2(true);
    setIsVisible_MeetingOthers(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_OutsideVenue(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isVisible_MeetingOthers, setIsVisible_MeetingOthers] = useState(false);
  const showOtherMeeting = (e) => {
    setIsVisible_MeetingOthers(true);
    setIsVisible_MeetingDocs(false);
    setIsVisible_OutsideVenue(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setOutsideMeetingActivities(false);
    setIsVisible_OutsideVenue2(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
    setShowOutsideMeetingVenue(false);
  };

  const [isVisible_MeetingDocs, setIsVisible_MeetingDocs] = useState(false);
  const showMeetingDocs = (e) => {
    setIsVisible_MeetingDocs(true);
    setIsVisible_MeetingOthers(false);
    setIsVisible_OutsideVenue(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingVenueName(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_Category(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIsShownSuccess(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownPurchase(false);
    setIisShownOutside(false);
    setIisShownGroundService(false);
    setIisShownMeeting(false);
    setIisShownMeeting_Venue(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantMobile(false);
    setIsVisible_ApplicantEmail(false);
    setOutsideMeetingActivities(false);
    setisVisibleMeetingVigilance(false);
    setisExrernalMeeting(false);
  };

  const [isEventHeadConfirm, setEventHeadConfirm] = useState(false);
  const showEventHeadConf = (event) => {
    // 👇️ toggle shown state
    setEventHeadConfirm((current) => !current);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setEventDate(false);
    setEventHeadDetail(false);
  };

  const [isEventHeadDetail, setEventHeadDetail] = useState(false);
  const showEventHead = (event) => {
    // 👇️ toggle shown state
    setEventHeadDetail((current) => !current);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventDate, setEventDate] = useState(false);
  const showEventDate = (event) => {
    // 👇️ toggle shown state
    setEventDate((current) => !current);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setEventPurposeAgenda(false);
  };

  const [isEventTime, setEventTime] = useState(false);
  const showEventTime = (event) => {
    // 👇️ toggle shown state
    setEventTime((current) => !current);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventPurpose, setEventPurposeAgenda] = useState(false);
  const showEventPurpose = (event) => {
    // 👇️ toggle shown state
    setEventPurposeAgenda((current) => !current);
    setEventTime(false);
    setEventDate(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setEventVigilanceConf(false);
  };

  const [isEventVigilanceConf, setEventVigilanceConf] = useState(false);
  const showEventVigilanceConf = (event) => {
    // 👇️ toggle shown state
    setEventVigilanceConf((current) => !current);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setEventVigilance(false);
    setEventageGroup(false);
    setEventPurposeAgenda(false);
  };

  const [isEventVigilance, setEventVigilance] = useState(false);
  const showEventVigilance = (event) => {
    // 👇️ toggle shown state
    setEventVigilance((current) => !current);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setEventageGroup(false);
    setEventActivites(false);
    setEventGuestsConf(false);
  };

  const [isEventageGroup, setEventageGroup] = useState(false);
  const showEventageGroup = (event) => {
    // 👇️ toggle shown state
    setEventageGroup((current) => !current);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setEventActivites(false);
  };

  const [isEventActivites, setEventActivites] = useState(false);
  const showEventActivites = (event) => {
    // 👇️ toggle shown state
    setEventActivites((current) => !current);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setEventGuestsConf(false);
    setEventRiskCheck(false);
  };

  const [isEventGuestsConf, setEventGuestsConf] = useState(false);
  const showEventGuestsConf = (event) => {
    // 👇️ toggle shown state
    setEventGuestsConf((current) => !current);
    setEventRiskCheck(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventRiskCheck, setEventRiskCheck] = useState(false);
  const showEventRiskCheck = (event) => {
    // 👇️ toggle shown state
    setEventRiskCheck((current) => !current);
    setAddGuest2(false);
    setEventGuestProfile(false);
    setGuestProfile(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventHighRisk, setEventHighRisk] = useState(false);
  const showEventHighRisk = (event) => {
    // 👇️ toggle shown state
    setEventHighRisk((current) => !current);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventMedRisk, setEvenMedRisk] = useState(false);
  const showEventMedRisk = (event) => {
    // 👇️ toggle shown state
    setEvenMedRisk((current) => !current);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventLowRisk, setEvenLowRisk] = useState(false);
  const showEventLowRisk = (event) => {
    // 👇️ toggle shown state
    setEvenLowRisk((current) => !current);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventGuestProfile, setEventGuestProfile] = useState(false);
  const showEventGuestProfile = (event) => {
    // 👇️ toggle shown state
    setEventGuestProfile((current) => !current);
    setEventGuestSocial(false);
    setGuestSocial(false);
    setEventGuestOrg(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventGuestSocial, setEventGuestSocial] = useState(false);
  const showEventGuestSocial = (event) => {
    // 👇️ toggle shown state
    setEventGuestSocial((current) => !current);
    setEventGuestProfile(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setGuestSummary(false);
    setEventGuestSpeech(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventGuestSpeech, setEventGuestSpeech] = useState(false);
  const showEventGuestSpeech = (event) => {
    // 👇️ toggle shown state
    setEventGuestSpeech((current) => !current);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEventGuestOrg(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventGuestOrg, setEventGuestOrg] = useState(false);
  const showEventGuestOrg = (event) => {
    // 👇️ toggle shown state
    setEventGuestOrg((current) => !current);
    setEventGuestSpeech(false);
    setEventExtParties(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventExtParties(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventExtParties, setEventExtParties] = useState(false);
  const showEventExtParties = (event) => {
    // 👇️ toggle shown state
    setEventExtParties((current) => !current);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setResearch(false);
    showGuestOutcomes(false);
    setEventResearch(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventAssessment, setEventAssessment] = useState(false);
  const showEventAssessment = (event) => {
    // 👇️ toggle shown state
    setEventAssessment((current) => !current);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isGuestSummary, setGuestSummary] = useState(false);
  const showGuestSummary = (event) => {
    // 👇️ toggle shown state
    setGuestSummary((current) => !current);
    setEventAssessment(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isGuest2, setGuest2] = useState(false);
  const showGuest2 = (event) => {
    // 👇️ toggle shown state
    setGuest2((current) => !current);
    setGuestSummary(false);
    setEventAssessment(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventPortal, setEventPortal] = useState(false);
  const showEventPortal = (event) => {
    // 👇️ toggle shown state
    setEventPortal((current) => !current);
    setIsVisible_Auditorium(false);
    setIsVisible_MeetingVenueDate(false);
    setAddGuest2(false);
    setGuestSummary(false);
    setEventAssessment(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);
    setAddGuest2(false);
    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventPortalConf, setEventPortalConf] = useState(false);
  const showEventPortalConf = (event) => {
    // 👇️ toggle shown state
    setEventPortalConf((current) => !current);
    setIsVisible_Auditorium(false);
    setIsVisible_MeetingVenueDate(false);
    setGuest2(false);
    setGuestSummary(false);
    setEventAssessment(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);

    setEventResearch((current) => !current);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isEventResearch, setEventResearch] = useState(false);
  const showEventResearch = (event) => {
    // 👇️ toggle shown state
    setEventResearch((current) => !current);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setGuestOutcomes(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isGuestOutcomes, setGuestOutcomes] = useState(false);
  const showGuestOutcomes = (event) => {
    // 👇️ toggle shown state
    setGuestOutcomes((current) => !current);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setAddGuest2(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isGuestAdd2, setAddGuest2] = useState(false);
  const showAddGuest2 = (event) => {
    // 👇️ toggle shown state
    setAddGuest2((current) => !current);
    setEventPortalConf(false);
    setEventPortal(false);
    setEventPortalDetails(false);
    setIsVisible_Auditorium(false);
    setGuestOutcomes(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventPortalConf(false);
    setEventPortalDetails(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
    setEventPortalDetails(false);
  };

  const [isEventPortalDetails, setEventPortalDetails] = useState(false);
  const showEventPortalDetails = (event) => {
    // 👇️ toggle shown state
    setEventPortalDetails((current) => !current);
    setIsVisible_Auditorium(false);
    setIsVisible_MeetingVenueDate(false);
    setAddGuest2(false);
    setEventPortal(false);
    setGuestOutcomes(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);
    setAddGuest2(false);
    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isOutsideVenueEvent, setOutsideVenueEvent] = useState(false);
  const showOutsideVenueEvent = (event) => {
    // 👇️ toggle shown state
    setOutsideVenueEvent((current) => !current);
    setEventPortalDetails(false);
    setAddGuest2(false);
    setIsVisible_Auditorium(false);
    setGuestOutcomes(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isOutsideVenueEventDetails, setOutsideVenueEventDetails] =
    useState(false);
  const showOutsideVenueEventDetails = (event) => {
    // 👇️ toggle shown state
    setOutsideVenueEventDetails((current) => !current);
    setPurchaseConf(false);
    setOutsideVenueEvent(false);
    setIsVisible_Auditorium(false);
    setEventPortalDetails(false);
    setAddGuest2(false);
    setGuestOutcomes(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isVisible_PurchaseConf, setPurchaseConf] = useState(false);
  const showPurchaseConf = (event) => {
    // 👇️ toggle shown state
    setPurchaseConf((current) => !current);
    setIsVisible_Purchase(false);
    setIisShownPurchase(false);
    setOutsideVenueEventDetails(false);
    setOutsideVenueEvent(false);
    setIsVisible_Auditorium(false);
    setEventPortalDetails(false);
    setAddGuest2(false);
    setPDHDetails(false);
    setGuestOutcomes(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isPDH, setPDHDetails] = useState(false);
  const showPDH = (event) => {
    // 👇️ toggle shown state
    setPDHDetails((current) => !current);
    setPDHinfo(false);
    setisVisible_JanitorConf(false);
    setPurchaseConf(false);
    setPurchaseConf(false);
    setOutsideVenueEventDetails(false);
    setIsVisible_Purchase(false);
    setOutsideVenueEvent(false);
    setIsVisible_Auditorium(false);
    setEventPortalDetails(false);
    setAddGuest2(false);
    setGuestOutcomes(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isPDHInfo, setPDHinfo] = useState(false);
  const showPDHInfo = (event) => {
    // 👇️ toggle shown state
    setPDHinfo((current) => !current);
    setPDHDetails(false);
    setisVisible_JanitorConf(false);
    setPurchaseConf(false);
    setPurchaseConf(false);
    setOutsideVenueEventDetails(false);
    setIsVisible_Purchase(false);
    setOutsideVenueEvent(false);
    setIsVisible_Auditorium(false);
    setEventPortalDetails(false);
    setAddGuest2(false);
    setGuestOutcomes(false);
    setEventResearch(false);
    setEventExtParties(false);
    setEventGuestOrg(false);
    setEventGuestSpeech(false);
    setEventGuestSocial(false);
    setEventGuestProfile(false);
    setEvenLowRisk(false);
    setEvenMedRisk(false);
    setEventHighRisk(false);
    setEventRiskCheck(false);
    setEventGuestsConf(false);
    setEventActivites(false);
    setEventageGroup(false);
    setEventVigilance(false);
    setEventVigilanceConf(false);
    setEventPurposeAgenda(false);
    setEventTime(false);
    setEventHeadDetail(false);
    setEventHeadConfirm(false);
    setisShownEvent(false);
    setIisShownMeeting(false);
    setIsShownBASIC(false);
    setIsShownInfo(false);
    setIisShownSchedule_event(false);
    setIisShownEventAgenda(false);
    setIisShownGroundService(false);
    setIisShownVigilence(false);
    setIisShownGuest(false);
    setIisShownAuditorium(false);
    setIisShownOutside(false);
    setIisShownPurchase(false);
    setIisShownGroundService(false);
    setIisShownMeeting_Venue(false);
    // 👇️ or simply set it to true
    //isShownBASIC(true);
    setIsVisible_Category(false);
    setSplash(false);
    setIsVisible_Applicant(false);
    setIsVisible_ApplicantEmail(false);
    setIsVisible_ApplicantMobile(false);
    setisExrernalMeeting(false);
    setisVisibleMeetingVigilance(false);
    setOutsideMeetingActivities(false);

    setIsVisible_OutsideVenue(false);
    setIsVisible_OutsideVenueDate(false);
    setIsVisible_OutsideVenueTime(false);
    setIsVisible_OutsideVenueCap(false);
    setIsVisible_OutsideVenueName(false);
    setIsVisible_OutsideVenueActivity(false);
    setIsVisible_OutsideVenue2(false);
    setIsVisible_MeetingOthers(false);
    setIsVisible_MeetingDocs(false);
    setIsVisible_MeetingVenueTime(false);
    setIsVisible_MeetingVenueCap(false);
    setIsVisible_MeetingVenueDate(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingVenue2(false);
    setIsVisible_MeetingPurpose(false);
    setIsVisible_MeetingPOCDetails(false);
    setIsVisible_MeetingPOC(false);
  };

  const [isAddAudit2, setAddAudit2] = useState(false);
  const [isAddAudit3, setAddAudit3] = useState(false);
  const [isAddAudit4, setAddAudit4] = useState(false);
  const [isAddAudit5, setAddAudit5] = useState(false);

  const addaudit2 = (e) => {
    setAddAudit2(true);
    setAddAudit3(false);
    setAddAudit4(false);
    setAddAudit5(false);
  };

  const addaudit3 = (e) => {
    setAddAudit3(true);
  };

  const addaudit4 = (e) => {
    setAddAudit4(true);
  };

  const addaudit5 = (e) => {
    setAddAudit5(true);
  };

  const [isAddspeaker2, setAddspeaker2] = useState(false);
  const [isAddspeaker3, setAddspeaker3] = useState(false);
  const [isAddspeaker4, setAddspeaker4] = useState(false);
  const [isAddspeaker5, setAddspeaker5] = useState(false);

  const addspeaker2 = (e) => {
    setAddspeaker2(true);
    setAddspeaker3(false);
    setAddspeaker4(false);
    setAddspeaker5(false);
  };

  const addspeaker3 = (e) => {
    setAddspeaker3(true);
  };

  const addspeaker4 = (e) => {
    setAddspeaker4(true);
  };

  const addspeaker5 = (e) => {
    setAddspeaker5(true);
  };

  const enableGuest = (e) => {
    if (e.target.value === "Yes") {
      setIsVisibleGuest(true);
    } else {
      setIsVisibleGuest(false);
    }
  };

  const showGuestDetails = (e) => {
    if (e.target.value === "High") {
      setIsVisible_Guest_High(true);
      setIsVisible_Guest_Med(false);
      setIsVisible_Guest_Low(false);
      setAddGuest2(false);
    } else if (e.target.value === "Low") {
      setIsVisible_Guest_Med(true);
      setIsVisible_Guest_High(false);
      setIsVisible_Guest_Low(false);
    } else if (e.target.value === "No") {
      setIsVisible_Guest_High(false);
      setIsVisible_Guest_Med(false);
      setIsVisible_Guest_Low(true);
    } else {
      setIsVisible_Guest_High(false);
      setIsVisible_Guest_Med(false);
      setIsVisible_Guest_Low(false);
    }
  };

  const showAuditDetails = (e) => {
    setIsVisible_Auditorium(true);
    setEventPortal(false);
    setEventPortalConf(false);
    setEventPortalDetails(false);
    setOutsideVenueEvent(false);
    setOutsideVenueEventDetails(false);
    setOutsideVenueEventDetails(false);
    setEventPortalDetails(false);
    isEventPortal(false);
  };

  const showPurchase = (e) => {
    setIsVisible_Purchase(true);
    setPurchaseConf(false);
    setPDHDetails(false);
    setPDHinfo(false);
  };

  const [isVisible_showSocietyOthersName, setshowSocietyOthersName] =
    useState(false);
  const showSocietyOthersName = (e) => {
    if (e.target.value === "Other") {
      setshowSocietyOthersName(true);
    } else {
      setshowSocietyOthersName(false);
    }
  };

  const enableVigilance = (e) => {
    if (e.target.value === "Yes") {
      setIsVisible_Vig(true);
    } else {
      setIsVisible_Vig(false);
    }
  };

  //const ApplicantEmail = currentUser.email;

  /*

  useEffect(() => {
    function handleScroll() {
      //const newOpacity = Math.max(0, 1 - window.scrollY / 500);
      const newOpacity = Math.min(1, window.scrollY / 500);
      setOpacity(newOpacity);
    }
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);*/

  ////console.log(currentUser?.username);

  const isAdmin = 0;
  return (
    <div>
      <div className="flex items-center justify-between gap-4 bg-indigo-600 px-12 py-3 text-white">
        <p
          className="text-sm font-medium "
          onClick={showCategory}
          style={{ cursor: "pointer" }}
        >
          📝 Request Form
        </p>
        <Link to="/">
          <button
            aria-label="Close"
            className="shrink-0 rounded-lg bg-black/10 p-1 transition hover:bg-black/20"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </Link>
      </div>

      <div className="add mx-auto max-w-screen-xl px-8 py-16 sm:px-6 lg:px-8">
        {isAdmin == "0" ? (
          <div></div>
        ) : (
          <div className="mx-auto max-w-lg text-center">
            <h1 className="text-2xl font-bold sm:text-3xl">Update Request</h1>

            <p className="mt-4 text-gray-500">Update User Request</p>
          </div>
        )}

        {/* 👇️ show elements on click */}

        <div className="mx-auto mt-8 mb-0 max-w-3xl space-y-4">
          {isAdmin == "0" ? (
            <div className="content">
              {/***********************************************************************/}
              {splash && (
                <div>
                  {/*
  <section className="">
  <div className="items-center">
    <div className="mx-auto max-w-3xl text-start">
    <span className="waveSplash">Hey 👋</span>
      <h1 className="text-3xl font-extrabold sm:text-3xl"> Welcome to the Society Engagement Approval Request Form! </h1>
      <p className="mt-4 sm:text-xl/relaxed">
      Please take a few minutes to provide us with the necessary information about your event. Your responses will help us assess the event's appropriateness and ensure that it aligns with the guidelines set by the Co-Curricular Activities Office (CCA).
      </p>

      <h3 className="mt-8 font-bold text-xl gap-1 " style={{display: 'flex', alignItems: 'center'}}> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
</svg>
 Instructions: </h3>

 <p className="mt-4 sm:text-xl/relaxed">
 <div>
 <ul style={{listStyle:'disc', marginLeft: '2vw'}}>
    <li>Please fill out the form below with accurate and complete information.</li>
    <li>Fields marked with (*) are required.</li>
    <li>You may save your progress and come back to complete the form at a later time.</li>
 </ul>
</div>
 </p>



      <h3 className="mt-8 font-bold text-xl gap-1 " style={{display: 'flex', alignItems: 'center'}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
</svg>

 Approval Process: </h3>

 <p className="mt-4 sm:text-xl/relaxed">
 <div>
 <ul style={{listStyle:'disc', marginLeft: '2vw'}}>
    <li>Once you submit the form, it will be reviewed by your designated CCA Official.</li>
    <li>Request status will updated after review.</li>
    <li>You will also receive a confirmation e-mail and updates on the status of your request.</li>
 </ul>
</div>
      </p>


      <h3 className="mt-8 font-bold text-xl gap-1 " style={{display: 'flex', alignItems: 'center'}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>


 Timelines for Review Process: </h3>

 <p className="mt-4 sm:text-xl/relaxed">
 <div>
 <ul style={{listStyle:'disc', marginLeft: '2vw'}}>
    <li>1 Day Event - 3 Days</li>
    <li>2-3 Days Event - 7 Days</li>
    <li>Event with Accommodation - 15 Days</li>
    <li>Meeting Requests - 2 Days</li>
    <li>External Events with Petitions - 7 Days</li>
    <li>Trips - 15 Days</li>
 </ul>

 <span>  </span>
</div>
 </p>


      <div className="mt-8 flex flex-wrap justify-start gap-4">
        <a
          className="btn btn-primary block w-full px-12 py-3 text-sm font-medium text-white shadow hover:bg-indigo-700 focus:outline-none focus:ring active:bg-indigo-500 sm:w-auto"
          onClick={handleBasic}
        >
          Start
        </a>

      </div>
    </div>
  </div>
</section>
*/}

                  <div className="max-w-3xl px-6 py-16 mx-auto space-y-12">
                    <article className="space-y-8 text-gray-900">
                      <div className="space-y-6">
                        <p className="waveSplash">Hey 👋</p>
                        <h1 className="text-3xl font-bold md:tracking-tight md:text-3xl splashHeading">
                          Welcome to the Society Engagement Approval Request
                          Form!
                        </h1>
                        <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center text-gray-600">
                          <div className="flex items-center md:space-x-2">
                            <img
                              src="https://res.cloudinary.com/ahbranding/image/upload/v1677941506/AHbranding/Group_1_lzsxib.png"
                              alt=""
                              className="w-6 h-6 border rounded-full bg-gray-500 border-gray-300"
                            />
                            <p className="text-sm">LUMS CCA</p>
                          </div>
                        </div>
                      </div>
                      <div className="text-gray-800">
                        <p>
                          We are excited to receive your request for event
                          approval. Please take a few minutes to provide us with
                          the necessary information about your event. Your
                          responses will help us assess the event's
                          appropriateness and ensure that it aligns with the
                          guidelines set by the Co-Curricular Activities Office
                          (CCA).
                        </p>
                      </div>
                    </article>
                    <div>
                      <div className="flex flex-wrap py-6 space-x-2 border-t border-dashed border-gray-600"></div>
                      <div className="space-y-2">
                        <h4 className="text-lg  font-semibold">Instructions</h4>
                        <ul className="ml-4 space-y-1 list-disc">
                          <li>
                            Please fill out the form below with accurate and
                            complete information.
                          </li>
                          <li>Fields marked with (*) are required.</li>
                          <li>
                            You may save your progress and come back to complete
                            the form at a later time.
                          </li>
                        </ul>
                      </div>

                      <div className="space-y-2 mt-6">
                        <h4 className="text-lg  font-semibold">
                          Approval Process
                        </h4>
                        <ul className="ml-4 space-y-1 list-disc">
                          <li>
                            Once you submit the form, it will be reviewed by
                            your designated CCA Official.
                          </li>
                          <li>Request status will updated after review.</li>
                          <li>
                            You will also receive a confirmation e-mail and
                            updates on the status of your request.
                          </li>
                        </ul>
                      </div>

                      <div className="space-y-2 mt-6">
                        <h4 className="text-lg  font-semibold">
                          Approval Timelines
                        </h4>
                        <ul className="ml-4 space-y-1 list-disc">
                          <li>1 Day Event - 3 Days</li>
                          <li>2-3 Days Event - 7 Days</li>
                          <li>Event with Accommodation - 15 Days</li>
                          <li>Meeting Requests - 2 Days</li>
                          <li>External Events with Petitions - 7 Days</li>
                          <li>Trips - 15 Days</li>
                        </ul>
                      </div>

                      <p className="mt-6">
                        We are excited to receive your request for event
                        approval. Please take a few minutes to provide us with
                        the necessary information about your event. Your
                        responses will help us assess the event's
                        appropriateness and ensure that it aligns with the
                        guidelines set by the Co-Curricular Activities Office
                        (CCA).
                      </p>
                    </div>

                    <button
                      className="btn btn-block btn-primary"
                      onClick={handleBasic}
                    >
                      Go to Form 📝
                    </button>
                  </div>
                </div>
              )}
              {isShownBASIC && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    What is the name of your Awesome Society? 👀{" "}
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Greetings! We're curious to know which society you
                    represent. Kindly select your society's name from the
                    drop-down list below. If your society's name is not listed,
                    please select "Other" and provide the name in the text field
                    that appears.
                  </p>

                  <div className="my-8">
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Select Society:
                    </span>
                    <select
                      id="status"
                      name="project"
                      //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                      //onChange={(e) => setVenue(e.target.value)}
                      className="input input-bordered w-full select"
                      onChange={showSocietyOthersName}
                    >
                      <option value="NULL">Society</option>
                      <option value="AIESEC Society">AIESEC Society</option>
                      <option value="Amnesty International">
                        Amnesty International
                      </option>
                      <option value="Dramaline Society">
                        Dramaline Society
                      </option>
                      <option value="Debating and Recitation Society">
                        Debating and Recitation Society
                      </option>
                      <option value="IEEE LUMS Student Chapter">
                        IEEE LUMS Student Chapter
                      </option>
                      <option value="LUMS Adventure Society">
                        LUMS Adventure Society
                      </option>
                      <option value="Law and Politics Society">
                        Law and Politics Society
                      </option>
                      <option value="LUMS Entreprenuerhsip Society">
                        LUMS Entreprenuerhsip Society
                      </option>
                      <option value="LUMS Literary Society">
                        LUMS Literary Society
                      </option>
                      <option value="LUMS Media Arts Society">
                        LUMS Media Arts Society
                      </option>
                      <option value="LUMS Art Club">LUMS Art Club</option>
                      <option value="LUMS Culture Society">
                        LUMS Culture Society
                      </option>
                      <option value="LUMS Religious Society">
                        LUMS Religious Society
                      </option>
                      <option value="LUMS Model United Nations">
                        LUMS Model United Nations
                      </option>
                      <option value="LUMS Enviornmental Action Forum">
                        LUMS Enviornmental Action Forum
                      </option>
                      <option value="LUMS Community Service Society">
                        LUMS Community Service Society
                      </option>
                      <option value="LUMS Daily Student Society">
                        LUMS Daily Student Society
                      </option>
                      <option value="Lums Photographic Society">
                        Lums Photographic Society
                      </option>
                      <option value="Lums Students Maths Society">
                        Lums Students Maths Society
                      </option>
                      <option value="Lums Music Society">
                        Lums Music Society
                      </option>
                      <option value="Publication at LUMS">
                        Publication at LUMS
                      </option>
                      <option value="The Economics Society">
                        The Economics Society
                      </option>
                      <option value="Sharing Analysis on Regional Ec">
                        Sharing Analysis on Regional Ec
                      </option>
                      <option value="SPADES">SPADES</option>
                      <option value="FINTRA - Finance">FINTRA - Finance</option>
                      <option value="Feministy Society">
                        Feministy Society
                      </option>
                      <option value="Lums Consultancy Group">
                        Lums Consultancy Group
                      </option>
                      <option value="Psychology society at LUMS">
                        Psychology society at LUMS
                      </option>
                      <option value="LUMS Student Professional Accountancy">
                        LUMS Student Professional Accountancy
                      </option>
                      <option value="Index the Design Society">
                        Index the Design Society
                      </option>
                      <option value="LUMS Culinary Society">
                        LUMS Culinary Society
                      </option>
                      <option value="Hum Aahang Society">
                        Hum Aahang Society
                      </option>
                      <option value="LUMS Society of Chemical Sciences & Engineering ">
                        LUMS Society of Chemical Sciences & Engineering{" "}
                      </option>
                      <option value="Animals and Welfare Society">
                        Animals and Welfare Society
                      </option>
                      <option value="Rizq">Rizq</option>
                      <option value="Better Educational Engagement (BEE)”.">
                        Better Educational Engagement (BEE)”.
                      </option>
                      <option value="LUMS Data Science Society (LDSS)">
                        LUMS Data Science Society (LDSS)
                      </option>
                      <option value="Riayat LUMS Chapter (Riayat)">
                        Riayat LUMS Chapter (Riayat)
                      </option>
                      <option value="LUMS Dance Society">
                        LUMS Dance Society
                      </option>
                      <option value="Radio at LUMS">Radio at LUMS</option>
                      <option value="LUMS Women in Computing">
                        LUMS Women in Computing
                      </option>
                      <option value="Anime and Manga at LUMS">
                        Anime and Manga at LUMS
                      </option>
                      <option value="LUMS Historical Research Society">
                        LUMS Historical Research Society
                      </option>
                      <option value="SCOLAR Lahore HUB">
                        SCOLAR Lahore HUB
                      </option>
                      <option value="LUMS Policy Research Initiative">
                        LUMS Policy Research Initiative
                      </option>
                      <option value="Toastmaster">Toastmaster</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  {isVisible_showSocietyOthersName && (
                    <div className="my-8">
                      <div>
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Please Specify Name:
                        </span>
                        <input
                          type="text"
                          placeholder="Other Society Name"
                          onChange={(e) => set_ApplicantName(e.target.value)}
                          value={applicant_name}
                          className="input input-bordered w-full mb-2"
                        />
                      </div>

                      <div>
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Please Specify Society Email:
                        </span>
                        <input
                          type="text"
                          placeholder="Official Society Email"
                          onChange={(e) => set_ApplicantName(e.target.value)}
                          value={applicant_name}
                          className="input input-bordered w-full mb-2"
                        />
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <button onClick={showApplicant} className="btn btn-primary">
                      Next
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                        />
                      </svg>
                    </button>

                    <div>
                      <div>
                        <span> Step 1 of 15 | (0% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="0"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisible_Applicant && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    What's your name, Superstar? 🙃{" "}
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Hi there! We'd love to know who's filling out this form.
                    Kindly provide us with your full name in the field below.
                    Don't be shy, we promise we won't reveal your secret
                    identity to anyone (unless you're a superhero, of course)!
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }} className="my-4">
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Applicant Name
                      </span>
                      <input
                        type="text"
                        placeholder="John Doe"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Applicant Designation
                      </span>
                      <input
                        type="text"
                        placeholder="eg: Vice President"
                        className="input input-bordered w-full mb-2"
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={handleBasic}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showApplicantMobile}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 2 of 15 | (10% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="10"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisible_ApplicantMobile && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    What's your mobile number? We promise not to call you during
                    your favourite TV show. 📲
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We need a way to get in touch with you, so please provide us
                    with your mobile number in the field below. Rest assured, we
                    won't bombard you with spammy texts or prank calls. Pinky
                    swear!
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Applicant Contact
                      </span>
                      <input
                        type="number"
                        placeholder="090078601"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showApplicant}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showApplicantEmail}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 3 of 15 | (20% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="20"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisible_ApplicantEmail && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    What's your official email address? We promise not to send
                    you cat memes. 💬
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Kindly provide us with your official email address in the
                    field below. This will help us communicate with you
                    regarding your event proposal and keep you in the loop on
                    all the exciting updates. Don't worry, we won't spam your
                    inbox with kitten GIFs (unless you ask us to)!
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Applicant Email{" "}
                        <small style={{ color: "#00000057" }}> LUMS ID </small>
                      </span>
                      <input
                        type="number"
                        placeholder="johndoe@lums.edu.pk.pk"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showApplicantMobile}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showCategory}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 4 of 15 | (25% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="25"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisible_Category && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Alright, spill the beans! What category does your epic event
                    fall under? Choose from the options below. 😤
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We want to know what type of event you're planning. Is it a
                    rockin' concert, a thrilling competition, a dazzling
                    cultural show, do you want to have an internal meeting, go
                    to competition or is a adventurous trip? Choose the category
                    that best fits your request from the drop-down list below.
                  </p>

                  <div className="my-8">
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Select Type:
                    </span>
                    <select
                      id="status"
                      name="project"
                      onChange={showRelevent}
                      //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                      //onChange={(e) => setVenue(e.target.value)}
                      className="input input-bordered w-full select"
                    >
                      <option value="null">Request Type</option>
                      <option value="Event">Event</option>
                      <option value="External Competition">
                        External Competition
                      </option>
                      <option value="Meeting">Meeting</option>
                      <option value="Meeting_Ext">
                        Meeting with External Participants
                      </option>
                      <option value="Trip" disabled>
                        Trip
                      </option>
                    </select>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showApplicantEmail}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      {isVisible_Event ? (
                        <button
                          onClick={handleEvent}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>
                      ) : null}

                      {isVisible_Meeting ? (
                        <button
                          onClick={handleMeeting}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>
                      ) : null}

                      {isVisible_Meeting_Ext ? (
                        <button
                          onClick={handleExternalMeeting}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>
                      ) : null}
                    </div>
                    <div>
                      <div>
                        <span> Step 5 of 15 | (30% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="30"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/***********************************************************************/}
              {isShownEvent && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Alright, let's give your epic event a name! What's the title
                    of your event? 🙌
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Every great event needs a catchy name, right? Let us know
                    what you've got in mind for your event title. Whether it's
                    "Rockin' Beats Music Fest," "Debating Super Slam
                    Championship," or "Cultural Extravaganza," we're eager to
                    hear your creative ideas. So spill the beans and tell us the
                    name of your event in the text field below!
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Event Name
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Enter Event Title. eg: SpellBee"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showCategory}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showEventHeadConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 6 of 45 | (38% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="38"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventHeadConfirm && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Are you the Event Head? 🤌
                  </h1>

                  <p className="mt-4 text-gray-500">
                    As the Event Head, you'll be leading the charge in planning
                    and organizing this epic event! Please let us know if you'll
                    be taking on this role by selecting "Yes" or "No" from the
                    options below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showEventHead}>
                        <label className="label cursor-pointer">
                          <span className="label-text">🔴 No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>
                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showEventDate}
                        >
                          <span className="label-text">🟢 Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={handleEvent}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={handleEvent}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 7 of 45 | (40% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="40"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventHeadDetail && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Please provide the details of the Event Head. 🔰
                  </h1>

                  <p className="mt-4 text-gray-500">
                    If you're not the Event Head, please provide us with the
                    name, mobile number, and email address of the person who
                    will be leading the event. This will help us to communicate
                    and coordinate effectively for a successful event.
                  </p>

                  <h2
                    className="auditoriumBadge"
                    style={{ margin: "3vh 0 0 0" }}
                  >
                    {" "}
                    Event Head 1{" "}
                  </h2>
                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Event Head Name:
                      </span>
                      <input
                        type="text"
                        placeholder="Event Head Name"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Mobile Number:
                      </span>
                      <input
                        type="number"
                        placeholder="Event Head Mobile"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Email Address:
                      </span>
                      <input
                        type="email"
                        placeholder="Event Head Email"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>

                    <button
                      className="btn gap-2 mt-6 addmoreAuditorium"
                      onClick={addaudit2}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Add more
                    </button>
                  </div>

                  {isAddAudit2 && (
                    <div>
                      <h2
                        className="auditoriumBadge"
                        style={{ margin: "3vh 0 0 0" }}
                      >
                        {" "}
                        Event Head 2{" "}
                      </h2>
                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full my-8">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Event Head Name:
                          </span>
                          <input
                            type="text"
                            placeholder="Event Head Name"
                            onChange={(e) => set_ApplicantName(e.target.value)}
                            value={applicant_name}
                            className="input input-bordered w-full mb-2"
                          />
                        </div>

                        <div className="w-full my-8">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Mobile Number:
                          </span>
                          <input
                            type="number"
                            placeholder="Event Head Mobile"
                            onChange={(e) => set_ApplicantName(e.target.value)}
                            value={applicant_name}
                            className="input input-bordered w-full mb-2"
                          />
                        </div>

                        <div className="w-full my-8">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Email Address:
                          </span>
                          <input
                            type="email"
                            placeholder="Event Head Email"
                            onChange={(e) => set_ApplicantName(e.target.value)}
                            value={applicant_name}
                            className="input input-bordered w-full mb-2"
                          />
                        </div>

                        <button
                          className="btn gap-2 mt-6 addmoreAuditorium"
                          onClick={addaudit3}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          Add more
                        </button>
                      </div>
                    </div>
                  )}

                  {isAddAudit3 && (
                    <div>
                      <h2
                        className="auditoriumBadge"
                        style={{ margin: "3vh 0 0 0" }}
                      >
                        {" "}
                        Event Head 3{" "}
                      </h2>
                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full my-8">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Event Head Name:
                          </span>
                          <input
                            type="text"
                            placeholder="Event Head Name"
                            onChange={(e) => set_ApplicantName(e.target.value)}
                            value={applicant_name}
                            className="input input-bordered w-full mb-2"
                          />
                        </div>

                        <div className="w-full my-8">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Mobile Number:
                          </span>
                          <input
                            type="number"
                            placeholder="Event Head Mobile"
                            onChange={(e) => set_ApplicantName(e.target.value)}
                            value={applicant_name}
                            className="input input-bordered w-full mb-2"
                          />
                        </div>

                        <div className="w-full my-8">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Email Address:
                          </span>
                          <input
                            type="email"
                            placeholder="Event Head Email"
                            onChange={(e) => set_ApplicantName(e.target.value)}
                            value={applicant_name}
                            className="input input-bordered w-full mb-2"
                          />
                        </div>

                        <button
                          className="btn gap-2 mt-6 addmoreAuditorium"
                          onClick={addaudit4}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          Add more
                        </button>
                      </div>
                    </div>
                  )}

                  {isAddAudit4 && (
                    <div>
                      <h2
                        className="auditoriumBadge"
                        style={{ margin: "3vh 0 0 0" }}
                      >
                        {" "}
                        Event Head 4{" "}
                      </h2>
                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full my-8">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Event Head Name:
                          </span>
                          <input
                            type="text"
                            placeholder="Event Head Name"
                            onChange={(e) => set_ApplicantName(e.target.value)}
                            value={applicant_name}
                            className="input input-bordered w-full mb-2"
                          />
                        </div>

                        <div className="w-full my-8">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Mobile Number:
                          </span>
                          <input
                            type="number"
                            placeholder="Event Head Mobile"
                            onChange={(e) => set_ApplicantName(e.target.value)}
                            value={applicant_name}
                            className="input input-bordered w-full mb-2"
                          />
                        </div>

                        <div className="w-full my-8">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Email Address:
                          </span>
                          <input
                            type="email"
                            placeholder="Event Head Email"
                            onChange={(e) => set_ApplicantName(e.target.value)}
                            value={applicant_name}
                            className="input input-bordered w-full mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventHeadConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showEventDate}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 8 of 45 | (42% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="42"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventDate && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    What are the dates of the event? 📆
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We need to know the dates when your epic event will be
                    taking place. Please provide us with the start and end dates
                    of the event, so we can block the dates and make necessary
                    arrangements accordingly.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Start Date
                      </span>
                      <input
                        type="date"
                        style={{ width: "100%" }}
                        placeholder="Proposed Date"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        End Date
                      </span>
                      <input
                        type="date"
                        style={{ width: "100%" }}
                        placeholder="Proposed Date"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventHeadConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showEventPurpose}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 9 of 45 | (44% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="44"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventPurpose && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    We're all ears! Tell us about the purpose and objectives of
                    your epic event. 🎯
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We're excited to know more about what you hope to achieve
                    with your event. Is it about creating awareness, promoting a
                    cause, celebrating a special occasion, or achieving specific
                    goals? Please provide us with a brief description of the
                    purpose and objectives of your event. Your input will help
                    us better understand your event and make necessary
                    arrangements accordingly.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Event Purpose & Objectives
                      </span>
                      <textarea
                        placeholder="Event Purpose & Objectives"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                        style={{ padding: "15px", height: "14vh" }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventDate}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showEventVigilanceConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 10 of 45 | (46% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="46"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventVigilanceConf && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Will there be any external participants attending the event?
                    🧑‍🤝‍🧑
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We want to make sure we have all the necessary information
                    to accommodate any external participants attending your
                    event. Please let us know if there will be any external
                    participants attending the event.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showEventageGroup}>
                        <label className="label cursor-pointer">
                          <span className="label-text">🔴 No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>
                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showEventVigilance}
                        >
                          <span className="label-text">🟢 Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventPurpose}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showMeetingPOCDetails}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 11 of 45 | (48% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="48"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventVigilance && (
                <div
                  style={{
                    display: "flex",
                    gap: "2vw",
                    flexDirection: "column",
                  }}
                  className="toColumn"
                >
                  <div className="mx-0 mb-8 max-w-3xl text-start">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Please provide the details of all external participants
                      attending the event. 🚓
                    </h1>

                    <p className="mt-4 text-gray-500">
                      If you have external participants attending your event,
                      please provide their details below.
                    </p>
                  </div>

                  <div style={{ width: "100%" }}>
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Upload a CSV File
                    </span>
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={changeHandler}
                      className="file-input file-input-bordered file-input-primary w-full "
                    />
                  </div>
                  <br />
                  <br />
                  {/* Table */}

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          {tableRows.map((rows, index) => {
                            return <th key={index}>{rows}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {values.map((value, index) => {
                          return (
                            <tr key={index}>
                              {value.map((val, i) => {
                                return <td key={i}>{val}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>CNIC</th>
                          <th>Vehicle Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* row 1 */}
                        <tr>
                          <td>{start_date}</td>
                          <td>{end_date}</td>
                          <td>{im_prefAudit}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="divider mt-8">OR Enter Manually</div>

                  {/* Audit 01*/}

                  <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                    <h2 className="auditoriumBadge"> Person 1 </h2>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Full Name
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Full Name"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={start_date}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          CNIC
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="CNIC #"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={end_date}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Vehicle Details
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "15px",
                          height: "8vh",
                        }}
                        placeholder="Vehicle Number, Type and other details"
                        onChange={(e) => setExt_Vigilance(e.target.value)}
                        value={ext_vigilance}
                        className="input input-bordered "
                      ></textarea>
                    </div>

                    <button
                      className="btn gap-2 mt-6 addmoreAuditorium"
                      onClick={addaudit2}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Add more
                    </button>
                  </div>

                  {/* Audit 02*/}
                  {isAddAudit2 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Person 1 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Full Name"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={start_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="CNIC #"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={end_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Vehicle Details
                        </span>
                        <textarea
                          style={{
                            width: "100%",
                            padding: "15px",
                            height: "8vh",
                          }}
                          placeholder="Vehicle Number, Type and other details"
                          onChange={(e) => setExt_Vigilance(e.target.value)}
                          value={ext_vigilance}
                          className="input input-bordered "
                        ></textarea>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 03*/}
                  {isAddAudit3 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Person 1 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Full Name"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={start_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="CNIC #"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={end_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Vehicle Details
                        </span>
                        <textarea
                          style={{
                            width: "100%",
                            padding: "15px",
                            height: "8vh",
                          }}
                          placeholder="Vehicle Number, Type and other details"
                          onChange={(e) => setExt_Vigilance(e.target.value)}
                          value={ext_vigilance}
                          className="input input-bordered "
                        ></textarea>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit4}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 04*/}
                  {isAddAudit4 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Person 1 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Full Name"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={start_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="CNIC #"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={end_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Vehicle Details
                        </span>
                        <textarea
                          style={{
                            width: "100%",
                            padding: "15px",
                            height: "8vh",
                          }}
                          placeholder="Vehicle Number, Type and other details"
                          onChange={(e) => setExt_Vigilance(e.target.value)}
                          value={ext_vigilance}
                          className="input input-bordered "
                        ></textarea>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit5}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 05*/}
                  {isAddAudit5 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Person 1 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Full Name"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={start_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="CNIC #"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={end_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Vehicle Details
                        </span>
                        <textarea
                          style={{
                            width: "100%",
                            padding: "15px",
                            height: "8vh",
                          }}
                          placeholder="Vehicle Number, Type and other details"
                          onChange={(e) => setExt_Vigilance(e.target.value)}
                          value={ext_vigilance}
                          className="input input-bordered "
                        ></textarea>
                      </div>
                    </div>
                  )}

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventVigilanceConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showEventageGroup}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 12 of 45 | (50% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="85"
                          max="50"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisible_MeetingOthers && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Please provide any other details or special requirements for
                    your meeting: 🔍
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Please provide an estimate of the number of attendees you
                    are expecting for the outdoor activity or event. This will
                    help us in determining the appropriate outdoor venue based
                    on the expected audience size.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Other Details
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "15px",
                          height: "10vh",
                        }}
                        placeholder="Other Detail we missed?"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showOutsideMeetingVenue}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showMeetingDocs}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 14 of 15 | (94% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="94"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventageGroup && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    We're curious! Tell us about your audience for the event. 👪
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Hey there, we want to know who's going to rock the event
                    with their presence! Are we expecting a young and vibrant
                    crowd, seasoned professionals, or a mix of different age
                    groups? Let us know the age group of your audience from the
                    options below, and it will help us plan for an epic event
                    that caters to everyone's expectations!
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Age Group:
                      </span>
                      <div className="form-control">
                        <label className="label cursor-pointer">
                          <span className="label-text">Less than 18 Years</span>
                          <input
                            type="checkbox"
                            className="checkbox checkbox-primary"
                          />
                        </label>

                        <label className="label cursor-pointer">
                          <span className="label-text">At least 18 Years</span>
                          <input
                            type="checkbox"
                            className="checkbox checkbox-primary"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventVigilanceConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showEventActivites}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 13 of 45 | (52% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="52"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventActivites && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Break it down for us! Tell us about the activities at your
                    event. ✍️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We want to know what exciting activities you have planned
                    for your event! Please provide a breakdown of the activities
                    in bullet points, so we can get a clear picture of what's in
                    store for the attendees. Feel free to provide as much detail
                    as possible, including the sequence, duration, and any
                    special highlights of each activity. We're all ears and
                    excited to hear about your event!
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Activities:
                      </span>
                      <div className="editorContainer " style={{ margin: "0" }}>
                        <ReactQuill
                          className="editor "
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder="Event Activities in Bullet Points"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventageGroup}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showEventGuestsConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 14 of 45 | (54% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="54"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventGuestsConf && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Are we expecting any special guests? 🛬
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Hey there! Is there a guest speaker, judge, or celebrity
                    invited for the event? Let us know by selecting "Yes" or
                    "No" from the options below. We're all ears to hear about
                    any special guests you're planning to have at your event!
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showEventPortal}>
                        <label className="label cursor-pointer">
                          <span className="label-text">🔴 No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>
                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showEventRiskCheck}
                        >
                          <span className="label-text">🟢 Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventActivites}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showMeetingPOCDetails}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 15 of 45 | (56% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="56"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventRiskCheck && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Risky Business! Assess the Risk Level for Inviting Guests.
                    ⚠️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Safety first! We want to make sure all attendees have a
                    blast at our event without any hiccups. Help us out by
                    assessing the risk level associated with inviting the guest
                    for your event. You can review our guest speaker approval
                    policy for guidance. Choose from the following categories:
                    "No Risk," "Low Risk," "Medium Risk," or "High Risk." Your
                    input will help us take appropriate measures to ensure a
                    successful and safe event.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Risk Status
                      </span>
                      <select
                        id="status"
                        name="project"
                        className="input input-bordered w-full select"
                        onChange={showGuestDetails}
                      >
                        <option value="NULL">Select Risk</option>
                        <option value="High">
                          High | eg: Celebrity/Main Stream Artists etc.
                        </option>
                        <option value="Medium">
                          Medium | eg: Influencer/Social media personality etc.
                        </option>
                        <option value="No">
                          Low | eg: Microfluencer/Academian etc.
                        </option>
                        <option value="None">None</option>
                      </select>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventGuestsConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      {isVisible_Guest_High ? (
                        <button
                          onClick={showEventGuestProfile}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>
                      ) : null}

                      {isVisible_Guest_Med ? (
                        <button
                          onClick={showEventMedRisk}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>
                      ) : null}

                      {isVisible_Guest_Low ? (
                        <button
                          onClick={showEventLowRisk}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>
                      ) : null}
                    </div>
                    <div>
                      <div>
                        <span> Step 16 of 45 | (58% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="58"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isEventGuestProfile ? (
                <div>
                  <div className="mx-0 mb-8 max-w-3xl text-start">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Guest Profile - Tell Us About Your VIP! 🤵
                    </h1>

                    <p className="mt-4 text-gray-500">
                      We're excited to learn more about the special guest you're
                      inviting to your event! Please provide us with some
                      details about the guest's name and a short introduction.
                      This information will help us better understand the
                      profile of the guest and ensure a warm welcome at the
                      event.
                    </p>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full my-8">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Guest Name
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="John Doe"
                          onChange={(e) => setIM_AuditDate(e.target.value)}
                          value={im_audit_date}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full my-8">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Guest Short Introduction
                        </span>
                        <textarea
                          style={{ width: "100%", padding: "10px" }}
                          placeholder="Short Introduction of Guest"
                          onChange={(e) => setIM_AuditDate(e.target.value)}
                          value={im_audit_date}
                          className="input input-bordered "
                        ></textarea>
                      </div>
                    </div>

                    <div className="trayMaster">
                      <div className="gap-2 flex">
                        <button
                          onClick={showEventRiskCheck}
                          className="btn btn-outline btn-primary"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                            />
                          </svg>
                          Back
                        </button>

                        <button
                          onClick={showEventGuestSocial}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>
                      </div>
                      <div>
                        <div>
                          <span> Step 17 of 45 | (60% Complete) </span>
                        </div>

                        <div>
                          <progress
                            className="progress progress-primary w-56"
                            value="60"
                            max="100"
                          ></progress>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isEventGuestSocial ? (
                <div>
                  <div className="mx-0 mb-8 max-w-3xl text-start">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Researching Our Guest - Let's Get to Know Them! 👀
                    </h1>

                    <p className="mt-4 text-gray-500">
                      To ensure the safety and appropriateness of our event, we
                      would like to conduct a brief research on our guest.
                      Please provide us with the social media accounts of the
                      guest, such as their Twitter handle, Instagram username,
                      Facebook page, or any other relevant accounts. This will
                      help us understand their online presence and take
                      appropriate measures to ensure a successful and safe
                      event.
                    </p>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full my-8">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Twitter Profile
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="twitter.com/"
                          onChange={(e) => setIM_AuditDate(e.target.value)}
                          value={im_audit_date}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full my-8">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Instagram Handle
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="instagram.com/"
                          onChange={(e) => setIM_AuditDate(e.target.value)}
                          value={im_audit_date}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full my-8">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Facebook Page
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="facebook.com/"
                          onChange={(e) => setIM_AuditDate(e.target.value)}
                          value={im_audit_date}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div className="trayMaster">
                      <div className="gap-2 flex">
                        <button
                          onClick={showEventGuestProfile}
                          className="btn btn-outline btn-primary"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                            />
                          </svg>
                          Back
                        </button>

                        {isVisible_Guest_High ? (
                          <button
                            onClick={showEventGuestSpeech}
                            className="btn btn-primary"
                          >
                            Next
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                              />
                            </svg>
                          </button>
                        ) : null}

                        {isVisible_Guest_Med ? (
                          <button
                            onClick={showGuestSummary}
                            className="btn btn-primary"
                          >
                            Next
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                              />
                            </svg>
                          </button>
                        ) : null}

                        {isVisible_Guest_Low ? (
                          <button
                            onClick={showGuestSummary}
                            className="btn btn-primary"
                          >
                            Next
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                              />
                            </svg>
                          </button>
                        ) : null}
                      </div>
                      <div>
                        <div>
                          <span> Step 18 of 45 | (62% Complete) </span>
                        </div>

                        <div>
                          <progress
                            className="progress progress-primary w-56"
                            value="62"
                            max="100"
                          ></progress>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isEventGuestSpeech ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Tell Us About the Guest Speaker's Speech or Talking Points
                    📜
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We're eager to learn more about what the guest speaker has
                    in store for your event! If you have a guest speaker, please
                    share the speech or the talking points they will cover
                    during their session. If there is no guest speaker, simply
                    select "N/A".
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Content of Speech
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "15px",
                          height: "8vh",
                        }}
                        placeholder="Content of Speec"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>

                    <div className="divider my-6">OR</div>

                    <div
                      className="w-full mb-8"
                      style={{
                        display: "flex",
                        gap: "2vw",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Upload File (PDF/Word/Docs File)
                      </span>
                      <input
                        type="file"
                        className="file-input file-input-bordered file-input-primary w-full max-w-xs"
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventGuestSocial}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showEventGuestOrg}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 19 of 45 | (64% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="64"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isEventGuestOrg ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Guest's Affiliations or Associations 🏢
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We're excited to learn more about the guest background and
                    connections! Are there any organizations or affiliations the
                    guest is associated with that we should know about? This
                    information can provide valuable context and insights into
                    the guest's credentials and expertise. It will also help us
                    assess risk.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Guest Affiliated Organisation
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Organisation Name"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Role at Organziation
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Guest Role"
                        onChange={(e) => setExt_AuditCap(e.target.value)}
                        value={ext_audit_cap}
                        className="input input-bordered "
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventGuestSpeech}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showEventExtParties}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 20 of 45 | (68% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="68"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isEventExtParties ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    External Consultation and Approval 👩‍💼
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We want to ensure that we are considering all necessary
                    external inputs and obtaining the required approvals for the
                    success of the event. This may involve consulting or seeking
                    approval from external parties. Please provide a list of all
                    the external parties that you have identified for
                    consultation or approval.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mt-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Title of External Party
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%", padding: "10px" }}
                        placeholder="External Parties Title"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>

                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Role(s) of External Party
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="External Parties for Consultation or Approval"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventGuestOrg}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showEventResearch}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 21 of 45 | (70% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="70"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isEventResearch ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Guest Speaker Research and Risk Assessment 👀
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We're curious to know the efforts you put into researching
                    and assessing your guest speaker for the event. Tell us
                    about the steps you took to access the guest, including any
                    sources you used or information you gathered to evaluate any
                    potential risks. We want to ensure the safety and
                    appropriateness of the guest for your event.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Guest Research
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Research Points here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventExtParties}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showGuestOutcomes}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 22 of 45 | (71% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="71"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isGuestOutcomes ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Outcome of Risk Assessment Research 🤔
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We appreciate the effort you put into researching and
                    assessing the risks associated with your guest speaker for
                    the event. Please share the outcome of your risk assessment
                    research. Did you identify any potential risks? If so, what
                    were they and how did you plan to address them? If there
                    were no risks identified, please simply state "No risks
                    identified".
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Guest Research Outcome
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Research Outcome here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventResearch}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showAddGuest2}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 23 of 45 | (73% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="73"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isGuestAdd2 ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Do We Have More Star Power? 💪
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We love your enthusiasm for adding guests to the event! Do
                    you have any more guests in mind who would be a great fit
                    for this occasion? It could be a guest speaker, a notable
                    figure, a celebrity, or anyone else who would bring an extra
                    level of excitement to the event. Let us know with a simple
                    "Yes" or "No" in the field below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showEventPortal}>
                        <label className="label cursor-pointer">
                          <span className="label-text">🔴 No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>
                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showEventRiskCheck}
                        >
                          <span className="label-text">🟢 Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showGuestOutcomes}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={ShowMeetingVenueDate}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 24 of 45 | (75% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="75"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              '
              {isEventPortalDetails ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Registration Form Design 🗃️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We would love to see your design for the registration form!
                    Please upload a file of your registration form design using
                    the attachment option below. You can use the provided sample
                    file as a reference.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div
                      className="w-full my-8"
                      style={{ display: "flex", gap: "2vw" }}
                    >
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Capacity
                      </span>
                      <input
                        type="file"
                        className="file-input file-input-bordered file-input-primary w-full max-w-xs"
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showAddGuest2}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showAuditDetails}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 25 of 45 | (76% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="76"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isEventPortal ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Registering with LUMS Portal? 🖥️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    To ensure a smooth registration process for the event, we
                    would like to know if you plan to use the LUMS registration
                    portal. The portal provides a convenient and efficient way
                    to manage registrations and payments. Please let us know if
                    you will be utilizing the LUMS registration portal by
                    selecting "Yes" or "No" in the field below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showAuditDetails}>
                        <label className="label cursor-pointer">
                          <span className="label-text">🔴 No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>
                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showEventPortalDetails}
                        >
                          <span className="label-text">🟢 Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showAddGuest2}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={ShowMeetingVenueDate}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 26 of 45 | (77% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="77"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_Auditorium ? (
                <div>
                  <div className="mx-0 mb-8 max-w-3xl text-start">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Please provide the preferred dates, timings, seating
                      capacity, and select your preferred auditorium option for
                      the event. 🏟️
                    </h1>

                    <p className="mt-4 text-gray-500">
                      If you have selected 'Yes' for booking an auditorium for
                      your meeting, please provide the preferred dates,
                      timing(s), and select your desired auditorium from the
                      options provided.
                    </p>
                  </div>

                  <div style={{ width: "100%" }}>
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Upload a CSV File
                    </span>
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={changeHandler}
                      className="file-input file-input-bordered file-input-primary w-full "
                    />
                  </div>
                  <br />
                  <br />
                  {/* Table */}

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          {tableRows.map((rows, index) => {
                            return <th key={index}>{rows}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {values.map((value, index) => {
                          return (
                            <tr key={index}>
                              {value.map((val, i) => {
                                return <td key={i}>{val}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          <th>Auditorium</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Capacity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* row 1 */}
                        <tr>
                          <td>{im_prefAudit}</td>
                          <td>{start_date}</td>
                          <td>{end_date}</td>
                          <td>{book_time}</td>
                          <td>{activity_time}</td>
                          <td>{audit_cap}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit2}</td>
                          <td>{start_date2}</td>
                          <td>{end_date2}</td>
                          <td>{book_time2}</td>
                          <td>{activity_time2}</td>
                          <td>{audit_cap2}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit3}</td>
                          <td>{start_date3}</td>
                          <td>{end_date3}</td>
                          <td>{book_time3}</td>
                          <td>{activity_time3}</td>
                          <td>{audit_cap3}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit4}</td>
                          <td>{start_date4}</td>
                          <td>{end_date4}</td>
                          <td>{book_time4}</td>
                          <td>{activity_time4}</td>
                          <td>{audit_cap4}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit5}</td>
                          <td>{start_date5}</td>
                          <td>{end_date5}</td>
                          <td>{book_time5}</td>
                          <td>{activity_time5}</td>
                          <td>{audit_cap5}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="divider mt-8">OR Enter Manually</div>

                  {/* Audit 01*/}

                  <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                    <h2 className="auditoriumBadge"> Auditorium 1 </h2>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Start Date
                        </span>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={start_date}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          End Date
                        </span>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={end_date}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Start Time
                        </span>
                        <input
                          type="time"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setAuditBookTime(e.target.value)}
                          value={book_time}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          End Time
                        </span>
                        <input
                          type="time"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setAuditActivityTime(e.target.value)}
                          value={activity_time}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Capacity
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Participant Capacity. eg: 500"
                          onChange={(e) => setAuditCap(e.target.value)}
                          value={audit_cap}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Preferred Auditorium
                        </span>
                        <select
                          id="status"
                          name="project"
                          className="input input-bordered w-full select"
                          onChange={(e) => setIM_PrefAudit(e.target.value)}
                          value={im_prefAudit}
                        >
                          <option value="NULL">Select Auditorium</option>
                          <option value="1">Auditorium 1</option>
                          <option value="2">Auditorium 2</option>
                          <option value="3">Auditorium 3</option>
                          <option value="4">Auditorium 4</option>
                        </select>
                      </div>
                    </div>

                    <button
                      className="btn gap-2 mt-6 addmoreAuditorium"
                      onClick={addaudit2}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Add more
                    </button>
                  </div>

                  {/* Audit 02*/}
                  {isAddAudit2 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Auditorium 2</h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate2(e.target.value)}
                            value={start_date2}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate2(e.target.value)}
                            value={end_date2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime2(e.target.value)}
                            value={book_time2}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime2(e.target.value)
                            }
                            value={activity_time2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap2(e.target.value)}
                            value={audit_cap2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit2(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 03*/}
                  {isAddAudit3 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Auditorium 3 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate3(e.target.value)}
                            value={start_date3}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate3(e.target.value)}
                            value={end_date3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime3(e.target.value)}
                            value={book_time3}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime3(e.target.value)
                            }
                            value={activity_time3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap3(e.target.value)}
                            value={audit_cap3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit3(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit4}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 04*/}
                  {isAddAudit4 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Auditorium 4 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate4(e.target.value)}
                            value={start_date4}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate4(e.target.value)}
                            value={end_date4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime4(e.target.value)}
                            value={book_time4}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime4(e.target.value)
                            }
                            value={activity_time4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap4(e.target.value)}
                            value={audit_cap4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit4(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit5}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 05*/}
                  {isAddAudit5 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Auditorium 5 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate5(e.target.value)}
                            value={start_date5}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate5(e.target.value)}
                            value={end_date5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime5(e.target.value)}
                            value={book_time5}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime5(e.target.value)
                            }
                            value={activity_time5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap5(e.target.value)}
                            value={audit_cap5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit5(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                        style={{ display: "none" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventPortal}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showOutsideVenueEvent}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 27 of 45 | (79% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="79"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isOutsideVenueEvent ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Want to take your meeting outdoors? Let's make it a breath
                    of fresh air! 🏞️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    If you're tired of the same old meeting rooms and want to
                    take your event outdoors, let us know by selecting "Yes"
                    below. If not, you can select "No" and we'll make sure to
                    arrange for an indoor venue.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showPurchaseConf}>
                        <label className="label cursor-pointer">
                          <span className="label-text">
                            No, an indoor venue is fine.
                          </span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showOutsideVenueEventDetails}
                        >
                          <span className="label-text">
                            Yes, let's go outdoors!
                          </span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showAuditDetails}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showAuditDetails}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 28 of 45 | (80% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="80"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isOutsideVenueEventDetails ? (
                <div
                  style={{
                    display: "flex",
                    gap: "2vw",
                    flexDirection: "column",
                  }}
                  className="toColumn"
                >
                  <div className="mx-0 mb-8 max-w-3xl text-start">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Please provide the preferred dates, timings, seating
                      capacity, and select your preferred venue option for the
                      meeting. 🏞️
                    </h1>

                    <p className="mt-4 text-gray-500">
                      If you have selected 'Yes' for booking an auditorium for
                      your meeting, please provide the preferred dates,
                      timing(s), and select your desired auditorium from the
                      options provided.
                    </p>
                  </div>

                  <div style={{ width: "100%" }}>
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Upload a CSV File
                    </span>
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={changeHandler}
                      className="file-input file-input-bordered file-input-primary w-full "
                    />
                  </div>
                  <br />
                  <br />
                  {/* Table */}

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          {tableRows.map((rows, index) => {
                            return <th key={index}>{rows}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {values.map((value, index) => {
                          return (
                            <tr key={index}>
                              {value.map((val, i) => {
                                return <td key={i}>{val}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          <th>Auditorium</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Capacity</th>
                          <th>Activites</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* row 1 */}
                        <tr>
                          <td>{im_prefAudit}</td>
                          <td>{start_date}</td>
                          <td>{end_date}</td>
                          <td>{book_time}</td>
                          <td>{activity_time}</td>
                          <td>{audit_cap}</td>
                          <td>{ext_vigilance}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit2}</td>
                          <td>{start_date2}</td>
                          <td>{end_date2}</td>
                          <td>{book_time2}</td>
                          <td>{activity_time2}</td>
                          <td>{audit_cap2}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit3}</td>
                          <td>{start_date3}</td>
                          <td>{end_date3}</td>
                          <td>{book_time3}</td>
                          <td>{activity_time3}</td>
                          <td>{audit_cap3}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit4}</td>
                          <td>{start_date4}</td>
                          <td>{end_date4}</td>
                          <td>{book_time4}</td>
                          <td>{activity_time4}</td>
                          <td>{audit_cap4}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit5}</td>
                          <td>{start_date5}</td>
                          <td>{end_date5}</td>
                          <td>{book_time5}</td>
                          <td>{activity_time5}</td>
                          <td>{audit_cap5}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="divider mt-8">OR Enter Manually</div>

                  {/* Audit 01*/}

                  <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                    <h2 className="auditoriumBadge"> Venue 1 </h2>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Start Date
                        </span>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={start_date}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          End Date
                        </span>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={end_date}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Activities
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "15px",
                          height: "8vh",
                        }}
                        placeholder="Extensive List of Activities"
                        onChange={(e) => setExt_Vigilance(e.target.value)}
                        value={ext_vigilance}
                        className="input input-bordered "
                      ></textarea>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Start Time
                        </span>
                        <input
                          type="time"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setAuditBookTime(e.target.value)}
                          value={book_time}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          End Time
                        </span>
                        <input
                          type="time"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setAuditActivityTime(e.target.value)}
                          value={activity_time}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Capacity
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Participant Capacity. eg: 500"
                          onChange={(e) => setAuditCap(e.target.value)}
                          value={audit_cap}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Preferred Venue
                        </span>
                        <select
                          id="status"
                          name="project"
                          className="input input-bordered w-full select"
                          onChange={(e) => setIM_PrefAudit(e.target.value)}
                          value={im_prefAudit}
                        >
                          <option value="NULL">Select Venue</option>
                          <option value="Courtyard">Courtyard</option>
                          <option value="Residence Hostels">
                            Residence Hostels
                          </option>
                          <option value="Hostel M1 & M2 Block">
                            Hostel M1 & M2 Block
                          </option>
                          <option value="Food Court">Food Court</option>
                        </select>
                      </div>
                    </div>

                    <button
                      className="btn gap-2 mt-6 addmoreAuditorium"
                      onClick={addaudit2}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Add more
                    </button>
                  </div>

                  {/* Audit 02*/}
                  {isAddAudit2 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Venue 2</h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate2(e.target.value)}
                            value={start_date2}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate2(e.target.value)}
                            value={end_date2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime2(e.target.value)}
                            value={book_time2}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime2(e.target.value)
                            }
                            value={activity_time2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap2(e.target.value)}
                            value={audit_cap2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit2(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 03*/}
                  {isAddAudit3 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Venue 3 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate3(e.target.value)}
                            value={start_date3}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate3(e.target.value)}
                            value={end_date3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime3(e.target.value)}
                            value={book_time3}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime3(e.target.value)
                            }
                            value={activity_time3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap3(e.target.value)}
                            value={audit_cap3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit3(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit4}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 04*/}
                  {isAddAudit4 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Venue 4 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate4(e.target.value)}
                            value={start_date4}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate4(e.target.value)}
                            value={end_date4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime4(e.target.value)}
                            value={book_time4}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime4(e.target.value)
                            }
                            value={activity_time4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap4(e.target.value)}
                            value={audit_cap4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit4(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit5}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 05*/}
                  {isAddAudit5 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Venue 5 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate5(e.target.value)}
                            value={start_date5}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate5(e.target.value)}
                            value={end_date5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime5(e.target.value)}
                            value={book_time5}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime5(e.target.value)
                            }
                            value={activity_time5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap5(e.target.value)}
                            value={audit_cap5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit5(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                        style={{ display: "none" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showAuditDetails}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showPurchaseConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 29 of 45 | (81% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="81"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_PurchaseConf ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Any special purchase requests for the event? 🛒
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We want to make sure your event is a smashing success, and
                    that means taking care of all the details. If you have any
                    special purchase requests to enhance the event experience,
                    such as decorations, equipment, rental service, printing,
                    artist performance, or other items, please let us know.
                    We're here to help bring your vision to life, so feel free
                    to share your purchase requests in the field below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showPDH}>
                        <label className="label cursor-pointer">
                          <span className="label-text">🔴 No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>
                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showPurchase}
                        >
                          <span className="label-text">🟢 Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showOutsideVenueEventDetails}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showMeetingPOCDetails}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 30 of 45 | (82% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="82"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_Purchase ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Any special purchase requests for the event? 🛒
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We want to make sure your event is a smashing success, and
                    that means taking care of all the details. If you have any
                    special purchase requests to enhance the event experience,
                    such as decorations, equipment, rental service, printing,
                    artist performance, or other items, please let us know.
                    We're here to help bring your vision to life, so feel free
                    to share your purchase requests in the field below.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <h2 className="auditoriumBadge"> Services/Rentals </h2>
                    <div className="w-full">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Delivery Date
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Full Name"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={start_date}
                        className="input input-bordered "
                      />
                    </div>

                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Details
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Complete list of details here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>

                    <h2 className="auditoriumBadge"> External Material </h2>
                    <div className="w-full">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Delivery Date
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Full Name"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={start_date}
                        className="input input-bordered "
                      />
                    </div>

                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Details
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Complete list of details here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>

                    <h2 className="auditoriumBadge"> Printing </h2>
                    <div className="w-full">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Delivery Date
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Full Name"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={start_date}
                        className="input input-bordered "
                      />
                    </div>

                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Details
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Complete list of details here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showPurchaseConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button onClick={showPDH} className="btn btn-primary">
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 31 of 45 | (84% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="84"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isPDH ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Do you require PDC or EDH service for your event? 🍽️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We want to ensure that all your event needs are met, so
                    please let us know if you require any additional services
                    such as Pepsi Dining Center (PDC) or Executive Dining Hall
                    (EDH) services. We're here to assist you. Simply select
                    "Yes" or "No" below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showJanitorConf}>
                        <label className="label cursor-pointer">
                          <span className="label-text">🔴 No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showPDHInfo}
                        >
                          <span className="label-text">🟢 Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showPurchaseConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showMeetingPOCDetails}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 32 of 45 | (88% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="44"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isPDHInfo ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Can you please provide the details of the service you
                    require? 🍴
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We would like to know more about the specific service.
                    Please provide as much detail as possible in the text field
                    below.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        EDH Form
                      </span>

                      <Editor
                        apiKey="9cakwre8ru12iuaid8x3hga161qx4wzv6rmm6e4b5lliab5z"
                        cloudChannel="5-stable"
                        disabled={false}
                        initialValue="PDC FOrm"
                        inline={false}
                        onEditorChange={set_RTF}
                        plugins=""
                        tagName="div"
                        textareaName=""
                        //value=""
                        outputFormat="html"
                        init={{
                          height: 500,
                          language: "en",
                          //menubar: false,
                          menu: {
                            format: {
                              title: "Format",
                              items:
                                "bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align | forecolor backcolor | removeformat",
                            },
                          },
                          plugins: [
                            "advlist lists autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                        }}
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showPDH}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showJanitorConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 33 of 45 | (89% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="89"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_JanitorConf ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Do you require the Janitorial or Ground Men service for your
                    event? 🧹
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Hey there! We want to make sure your event is clean and
                    well-maintained, so we're checking if you require Janitorial
                    or Ground Men service. This service can help with cleaning,
                    maintenance, and upkeep of the event venue, ensuring a
                    pleasant experience for all attendees. Please let us know if
                    you would like to avail this service by selecting "Yes" or
                    "No" below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showSecurityConf}>
                        <label className="label cursor-pointer">
                          <span className="label-text">No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showJanitor}
                        >
                          <span className="label-text">Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showPDHInfo}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showGuestOutcomes}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 34 of 45 | (90% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="90"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_Janitor ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Do you require the Janitorial or Ground Men service for your
                    event? 🧹
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Hey there! We want to make sure your event is clean and
                    well-maintained, so we're checking if you require Janitorial
                    or Ground Men service. This service can help with cleaning,
                    maintenance, and upkeep of the event venue, ensuring a
                    pleasant experience for all attendees. Please let us know if
                    you would like to avail this service by selecting "Yes" or
                    "No" below.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Janitorial Services
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Service Details here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showJanitorConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showSecurityConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 35 of 45 | (91% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="91"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_SecurityConf ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    {" "}
                    Do you require Security Staff service for your event? 🚔
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Safety first! We want to make sure your event is secure and
                    well-protected, so we're checking if you require Security
                    Staff service. Our security personnel can help maintain
                    order, manage access, and ensure the safety of all
                    attendees. Please let us know if you would like to avail of
                    this service by selecting "Yes" or "No" below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div
                        className="form-control"
                        onClick={showElectricalConf}
                      >
                        <label className="label cursor-pointer">
                          <span className="label-text">No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showSecurity}
                        >
                          <span className="label-text">Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showJanitor}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showGuestOutcomes}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 36 of 45 | (94% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="94"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_Security ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Can you please provide the details of the service you
                    require? 👮
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We would like to know more about the specific service.
                    Please provide as much detail as possible in the text field
                    below.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Security Services
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Service Details here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showSecurityConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showElectricalConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 37 of 45 | (95% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="95"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_ElectricalConf ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    {" "}
                    Do you require Electrical service for your event? 🔦
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We want to make sure your event is well-lit, so we're
                    checking if you require electrical services. Our technicians
                    can help maintain, manage, access, and ensure the safety of
                    all participants. Please let us know if you would like to
                    avail of this service by selecting "Yes" or "No" below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showPlumberConf}>
                        <label className="label cursor-pointer">
                          <span className="label-text">No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showElectrical}
                        >
                          <span className="label-text">Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showSecurity}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showGuestOutcomes}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 38 of 45 | (96% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="96"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_Electrical ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Can you please provide the details of the service you
                    require? ⚡
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We would like to know more about the specific service.
                    Please provide as much detail as possible in the text field
                    below.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Electrical Services
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Service Details here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showElectricalConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showPlumberConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 39 of 45 | (97% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="97"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_PlumberConf ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    {" "}
                    Do you require Plumber service for your event? 🧑‍🔧
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Need water connections or plumbing services for your event?
                    We've got you covered! Our GAS department can help with
                    tasks such as setting up temporary water connections. Please
                    let us know if you would like to avail of this service by
                    selecting "Yes" or "No" below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showWelderConf}>
                        <label className="label cursor-pointer">
                          <span className="label-text">No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showPlumber}
                        >
                          <span className="label-text">Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showElectrical}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showGuestOutcomes}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 40 of 45 | (98% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="98"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_Plumber ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Can you please provide the details of the service you
                    require? 🪠
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We would like to know more about the specific service.
                    Please provide as much detail as possible in the text field
                    below.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Plumbing Services
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Service Details here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showPlumberConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showWelderConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 41 of 45 | (98% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="98"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_WelderConf ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    {" "}
                    Do you require Welder service for your event? 🥽
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Need some welding work done for your event? We've got you
                    covered! Our GAS department can provide welding services for
                    stage setup, decorations, or any other requirements you may
                    have. Please let us know if you would like to avail of this
                    service by selecting "Yes" or "No" below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showLaboursConf}>
                        <label className="label cursor-pointer">
                          <span className="label-text">No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showWelder}
                        >
                          <span className="label-text">Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showPlumber}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showGuestOutcomes}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 42 of 45 | (98% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="98"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_Welder ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Can you please provide the details of the service you
                    require? 🎇
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We would like to know more about the specific service.
                    Please provide as much detail as possible in the text field
                    below.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Welding Services
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Service Details here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showWelderConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showLaboursConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 43 of 45 | (98% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="98"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_LaboursConf ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    {" "}
                    Do you require Labour service for your event? 👷
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Need some extra hands to assist with setup or teardown of
                    your event? Our GAS department can provide some skilled
                    labourers to help with tasks such as lifting, moving, and
                    setting up equipment or furniture. Please let us know if you
                    would like to avail of this service by selecting "Yes" or
                    "No" below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div
                        className="form-control"
                        onClick={showCarpentersConf}
                      >
                        <label className="label cursor-pointer">
                          <span className="label-text">No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showLabours}
                        >
                          <span className="label-text">Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showWelder}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showGuestOutcomes}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 44 of 45 | (98% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="98"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_Labour ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Can you please provide the details of the service you
                    require? 👷‍♀️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We would like to know more about the specific service.
                    Please provide as much detail as possible in the text field
                    below.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Labour Services
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Service Details here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showLaboursConf}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showCarpentersConf}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 44 of 4 | 5(98% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="98"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_CarpenterConf ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    {" "}
                    Do you require Carpenter service for your event? 🪚
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Got some carpentry needs for your event? We've got the
                    expertise! Our GAS Department can provide services such as
                    stage setup, booth construction, or custom installations.
                    Please let us know if you would like to avail of this
                    service by selecting "Yes" or "No" below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={handleSuccess}>
                        <label className="label cursor-pointer">
                          <span className="label-text">No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showCarpenter}
                        >
                          <span className="label-text">Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showLabours}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={handleSuccess}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 45 of 45 | (99% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="99"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_Carpenter ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Can you please provide the details of the service you
                    require? 👷‍♀️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We would like to know more about the specific service.
                    Please provide as much detail as possible in the text field
                    below.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      gap: "2vw",
                      flexDirection: "column",
                    }}
                    className="toColumn"
                  >
                    <div className="w-full mb-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Carpenter Services
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          height: "10vh",
                        }}
                        placeholder="Service Details here.."
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showEventExtParties}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        className="btn btn-primary"
                        onClick={handleSuccess}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 45 of 15 | (99% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="70"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isShownSchedule_event && (
                <div className="ScheduleEvent">
                  <div className="mx-auto max-w-lg text-center">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Add Request
                    </h1>

                    <p className="mt-4 text-gray-500">
                      Enter necessary details to submit your request.
                    </p>
                  </div>

                  <h3 className="text-lg font-semibold my-4">
                    {" "}
                    3. 🗓️ Schedule Details{" "}
                  </h3>
                  <div style={{ margin: "0" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1vw",
                      }}
                    >
                      <div>
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Select Start Date
                        </span>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={start_date}
                          className="input input-bordered "
                        />
                      </div>

                      <div>
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Select End Date
                        </span>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={end_date}
                          className="input input-bordered "
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2vw",
                      margin: "4vh 0",
                    }}
                  >
                    <button
                      onClick={handleEventAgenda}
                      className="btn btn-primary"
                    >
                      Next
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                        />
                      </svg>
                    </button>

                    <button
                      onClick={handleInfo}
                      className="btn btn-primary btn-outline backBtn"
                      style={{ background: "none", border: "none" }}
                    >
                      Back
                    </button>

                    <div>
                      <div>
                        <span> Step 3 of 10 | (30% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="30"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownEventAgenda && (
                <div className="EventAgenda">
                  <div className="mx-auto max-w-lg text-center">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Add Request
                    </h1>

                    <p className="mt-4 text-gray-500">
                      Enter necessary details to submit your request.
                    </p>
                  </div>

                  <h3 className="text-lg font-semibold my-4">
                    {" "}
                    4. 🚩 Event Agenda{" "}
                  </h3>
                  <div>
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Purpose & Objectives
                    </span>
                    <textarea
                      className="textarea textarea-bordered"
                      style={{ width: "100%" }}
                      onChange={(e) => setEventPurpose(e.target.value)}
                      placeholder="Purpose & Objectives"
                    >
                      {purpose}
                    </textarea>
                  </div>

                  <div>
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Audience Age Group
                    </span>
                    <select
                      id="status"
                      name="status"
                      onChange={(e) => setAgeGroup(e.target.value)}
                      value={ageGroup}
                      className="input input-bordered w-full select"
                    >
                      <option value="null"> </option>
                      <option value="<18">Less than 18 Years</option>
                      <option value="18-24">18 to 24 Years</option>
                      <option value=">24">24 Years & Above</option>
                    </select>
                  </div>

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Event Activities
                  </span>
                  <div className="editorContainer " style={{ margin: "0" }}>
                    <ReactQuill
                      className="editor "
                      theme="snow"
                      value={value}
                      onChange={setValue}
                      placeholder="Event Activities in Bullet Points"
                    />
                  </div>

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    {" "}
                    Portal Link{" "}
                  </span>
                  <input
                    type="text"
                    placeholder="Portal Link"
                    onChange={(e) => setPortalLink(e.target.value)}
                    value={portalLink}
                    className="input input-bordered w-full mb-2"
                  />

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require External Audience
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={enableVigilance}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Vigilance Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Vig ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2vw",
                        margin: "4vh 0",
                      }}
                    >
                      <div>
                        <button
                          onClick={handleVigilance}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>

                        <button
                          onClick={handleSchedule_event}
                          className="btn btn-primary btn-outline backBtn"
                          style={{ background: "none", border: "none" }}
                        >
                          Back
                        </button>

                        <progress
                          className="progress progress-primary w-56"
                          value="25"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2vw",
                          margin: "4vh 0",
                        }}
                      >
                        <button
                          onClick={handleGuest}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>

                        <button
                          onClick={handleSchedule_event}
                          className="btn btn-primary btn-outline backBtn"
                          style={{ background: "none", border: "none" }}
                        >
                          Back
                        </button>

                        <div>
                          <div>
                            <span> Step 4 of 10 | (40% Complete) </span>
                          </div>

                          <div>
                            <progress
                              className="progress progress-primary w-56"
                              value="40"
                              max="100"
                            ></progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isShownVigilence && (
                <div className="Vigilance">
                  <div className="mx-auto max-w-lg text-center">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Add Request
                    </h1>

                    <p className="mt-4 text-gray-500">
                      Enter necessary details to submit your request.
                    </p>
                  </div>

                  <h3 className="text-lg font-semibold my-4">
                    {" "}
                    5. 👯 Vigilance Details{" "}
                  </h3>
                  <div>
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Purpose & Objectives
                    </span>
                    <textarea
                      className="textarea textarea-bordered"
                      style={{ width: "100%" }}
                      onChange={(e) => setVigilence(e.target.value)}
                      placeholder="Vigilance Details"
                    >
                      {vigilenceDetails}
                    </textarea>
                  </div>

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Guest Speaker
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={enableGuest}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Guest Speaker Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisibleGuest ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2vw",
                          margin: "4vh 0",
                        }}
                      >
                        <button
                          onClick={handleGuest}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>

                        <button
                          onClick={handleEventAgenda}
                          className="btn btn-primary btn-outline backBtn"
                          style={{ background: "none", border: "none" }}
                        >
                          Back
                        </button>

                        <progress
                          className="progress progress-primary w-56"
                          value="30"
                          max="100"
                        ></progress>

                        <div>
                          <div>
                            <span> Step 5 of 10 | (50% Complete) </span>
                          </div>

                          <div>
                            <progress
                              className="progress progress-primary w-56"
                              value="50"
                              max="100"
                            ></progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2vw",
                          margin: "4vh 0",
                        }}
                      >
                        <button
                          onClick={handleAuditorium}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>

                        <button
                          onClick={handleEventAgenda}
                          className="btn btn-primary btn-outline backBtn"
                          style={{ background: "none", border: "none" }}
                        >
                          Back
                        </button>

                        <div>
                          <div>
                            <span> Step 5 of 10 | (50% Complete) </span>
                          </div>

                          <div>
                            <progress
                              className="progress progress-primary w-56"
                              value="50"
                              max="100"
                            ></progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isShownGuest && (
                <div className="Vigilance">
                  <div className="mx-auto max-w-lg text-center">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Add Request
                    </h1>

                    <p className="mt-4 text-gray-500">
                      Enter necessary details to submit your request.
                    </p>
                  </div>

                  <h3 className="text-lg font-semibold my-4">
                    {" "}
                    6. 🧑‍💼 Guest Details{" "}
                  </h3>

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Guest Type
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showGuestDetails}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Guest Status</option>
                    <option value="High">Celebrity</option>
                    <option value="Low">Microfluencer</option>
                    <option value="No">Academian</option>
                  </select>

                  {isVisible_Guest_High ? (
                    <div style={{ marginTop: "3vh" }}>
                      <div style={{ margin: "3vh 0" }}>
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Guest Name
                        </span>
                        <input
                          type="text"
                          placeholder="Guest Name"
                          onChange={(e) => setGuestName(e.target.value)}
                          value={guestName}
                          className="input input-bordered w-full mb-2"
                        />

                        <div>
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Guest Profile
                          </span>
                          <textarea
                            className="textarea textarea-bordered"
                            style={{ width: "100%" }}
                            onChange={(e) => setGuestProfile(e.target.value)}
                            placeholder="Guest Profile"
                          >
                            {guestProfile}
                          </textarea>
                        </div>

                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Guest LinkedIn / Instagram Account
                        </span>
                        <input
                          type="text"
                          placeholder="Guest Social Account"
                          onChange={(e) => setGuestSocial(e.target.value)}
                          value={guestSocial}
                          className="input input-bordered w-full mb-2"
                        />

                        <div style={{ opacity }}>
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Content of Speech
                          </span>
                          <textarea
                            className="textarea textarea-bordered"
                            style={{ width: "100%" }}
                            onChange={(e) => setProject(e.target.value)}
                            placeholder="Speech Content"
                          >
                            {project}
                          </textarea>
                        </div>

                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Org/Company Associated
                        </span>
                        <input
                          type="text"
                          placeholder="Organisation Associated"
                          onChange={(e) => setNature(e.target.value)}
                          value={nature}
                          className="input input-bordered w-full mb-2"
                        />

                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          External Consultants
                        </span>
                        <input
                          type="text"
                          placeholder="External Consultants"
                          onChange={(e) => setexternalConst(e.target.value)}
                          value={externalConst}
                          className="input input-bordered w-full mb-2"
                        />

                        <div>
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Research Details
                          </span>
                          <textarea
                            className="textarea textarea-bordered"
                            style={{ width: "100%" }}
                            onChange={(e) => setResearch(e.target.value)}
                            placeholder="Speech Content"
                          >
                            {research}
                          </textarea>
                        </div>

                        <div>
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Risk Assessment Outcome
                          </span>
                          <textarea
                            className="textarea textarea-bordered"
                            style={{ width: "100%" }}
                            onChange={(e) => setRAO(e.target.value)}
                            placeholder="Speech Content"
                          >
                            {riskass}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {isVisible_Guest_Med ? (
                    <div style={{ marginTop: "3vh" }}>
                      <div style={{ marginTop: "3vh" }}>
                        <div style={{ margin: "3vh 0" }}>
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Guest Name
                          </span>
                          <input
                            type="text"
                            placeholder="Guest Name"
                            onChange={(e) => setGuestName(e.target.value)}
                            value={guestName}
                            className="input input-bordered w-full mb-2"
                          />

                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Guest Profile
                            </span>
                            <textarea
                              className="textarea textarea-bordered"
                              style={{ width: "100%" }}
                              onChange={(e) => setGuestProfile(e.target.value)}
                              placeholder="Guest Profile"
                            >
                              {guestProfile}
                            </textarea>
                          </div>

                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Guest LinkedIn / Instagram Account
                          </span>
                          <input
                            type="text"
                            placeholder="Guest Social Account"
                            onChange={(e) => setGuestSocial(e.target.value)}
                            value={guestSocial}
                            className="input input-bordered w-full mb-2"
                          />

                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Talk Summary
                            </span>
                            <textarea
                              className="textarea textarea-bordered"
                              style={{ width: "100%" }}
                              onChange={(e) => setTalksummary(e.target.value)}
                              placeholder="Talk Summary"
                            >
                              {talksummary}
                            </textarea>
                          </div>

                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Talk Points
                            </span>
                            <textarea
                              className="textarea textarea-bordered"
                              style={{ width: "100%" }}
                              onChange={(e) => setTalkpoints(e.target.value)}
                              placeholder="Talk Points"
                            >
                              {talkpoints}
                            </textarea>
                          </div>

                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Research Details
                            </span>
                            <textarea
                              className="textarea textarea-bordered"
                              style={{ width: "100%" }}
                              onChange={(e) => setResearch(e.target.value)}
                              placeholder="Speech Content"
                            >
                              {research}
                            </textarea>
                          </div>

                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Risk Assessment Outcome
                            </span>
                            <textarea
                              className="textarea textarea-bordered"
                              style={{ width: "100%" }}
                              onChange={(e) => setRAO(e.target.value)}
                              placeholder="Speech Content"
                            >
                              {riskass}
                            </textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {isVisible_Guest_Low ? (
                    <div style={{ marginTop: "3vh" }}>
                      <div style={{ marginTop: "3vh" }}>
                        <div style={{ margin: "3vh 0" }}>
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Guest Name
                          </span>
                          <input
                            type="text"
                            placeholder="Guest Name"
                            onChange={(e) => setGuestName(e.target.value)}
                            value={guestName}
                            className="input input-bordered w-full mb-2"
                          />

                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Guest Profile
                            </span>
                            <textarea
                              className="textarea textarea-bordered"
                              style={{ width: "100%" }}
                              onChange={(e) => setGuestProfile(e.target.value)}
                              placeholder="Guest Profile"
                            >
                              {guestProfile}
                            </textarea>
                          </div>

                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Guest LinkedIn / Instagram Account
                          </span>
                          <input
                            type="text"
                            placeholder="Guest Social Account"
                            onChange={(e) => setGuestSocial(e.target.value)}
                            value={guestSocial}
                            className="input input-bordered w-full mb-2"
                          />

                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Talk Summary
                            </span>
                            <textarea
                              className="textarea textarea-bordered"
                              style={{ width: "100%" }}
                              onChange={(e) => setTalksummary(e.target.value)}
                              placeholder="Talk Summary"
                            >
                              {talksummary}
                            </textarea>
                          </div>

                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Talk Points
                            </span>
                            <textarea
                              className="textarea textarea-bordered"
                              style={{ width: "100%" }}
                              onChange={(e) => setTalkpoints(e.target.value)}
                              placeholder="Talk Points"
                            >
                              {talkpoints}
                            </textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2vw",
                        margin: "4vh 0",
                      }}
                    >
                      <button
                        onClick={handleAuditorium}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>

                      <button
                        onClick={handleEventAgenda}
                        className="btn btn-primary btn-outline backBtn"
                        style={{ background: "none", border: "none" }}
                      >
                        Back
                      </button>

                      <div>
                        <div>
                          <span> Step 6 of 20 | (35% Complete) </span>
                        </div>

                        <div>
                          <progress
                            className="progress progress-primary w-56"
                            value="35"
                            max="100"
                          ></progress>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownAuditorium && (
                <div className="Vigilance">
                  <div className="mx-auto max-w-lg text-center">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Add Request
                    </h1>

                    <p className="mt-4 text-gray-500">
                      Enter necessary details to submit your request.
                    </p>
                  </div>

                  <h3 className="text-lg font-semibold my-4">
                    {" "}
                    7. 🏟️ Auditorium Details{" "}
                  </h3>

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Auditorium?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showAuditDetails}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Auditorium Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Auditorium ? (
                    <div>
                      <div style={{ margin: "3vh 0" }}>
                        <div>
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Select Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditDate(e.target.value)}
                            value={audit_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div style={{ margin: "3vh 0" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1vw",
                          }}
                        >
                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Select Book Time
                            </span>
                            <input
                              type="time"
                              style={{ width: "100%" }}
                              placeholder="Proposed Date"
                              onChange={(e) => setAuditBookTime(e.target.value)}
                              value={book_time}
                              className="input input-bordered "
                            />
                          </div>

                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Select Activity Time
                            </span>
                            <input
                              type="time"
                              style={{ width: "100%" }}
                              placeholder="Proposed Date"
                              onChange={(e) =>
                                setAuditActivityTime(e.target.value)
                              }
                              value={activity_time}
                              className="input input-bordered "
                            />
                          </div>
                        </div>
                      </div>

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Capacity
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Capacity"
                        onChange={(e) => setAuditCap(e.target.value)}
                        value={audit_cap}
                        className="input input-bordered w-full mb-2"
                      />

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Preferred Auditorium
                      </span>
                      <select
                        id="status"
                        name="project"
                        className="input input-bordered w-full select"
                        onChange={(e) => setPrefAudit(e.target.value)}
                      >
                        <option value="NULL">Select Auditorium</option>
                        <option value="1">Auditorium 1</option>
                        <option value="2">Auditorium 2</option>
                        <option value="3">Auditorium 3</option>
                        <option value="4">Auditorium 4</option>
                      </select>

                      <div className="divider mt-8">OR</div>

                      <div style={{ width: "100%" }}>
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Upload a CSV File
                        </span>
                        <input
                          type="file"
                          name="file"
                          accept=".csv"
                          onChange={changeHandler}
                          className="file-input file-input-bordered file-input-primary w-full "
                        />
                      </div>
                      <br />
                      <br />
                      {/* Table */}

                      <div className="overflow-x-auto">
                        <table className="table w-full">
                          {/* head */}
                          <thead>
                            <tr>
                              {tableRows.map((rows, index) => {
                                return <th key={index}>{rows}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {values.map((value, index) => {
                              return (
                                <tr key={index}>
                                  {value.map((val, i) => {
                                    return <td key={i}>{val}</td>;
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}

                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2vw",
                        margin: "4vh 0",
                      }}
                    >
                      <button
                        onClick={handleOutside}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>

                      <button
                        onClick={handleGuest}
                        className="btn btn-primary btn-outline backBtn"
                        style={{ background: "none", border: "none" }}
                      >
                        Back
                      </button>

                      <div>
                        <div>
                          <span> Step 7 of 10 | (70% Complete) </span>
                        </div>

                        <div>
                          <progress
                            className="progress progress-primary w-56"
                            value="70"
                            max="100"
                          ></progress>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownOutside && (
                <div className="Outside">
                  <div className="mx-auto max-w-lg text-center">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Add Request
                    </h1>

                    <p className="mt-4 text-gray-500">
                      Enter necessary details to submit your request.
                    </p>
                  </div>

                  <h3 className="text-lg font-semibold my-4">
                    {" "}
                    8. 🏕️ Outside Venue Details{" "}
                  </h3>

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Outside Venue?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showOutsideMeetingVenue}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Outside Venue Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_OutsideVenue ? (
                    <div>
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Activity
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Activity Name"
                        onChange={(e) => setOutsideActivity(e.target.value)}
                        value={outsideActivity}
                        className="input input-bordered w-full mb-2"
                      />

                      <div style={{ margin: "3vh 0" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1vw",
                          }}
                        >
                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Select Book Date
                            </span>
                            <input
                              type="date"
                              style={{ width: "100%" }}
                              placeholder="Date"
                              onChange={(e) => setOutsideDate(e.target.value)}
                              value={outsideDate}
                              className="input input-bordered "
                            />
                          </div>

                          <div>
                            <span
                              className="label-text"
                              style={{ lineHeight: "3rem" }}
                            >
                              Select Activity Time
                            </span>
                            <input
                              type="time"
                              style={{ width: "100%" }}
                              placeholder="Time"
                              onChange={(e) => setOutsideTime(e.target.value)}
                              value={outsideTime}
                              className="input input-bordered "
                            />
                          </div>
                        </div>
                      </div>

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Capacity
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Capacity"
                        onChange={(e) => setOutsideCap(e.target.value)}
                        value={outsideCap}
                        className="input input-bordered w-full mb-2"
                      />

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Venue
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Preferred Venue"
                        onChange={(e) => setOutsideVenue(e.target.value)}
                        value={outsideVenue}
                        className="input input-bordered w-full mb-2"
                      />

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Alternate Venue
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Alternate Venue"
                        onChange={(e) => setAltOutsideVenue(e.target.value)}
                        value={alt_outsideVenue}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>
                  ) : null}

                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2vw",
                        margin: "4vh 0",
                      }}
                    >
                      <button
                        onClick={handlePurchase}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>

                      <button
                        onClick={handleAuditorium}
                        className="btn btn-primary btn-outline backBtn"
                        style={{ background: "none", border: "none" }}
                      >
                        Back
                      </button>

                      <div>
                        <div>
                          <span> Step 8 of 10 | (80% Complete) </span>
                        </div>

                        <div>
                          <progress
                            className="progress progress-primary w-56"
                            value="80"
                            max="100"
                          ></progress>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownPurchase && (
                <div className="Outside">
                  <div className="mx-auto max-w-lg text-center">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Add Request
                    </h1>

                    <p className="mt-4 text-gray-500">
                      Enter necessary details to submit your request.
                    </p>
                  </div>

                  <h3 className="text-lg font-semibold my-4">
                    {" "}
                    9. 🛒 Purchase Details{" "}
                  </h3>

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Purchase?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showPurchase}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Purchase Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Purchase ? (
                    <div>
                      <div>
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Service / Rental Details
                        </span>
                        <textarea
                          className="textarea textarea-bordered"
                          style={{ width: "100%" }}
                          onChange={(e) => setPurchaseDetails(e.target.value)}
                          placeholder="Purchase Details"
                        >
                          {purchaseDetails}
                        </textarea>

                        <div>
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Delivery Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setPurchaseDate(e.target.value)}
                            value={purchaseDate}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div>
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          External Material
                        </span>
                        <textarea
                          className="textarea textarea-bordered"
                          style={{ width: "100%" }}
                          onChange={(e) => setPurchaseDetails(e.target.value)}
                          placeholder="Purchase Details"
                        >
                          {purchaseDetails}
                        </textarea>

                        <div>
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Delivery Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setPurchaseDate(e.target.value)}
                            value={purchaseDate}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div>
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Printing
                        </span>
                        <textarea
                          className="textarea textarea-bordered"
                          style={{ width: "100%" }}
                          onChange={(e) => setPurchaseDetails(e.target.value)}
                          placeholder="Purchase Details"
                        >
                          {purchaseDetails}
                        </textarea>

                        <div>
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Delivery Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setPurchaseDate(e.target.value)}
                            value={purchaseDate}
                            className="input input-bordered "
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Procurement?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showProcure}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Procurement Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Procure ? (
                    <div style={{ margin: "3vh 0" }}>
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Upload PDC Form
                      </span>

                      <Editor
                        apiKey="9cakwre8ru12iuaid8x3hga161qx4wzv6rmm6e4b5lliab5z"
                        cloudChannel="5-stable"
                        disabled={false}
                        //initialValue={rtf}
                        inline={false}
                        onEditorChange={set_RTF}
                        plugins=""
                        tagName="div"
                        textareaName=""
                        toolbar="checklist"
                        value={rtf}
                        outputFormat="html"
                        init={{
                          height: 500,
                          language: "en",
                          //menubar: false,
                          menu: {
                            format: {
                              title: "Format",
                              items:
                                "checklist | bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align | forecolor backcolor | removeformat",
                            },
                          },
                          plugins: [
                            "advlist lists checklist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                        }}
                      />
                    </div>
                  ) : null}

                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2vw",
                        margin: "4vh 0",
                      }}
                    >
                      <button
                        onClick={handleGroundServices}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>

                      <button
                        onClick={handleOutside}
                        className="btn btn-primary btn-outline backBtn"
                        style={{ background: "none", border: "none" }}
                      >
                        Back
                      </button>

                      <div>
                        <div>
                          <span> Step 9 of 10 | (90% Complete) </span>
                        </div>

                        <div>
                          <progress
                            className="progress progress-primary w-56"
                            value="90"
                            max="100"
                          ></progress>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownGroundService && (
                <div className="Outside">
                  <div className="mx-auto max-w-lg text-center">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Add Request
                    </h1>

                    <p className="mt-4 text-gray-500">
                      Enter necessary details to submit your request.
                    </p>
                  </div>

                  <h3 className="text-lg font-semibold my-4">
                    {" "}
                    10. 👷🏾‍♂️ Ground Services{" "}
                  </h3>

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Janitorial Services?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showJanitor}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Janitorial Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Janitor ? (
                    <div>
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Services Details
                      </span>
                      <textarea
                        className="textarea textarea-bordered"
                        style={{ width: "100%" }}
                        onChange={(e) => setJanitorialService(e.target.value)}
                        placeholder="Services Details"
                      >
                        {janitorialService}
                      </textarea>
                    </div>
                  ) : null}

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Security Services?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showSecurity}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Security Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Security ? (
                    <div>
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Services Details
                      </span>
                      <textarea
                        className="textarea textarea-bordered"
                        style={{ width: "100%" }}
                        onChange={(e) => setSecurity(e.target.value)}
                        placeholder="Services Details"
                      >
                        {security}
                      </textarea>
                    </div>
                  ) : null}

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Electrical Services?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showElectrical}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Electrical Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Electrical ? (
                    <div>
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Services Details
                      </span>
                      <textarea
                        className="textarea textarea-bordered"
                        style={{ width: "100%" }}
                        onChange={(e) => setElectrical(e.target.value)}
                        placeholder="Services Details"
                      >
                        {electricals}
                      </textarea>
                    </div>
                  ) : null}

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Plumber Services?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showPlumber}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Plumber Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Plumber ? (
                    <div>
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Services Details
                      </span>
                      <textarea
                        className="textarea textarea-bordered"
                        style={{ width: "100%" }}
                        onChange={(e) => setPlumber(e.target.value)}
                        placeholder="Services Details"
                      >
                        {plumbers}
                      </textarea>
                    </div>
                  ) : null}

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Welding Services?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showWelder}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Plumber Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Welder ? (
                    <div>
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Services Details
                      </span>
                      <textarea
                        className="textarea textarea-bordered"
                        style={{ width: "100%" }}
                        onChange={(e) => setWelder(e.target.value)}
                        placeholder="Services Details"
                      >
                        {welders}
                      </textarea>
                    </div>
                  ) : null}

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Carpenter Services?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showCarpenter}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Carpenter Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Carpenter ? (
                    <div>
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Services Details
                      </span>
                      <textarea
                        className="textarea textarea-bordered"
                        style={{ width: "100%" }}
                        onChange={(e) => setCarpenter(e.target.value)}
                        placeholder="Services Details"
                      >
                        {carpenters}
                      </textarea>
                    </div>
                  ) : null}

                  <span className="label-text" style={{ lineHeight: "3rem" }}>
                    Do you require Labour Services?
                  </span>
                  <select
                    id="status"
                    name="project"
                    onChange={showLabours}
                    //onChange={e => { this.setVenue(e.target.value); showRelevent()}}
                    //onChange={(e) => setVenue(e.target.value)}
                    className="input input-bordered w-full select"
                  >
                    <option value="NULL">Select Labour Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  {isVisible_Labour ? (
                    <div>
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Services Details
                      </span>
                      <textarea
                        className="textarea textarea-bordered"
                        style={{ width: "100%" }}
                        onChange={(e) => setLabour(e.target.value)}
                        placeholder="Services Details"
                      >
                        {labours}
                      </textarea>
                    </div>
                  ) : null}

                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2vw",
                        margin: "4vh 0",
                      }}
                    >
                      <button
                        onClick={handleSuccess}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>

                      <button
                        onClick={handlePurchase}
                        className="btn btn-primary btn-outline backBtn"
                        style={{ background: "none", border: "none" }}
                      >
                        Back
                      </button>

                      <div>
                        <div>
                          <span> Step 10 of 10 | (98% Complete) </span>
                        </div>

                        <div>
                          <progress
                            className="progress progress-primary w-56"
                            value="98"
                            max="100"
                          ></progress>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownMeeting && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    What's the name of the meeting? Don't worry, we won't tell
                    your patron if it's called "Snack Time". 🤭🍿
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We'd love to know the name of the meeting. Is it a
                    brainstorming session, a team huddle, or something else
                    entirely? Feel free to get creative with the name, even if
                    it's "Snack Time" or "Nap Break" (we won't tell your patron,
                    promise!). Share the meeting name in the field below and let
                    us know what's cookin'!
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Meeting Title
                      </span>
                      <input
                        type="text"
                        placeholder="Joshua Meetup"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showCategory}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showMeetingPurpose}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 6 of 15 | (35% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="35"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isExrernalMeeting && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    What's the name of the external meeting? Don't worry, we
                    won't tell your patron if it's called "Snack Time". 🤭🍿
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We'd love to know the name of the meeting. Is it a
                    brainstorming session, a team huddle, or something else
                    entirely? Feel free to get creative with the name, even if
                    it's "Snack Time" or "Nap Break" (we won't tell your patron,
                    promise!). Share the meeting name in the field below and let
                    us know what's cookin'!
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Meeting Title
                      </span>
                      <input
                        type="text"
                        placeholder="Joshua Meetup"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showCategory}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      {isExrernalMeeting ? (
                        <button
                          onClick={showMeetingVigilance}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={showMeetingPurpose}
                          className="btn btn-primary"
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                    <div>
                      <div>
                        <span> Step 6 of 15 | (35% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="35"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownMeetingPurpose && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Tell us about the purpose and objectives of your event. Give
                    us the lowdown! 🗺️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We want to know the ins and outs of your meeting's purpose
                    and objectives. What's the agenda? Is it a team
                    brainstorming session, a progress update, a strategy
                    planning discussion or a fun GBM? Share the purpose and
                    objectives of your meeting in the field below. Feel free to
                    provide as much detail as you can, so we can better
                    understand what you're aiming to achieve!
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Meeting Purpose
                      </span>
                      <textarea
                        placeholder="Meeting Purpose & Agenda"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                        style={{ padding: "15px", height: "14vh" }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={handleMeeting}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showMeetingPOC}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 7 of 15 | (42% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="42"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownMeetingPOC && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Are you also the designated point of contact for the
                    meeting? You're the go-to person, after all! 😳
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Are you also the designated point of contact for the
                    meeting? As the go-to person, we will be communicating with
                    you and you'll be responsible for coordinating and
                    communicating with others. Let us know if you're the point
                    of contact for the meeting by selecting "Yes" or "No" from
                    the options below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div
                        className="form-control"
                        onClick={showMeetingPOCDetails}
                      >
                        <label className="label cursor-pointer">
                          <span className="label-text">🔴 No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>
                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={handleMeeting_Venue}
                        >
                          <span className="label-text">🟢 Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showMeetingPurpose}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showMeetingPOCDetails}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 8 of 15 | (49% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="49"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownMeetingPOCDetails && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    If you're not the point of contact, who's the lucky person?
                    We'll need their name, mobile number, and e-mail address! 🕵️‍♂️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    If you're not the designated point of contact for the
                    meeting, we'd love to know who's taking on that role. Please
                    provide the name, mobile number, and e-mail address of the
                    lucky person who we will be coordinating with regarding the
                    meeting in the field below. We appreciate your cooperation
                    in providing this information!
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Point of Contact Name:
                      </span>
                      <input
                        type="text"
                        placeholder="POC Full Name"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Mobile Number:
                      </span>
                      <input
                        type="number"
                        placeholder="POC Mobile"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Email Address:
                      </span>
                      <input
                        type="email"
                        placeholder="POC Email"
                        onChange={(e) => set_ApplicantName(e.target.value)}
                        value={applicant_name}
                        className="input input-bordered w-full mb-2"
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showMeetingPOC}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={handleMeeting_Venue}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 9 of 15 | (56% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="56"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownMeeting_Venue && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Do you need to book an auditorium for the meeting? 🏟️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    If your meeting requires an auditorium, we'd love to know.
                    Please let us know if you need to book an auditorium for
                    your meeting. Select 'Yes' or 'No' from the options below.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div
                        className="form-control"
                        onClick={showOutsideMeetingVenue}
                      >
                        <label className="label cursor-pointer">
                          <span className="label-text">🔴 No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>
                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={ShowMeetingVenueDate}
                        >
                          <span className="label-text">🟢 Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showMeetingPOCDetails}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={ShowMeetingVenueDate}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 10 of 15 | (53% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="53"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownMeetingVenueDate && (
                <div>
                  <div className="mx-0 mb-8 max-w-3xl text-start">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Please provide the preferred dates, timings, seating
                      capacity, and select your preferred auditorium option for
                      the meeting. 🏟️
                    </h1>

                    <p className="mt-4 text-gray-500">
                      If you have selected 'Yes' for booking an auditorium for
                      your meeting, please provide the preferred dates,
                      timing(s), and select your desired auditorium from the
                      options provided.
                    </p>
                  </div>

                  <div style={{ width: "100%" }}>
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Upload a CSV File
                    </span>
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={changeHandler}
                      className="file-input file-input-bordered file-input-primary w-full "
                    />
                  </div>
                  <br />
                  <br />
                  {/* Table */}

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          {tableRows.map((rows, index) => {
                            return <th key={index}>{rows}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {values.map((value, index) => {
                          return (
                            <tr key={index}>
                              {value.map((val, i) => {
                                return <td key={i}>{val}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          <th>Auditorium</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Capacity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* row 1 */}
                        <tr>
                          <td>{im_prefAudit}</td>
                          <td>{start_date}</td>
                          <td>{end_date}</td>
                          <td>{book_time}</td>
                          <td>{activity_time}</td>
                          <td>{audit_cap}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit2}</td>
                          <td>{start_date2}</td>
                          <td>{end_date2}</td>
                          <td>{book_time2}</td>
                          <td>{activity_time2}</td>
                          <td>{audit_cap2}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit3}</td>
                          <td>{start_date3}</td>
                          <td>{end_date3}</td>
                          <td>{book_time3}</td>
                          <td>{activity_time3}</td>
                          <td>{audit_cap3}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit4}</td>
                          <td>{start_date4}</td>
                          <td>{end_date4}</td>
                          <td>{book_time4}</td>
                          <td>{activity_time4}</td>
                          <td>{audit_cap4}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit5}</td>
                          <td>{start_date5}</td>
                          <td>{end_date5}</td>
                          <td>{book_time5}</td>
                          <td>{activity_time5}</td>
                          <td>{audit_cap5}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="divider mt-8">OR Enter Manually</div>

                  {/* Audit 01*/}

                  <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                    <h2 className="auditoriumBadge"> Auditorium 1 </h2>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Start Date
                        </span>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={start_date}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          End Date
                        </span>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={end_date}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Start Time
                        </span>
                        <input
                          type="time"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setAuditBookTime(e.target.value)}
                          value={book_time}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          End Time
                        </span>
                        <input
                          type="time"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setAuditActivityTime(e.target.value)}
                          value={activity_time}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Capacity
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Participant Capacity. eg: 500"
                          onChange={(e) => setAuditCap(e.target.value)}
                          value={audit_cap}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Preferred Auditorium
                        </span>
                        <select
                          id="status"
                          name="project"
                          className="input input-bordered w-full select"
                          onChange={(e) => setIM_PrefAudit(e.target.value)}
                          value={im_prefAudit}
                        >
                          <option value="NULL">Select Auditorium</option>
                          <option value="1">Auditorium 1</option>
                          <option value="2">Auditorium 2</option>
                          <option value="3">Auditorium 3</option>
                          <option value="4">Auditorium 4</option>
                        </select>
                      </div>
                    </div>

                    <button
                      className="btn gap-2 mt-6 addmoreAuditorium"
                      onClick={addaudit2}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Add more
                    </button>
                  </div>

                  {/* Audit 02*/}
                  {isAddAudit2 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Auditorium 2</h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate2(e.target.value)}
                            value={start_date2}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate2(e.target.value)}
                            value={end_date2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime2(e.target.value)}
                            value={book_time2}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime2(e.target.value)
                            }
                            value={activity_time2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap2(e.target.value)}
                            value={audit_cap2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit2(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 03*/}
                  {isAddAudit3 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Auditorium 3 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate3(e.target.value)}
                            value={start_date3}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate3(e.target.value)}
                            value={end_date3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime3(e.target.value)}
                            value={book_time3}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime3(e.target.value)
                            }
                            value={activity_time3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap3(e.target.value)}
                            value={audit_cap3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit3(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit4}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 04*/}
                  {isAddAudit4 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Auditorium 4 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate4(e.target.value)}
                            value={start_date4}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate4(e.target.value)}
                            value={end_date4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime4(e.target.value)}
                            value={book_time4}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime4(e.target.value)
                            }
                            value={activity_time4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap4(e.target.value)}
                            value={audit_cap4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit4(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit5}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 05*/}
                  {isAddAudit5 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Auditorium 5 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate5(e.target.value)}
                            value={start_date5}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate5(e.target.value)}
                            value={end_date5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime5(e.target.value)}
                            value={book_time5}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime5(e.target.value)
                            }
                            value={activity_time5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap5(e.target.value)}
                            value={audit_cap5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit5(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                        style={{ display: "none" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={handleMeeting_Venue}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showOutsideMeetingVenue}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 11 of 15 | (70% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="56"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownMeetingVenueTime && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    What are the preferred timing(s) for the meeting? ⏰
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Please let us know the preferred timing(s) for your meeting.
                    You can enter one or multiple timing options in the field
                    below.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Start Time
                      </span>
                      <input
                        type="time"
                        style={{ width: "100%" }}
                        placeholder="Proposed Date"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        End Time
                      </span>
                      <input
                        type="time"
                        style={{ width: "100%" }}
                        placeholder="Proposed Date"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={ShowMeetingVenueDate}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={ShowMeetingVenueCap}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 12 of 15 | (60% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="60"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownMeetingVenueCap && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    What should be the seating capacity of the auditorium for
                    your meeting? ⛔
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Please let us know the preferred timing(s) for your meeting.
                    You can enter one or multiple timing options in the field
                    below.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Capacity
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Participant Capacity. eg: 500"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={ShowMeetingVenueTime}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={ShowMeetingVenueName}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 13 of 15 | (65% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="65"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownMeetingVenueName && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Please select your preferred auditorium from the options
                    below for your meeting: 📌
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We have multiple auditoriums available for booking. Please
                    select your preferred auditorium from the list below that
                    best suits your meeting requirements.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Preferred Auditorium
                      </span>
                      <select
                        id="status"
                        name="project"
                        className="input input-bordered w-full select"
                        onChange={(e) => setIM_PrefAudit(e.target.value)}
                      >
                        <option value="NULL">Select Auditorium</option>
                        <option value="1">Auditorium 1</option>
                        <option value="2">Auditorium 2</option>
                        <option value="3">Auditorium 3</option>
                        <option value="4">Auditorium 4</option>
                      </select>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={ShowMeetingVenueCap}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={handleMeeting_Venue2}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 14 of 15 | (70% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="70"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShowOutsideMeetingVenue ? (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Want to take your meeting outdoors? Let's make it a breath
                    of fresh air! 🏞️
                  </h1>

                  <p className="mt-4 text-gray-500">
                    If you're tired of the same old meeting rooms and want to
                    take your meeting outdoors, let us know by selecting "Yes"
                    below. If not, you can select "No" and we'll make sure to
                    arrange for an indoor venue.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showOtherMeeting}>
                        <label className="label cursor-pointer">
                          <span className="label-text">
                            No, an indoor venue is fine.
                          </span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={showOutsideVenueActivities}
                        >
                          <span className="label-text">
                            Yes, let's go outdoors!
                          </span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={ShowMeetingVenueDate}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={ShowMeetingVenueDate}
                        className="btn btn-primary"
                        style={{ opacity: "0" }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 12 of 15 | (77% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="77"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {isVisible_OutsideMeetingActivities && (
                <div
                  style={{
                    display: "flex",
                    gap: "2vw",
                    flexDirection: "column",
                  }}
                  className="toColumn"
                >
                  <div className="mx-0 mb-8 max-w-3xl text-start">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Please provide the preferred dates, timings, seating
                      capacity, and select your preferred venue option for the
                      meeting. 🏞️
                    </h1>

                    <p className="mt-4 text-gray-500">
                      If you have selected 'Yes' for booking an auditorium for
                      your meeting, please provide the preferred dates,
                      timing(s), and select your desired auditorium from the
                      options provided.
                    </p>
                  </div>

                  <div style={{ width: "100%" }}>
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Upload a CSV File
                    </span>
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={changeHandler}
                      className="file-input file-input-bordered file-input-primary w-full "
                    />
                  </div>
                  <br />
                  <br />
                  {/* Table */}

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          {tableRows.map((rows, index) => {
                            return <th key={index}>{rows}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {values.map((value, index) => {
                          return (
                            <tr key={index}>
                              {value.map((val, i) => {
                                return <td key={i}>{val}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          <th>Auditorium</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Capacity</th>
                          <th>Activites</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* row 1 */}
                        <tr>
                          <td>{im_prefAudit}</td>
                          <td>{start_date}</td>
                          <td>{end_date}</td>
                          <td>{book_time}</td>
                          <td>{activity_time}</td>
                          <td>{audit_cap}</td>
                          <td>{ext_vigilance}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit2}</td>
                          <td>{start_date2}</td>
                          <td>{end_date2}</td>
                          <td>{book_time2}</td>
                          <td>{activity_time2}</td>
                          <td>{audit_cap2}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit3}</td>
                          <td>{start_date3}</td>
                          <td>{end_date3}</td>
                          <td>{book_time3}</td>
                          <td>{activity_time3}</td>
                          <td>{audit_cap3}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit4}</td>
                          <td>{start_date4}</td>
                          <td>{end_date4}</td>
                          <td>{book_time4}</td>
                          <td>{activity_time4}</td>
                          <td>{audit_cap4}</td>
                        </tr>

                        <tr>
                          <td>{im_prefAudit5}</td>
                          <td>{start_date5}</td>
                          <td>{end_date5}</td>
                          <td>{book_time5}</td>
                          <td>{activity_time5}</td>
                          <td>{audit_cap5}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="divider mt-8">OR Enter Manually</div>

                  {/* Audit 01*/}

                  <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                    <h2 className="auditoriumBadge"> Venue 1 </h2>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Start Date
                        </span>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={start_date}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          End Date
                        </span>
                        <input
                          type="date"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={end_date}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Activities
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "15px",
                          height: "8vh",
                        }}
                        placeholder="Extensive List of Activities"
                        onChange={(e) => setExt_Vigilance(e.target.value)}
                        value={ext_vigilance}
                        className="input input-bordered "
                      ></textarea>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Start Time
                        </span>
                        <input
                          type="time"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setAuditBookTime(e.target.value)}
                          value={book_time}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          End Time
                        </span>
                        <input
                          type="time"
                          style={{ width: "100%" }}
                          placeholder="Proposed Date"
                          onChange={(e) => setAuditActivityTime(e.target.value)}
                          value={activity_time}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Capacity
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Participant Capacity. eg: 500"
                          onChange={(e) => setAuditCap(e.target.value)}
                          value={audit_cap}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Preferred Venue
                        </span>
                        <select
                          id="status"
                          name="project"
                          className="input input-bordered w-full select"
                          onChange={(e) => setIM_PrefAudit(e.target.value)}
                          value={im_prefAudit}
                        >
                          <option value="NULL">Select Venue</option>
                          <option value="Courtyard">Courtyard</option>
                          <option value="Residence Hostels">
                            Residence Hostels
                          </option>
                          <option value="Hostel M1 & M2 Block">
                            Hostel M1 & M2 Block
                          </option>
                          <option value="Food Court">Food Court</option>
                        </select>
                      </div>
                    </div>

                    <button
                      className="btn gap-2 mt-6 addmoreAuditorium"
                      onClick={addaudit2}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Add more
                    </button>
                  </div>

                  {/* Audit 02*/}
                  {isAddAudit2 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Venue 2</h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate2(e.target.value)}
                            value={start_date2}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate2(e.target.value)}
                            value={end_date2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime2(e.target.value)}
                            value={book_time2}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime2(e.target.value)
                            }
                            value={activity_time2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap2(e.target.value)}
                            value={audit_cap2}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit2(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 03*/}
                  {isAddAudit3 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Venue 3 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate3(e.target.value)}
                            value={start_date3}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate3(e.target.value)}
                            value={end_date3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime3(e.target.value)}
                            value={book_time3}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime3(e.target.value)
                            }
                            value={activity_time3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap3(e.target.value)}
                            value={audit_cap3}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit3(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit4}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 04*/}
                  {isAddAudit4 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Venue 4 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate4(e.target.value)}
                            value={start_date4}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate4(e.target.value)}
                            value={end_date4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime4(e.target.value)}
                            value={book_time4}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime4(e.target.value)
                            }
                            value={activity_time4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap4(e.target.value)}
                            value={audit_cap4}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit4(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit5}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 05*/}
                  {isAddAudit5 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Venue 5 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setStartDate5(e.target.value)}
                            value={start_date5}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setEndDate5(e.target.value)}
                            value={end_date5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setAuditBookTime5(e.target.value)}
                            value={book_time5}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) =>
                              setAuditActivityTime5(e.target.value)
                            }
                            value={activity_time5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Capacity
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Participant Capacity. eg: 500"
                            onChange={(e) => setAuditCap5(e.target.value)}
                            value={audit_cap5}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Preferred Auditorium
                          </span>
                          <select
                            id="status"
                            name="project"
                            className="input input-bordered w-full select"
                            onChange={(e) => setIM_PrefAudit5(e.target.value)}
                            value={im_prefAudit}
                          >
                            <option value="NULL">Select Auditorium</option>
                            <option value="1">Auditorium 1</option>
                            <option value="2">Auditorium 2</option>
                            <option value="3">Auditorium 3</option>
                            <option value="4">Auditorium 4</option>
                          </select>
                        </div>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                        style={{ display: "none" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showOutsideMeetingVenue}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showOtherMeeting}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 13 of 15 | (84% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="84"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisible_OutsideVenueDate && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    When do you need the outdoor venue? 📆
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Please provide the dates for which you require the outdoor
                    venue. If you have multiple dates, you can list them all
                    here. We will do our best to accommodate your preferred
                    dates based on availability.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Start Date
                      </span>
                      <input
                        type="date"
                        style={{ width: "100%" }}
                        placeholder="Proposed Date"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        End Date
                      </span>
                      <input
                        type="date"
                        style={{ width: "100%" }}
                        placeholder="Proposed Date"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showOutsideVenueActivity}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showOutsideVenueTime}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 18 of 15 | (88% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="88"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisible_OutsideVenueTime && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    What will be the timing for the outdoor venue booking? ⏰
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Please provide the timing for the outdoor venue booking.
                    This includes the start and end time for the activity or
                    event you have planned at the outdoor venue.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Start Time
                      </span>
                      <input
                        type="time"
                        style={{ width: "100%" }}
                        placeholder="Proposed Date"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>

                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        End Time
                      </span>
                      <input
                        type="time"
                        style={{ width: "100%" }}
                        placeholder="Proposed Date"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showOutsideVenueDate}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showOutsideVenueCap}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 19 of 15| (90% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="90"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisible_OutsideVenueCap && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    How many attendees are you expecting for the outdoor
                    activity? ⛔
                  </h1>

                  <p className="mt-4 text-gray-500">
                    Please provide an estimate of the number of attendees you
                    are expecting for the outdoor activity or event. This will
                    help us in determining the appropriate outdoor venue based
                    on the expected audience size.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Capacity
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Participant Capacity. eg: 500"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showOutsideVenueTime}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showOutsideVenueName}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 20 of 15 | (90% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="90"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisible_OutsideVenueName && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Please select your preferred outdoor venue from the options
                    below for your outdoor activity: 📌
                  </h1>

                  <p className="mt-4 text-gray-500">
                    We have various outdoor venues available for booking. Please
                    select your preferred venue from the list below that best
                    suits your outdoor activity requirements.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Preferred Outdoor Venue
                      </span>
                      <select
                        id="status"
                        name="project"
                        className="input input-bordered w-full select"
                        onChange={(e) => setIM_PrefAudit(e.target.value)}
                      >
                        <option value="NULL">Select Outdoor Venue</option>
                        <option value="1">Courtyard</option>
                        <option value="2">Residence Hostels</option>
                        <option value="3">Hostel M1 & M2 Block</option>
                        <option value="4">Food Court</option>
                      </select>
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showOutsideVenueCap}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showOutsideVenue2}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 21 of 15 | (92% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="92"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownOutside_Venue2 && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Do you require booking of any other venue for your event?
                    🏞️+1
                  </h1>

                  <p className="mt-4 text-gray-500">
                    If you need to book another venue in addition to the
                    previously added outdoor venue, please let us know here.
                  </p>

                  <div style={{ display: "flex", gap: "2vw" }}>
                    <div className="w-full my-8">
                      <div className="form-control" onClick={showOtherMeeting}>
                        <label className="label cursor-pointer">
                          <span className="label-text">🔴 No</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-red-500"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label
                          className="label cursor-pointer"
                          onClick={addaudit2}
                        >
                          <span className="label-text">🟢 Yes</span>
                          <input
                            type="radio"
                            name="radio-10"
                            className="radio checked:bg-blue-500"
                            checked
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  {isAddAudit2 && (
                    <div className="w-full my-8">
                      <div style={{ width: "100%" }}>
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Upload a CSV File
                        </span>
                        <input
                          type="file"
                          name="file"
                          accept=".csv"
                          onChange={changeHandler}
                          className="file-input file-input-bordered file-input-primary w-full "
                        />
                      </div>
                      <br />
                      <br />
                      {/* Table */}

                      <div className="overflow-x-auto">
                        <table className="table w-full">
                          {/* head */}
                          <thead>
                            <tr>
                              {tableRows.map((rows, index) => {
                                return <th key={index}>{rows}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {values.map((value, index) => {
                              return (
                                <tr key={index}>
                                  {value.map((val, i) => {
                                    return <td key={i}>{val}</td>;
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      <div className="divider mt-8">OR Enter Manually</div>

                      <h2 className="auditoriumBadge"> Venue 2 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Capacity
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Participant Capacity. eg: 500"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Preferred Auditorium
                      </span>
                      <select
                        id="status"
                        name="project"
                        className="input input-bordered w-full select"
                        onChange={(e) => setIM_PrefAudit(e.target.value)}
                      >
                        <option value="NULL">Select Outdoor Venue</option>
                        <option value="1">Courtyard</option>
                        <option value="2">Residence Hostels</option>
                        <option value="3">Hostel M1 & M2 Block</option>
                        <option value="4">Food Court</option>
                      </select>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {isAddAudit3 && (
                    <div className="w-full my-8">
                      <h2 className="auditoriumBadge"> Venue 3 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Capacity
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Participant Capacity. eg: 500"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Preferred Auditorium
                      </span>
                      <select
                        id="status"
                        name="project"
                        className="input input-bordered w-full select"
                        onChange={(e) => setIM_PrefAudit(e.target.value)}
                      >
                        <option value="NULL">Select Outdoor Venue</option>
                        <option value="1">Courtyard</option>
                        <option value="2">Residence Hostels</option>
                        <option value="3">Hostel M1 & M2 Block</option>
                        <option value="4">Food Court</option>
                      </select>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit4}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {isAddAudit4 && (
                    <div className="w-full my-8">
                      <h2 className="auditoriumBadge"> Venue 4 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Capacity
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Participant Capacity. eg: 500"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Preferred Auditorium
                      </span>
                      <select
                        id="status"
                        name="project"
                        className="input input-bordered w-full select"
                        onChange={(e) => setIM_PrefAudit(e.target.value)}
                      >
                        <option value="NULL">Select Outdoor Venue</option>
                        <option value="1">Courtyard</option>
                        <option value="2">Residence Hostels</option>
                        <option value="3">Hostel M1 & M2 Block</option>
                        <option value="4">Food Court</option>
                      </select>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit5}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {isAddAudit5 && (
                    <div className="w-full my-8">
                      <h2 className="auditoriumBadge"> Venue 5 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Time
                          </span>
                          <input
                            type="time"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="Proposed Date"
                            onChange={(e) => setIM_AuditDate(e.target.value)}
                            value={im_audit_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Capacity
                      </span>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Participant Capacity. eg: 500"
                        onChange={(e) => setIM_AuditDate(e.target.value)}
                        value={im_audit_date}
                        className="input input-bordered "
                      />

                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Preferred Auditorium
                      </span>
                      <select
                        id="status"
                        name="project"
                        className="input input-bordered w-full select"
                        onChange={(e) => setIM_PrefAudit(e.target.value)}
                      >
                        <option value="NULL">Select Outdoor Venue</option>
                        <option value="1">Courtyard</option>
                        <option value="2">Residence Hostels</option>
                        <option value="3">Hostel M1 & M2 Block</option>
                        <option value="4">Food Court</option>
                      </select>
                    </div>
                  )}

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showOutsideVenueName}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showOtherMeeting}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 22 of 15 | (94% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="94"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisibleMeetingVigilance && (
                <div
                  style={{
                    display: "flex",
                    gap: "2vw",
                    flexDirection: "column",
                  }}
                  className="toColumn"
                >
                  <div className="mx-0 mb-8 max-w-3xl text-start">
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Please provide the details of all external participants
                      attending the meeting. 🚨
                    </h1>

                    <p className="mt-4 text-gray-500">
                      If you have external participants attending your meeting,
                      please provide their details below.
                    </p>
                  </div>

                  <div style={{ width: "100%" }}>
                    <span className="label-text" style={{ lineHeight: "3rem" }}>
                      Upload a CSV File
                    </span>
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={changeHandler}
                      className="file-input file-input-bordered file-input-primary w-full "
                    />
                  </div>
                  <br />
                  <br />
                  {/* Table */}

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          {tableRows.map((rows, index) => {
                            return <th key={index}>{rows}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {values.map((value, index) => {
                          return (
                            <tr key={index}>
                              {value.map((val, i) => {
                                return <td key={i}>{val}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      {/* head */}
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>CNIC</th>
                          <th>Vehicle Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* row 1 */}
                        <tr>
                          <td>{start_date}</td>
                          <td>{end_date}</td>
                          <td>{im_prefAudit}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="divider mt-8">OR Enter Manually</div>

                  {/* Audit 01*/}

                  <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                    <h2 className="auditoriumBadge"> Person 1 </h2>

                    <div
                      style={{ display: "flex", gap: "2vw" }}
                      className="toColumn"
                    >
                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Full Name
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Full Name"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={start_date}
                          className="input input-bordered "
                        />
                      </div>

                      <div className="w-full ">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          CNIC
                        </span>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="CNIC #"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={end_date}
                          className="input input-bordered "
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <span
                        className="label-text"
                        style={{ lineHeight: "3rem" }}
                      >
                        Vehicle Details
                      </span>
                      <textarea
                        style={{
                          width: "100%",
                          padding: "15px",
                          height: "8vh",
                        }}
                        placeholder="Vehicle Number, Type and other details"
                        onChange={(e) => setExt_Vigilance(e.target.value)}
                        value={ext_vigilance}
                        className="input input-bordered "
                      ></textarea>
                    </div>

                    <button
                      className="btn gap-2 mt-6 addmoreAuditorium"
                      onClick={addaudit2}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Add more
                    </button>
                  </div>

                  {/* Audit 02*/}
                  {isAddAudit2 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Person 1 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Full Name"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={start_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="CNIC #"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={end_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Vehicle Details
                        </span>
                        <textarea
                          style={{
                            width: "100%",
                            padding: "15px",
                            height: "8vh",
                          }}
                          placeholder="Vehicle Number, Type and other details"
                          onChange={(e) => setExt_Vigilance(e.target.value)}
                          value={ext_vigilance}
                          className="input input-bordered "
                        ></textarea>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit3}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 03*/}
                  {isAddAudit3 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Person 1 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Full Name"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={start_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="CNIC #"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={end_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Vehicle Details
                        </span>
                        <textarea
                          style={{
                            width: "100%",
                            padding: "15px",
                            height: "8vh",
                          }}
                          placeholder="Vehicle Number, Type and other details"
                          onChange={(e) => setExt_Vigilance(e.target.value)}
                          value={ext_vigilance}
                          className="input input-bordered "
                        ></textarea>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit4}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 04*/}
                  {isAddAudit4 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Person 1 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Full Name"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={start_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="CNIC #"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={end_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Vehicle Details
                        </span>
                        <textarea
                          style={{
                            width: "100%",
                            padding: "15px",
                            height: "8vh",
                          }}
                          placeholder="Vehicle Number, Type and other details"
                          onChange={(e) => setExt_Vigilance(e.target.value)}
                          value={ext_vigilance}
                          className="input input-bordered "
                        ></textarea>
                      </div>

                      <button
                        className="btn gap-2 mt-6 addmoreAuditorium"
                        onClick={addaudit5}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add more
                      </button>
                    </div>
                  )}

                  {/* Audit 05*/}
                  {isAddAudit5 && (
                    <div className="mx-0 mb-8 max-w-3xl text-start dottedSection">
                      <h2 className="auditoriumBadge"> Person 1 </h2>

                      <div
                        style={{ display: "flex", gap: "2vw" }}
                        className="toColumn"
                      >
                        <div className="w-full">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            Start Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Full Name"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={start_date}
                            className="input input-bordered "
                          />
                        </div>

                        <div className="w-full ">
                          <span
                            className="label-text"
                            style={{ lineHeight: "3rem" }}
                          >
                            End Date
                          </span>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="CNIC #"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={end_date}
                            className="input input-bordered "
                          />
                        </div>
                      </div>

                      <div className="w-full">
                        <span
                          className="label-text"
                          style={{ lineHeight: "3rem" }}
                        >
                          Vehicle Details
                        </span>
                        <textarea
                          style={{
                            width: "100%",
                            padding: "15px",
                            height: "8vh",
                          }}
                          placeholder="Vehicle Number, Type and other details"
                          onChange={(e) => setExt_Vigilance(e.target.value)}
                          value={ext_vigilance}
                          className="input input-bordered "
                        ></textarea>
                      </div>
                    </div>
                  )}

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={handleMeeting}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={showMeetingPurpose}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 17 of 15 | (85% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="85"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isVisible_MeetingDocs && (
                <div className="mx-0 mb-8 max-w-3xl text-start">
                  <h1 className="text-2xl font-bold sm:text-3xl">
                    Do you have any supporting documents or attachments related
                    to your meeting that you would like to submit? 📃
                  </h1>

                  <p className="mt-4 text-gray-500">
                    If you have any supporting documents or attachments, such as
                    meeting agenda, presentation materials, or other relevant
                    files, please let us know if you would like to submit them
                    along with your meeting request. You can upload the files
                    using the file upload feature below.
                  </p>

                  <div
                    style={{ display: "flex", gap: "2vw" }}
                    className="toColumn"
                  >
                    <div className="w-full my-8">
                      <input
                        type="file"
                        className="file-input file-input-bordered file-input-primary w-full max-w-xs"
                      />
                    </div>
                  </div>

                  <div className="trayMaster">
                    <div className="gap-2 flex">
                      <button
                        onClick={showOtherMeeting}
                        className="btn btn-outline btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        Back
                      </button>

                      <button
                        onClick={handleSuccess}
                        className="btn btn-primary"
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div>
                        <span> Step 15 of 15 | (98% Complete) </span>
                      </div>

                      <div>
                        <progress
                          className="progress progress-primary w-56"
                          value="98"
                          max="100"
                        ></progress>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isShownSuccess && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <section className="text-gray-800">
                    <div className="container max-w-xl p-6 py-2 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
                      <div>
                        <img
                          style={{ width: "52%", margin: "1vh auto" }}
                          src="https://res.cloudinary.com/ahbranding/image/upload/v1681377812/AHbranding/image_processing20210912-11588-ci4lmq_oqwsk2.gif"
                        />
                        <h2 className="text-3xl font-bold tracking-tight text-center sm:text-5xl text-gray-900">
                          You're all set!
                        </h2>
                        <p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-600">
                          Submit request to end the form
                        </p>
                      </div>
                    </div>
                  </section>
                  <button
                    onClick={handleClick}
                    className="btn btn-block btn-primary"
                  >
                    Submit Request
                  </button>

                  <button
                    onClick={handleBasic}
                    className="btn btn-primary btn-outline backBtn my-4 text-center"
                    style={{ background: "none", border: "none" }}
                  >
                    No, I want to change
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className=""></div>
          )}

          {isAdmin == "0" ? <div></div> : <div className=""></div>}

          {isAdmin == "0" ? <div></div> : <div className=""></div>}

          {(() => {
            if (isAdmin == "1") {
              return (
                <div>
                  <select
                    id="status"
                    name="status"
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                    className="input input-bordered w-full select"
                  >
                    <option value="null">Status</option>
                    <option value="0">Reviewing</option>
                    <option value="1">Accepted</option>
                    <option value="2">Update Info.</option>
                    <option value="4">Stall</option>
                    <option value="3">Rejected</option>
                  </select>

                  <textarea
                    className="textarea textarea-bordered"
                    style={{ margin: "3vh 0", width: "100%" }}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Comment(s)"
                  ></textarea>

                  <Editor
                    apiKey="9cakwre8ru12iuaid8x3hga161qx4wzv6rmm6e4b5lliab5z"
                    cloudChannel="5-stable"
                    disabled={false}
                    //initialValue={rtf}
                    inline={false}
                    onEditorChange={set_RTF}
                    plugins=""
                    tagName="div"
                    textareaName=""
                    toolbar="checklist"
                    value={rtf}
                    outputFormat="html"
                    init={{
                      height: 500,
                      language: "en",
                      //menubar: false,
                      menu: {
                        format: {
                          title: "Format",
                          items:
                            "checklist | bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align | forecolor backcolor | removeformat",
                        },
                      },
                      plugins: [
                        "advlist lists checklist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                    }}
                  />

                  <button
                    onClick={handleClick}
                    className="btn btn-block btn-primary mt-4"
                  >
                    Submit Request
                  </button>
                </div>
              );
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default Write;
