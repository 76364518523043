import React from 'react';

function Sidebar() {
  return (
    <aside className="bg-gray-800 text-white w-64 flex-shrink-0">
      {/* Sidebar content */}
      <h2>Sidebar</h2>
    </aside>
  );
}

export default Sidebar;