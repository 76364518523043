import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import ProfileEditForm from "../components/ProfileEditForm";
import { axiosInstance } from "../Axios";

const SocietyStats = () => {
  const [members, setMembers] = useState();

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  // initalize thread and posts component state
  const [memberscount, setMemberscount] = useState(0);
  const [events, setevents] = useState(0);
  const [myevents, setmyevents] = useState(0);
  const [socieites, setsocieties] = useState(0);

  const [requestcount, setrequestcount] = useState(0);

  const [allSocieties, setallSocieties] = useState(0);
  const [allccamembers, setallccamembers] = useState(0);
  const [role, setRole] = useState(false);

  useEffect(() => {
    async function fetchMembers() {
      try {
        const response = await axiosInstance.get("get_stats/");
        if(user['role']==='ccaAdmin'){

          setMemberscount(response.data.members);
          setevents(response.data.events);
          setmyevents(response.data.my_events);
          setrequestcount(response.data.requests);
          setsocieties(response.data.societies);
          setRole(response.data.fourthCount);
          setallSocieties(response.data.current_societies);

        }

        else if(user['role']==='manager'){

          setMemberscount(response.data.all_members);
          setevents(response.data.all_events);
          setrequestcount(response.data.all_requests);
          setsocieties(response.data.all_socities);
          setRole(response.data.role);
          setallccamembers(response.data.all_ccamembers);
        }


        else if(user['role']==='society'){

          setMemberscount(response.data.members);
          setmyevents(response.data.events_approved);
          setevents(response.data.events);
          setrequestcount(response.data.requests);
          setRole(response.data.role);

        }
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    }

    fetchMembers();
  }, []);



  //console.log(role);

  const scrollContainerRef = useRef(null);
    // Function to scroll the content left
    const scrollLeft = () => {
      if (scrollContainerRef.current) {
        const scrollAmount = 250; // Adjust the scroll amount as needed
        scrollContainerRef.current.scrollLeft -= scrollAmount;
      }
    };
    
    const scrollRight = () => {
      if (scrollContainerRef.current) {
        const scrollAmount = 250; // Adjust the scroll amount as needed
        scrollContainerRef.current.scrollLeft += scrollAmount;
      }
    };
  return (

    <div>
{/* <div className="flex pb-4" style={{overflowX:'scroll'}}> */}

<div className={`pb-4 ${user.role === 'ccaAdmin' || user.role === 'manager' ? 'flex items-center' : ''}`}>


{(() => {
        if (user['role']==='ccaAdmin' || user['role']==='manager') {
          return (
            <button className="btn btn-circle" onClick={scrollLeft}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
</svg>
</button>
          )
        }
      })()}


          {/* card1 */}
          {/* <div className="flex" style={{ overflowX: 'scroll' }} ref={scrollContainerRef}> */}
          <div className="flex scrollable-container pb-4" ref={scrollContainerRef}>
          <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                        Total Members
                      </p>
                      <h5 className="mb-2 font-bold ">{memberscount}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +55%
                        </span>
                        since yesterday
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3">
                    <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                      <i className="ni leading-none ni-circle-08 text-lg relative top-3.5 text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* card2 */}


          {(() => {
            if (user['role']==='manager') {
                return (
                  <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 " >
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                      CCA Members
                      </p>
                      <h5 className="mb-2 font-bold ">{allccamembers}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +3%
                        </span>
                        since last week
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3">
                    <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                    <i className="ni leading-none ni-circle-08 text-lg relative top-3.5 text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                )
              }
            })()}


          {(() => {
            if (user['role']==='ccaAdmin' || user['role']==='manager') {
                return (
                  <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 " >
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                      All Societies
                      </p>
                      <h5 className="mb-2 font-bold ">{socieites}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +3%
                        </span>
                        since last week
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3">
                    <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                      <i className="ni leading-none ni-app text-lg relative top-3.5 text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                )
              }
            })()}



            {(() => {
            if (user['role']==='ccaAdmin') {
                return (
                  <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 " >
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                      My Societies
                      </p>
                      <h5 className="mb-2 font-bold ">{allSocieties}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +3%
                        </span>
                        since last week
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3">
                    <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                      <i className="ni leading-none ni-app text-lg relative top-3.5 text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                )
              }
            })()}




            {(() => {
            if (user['role']==='society') {
                return (
                  <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 " >
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                      Total Events
                      </p>
                      <h5 className="mb-2 font-bold ">{events}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +3%
                        </span>
                        since last week
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3">
                    <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                      <i className="ni leading-none ni-app text-lg relative top-3.5 text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                )
              }
            })()}



          <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                        Requests
                      </p>
                      <h5 className="mb-2 font-bold ">{requestcount}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +5%
                        </span>
                        than last month
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3">
                    <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                      <i className="ni leading-none ni-watch-time text-lg relative top-3.5 text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {(() => {
            if (user['role']==='ccaAdmin' || user['role']==='society') {
                return (
                  <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                        My Events
                      </p>
                      <h5 className="mb-2 font-bold ">{myevents}</h5> 
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-red-600">
                          -2%
                        </span>
                        since last quarter
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3">
                    <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-emerald-500 to-teal-400">
                      <i className="ni leading-none ni-calendar-grid-58 text-lg relative top-3.5 text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                )
              }
            })()}

          {/* card3 */}

          {/* card4 */}


          {(() => {
            if (user['role']==='ccaAdmin' || user['role']==='manager') {
                return (
                  <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                        All Events
                      </p>
                      <h5 className="mb-2 font-bold ">{events}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +5%
                        </span>
                        than last month
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3">
                    <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                      <i className="ni leading-none ni-calendar-grid-58 text-lg relative top-3.5 text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                )
              }
            })()}

</div>




{(() => {
        if (user['role']==='ccaAdmin' || user['role']==='manager') {
          return (
            <button className="btn btn-circle" onClick={scrollRight}>

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
</svg>

</button>
          )
        }
      })()}
        </div>

    </div>
    
  );
};

export default SocietyStats;
