import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../Axios";
import SocietySidebar from "./SocietySidebar";
import AuthContext from "../context/AuthContext";
import SocNav from "./SocNav";
import SocietyStats from "./SocietyStats";
import SocietySideInnerSM from "./SocietySideInnerSM";
import { toast, Toaster } from 'react-hot-toast';
export default function SocietiesList() {
  const [societies, setSocieties] = useState([]);
  const [mySocieties, setMySocieties] = useState([]);
  const [activeTab, setActiveTab] = useState("mine");
  const [viewMode, setViewMode] = useState("grid");
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchSocieties() {
      try {
        setIsLoading(true);

        if (user.role === 'ccaAdmin') {
          // If the user is a ccaAdmin, set the active tab to "mine"
          setActiveTab("mine");
          const response1 = await axiosInstance.get("get_admin_societies/");
          setMySocieties(response1.data);
        } else {
          // If the user is a manager or other role, set the active tab to "all"
          setActiveTab("all");
        }

        const response2 = await axiosInstance.get("get_all_societies/");
        setSocieties(response2.data);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching societies:", error);
      }
    }

    fetchSocieties();
  }, []);
  

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const toggleViewMode = (mode) => {
    setViewMode(mode);
  };

  return (
    <div>
      <div className="absolute w-full bg-primary min-h-75"></div>

      <SocietySidebar />
      {isSidebarVisible && <SocietySideInnerSM />}

      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
        <SocNav toggleSidebar={toggleSidebar} />

        <div className="view-toggle flex justify-center w-full px-6 py-6 mx-auto gap-4">
        <button
  className={`view-toggle-btn flex items-center gap-2 ${
    viewMode === "grid" ? "active-button" : "de-active-button"
  }`}
  onClick={() => toggleViewMode("grid")}
>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
</svg>
Grid View
</button>
<button
  className={`view-toggle-btn flex items-center gap-2 ${
    viewMode === "list" ? "active-button" : "de-active-button"
  }`}
  onClick={() => toggleViewMode("list")}
>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
</svg>
List View
</button>

        </div>

        <div className="w-full px-6 py-6 mx-auto">
          <div className="mb-6">
            <div className="border-b border-gray-300">
              <nav className="-mb-px flex">
              {(() => {
                  if (user['role']==='ccaAdmin') {
                    return (
                      <button
                  className={`py-4 px-6 border-b-2 font-medium text-lg ${
                    activeTab === "mine"
                      ? "border-white text-white"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }`}
                  onClick={() => setActiveTab("mine")}
                >
                  My Societies
                </button>
                    )
                  }
                })()}
                
                <button
                  className={`mr-8 py-4 px-6 border-b-2 font-medium text-lg ${
                    activeTab === "all"
                      ? "border-white text-white"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }`}
                  onClick={() => setActiveTab("all")}
                >
                  All Societies
                </button>
              </nav>
            </div>
          </div>


          {(() => {
                  if (user['role']==='ccaAdmin') {
                    return (
                      <div>
                      {viewMode === "grid" && activeTab === "mine" && (
            <SocietyGrid societies={mySocieties} />
          )}
                      </div>
                    )
                  }
                })()}

          {viewMode === "grid" && activeTab === "all" && (
            <SocietyGrid societies={societies} />
          )}
          
          {viewMode === "list" && activeTab === "all" && (
            <SocietyList societies={societies} />
          )}

          {(() => {
                  if (user['role']==='ccaAdmin') {
                    return (
                      <div>
                      {viewMode === "list" && activeTab === "mine" && (
            <SocietyList societies={mySocieties} />
          )}
                      </div>
                    )
                  }
                })()}
          
        </div>
      </main>
    </div>
  );
}

function SocietyGrid({ societies }) {
  return (
<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-4">
  {societies.map((society) => (
    <div key={society.id} className="bg-white p-4 rounded-lg shadow-md">
      <div className="avatar mb-4 placeholder">
        <div className="bg-neutral-focus text-neutral-content rounded-full w-16">
          <span className="text-xl">
            {society.name.slice(0, 2)} {/* Display the first two characters */}
          </span>
        </div>
      </div>
      <h3 className="text-lg font-semibold mb-1">{society.name}</h3>
      <p className="text-gray-600 text-sm">Email: {society.email}</p>
    </div>
  ))}
</div>

  );
}

function SocietyList({ societies }) {
  return (
    <div className="grid grid-cols-1 gap-8">
      {societies.map((society) => (
        <div key={society.id} className="bg-white p-6 rounded-lg shadow-md mb-4">
          <h3 className="text-xl font-semibold mb-2">{society.name}</h3>
          <p className="text-gray-600">Email: {society.email}</p>
        </div>
      ))}
    </div>
  );
}
