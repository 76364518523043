import React, { Fragment } from "react";
import randomcolor from "randomcolor";
import data from './data.json';
import Chance from 'chance';
import { randPersonTitle,randAvatar,randJobTitle,randFullName   } from '@ngneat/falso';
import './Map.css'

const RepoList = () => {

        const handleDownload = () => {
          // Perform any necessary logic before downloading the document
          // For example, you can fetch the document URL from an API
      
          // Simulate a document download by creating a temporary link element
          const link = document.createElement('a');
          link.href = 'https://drive.google.com/file/d/1HnMUSa5VLJscdAPb-lEz6t-RJYolqkpA/view?usp=sharing'; // Replace with the actual document URL
          link.download = 'document.pdf'; // Replace with the desired file name
          link.click();
        };
      

  return (
<div className="overflow-x-auto mt-6">
  <table className="table">
    {/* head */}
    <thead>
      <tr>
        <th>Name</th>
        <th>Category</th>
        <th>Published on</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {/* row 1 */}
     
      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>

      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>

      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>
      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>


      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>

      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>


      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>

      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>

      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>

      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>

      <tr>
        <td>LUMS Co-curricular Handbook</td>
        <td>Guideline Doc.</td>
        <td>Apr. 29th</td>
        <td><a onClick={handleDownload} className="link link-primary"> Download</a></td>
      </tr>
     
    </tbody>
  </table>
</div>
  );
};

export default RepoList;
