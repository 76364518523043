import React from 'react';
import changeLogsData from './changeLogsData.json'; // Import the JSON data

function ChangeLogs() {
  const reversedChangeLogsData = [...changeLogsData].reverse(); // Create a reversed copy of the original array

  return (
    <div className="max-w-2xl px-6 py-16 mx-auto space-y-12">
      <article className="space-y-8 text-gray-900">
        <div className="space-y-6">
          <h1 className="text-4xl font-bold md:tracki md:text-5xl">Change Logs</h1>
        </div>
        <div className="text-gray-800">
          <p>
           Stay informed about the latest updates, features, and improvements. Explore our version history to discover exciting additions, enhanced functionality, and bug fixes. We're committed to providing you with a seamless and continually improving user experience. Keep your app up to date to enjoy the latest enhancements. Check back regularly for the most recent changes and innovations!
          </p>
        </div>
        <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center text-gray-600">
          <div className="flex items-center md:space-x-2 gap-2">
            <img src="https://res.cloudinary.com/ahbranding/image/upload/v1704186829/AHbranding/image_1_ea6dta.png" alt="" className="w-4 h-4 border rounded-full bg-white-500 border-gray-300" />
            <p className="text-sm m-0">Team CampusConnect </p>
          </div>
        </div>
      </article>

      <div>
        {reversedChangeLogsData.map((version, index) => (
          <div key={index} className="space-y-2 my-6">
          <div className="flex flex-wrap py-6 gap-2 border-t border-dashed border-gray-600"></div>
            <h4 className="text-lg font-semibold flex justify-between">
              <span>Version {version.version}</span>
              <span className='font-normal text-sm text-gray-400'>{version.releaseDate}</span>
            </h4>
            <ul className="my-6 list-disc block px-4">
              {version.changes.map((change, idx) => (
                <li key={idx}>
                  <span>{change}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChangeLogs;
