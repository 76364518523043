import React from 'react';

const EventReportTable = ({ eventReport }) => {
    const handleDownloadCSV = () => {
        const csvData = [];
        
        csvData.push(['Title', 'Data']);
        csvData.push(['Local Delegates', eventReport.delegates_local]);
        csvData.push(['International Delegates', eventReport.delegates_international]);
        csvData.push(['Revenue', `PKR ${eventReport.revenue}`]);
        csvData.push(['Expense', `PKR ${eventReport.expense}`]);
        csvData.push(['Profit', `PKR ${eventReport.profit}`]);
        csvData.push(['Feedback Complaints', eventReport.feedbackComplaints]);
        csvData.push(['Other Feedback', eventReport.other_feedback]);
        csvData.push(['Event ID', eventReport.event]);

        eventReport.sponsors.forEach((sponsor, index) => {
            csvData.push([`Sponsor ${index + 1}`, `${sponsor.name}: PKR ${sponsor.price}`]);
        });

        eventReport.inKindSponsors.forEach((sponsor, index) => {
            csvData.push([`In-Kind Sponsor ${index + 1}`, `${sponsor.name}: PKR ${sponsor.price}`]);
        });

        eventReport.EventReport.forEach((report, index) => {
            csvData.push([`Event Report File ${index + 1}`, report.url]);
        });

        eventReport.MoUswithSponsor.forEach((mou, index) => {
            csvData.push([`MoU with Sponsor ${index + 1}`, mou.url]);
        });

        eventReport.highlightsMedia.forEach((media, index) => {
            csvData.push([`Highlight Media ${index + 1}`, media.url]);
        });

        csvData.push(['Learnings', eventReport.learnings]);
        csvData.push(['Improvements', eventReport.improvement]);
        csvData.push(['Milestones', eventReport.milestones]);

        const csvContent = csvData.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'event_report.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="container mx-auto my-8 p-4 bg-white rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Event Report</h2>
            <table className="table-auto w-full text-left">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="px-4 py-2">Title</th>
                        <th className="px-4 py-2">Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border px-4 py-2">Local Delegates</td>
                        <td className="border px-4 py-2">{eventReport.delegates_local}</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">International Delegates</td>
                        <td className="border px-4 py-2">{eventReport.delegates_international}</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">Revenue</td>
                        <td className="border px-4 py-2">PKR {eventReport.revenue}</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">Expense</td>
                        <td className="border px-4 py-2">PKR {eventReport.expense}</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">Profit</td>
                        <td className="border px-4 py-2">PKR {eventReport.profit}</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">Feedback Complaints</td>
                        <td className="border px-4 py-2">{eventReport.feedbackComplaints}</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">Other Feedback</td>
                        <td className="border px-4 py-2">{eventReport.other_feedback}</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">Event ID</td>
                        <td className="border px-4 py-2">{eventReport.event}</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">Sponsors</td>
                        <td className="border px-4 py-2">
                            <ul>
                                {eventReport.sponsors.map((sponsor, index) => (
                                    <li key={index} className="list-disc ml-5">
                                        {sponsor.name}: PKR {sponsor.price}
                                    </li>
                                ))}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">In-Kind Sponsors</td>
                        <td className="border px-4 py-2">
                            <ul>
                                {eventReport.inKindSponsors.map((sponsor, index) => (
                                    <li key={index} className="list-disc ml-5">
                                        {sponsor.name}: PKR {sponsor.price}
                                    </li>
                                ))}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">Event Report Files</td>
                        <td className="border px-4 py-2">
                            <ul>
                                {eventReport.EventReport.map((report, index) => (
                                    <li key={index} className="list-disc ml-5">
                                        <a href={report.url} className='text-blue-500 hover:underline' target="_blank" rel="noopener noreferrer">
                                            {report.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">MoUs with Sponsors</td>
                        <td className="border px-4 py-2">
                            <ul>
                                {eventReport.MoUswithSponsor.map((mou, index) => (
                                    <li key={index} className="list-disc ml-5">
                                        <a href={mou.url} className='text-blue-500 hover:underline' target="_blank" rel="noopener noreferrer">
                                            {mou.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">Highlights Media</td>
                        <td className="border px-4 py-2">
                            <ul>
                                {eventReport.highlightsMedia.map((media, index) => (
                                    <li key={index} className="list-disc ml-5">
                                        <a href={media.url} className='text-blue-500 hover:underline' target="_blank" rel="noopener noreferrer">
                                            {media.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="mt-4">
                <h3 className="text-xl font-semibold mb-2">Learnings</h3>
                <p className="bg-gray-100 p-4 rounded-lg">{eventReport.learnings}</p>
            </div>
            <div className="mt-4">
                <h3 className="text-xl font-semibold mb-2">Improvements</h3>
                <p className="bg-gray-100 p-4 rounded-lg">{eventReport.improvement}</p>
            </div>
            <div className="mt-4">
                <h3 className="text-xl font-semibold mb-2">Milestones</h3>
                <p className="bg-gray-100 p-4 rounded-lg">{eventReport.milestones}</p>
            </div>
            <button 
                onClick={handleDownloadCSV}
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                Download CSV
            </button>
        </div>
    );
};

export default EventReportTable;
