import React from 'react';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Captcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleRecaptchaVerify = (recaptchaToken) => {
    console.log('Recaptcha token:', recaptchaToken);
    // Your logic after verification
  };

  const executeCaptcha = async () => {
    const token = await executeRecaptcha('submit');
    handleRecaptchaVerify(token);
  };

  return (
    <div>
      {/* Your other form components */}
      <GoogleReCaptcha onVerify={handleRecaptchaVerify} action="submit" />

    </div>
  );
};

export default Captcha;
