import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../Axios";
import { toast, Toaster } from "react-hot-toast";

const AutoApprovalPage = () => {
  const { type, event, id } = useParams();
  const [approvalStatus, setApprovalStatus] = useState(null);
  const venue = 4;
  const handleApproval = (status) => {
    axiosInstance
      .post(`venueApproval/${type}/${venue}/${id}/`, {
        approvalStatus: status,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Purchase status changed successfully!");
          let statusTemp = status;
          axiosInstance.post("post_notification_esclate_update_req/", {
            statusTemp,
          });
          toast.success("Approval Status Changed", {
            icon: "✅",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
          setApprovalStatus(status);
        } else {
          console.error("Error changing purchase status.");
          toast.error("Error occurred", {
            icon: "☠️",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    console.log("type: ", type);
    console.log("event: ", event);
    console.log("id: ", id);
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <p>Change status with one click 👆</p>
      {approvalStatus === null ? (
        <div className="mt-4">
          <button
            onClick={() => handleApproval(true)}
            className="bg-green-500 text-white px-4 py-2 m-2"
          >
            Approve
          </button>
          <button
            onClick={() => handleApproval(false)}
            className="bg-red-500 text-white px-4 py-2 m-2"
          >
            Reject
          </button>
        </div>
      ) : (
        <p>Approval Status: {approvalStatus ? "Approved" : "Rejected"}</p>
      )}
    </div>
  );
};

export default AutoApprovalPage;
