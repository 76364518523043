import React, { useState, useEffect, useContext } from "react";

import SocietySidebar from "../components/SocietySidebar";
import "react-calendar/dist/Calendar.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import AllSocietyEvents from "../components/AllSocietyEvents";
import SocNav from "../components/SocNav";
import SocietySideInnerSM from "../components/SocietySideInnerSM";
import TUICalendar from "./TUICalendar";
import { Link, useNavigate } from "react-router-dom";
import EventsCalendar from "../components/EventsCalender";
import Modal from "react-modal";
import AddScheduleForm from "../components/AddScheduleForm";
import EventsTable from "../components/AllSocietyEvents";
function Schedule() {
  const [profile, setProfile] = useState();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (



    <div>


    <div><>
    
    <div class="absolute w-full bg-primary  min-h-75"></div>
    
    
    <SocietySidebar />
    
    {isSidebarVisible && <SocietySideInnerSM/>}
    
        <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
          {/* Navbar */}
          <SocNav toggleSidebar={toggleSidebar} />
    
          {/* end Navbar */}
          {/* cards */}
          <div className="w-full px-6 py-6 mx-auto">
    
    
    
          <EventsTable />
    
          </div>
        </main>
      </>
      </div>


      </div>


  );
}

export default Schedule;
