import { useState, useEffect, useRef } from "react";
import MessageItem from "./ChatMessageItem";

export default function ChatWindow({ chat, onSendMessage }) {
    const [message, setMessage] = useState("");
    const messagesEndRef = useRef(null);

    useEffect(() => {
        // Scroll to the latest message when messages are updated
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [chat.messages]);

    const handleSend = () => {
        onSendMessage(chat.chat_id, message);
        setMessage("");
    };

    return (
        <div>
            <div className="messages">
                {chat?.messages?.map((msg) => (
                    <MessageItem
                        key={msg?.id}
                        message={msg}
                        isRequestUser={msg?.is_request_user}
                    />
                ))}
                {/* This div serves as a target for scrolling to the latest message */}
                <div ref={messagesEndRef} />
            </div>
        </div>
    );
}