import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import "react-calendar/dist/Calendar.css";
import { axiosInstance } from "../Axios";
import { Link } from "react-router-dom";
import FeedbackButton from "../pages/FeedbackButton";
import NotificationComponent from "./NotificationComponent";
import ChangeLogs from "./ChangeLogs";

const SocNav = ({ toggleSidebar }) => {
  const [events, setEvents] = useState();

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  const [profile, setProfile] = useState();
  const userID = user["user_id"];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = (item) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const getEvents = async () => {
      if (user !== null) {
        let userID = user["user_id"];
      }
      axiosInstance
        .get("get_profile/")
        .then((res) => {
          ////console.log(res.data);
          setProfile(res.data);
        })
        .catch((err) => {
          ////console.log(err);
        });
    };

    // getProfile();
    getEvents();
  }, [user]);
  // ////console.log(events);

  let role = user["role"];

  if (profile?.status == "2") {
    role = "Student";
  }

  const [page, setPage] = useState("");

  // function extractAfter3000(url) {
  //   const match = url.match(/https:\/\/lums.cca.startupearly.com\/([^/0-9]+)/);
  //   if (match && match[1]) {
  //     return match[1];
  //   }
  //   return null; // Return null if the pattern is not found
  // }

  function extractAfter3000(url) {
    const match = url.match(/\/([^\/]+)\/?$/);
    if (match && match[1]) {
      return match[1];
    }
    return null; // Return null if the pattern is not found
  }

  useEffect(() => {
    setPage(extractAfter3000(window.location.href));
  }, []);

  let contentTitle = "";
  let contentSubtitle = "";

  if (page === "society" || page === "admin" || page === "manager") {
    contentTitle = "Dashboard";
    contentSubtitle = "Overview of activities";
  } else if (page === "organisation") {
    contentTitle = "Society Members";
    contentSubtitle = "List of members in the society";
  } else if (page === "events_all" || page === "events_all") {
    contentTitle = "All Events";
    contentSubtitle = "Browse all events";
  } else if (page === "events_self") {
    contentTitle = "Society Events";
    contentSubtitle = "View events organized by the society";
  } else if (page === "all_society_details") {
    contentTitle = "Societies";
    contentSubtitle = "List of all and assigned societies";
  } else if (page === "chats") {
    contentTitle = "Chats";
    contentSubtitle = "Communiate with all users";
  } else if (page === "event_details") {
    contentTitle = "Event Details";
    contentSubtitle = "Details of current event";
  } else if (page === "add_society") {
    contentTitle = "Add Society";
    contentSubtitle = "Details of current event";
  } else if (page === "society_profile" || page === "admin_profile") {
    contentTitle = "Profile";
    contentSubtitle = "Details of current event";
  } else if (page === "set_proxy") {
    if (user["role"] === "ccaAdmin") {
      contentTitle = "Set Proxy";
      contentSubtitle = "Details of current event";
    } else if (user["role"] === "manager") {
      contentTitle = "Assign Societies";
      contentSubtitle = "Details of current event";
    }
  } else if (page === "event_form") {
    contentTitle = "Event Request";
    contentSubtitle = "Details of current event";
  } else if (page === "venues") {
    contentTitle = "Venues";
    contentSubtitle = "Details of current event";
  } else if (page === "add_venue") {
    contentTitle = "Add Venues";
    contentSubtitle = "Details of current event";
  } else if (page === "add_admin") {
    contentTitle = "Add CCA Member";
    contentSubtitle = "Details of current event";
  } else if (page === "edit_venue") {
    contentTitle = "Edit Venue";
    contentSubtitle = "Details of current event";
  } else if (page === "repo") {
    contentTitle = "Resource Vault";
    contentSubtitle = "Details of current event";
  } else if (page === "feedbacks") {
    contentTitle = "Feedbacks";
    contentSubtitle = "Details of current event";
  } else if (page === "announcements") {
    contentTitle = "Announcements";
    contentSubtitle = "Details of current event";
  } else if (page === "create_repo") {
    contentTitle = "New Resource";
    contentSubtitle = "Details of current event";
  } else if (page === "new_announcement") {
    contentTitle = "New Announcement";
    contentSubtitle = "Details of current event";
  } else if (page === "schedule") {
    contentTitle = "Schedule";
    contentSubtitle = "Details of current event";
  } else {
    contentTitle = "Edit Data";
    contentSubtitle = "Other Name Overview";
  }

  let fullname = user["first_name"] + " " + user["last_name"];

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  let getendpoint = "";
  let postendpoint = "";

  if (user["role"] === "ccaAdmin") {
    getendpoint = `/admin/profile/${userID}/`;
    postendpoint = `/admin/profile/${userID}/update/`;
  } else if (user["role"] === "manager") {
    getendpoint = `/manager/profile/${userID}/`;
    postendpoint = `/manager/profile/${userID}/update/`;
  } else if (user["role"] === "society") {
    getendpoint = `/society/profile/${userID}/`;
    postendpoint = `/society/profile/${userID}/update/`;
  }

  useEffect(() => {
    axiosInstance
      .get(getendpoint)
      .then((response) => {
        const data = response.data;
        setProfileData({
          name: data.name,
          dob: data.dob,
          gender: data.gender,
          contact: data.contact,
          bio: data.bio,
          active_status: data.active_status,
          employeeID: data.employeeID,
        });

        setProfilePhotoUrl(data.profile_photo);
        //console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  return (
    <div style={{ zIndex: "99999999999999999999999999999 !important" }}>
      <nav
        className="relative flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
        navbar-main=""
        navbar-scroll="false"
      >
        <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
          <nav>
            {/* breadcrumb */}
            {/* <ol className="flex flex-wrap pt-1 mr-12 bg-transparent rounded-lg sm:mr-16">
              <li className="text-sm leading-normal">
                <a className="text-white opacity-50" href="javascript:;">
                  
                {(() => {
                    if (user["role"] === "society") {
                      return <div>Society</div>;
                    } else if (user["role"] === "ccaAdmin") {
                      return <div> Admin </div>;
                    }
                    else if (user["role"] === "manager") {
                      return <div> Manager </div>;
                    } else {
                      return <div></div>;
                    }
                  })()}
                </a>
              </li>
              <li
                className="text-sm pl-2 capitalize leading-normal text-white before:float-left before:pr-2 before:text-white before:content-['/']"
                aria-current="page"
              >
                {contentTitle}
              </li>
            </ol> */}
            <h6 className="mb-0 font-bold text-white capitalize">
              {contentTitle}
            </h6>
          </nav>
          <div className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
            <div className="flex items-center md:ml-auto md:pr-4 opacity-0">
              <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease">
                <span className="text-sm ease leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                  <i className="fas fa-search" />
                </span>
                <input
                  type="text"
                  className="hidden pl-9 text-sm focus:shadow-primary-outline ease w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300   bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
                  placeholder="Type here..."
                />
              </div>
            </div>
            <ul className="flex flex-row items-center justify-end pl-0 mb-0 list-none md-max:w-full">
              {/* online builder btn  */}
              {/* <li class="flex items-center">
            <a class="inline-block px-8 py-2 mb-0 mr-4 text-xs font-bold text-center text-blue-500 uppercase align-middle transition-all ease-in bg-transparent border border-blue-500 border-solid rounded-lg shadow-none cursor-pointer leading-pro hover:-translate-y-px active:shadow-xs hover:border-blue-500 active:bg-blue-500 active:hover:text-blue-500 hover:text-blue-500 tracking-tight-rem hover:bg-transparent hover:opacity-75 hover:shadow-none active:text-white active:hover:bg-transparent" target="_blank" href="https://www.creative-tim.com/builder/soft-ui?ref=navbar-dashboard&amp;_ga=2.76518741.1192788655.1647724933-1242940210.1644448053">Online Builder</a>
          </li> */}

              <div
                className="dropdown dropdown-start mr-2"
                onClick={() => {
                  openModal();
                  document.getElementById("my_modal_5").showModal();
                }}
              >
                <label
                  tabIndex={0}
                  className="btn no-animation  btn bg-none text-white m-0 relative"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                    />
                  </svg>
                  What's New
                </label>
              </div>
              <div className="dropdown dropdown-start">
                <label
                  tabIndex={0}
                  className="btn no-animation  btn-circle m-0 relative"
                >
                  {/* <span className="absolute top-0 right-0 inline-flex h-2 w-2 bg-red-500 rounded-full" /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                    />
                  </svg>
                </label>
                <div
                  tabIndex={0}
                  className="dropdown-content px-2  z-[1] menu p-2 shadow bg-base-100 rounded-box w-96"
                >
                  <NotificationComponent />
                </div>
              </div>

              <li
                className="flex items-center dropdown dropdown-start "
                style={{ zIndex: "999999999999999999999" }}
              >
                <a
                  tabIndex={0}
                  className="bg-primary border-0 btn flex flex-row items-center gap-2text-sm font-semibold text-white transition-all ease-nav-brand"
                >
                  <i className="fa fa-user sm:mr-1 hidden" />

                  {(() => {
                    if (
                      profilePhotoUrl ===
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                    ) {
                      return (
                        <img
                          src={profilePhotoUrl}
                          alt="profile_image"
                          class="w-10 h-10 rounded-full"
                        />
                      );
                    } else {
                      return (
                        <img
                          className="w-10 h-10 rounded-full"
                          src={profilePhotoUrl}
                        />
                      );
                    }
                  })()}

                  <span className="  sm:inline">
                    {/* {(() => {
                    if (user["role"] === "society") {
                      return <div >{profile?.name} <small className="badge text-xs"> {user.role} </small></div>;
                    } else if (user["role"] === "ccaAdmin") {
                      return <div > {user.name} <small className="badge  text-xs"> {user.role} </small></div>;
                    } else {
                      return <div></div>;
                    }
                  })()} */}

                    <div>
                      {profileData?.name}{" "}
                      <small className="badge text-xs"> {user.role} </small>
                    </div>

                    <ul
                      tabIndex={0}
                      className="pr-8 mr-8 dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                    >
                      <li className="text-black p-2">
                        <Link
                          to={
                            user["role"] === "ccaAdmin" ||
                            user["role"] === "manager"
                              ? `/admin_profile`
                              : `/society_profile`
                          }
                        >
                          Profile
                        </Link>
                      </li>

                      <li onClick={logoutUser} className="text-red-400 p-2">
                        <a>Logout</a>
                      </li>
                    </ul>
                  </span>
                </a>
              </li>

              <li
                className="flex items-center pl-4 xl:hidden btn bg-primary border-0"
                onClick={toggleSidebar}
              >
                <a
                  href="javascript:;"
                  className="block p-0 text-sm text-white transition-all ease-nav-brand"
                >
                  <div className="w-4.5 overflow-hidden">
                    <i className="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all" />
                    <i className="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all" />
                    <i className="ease relative block h-0.5 rounded-sm bg-white transition-all" />
                  </div>
                </a>
              </li>
              <li className="flex items-center px-4 hidden">
                <a
                  href="javascript:;"
                  className="p-0 text-sm text-white transition-all ease-nav-brand"
                >
                  <i
                    fixed-plugin-button-nav=""
                    className="cursor-pointer fa fa-cog"
                  />
                  {/* fixed-plugin-button-nav  */}
                </a>
              </li>
              {/* notifications */}
              <li className="relative flex items-center pr-2 hidden">
                <p className="hidden transform-dropdown-show" />
                <a
                  href="javascript:;"
                  className="block p-0 text-sm text-white transition-all ease-nav-brand"
                  dropdown-trigger=""
                  aria-expanded="false"
                >
                  <i className="cursor-pointer fa fa-bell" />
                </a>
                <ul
                  dropdown-menu=""
                  className="text-sm transform-dropdown before:font-awesome before:leading-default before:duration-350 before:ease lg:shadow-3xl duration-250 min-w-44 before:sm:right-8 before:text-5.5 pointer-events-none absolute right-0 top-0 z-50 origin-top list-none rounded-lg border-0 border-solid border-transparent   bg-white bg-clip-padding px-2 py-4 text-left text-slate-500 opacity-0 transition-all before:absolute before:right-2 before:left-auto before:top-0 before:z-50 before:inline-block before:font-normal before:text-white before:antialiased before:transition-all before:content-['\f0d8'] sm:-mr-6 lg:absolute lg:right-0 lg:left-auto lg:mt-2 lg:block lg:cursor-pointer"
                >
                  {/* add show class on dropdown open js */}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {isModalOpen && (
        <dialog id="my_modal_5" className="modal">
          <div className="modal-box w-11/12 max-w-5xl">
            <div className="modal-action absolute top-0 right-4  z-[99999]">
              <form method="dialog">
                <button className="btn btn-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </form>
            </div>

            <div className="w-full bg-white  transform   duration-200 easy-in-out rounded-t-2xl ">
              <ChangeLogs />
            </div>
          </div>
        </dialog>
      )}

      <FeedbackButton />
    </div>
  );
};
export default SocNav;