import React, { useState, useEffect } from "react";
import { axiosInstance } from "../Axios";
import { toast, Toaster } from "react-hot-toast";

const EditSocietyMember = () => {
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [memberDetails, setMemberDetails] = useState({});

  useEffect(() => {
    // Fetch members from the server when the component mounts
    axiosInstance
      .get("get_members/")
      .then((response) => {
        //console.log(response.data);
        toast.success("Update is being viewed by Admin", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setMembers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching members:", error);
        toast.error("Oh No! Something went wrong. Check the data again", {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  }, []);

  //   useEffect(() => {
  //     if (selectedMember) {
  //       // Fetch details for the selected member
  //       axios
  //         .get(`/path/to/api/members/${selectedMember}/`)
  //         .then((response) => {
  //           setMemberDetails(response.data);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching member details:", error);
  //         });
  //     }
  //   }, [selectedMember]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedMember((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    setSelectedMember((prev) => {
      return { ...prev, image: e.target.files[0] };
    });
  };

  const handleSubmit = () => {
    // Logic to submit the edited member details to the server
    //console.log(selectedMember);
    const formData = new FormData();
    for (const field in selectedMember) {
      formData.append(field, selectedMember[field]);
    }
    axiosInstance
      .put(`updateMemberRequest/`, formData)
      .then((response) => {
        //console.log("Member details updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating member details:", error);
      });
  };

  return (
    <div>
      <select
        value={selectedMember}
        onChange={(e) => {
          setSelectedMember(members[e.target.value]);
          //console.log(members[e.target.value]);
        }}
      >
        <option value="">Select a member</option>
        {members.map((member, index) => (
          <option key={member.id} value={index}>
            {member.name}
          </option>
        ))}
      </select>

      {selectedMember && (
        <div>
          <input
            type="text"
            name="name"
            disabled
            className="input input-bordered w-full"
            value={selectedMember.name || ""}
            onChange={handleInputChange}
            placeholder="Name"
          />

          
          <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
            <div className="col-span-full sm:col-span-2">
              <label htmlFor="email" className="text-sm">
                Email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={selectedMember.email || ""}
                onChange={handleInputChange}
                placeholder="email@lums.edu.pk"
                className="input input-bordered w-full rounded-md focus:ring focus:ri focus:ri border-gray-300 text-gray-900"
              />
            </div>

            <div className="col-span-full sm:col-span-2">
              <label htmlFor="city" className="text-sm">
                Designation
              </label>
              <input
                type="text"
                name="designation"
                value={selectedMember.designation || ""}
                onChange={handleInputChange}
                placeholder="Vice President"
                className="input input-bordered w-full rounded-md focus:ring focus:ri focus:ri border-gray-300 text-gray-900"
              />
            </div>

            <div className="col-span-full sm:col-span-2">
              <label htmlFor="state" className="text-sm">
                Contact No.
              </label>
              <input
                id="state"
                type="number"
                name="contact"
                value={selectedMember.contact || ""}
                onChange={handleInputChange}
                placeholder="0340456789"
                className="input input-bordered w-full rounded-md focus:ring focus:ri focus:ri border-gray-300 text-gray-900"
              />
            </div>

            <div className="col-span-full sm:col-span-2">
              <label htmlFor="zip" className="text-sm">
                School
              </label>
              <select
                className="select select-bordered w-full max-w-xs"
                name="school"
                value={selectedMember.school || ""}
                onChange={handleInputChange}
              >
                <option value="-">Pick One</option>
                <option value="SBASEE">SBASEE</option>
                <option value="SDSB">SDSB</option>
                <option value="MGHSS">MGHSS</option>
                <option value="SAHSOL">SAHSOL</option>
              </select>
            </div>
            <div className="col-span-full sm:col-span-2">
              <label htmlFor="zip" className="text-sm">
                Is this an EC membery?
              </label>
              <select
                className="select select-bordered w-full max-w-xs"
                name="headOf"
                value={selectedMember.headOf ? "Yes" : "No"}
                onChange={handleInputChange}
              >
                <option value="-">Pick One</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="col-span-full sm:col-span-3">
              <fieldset className="w-full space-y-1 text-gray-800">
                <label for="files" className="block text-sm font-medium">
                  Profile Photo
                </label>
                <div className="flex">
                  <input
                    type="file"
                    name="image"
                    onChange={(e) => handleImageChange(e)}
                    id="files"
                    className="px-8 py-12 border-2 border-dashed rounded-md border-gray-300 text-gray-600 bg-gray-100"
                  />
                </div>
              </fieldset>
            </div>

            
          </div>

          <button className="btn btn-primary mt-6" onClick={handleSubmit}>Update Member</button>
        </div>
      )}



      <div style={{ zIndex: 999999999999999 }}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
};

export default EditSocietyMember;
