import React from 'react';

function Calendar() {
  return (
    <section className="bg-white p-4">
      <h2>Calendar</h2>
      {/* Add your calendar component here */}
    </section>
  );
}

export default Calendar;