import React, { useState } from "react";
import { axiosInstance } from "../Axios";

function EventForm() {
  const [status, setStatus] = useState("");
  const [formData, setFormData] = useState({
    applicant_name: "",
    applicant_designation: "",
    applicant_contact: "",
    applicant_email: "",
    event_name: "",
    is_event_head: false,
    event_heads: "",
    start_date: "",
    end_date: "",
    objective: "",
    is_external_participation: false,
    age_group: 3,
    event_activities: "",
    guest_speaker_required: false,
    guest_speaker_risk: false,
    guest_speaker_details: "",
    registration_form_required: false,
    registration_form_link: "dummy_link",
    auditorium_required: false,
    auditorium_details: "",
    outdoor_preferred: false,
    special_purchases: "",
    edh_service: "",
    ground_men_service: "",
    security_service: "",
    electrical_service: "",
    plumbing_service: "",
    welder_service: "",
    carpenter_service: "",
    labour_service: "",
    meeting_title: "",
    meeting_objective: "",
    poc_name: "",
    poc_contact: "",
    poc_email: "",
    special_requirements: "",
    supporting_doc: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you can handle the form submission. For example, you can send a request to your server with the form data.
    axiosInstance
      .post("event/", {
        formData,
      })
      .then((res) => {
        //console.log(res.data);
        setStatus(res.data);
      })
      .catch((err) => {
        //console.log(err);
        setStatus(err.response.data);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>{status}</div>
      <label>
        Event Name:
        <input type="text" name="event_name" required onChange={handleChange} />
      </label>
      <label>
        Applicant Name:
        <input
          type="text"
          name="applicant_name"
          required
          onChange={handleChange}
        />
      </label>
      <label>
        Applicant Designation:
        <input
          type="text"
          name="applicant_designation"
          required
          onChange={handleChange}
        />
      </label>
      <label>
        Applicant Contact:
        <input
          type="text"
          name="applicant_contact"
          required
          onChange={handleChange}
        />
      </label>

      <label>
        Applicant Email:
        <input
          type="text"
          name="applicant_email"
          required
          onChange={handleChange}
        />
      </label>
      <label>
        Event Heads:
        <input
          type="text"
          name="event_heads"
          required
          onChange={handleChange}
        />
      </label>
      <label>
        Start Date:
        <input
          type="datetime-local"
          name="start_date"
          required
          onChange={handleChange}
        />
      </label>
      <label>
        End Date:
        <input
          type="datetime-local"
          name="end_date"
          required
          onChange={handleChange}
        />
      </label>
      {/* Add the rest of the fields in a similar way */}
      <button type="submit">Submit</button>
    </form>
  );
}

export default EventForm;
