import React, { useContext } from 'react';
import { Page, Document, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import AuthContext from '../context/AuthContext';



const styles = StyleSheet.create({
  page: {
    padding: 30
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const MyDocument = ({ event }) => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text>Event{event?.name}</Text>
        </View>
        <View style={styles.section}>
          <Text>Request Content</Text>
        </View>
        {/* ... Add other sections for each tab ... */}
      </Page>
    </Document>
  );

 

export default function YourComponent() {

    const location = useLocation();
const { event } = location.state || {};
let { user } = useContext(AuthContext);

 console.log("EVENT DATA: "+event,);
    return (
        <>
            <PDFDownloadLink document={<MyDocument />} fileName="download.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
            </PDFDownloadLink>
            {/* ... Your tabs and other components ... */}
        </>
    );
}

