import React, { useContext, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import AuthContext from "../context/AuthContext";
import { axiosInstance } from "../Axios";
import { useLocation } from "react-router-dom";

const FeedbackButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackNature, setFeedbackNature] = useState(null);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFeedbacTextkChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleFeedbacNaturekChange = (event) => {
    setFeedbackNature(event.target.value);
  };



  let { user } = useContext(AuthContext);
//   const location = useLocation();

let currentURL = window.location.href;



  const handleSubmit = () => {
    // Simulate a feedback submission to a sample API endpoint
    axiosInstance
      .post("add_feedback/", {
        username: user?.username,
        role: user?.role,
        text: feedbackText,
        nature: feedbackNature,
        location: currentURL
      })
      .then((response) => {

        let person = user?.username;

        axiosInstance.post("post_notification_feedback/", { person });

        setIsFeedbackSubmitted(true);
        setIsFeedbackSubmitted(false);
        setIsOpen(false);

        toast.success("Your feedback has been received", {
            icon: "💖",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });

      })
      .catch((error) => {
        toast.error("Something went wrong", {
            icon: "🤕",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });

          // console.log("Error: "+error)
      });
  };

  // console.log("User: "+user?.username);
  // console.log("Role: "+user?.role);
  // console.log("Nature: "+feedbackNature);
  // console.log("Feedback: "+feedbackText);
  // console.log("Location: "+currentURL);

  return (
    <div className="fixed bottom-4 right-4" style={{zIndex: '99999999999999999999'}}>

{/* <div className="tooltip tooltip-left" data-tip="Feedback">
<button
        onClick={toggleDropdown}
        className="btn btn-circle btn-lg bg-primary text-white"
      >
        <i className="fas fa-comment-dots text-xl"></i>
      </button>
</div>



      {isOpen && (
        <div className="absolute bottom-14 right-4 bg-white p-4 rounded-lg shadow-lg z-10">

        <h6 >Share Feedback </h6>

        <div className="form-control w-full max-w-xs mb-6">
  <label className="label">
    <span className="label-text">Select Nature</span>
  </label>
  <select className="select select-bordered" value={feedbackNature} onChange={handleFeedbacNaturekChange}>
    <option selected>Pick one</option>
    <option>Bug Report</option>
    <option>General Feedback</option>
  </select>

</div>

          <textarea
            value={feedbackText}
            onChange={handleFeedbacTextkChange}
            className="input input-bordered w-64 h-32 p-4"
            placeholder="Type your feedback..."
          />
          <button className="btn bg-primary text-white mt-2" onClick={handleSubmit}>
            Send <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
</svg>

          </button>
        </div>
      )} */}
    </div>
  );
};

export default FeedbackButton;
