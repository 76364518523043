import React, { useEffect, useState } from "react";
import { axiosInstance } from "../Axios";
import toast from "react-hot-toast";

export default function EditDetails(props) {
  const [guest, setGuests] = useState({
    name: "",
    intro: "",
    twitter_link: "",
    instagram_link: "",
    linkedin: "",
    speech: "",
    org: "",
    role: "",
    consult: "",
    research: "",
    outcome: "",
    file: "",
    filename: "",
    risk: "",
  });

  const [auditorium, setAuditoriums] = useState({
    name: "",
    capacity: "",
    venue: "",
    date: "",
    time: "",
    status: "P",
    conditional: "c",
    feedback: "",
  });

  const [venue, setVenues] = useState({
    name: "",
    activity: "",
    capacity: "",
    venue: "",
    date: "",
    time: "",
    status: "P",
    conditional: "c",
    feedback: "",
  });

  const [purchase, setPurchase] = useState({
    category: "",
    file: "",
    status: "",
    due_date: "",
    details: "",
    selected_category: "",
  });

  const [data, setData] = useState([]);
  const [type, setType] = useState(props.type);
  useEffect(() => {
    axiosInstance
      .get("get_venues/")
      .then((response) => {
        setData(response.data);
        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // setLoading(false);
      });
  }, []);

  useEffect(() => {
    axiosInstance
      .get("get_items/", {
        params: {
          type: props.type,
          id: props.id,
        },
      })
      .then((details) => {
        console.warn("GUEST DETAILS---", details);
        if (props.type === "guest") {
          const data = details.data;
          setGuests(data);
          setRiskLevel(data.risk);
        } else if (props.type === "auditorium") {
          const data = details.data;
          setAuditoriums(data);
        } else if (props.type === "venue") {
          const data = details.data;
          setVenues(data);
        } else if (props.type === "purchase") {
          const data = details.data;
          setPurchase(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching guest details:", error);
      });
  }, []);

  console.warn("GUEST ID---", guest);

  const [riskLevel, setRiskLevel] = useState("");

  const updateArr = (field, value, type) => {
    switch (type) {
      case "guest":
        setGuests((prevGuests) => ({
          ...prevGuests,
          [field]: value,
        }));
        break;
      case "purchase":
        setPurchase((prevPurchase) => ({
          ...prevPurchase,
          [field]: value,
        }));
        break;
      case "venue":
        setVenues((prevVenues) => ({
          ...prevVenues,
          [field]: value,
        }));
        break;
      case "auditorium":
        setAuditoriums((prevAuditoriums) => ({
          ...prevAuditoriums,
          [field]: value,
        }));
        break;
      default:
        break;
    }
  };

  const handleRiskChange = (value) => {
    setRiskLevel(value);
  };
  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    uploadFile(file, type);
  };

  const uploadFile = async (file, type) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      toast.error("Error uploading image");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      toast.success("File uploaded successfully");
      console.warn("URL---", sendData.secure_url);
      if (type === "guest") {
        setGuests((prevGuests) => ({
          ...prevGuests,
          file: sendData.secure_url,
        }));
      } else if (type === "purchase") {
        setPurchase((prevPurchase) => ({
          ...prevPurchase,
          file: sendData.secure_url,
        }));
      }
    }
  };

  const handleSubmit = () => {
    if (type === "guest") {
      var sendData = guest;
    } else if (type === "purchase") {
      var sendData = purchase;
    } else if (type === "auditorium") {
      var sendData = auditorium;
    } else if (type === "venue") {
      var sendData = venue;
    }

    axiosInstance
      .put("update_items/", {
        id: props.id,
        type: type,
        data: sendData,
        event_id: props.event.id,
      })
      .then(() => {
        toast.success("Event Updated Successfully");
        props.onModalClose();
        props.refreshEvent();
        // props.history.push("/events");
      })
      .catch((error) => {
        console.error("Error updating event:", error);
        toast.error("Error updating event");
      });
  };

  return (
    <div>
      <div className="bg-white p-6 rounded ">
        {props.type === "guest" ? (
          <div>
            <div className="form-control w-full pb-6 border-b-2">
              <label className="label">
                <span className="label-text">Pick the Risk Level</span>
              </label>
              {/* <select
                  className="select select-bordered"
                  checked={isChecked_Risk}
                  onChange={handleRiskChange}
                > */}
              <select
                className="select select-bordered"
                value={riskLevel}
                onChange={(e) => handleRiskChange(e.target.value)}
              >
                <option value="">Select One</option>
                <option value="No">No | In-House Experts/Members, etc.</option>
                <option value="Low">Low | Academian/Microfluencer</option>
                <option value="Medium">
                  Medium | Social influencer, Artist
                </option>
                <option value="High">
                  High | Celebrity, Politician, Media personality, etc.
                </option>
              </select>
            </div>

            <label className="bg-black text-white py-2 px-4 mt-4 block w-max rounded-full">
              {riskLevel === "No"
                ? "No Risk"
                : riskLevel === "Low"
                ? "Low Risk"
                : riskLevel === "Medium"
                ? "Medium Risk"
                : riskLevel === "High"
                ? "High Risk"
                : ""}
            </label>
            <div>
              <div>
                <div className="flex gap-4 flex-col bg mt-6">
                  <p className="font-bold"> Guest Profile </p>
                  <div className="bg-gray-100 rounded-lg p-4">
                    <div className="w-full ">
                      <span className="label-text">Guest Name</span>
                      <input
                        type="text"
                        placeholder="John Doe"
                        className="input input-bordered w-full mb-2"
                        value={guest.name}
                        onChange={(e) =>
                          updateArr("name", e.target.value, "guest")
                        }
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <span className="label-text">Guest Introduction</span>
                      <textarea
                        className="textarea textarea-bordered"
                        placeholder="Intro text...."
                        value={guest.intro}
                        onChange={(e) =>
                          updateArr("intro", e.target.value, "guest")
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="flex gap-4 flex-col bg mt-6">
                  <p className="font-bold"> Social Media Accounts </p>
                  <div className="bg-gray-100 rounded-lg p-4 flex-row gap-4 flex">
                    <div className="w-full ">
                      <span className="label-text">Twitter</span>
                      <input
                        type="url"
                        placeholder="twitter.com/"
                        className="input input-bordered w-full mb-2"
                        value={guest.twitter_link}
                        onChange={(e) =>
                          updateArr("twitter_link", e.target.value, "guest")
                        }
                      />
                    </div>
                    <div className="w-full ">
                      <span className="label-text">Instagram</span>
                      <input
                        type="url"
                        placeholder="Instagram.com/"
                        className="input input-bordered w-full mb-2"
                        value={guest.instagram_link}
                        onChange={(e) =>
                          updateArr("instagram_link", e.target.value, "guest")
                        }
                      />
                    </div>
                    <div className="w-full ">
                      <span className="label-text">LinkedIn</span>
                      <input
                        type="url"
                        placeholder="linkedin.com/"
                        className="input input-bordered w-full mb-2"
                        value={guest.linkedin}
                        onChange={(e) =>
                          updateArr("linkedin", e.target.value, "guest")
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-4 flex-col bg mt-6">
                  <p className="font-bold"> Speech Content </p>
                  <div className="bg-gray-100 rounded-lg p-4">
                    <div className="w-full flex flex-col">
                      <span className="label-text">Content of speech</span>
                      <textarea
                        className="textarea textarea-bordered"
                        placeholder="content...."
                        value={guest.speech}
                        onChange={(e) =>
                          updateArr("speech", e.target.value, "guest")
                        }
                      ></textarea>
                    </div>
                    <div className="divider"> OR </div>

                    <div className="form-control w-full ">
                      <label className="label">
                        <span className="label-text">Upload PDF/Word</span>
                      </label>
                      <input
                        type="file"
                        className="file-input file-input-bordered w-full "
                        onChange={(e) => handleFileChange(e, "guest")}
                      />
                    </div>
                  </div>
                </div>

                {(() => {
                  if (riskLevel === "High" || riskLevel === "Medium") {
                    return (
                      <div class="flex gap-4 flex-col bg mt-6">
                        <p className="font-bold"> Associated Organisation </p>
                        <div className="bg-gray-100 rounded-lg p-4 flex-row gap-4 flex">
                          <div className="w-full ">
                            <span className="label-text">
                              Organisation Title
                            </span>
                            <input
                              type="text"
                              placeholder="ABC Cops."
                              className="input input-bordered w-full mb-2"
                              value={guest.org}
                              onChange={(e) =>
                                updateArr("org", e.target.value, "guest")
                              }
                            />
                          </div>
                          <div className="w-full ">
                            <span className="label-text">Role/Designation</span>
                            <input
                              type="url"
                              placeholder="Vice President"
                              className="input input-bordered w-full mb-2"
                              value={guest.role}
                              onChange={(e) =>
                                updateArr("role", e.target.value, "guest")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                })()}

                {(() => {
                  if (riskLevel === "High" || riskLevel === "Medium") {
                    return (
                      <div className="flex gap-4 flex-col bg mt-6">
                        <p className="font-bold">
                          {" "}
                          External Consultation Parties for Approval
                        </p>
                        <div className="bg-gray-100 rounded-lg p-4 flex-row gap-4 flex">
                          <div className="w-full flex flex-col">
                            <span className="label-text">
                              Consultation Parties
                            </span>
                            <textarea
                              className="textarea textarea-bordered"
                              placeholder="Write here...."
                              value={guest.consult}
                              onChange={(e) =>
                                updateArr("consult", e.target.value, "guest")
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })()}

                {(() => {
                  if (
                    riskLevel === "High" ||
                    riskLevel === "Low" ||
                    riskLevel === "Medium"
                  ) {
                    return (
                      <div className="flex gap-4 flex-col bg mt-6">
                        <p className="font-bold">
                          {" "}
                          Details of Research Undertaken{" "}
                        </p>
                        <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                          <div className="w-full flex flex-col">
                            <span className="label-text">Research Process</span>
                            <textarea
                              className="textarea textarea-bordered"
                              placeholder="Write here...."
                              value={guest.research}
                              onChange={(e) =>
                                updateArr("research", e.target.value, "guest")
                              }
                            ></textarea>
                          </div>

                          <div className="w-full flex flex-col">
                            <span className="label-text">Research Outcome</span>
                            <textarea
                              className="textarea textarea-bordered"
                              placeholder="Write here...."
                              value={guest.outcome}
                              onChange={(e) =>
                                updateArr("outcome", e.target.value, "guest")
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        ) : props.type === "venue" ? (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Please provide the preferred dates, timings, seating capacity, and
              select your preferred outdoor venue 🏞️
            </h1>
            <p className="mt-4 text-gray-500">
              Please provide the preferred dates, timing(s), and select your
              desired auditorium from the options provided.
            </p>
            <div>
              <div className="form-control w-full ">
                <div className="flex gap-4 flex-col bg mt-6">
                  <p className="font-bold"> Outside Venue Details</p>
                  <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                    <div className="flex gap-4 flex-row">
                      {/* <div class="w-full">
                            <span class="label-text">Activity</span>
                            <input
                              type="text"
                              placeholder="Activity Name"
                              class="input input-bordered w-full mb-2"
                              value={venue.activity}
                              onChange={(e) => {
                                updateArr(
                                  index,
                                  "activity",
                                  e.target.value,
                                  "venue"
                                );
                                updateArr(index, "name", e.target.value, "venue");
                              }}
                            />
                          </div> */}
                      <div className="w-full">
                        <span className="label-text">Capacity</span>
                        <input
                          type="number"
                          placeholder="No. of participants"
                          className="input input-bordered w-full mb-2"
                          value={venue.capacity}
                          onChange={(e) =>
                            updateArr("capacity", e.target.value, "venue")
                          }
                        />
                      </div>{" "}
                      <div className="w-full">
                        <span className="label-text">Venue</span>
                        <select
                          className="select select-bordered w-full max-w-xs"
                          value={venue.venue}
                          onChange={(e) =>
                            updateArr("venue", e.target.value, "venue")
                          }
                        >
                          <option value="">Select Venue</option>
                          {data
                            .filter((venue) => venue.type === "Outdoor")
                            .map((outdoorVenue) => (
                              <option
                                key={outdoorVenue.id}
                                value={outdoorVenue.name}
                              >
                                {outdoorVenue.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="flex gap-4 flex-row">
                      <div className="w-full">
                        <span className="label-text">Date</span>
                        <input
                          type="date"
                          className="input input-bordered w-full mb-2"
                          value={venue.date}
                          onChange={(e) =>
                            updateArr("date", e.target.value, "venue")
                          }
                        />
                      </div>
                      <div className="w-full">
                        <span className="label-text">Time</span>
                        <input
                          type="time"
                          className="input input-bordered w-full mb-2"
                          value={venue.time}
                          onChange={(e) =>
                            updateArr("time", e.target.value, "venue")
                          }
                        />
                      </div>{" "}
                    </div>
                    <div className="w-full">
                      <span className="label-text">Activity Details</span>
                      <textarea
                        type="text"
                        placeholder="Detailed Description"
                        className="input input-bordered w-full mb-2 p-4"
                        value={venue.activity}
                        onChange={(e) => {
                          updateArr("activity", e.target.value, "venue");
                          updateArr("name", e.target.value, "venue");
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : type === "auditorium" ? (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Please provide the preferred dates, timings, seating capacity, and
              select your preferred auditorium option for the event. 🏟️
            </h1>
            <p className="mt-4 text-gray-500">
              If you have selected 'Yes' for booking an auditorium for your
              meeting, please provide the preferred dates, timing(s), and select
              your desired auditorium from the options provided.
            </p>

            <div>
              <div className="form-control w-full ">
                <div className="flex gap-4 flex-col bg mt-6">
                  <p className="font-bold"> Auditorium Details </p>
                  <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                    <div className="flex gap-4 flex-row">
                      <div className="w-full">
                        <span className="label-text">Capacity</span>
                        <input
                          type="number"
                          placeholder="No. of participants"
                          className="input input-bordered w-full mb-2"
                          value={auditorium.capacity}
                          onChange={(e) =>
                            updateArr("capacity", e.target.value, "auditorium")
                          }
                        />
                      </div>{" "}
                      <div className="w-full">
                        <span className="label-text">Venue</span>
                        <select
                          className="select select-bordered w-full max-w-xs"
                          value={auditorium.venue}
                          onChange={(e) =>
                            updateArr("venue", e.target.value, "auditorium")
                          }
                        >
                          <option value="">Select Venue</option>
                          {data
                            .filter((venue) => venue.type === "Indoor")
                            .map((indoorVenue) => (
                              <option
                                key={indoorVenue.id}
                                value={indoorVenue.name}
                              >
                                {indoorVenue.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="flex gap-4 flex-row">
                      <div className="w-full">
                        <span className="label-text">Date</span>
                        <input
                          type="date"
                          className="input input-bordered w-full mb-2"
                          value={auditorium.date}
                          onChange={(e) =>
                            updateArr("date", e.target.value, "auditorium")
                          }
                        />
                      </div>
                      <div className="w-full">
                        <span className="label-text">Time</span>
                        <input
                          type="time"
                          className="input input-bordered w-full mb-2"
                          value={auditorium.time}
                          onChange={(e) =>
                            updateArr("time", e.target.value, "auditorium")
                          }
                        />
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : type === "purchase" ? (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Any special purchase requests for the event? 🛒
            </h1>
            <p className="mt-4 text-gray-500">
              We want to make sure your event is a smashing success, and that
              means taking care of all the details. If you have any special
              purchase requests to enhance the event experience, such as
              decorations, equipment, rental service, printing, artist
              performance, or other items, please let us know. We're here to
              help bring your vision to life, so feel free to share your
              purchase requests in the field below.
            </p>

            <div>
              <div className="form-control w-full ">
                <label className="label">
                  <span className="label-text">Pick the category</span>
                </label>
                <select
                  className="select select-bordered"
                  value={purchase.category}
                  onChange={(e) =>
                    updateArr("category", e.target.value, "purchase")
                  }
                >
                  <option value="Service/Rental/Artist">Service/Rental</option>
                  <option value="Artist">Artist</option>
                  <option value="External Material">External Material</option>
                  <option value="Printing">Printing</option>
                </select>
              </div>

              <div className="flex gap-4 flex-col bg mt-6">
                <p className="font-bold"> Purchase Details </p>
                <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                  <div className="w-full flex flex-col">
                    <span className="label-text">Due Date</span>
                    <input
                      type="date"
                      placeholder="Type here"
                      className="input input-bordered w-full "
                      value={purchase.due_date}
                      onChange={(e) =>
                        updateArr("due_date", e.target.value, "purchase")
                      }
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <span className="label-text">
                      Complete List of items/details
                    </span>

                    <div className="w-full flex flex-col">
                      <div className="w-full flex flex-col gap-3">
                        <label>Name:</label>
                        <input
                          type="text"
                          className="input input-bordered w-full "
                          value={purchase.details}
                          onChange={(e) =>
                            updateArr("details", e.target.value, "purchase")
                          }
                        />

                        <input
                          type="text"
                          className="input input-bordered w-full hidden"
                          value={purchase.status}
                          onChange={(e) =>
                            updateArr("status", e.target.value, "purchase")
                          }
                        />

                        <div className="form-control w-full ">
                          <label className="label">
                            <span className="label-text">Upload Sample(s)</span>
                          </label>
                          <input
                            type="file"
                            className="file-input file-input-bordered w-full "
                            onChange={(e) => handleFileChange(e, "purchase")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">Error</h1>
          </div>
        )}
      </div>
      <div className="text-right">
        <button
          type="submit"
          className="btn btn-primary mr-2"
          onClick={() => {
            handleSubmit();
            props.onModalClose();
          }}
        >
          edit {type}
        </button>
        <button onClick={props.onModalClose} className="btn btn-ghost">
          Cancel
        </button>
      </div>
    </div>
  );
}