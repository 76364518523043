import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../Axios";
import SocNav from "./SocNav";
import SocietySideInnerSM from "./SocietySideInnerSM";
import SocietySideInner from "./SocietySideInner";
import { toast, Toaster } from "react-hot-toast";
import AuthContext from "../context/AuthContext";
import NotificationComponent from "./NotificationComponent";
import ReactModal from "react-modal";

export default function AddSociety() {
  const [err, setErr] = useState("");
  const [formData, setFormData] = useState([
    {
      username: "",
      email: "",
      password: "",
      name: "",
    },
  ]);

  const { user } = useContext(AuthContext);

  const [societies, setSocieties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false); // State for show/hide password
  const [deleteConfirmation, setDeleteConfirmation] = useState(""); // State for delete confirmation input
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSociety, setselectedSociety] = useState(null);
  const [editSocietyId, seteditSocietyId] = useState(null);

  const handleEditClick = (event) => {
    console.warn(event);
    seteditSocietyId(event.id);
    setShowEditModal(true);
    setselectedSociety(event);
  };

  useEffect(() => {
    if (selectedSociety) {
      const { name, username, email, password } = selectedSociety;
      setEditFormData({
        name,
        username,
        email,
        password: "",
      });
    }
  }, [selectedSociety]);

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Load societies when the component mounts
    loadSocieties();
  }, []);

  const [editFormData, setEditFormData] = useState({
    username: "",
    email: "",
    password: "",
    name: "",
  });

  const handleConfirmChanges = () => {
    handleEdit(editSocietyId);
    setShowEditModal(false);
  };

  const handleEdit = (society_id) => {
    axiosInstance
      .put("update_society/", { society_id, data: editFormData })
      .then((response) => {
        // Update the admin list after a successful edit
        loadSocieties();
        toast.success("Society updated successfully", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((error) => {
        toast.error("Error!", {
          icon: "",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        console.error("ERROR: ", error);
      });
  };

  const loadSocieties = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get("get_societies/");
      setSocieties(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const addArr = () => {
    setFormData([
      ...formData,
      {
        username: "",
        email: "",
        password: "",
        name: "",
      },
    ]);
  };

  const removeArr = (index) => {
    const newData = [...formData];
    newData.splice(index, 1);
    setFormData(newData);
  };

  const handleChange = (event, memberIndex) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      const updatedFormData = [...prevData];
      updatedFormData[memberIndex] = {
        ...updatedFormData[memberIndex],
        [name]: value,
      };
      return updatedFormData;
    });
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  //   if (!passwordRegex.test(formData.password)) {
  //     setErr(
  //       "Password must be at least 8 characters long and contain at least one uppercase letter and one lowercase letter."
  //     );
  //     return;
  //   }

  //   try {
  //     const response = await axiosInstance.post("create_society/", formData);
  //     setErr("Society created successfully!");

  //     toast.success("Society Added", {
  //       icon: "👏",
  //       style: {
  //         borderRadius: "55px",
  //         marginBottom: "15px",
  //       },
  //     });

  //     setFormData({
  //       username: "",
  //       email: "",
  //       password: "",
  //       name: "",
  //     });

  //     loadSocieties();

  //     setTimeout(() => {
  //       setErr("");
  //     }, 1500);
  //   } catch (error) {
  //     setErr(error.response.data);
  //     console.error(error);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    // if (!passwordRegex.test(formData.password)) {
    //   setErr(
    //     "Password must be at least 8 characters long and contain at least one uppercase letter and one lowercase letter."
    //   );
    //   return;
    // }

    try {
      const response = await axiosInstance.post("create_society/", formData);
      setErr("Society created successfully!");

      toast.success("Society(s) Added", {
        icon: "👏",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });

      // Extract the society name from the form data
      const society_name = formData.name;

      // Post a notification when a society is created, including the society name
      await axiosInstance.post("post_notification_society_create/", {
        society_name,
      });

      setFormData([
        {
          username: "",
          email: "",
          password: "",
          name: "",
        },
      ]);

      loadSocieties();

      setTimeout(() => {
        setErr("");
      }, 1500);
    } catch (error) {
      setErr(error.response.data);
      console.error(error);
    }
  };

  const handleDeleteSociety = async (societyId) => {
    // Prompt the user to type "DELETE" to confirm deletion
    const userInput = window.prompt(
      "Type 'DELETE' to confirm society deletion:"
    );

    if (userInput === "DELETE") {
      try {
        await axiosInstance.delete(`delete_society/${societyId}/`);
        toast.success("Society Deleted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });

        const society = societies.find((society) => society.id === societyId);
        const societyName = society.name;

        axiosInstance.post("post_notification_delete_society/", {
          societyName,
        });

        loadSocieties(); // Reload the list of societies after deletion
      } catch (error) {
        setErr(error.response.data);
        console.error(error);
      }
    } else {
      // The user did not type "DELETE," so the deletion is not confirmed.
      alert("Deletion not confirmed.");
    }
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  return (
    <div>
      <div className="absolute w-full bg-primary min-h-75"></div>
      <SocietySideInner />
      <SocietySideInnerSM />
      <SocNav toggleSidebar={toggleSidebar} />
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
        <div className="w-full px-6 py-6 mx-auto">
          <div className="min-h-screen flex items-center justify-center">
            <div className="bg-white p-8 rounded shadow-md w-1/2">
              <div className="mb-4">
                <button
                  onClick={() => setActiveTab(1)}
                  className={`${
                    activeTab === 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300 text-gray-600"
                  } py-2 px-4 mr-4 rounded-full`}
                >
                  All Societies
                </button>
                <button
                  onClick={() => setActiveTab(2)}
                  className={`${
                    activeTab === 2
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300 text-gray-600"
                  } py-2 px-4 rounded-full`}
                >
                  Add Society
                </button>
              </div>
              {activeTab === 1 ? (
                <div>
                  <h2 className="text-2xl font-semibold mb-4">All Societies</h2>
                  {isLoading ? (
                    <p>Loading...</p>
                  ) : (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {societies.map((society) => (
                          <tr key={society.id}>
                            <td>{society?.name}</td>
                            <td>{society?.email}</td>
                            <td>
                              {user["role"] === "manager" && (
                                <div>
                                  <button
                                    // onClick={() => handleEditClick(event)}
                                    // onClick={() => handleEditClick(admin)}
                                    onClick={() => handleEditClick(society)}
                                    className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      class="w-5 h-5"
                                    >
                                      <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                                    </svg>
                                  </button>

                                  <button
                                    onClick={() =>
                                      handleDeleteSociety(society.id)
                                    }
                                    className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      class="w-5 h-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              ) : (
                <div>
                  <h2 className="text-2xl font-semibold mb-4">Add a Society</h2>
                  {formData.map((member, index) => (
                    <form>
                      <div className="mb-4">
                        <label
                          htmlFor="username"
                          className="block text-gray-600"
                        >
                          Username:
                        </label>
                        <input
                          type="text"
                          name="username"
                          value={member.username}
                          onChange={(e) => handleChange(e, index)}
                          className="w-full border rounded p-2"
                          placeholder="eg: LUMUN"
                          required
                        />

                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-600"
                          >
                            Email:
                          </label>
                          <input
                            type="email"
                            name="email"
                            value={member.email}
                            onChange={(e) => handleChange(e, index)}
                            className="w-full border rounded p-2"
                            placeholder="eg: email@lums.edu.pk"
                            required
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="password"
                            className="block text-gray-600"
                          >
                            Set Password:
                          </label>
                          <div className="flex">
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={member.password}
                              onChange={(e) => handleChange(e, index)}
                              className="w-full border rounded p-2"
                              placeholder="Case sensitive. Must contain Letters, Numbers, and Special Chars"
                              required
                            />
                            <button
                              onClick={() => setShowPassword(!showPassword)}
                              type="button"
                              className="ml-2 px-3 py-1 bg-gray-300 text-gray-600 rounded-full focus:outline-none"
                            >
                              {showPassword ? "Hide" : "Show"}
                            </button>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label htmlFor="name" className="block text-gray-600">
                            Name:
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={member.name}
                            onChange={(e) => handleChange(e, index)}
                            className="w-full border rounded p-2"
                            placeholder="LUMS Photo Society"
                            required
                          />
                        </div>
                      </div>
                      <div className="mt-4 flex flex-row gap-2 justify-start">
                        {formData.length > 1 && (
                          <button
                            className="btn btn-outlined btn-sm font-bold"
                            onClick={() => removeArr(index)}
                          >
                            - Remove
                          </button>
                        )}
                      </div>
                    </form>
                  ))}

                  <div className="mt-4 flex flex-row gap-2 justify-start">
                    <button
                      className="btn btn-outlined btn-sm font-bold"
                      onClick={addArr}
                    >
                      + Add More
                    </button>
                  </div>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full"
                    onClick={handleSubmit}
                  >
                    Add Society
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <ReactModal
        className={`w-5/12 h-min m-auto mt-8 rounded-xl bg-white transform transition-all duration-300 ${
          showEditModal ? "opacity-100 scale-100" : "opacity-0 scale-95"
        }`}
        overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-50 transition-opacity duration-300"
        isOpen={showEditModal}
        onRequestClose={() => setShowEditModal(false)}
      >
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Edit Society</h2>
            <button
              className="btn btn-error btn-circle"
              onClick={() => setShowEditModal(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="mb-4">
            <label htmlFor={`username-${1}`} className="block text-gray-600">
              Username:
            </label>
            <input
              type="text"
              name={`username`}
              value={editFormData?.username}
              onChange={(e) => handleEditChange(e)}
              className="w-full border rounded p-2"
              placeholder="eg: john.doe"
              required
            />

            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-600">
                Email:
              </label>
              <input
                type="email"
                name={`email`}
                value={editFormData?.email}
                onChange={(e) => handleEditChange(e)}
                className="w-full border rounded p-2"
                placeholder="eg: email@lums.edu.pk"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-600">
                Password:
              </label>
              <div className="flex">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={(e) => handleEditChange(e)}
                  className="w-full border rounded p-2"
                  // placeholder="Case sensitive. Must contain Letters, Numbers and Special Chars"
                  required
                />
                <button
                  // onClick={handleShowPassword}
                  type="button"
                  className="ml-2 px-3 py-1 bg-gray-300 text-gray-600 rounded-full focus:outline-none"
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-600">
                Name:
              </label>
              <input
                type="text"
                name="name"
                value={editFormData?.name}
                onChange={(e) => handleEditChange(e)}
                className="w-full border rounded p-2"
                placeholder="John Doe"
                required
              />
            </div>
          </div>
          <button
            onClick={handleConfirmChanges}
            className="btn btn-primary mt-6"
          >
            Confirm Changes
          </button>
        </div>
      </ReactModal>
      <div style={{ zIndex: 999999999999999 }}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
}
