import React from "react";
import ContentLoader from "react-content-loader";

const SkeletonLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={100}
      viewBox="0 0 400 100"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="10" y="10" rx="3" ry="3" width="80" height="10" />
      <rect x="10" y="30" rx="3" ry="3" width="100" height="10" />
      <rect x="10" y="50" rx="3" ry="3" width="120" height="10" />
      <rect x="10" y="70" rx="3" ry="3" width="80" height="10" />
    </ContentLoader>
  );
};

export default SkeletonLoader;
