import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { axiosInstance } from "../Axios";
import { toast, Toaster } from 'react-hot-toast';
const UpdatePasswordPage = () => {
  const { id } = useParams(); // Get the user ID from the URL
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // New state for confirm password
  const [message, setMessage] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true); // State to track password matching
  const [successUI, setsuccessUI] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      try {
        const response = await axiosInstance.post(`update_password/${id}/`, {
          new_password: newPassword,
        });

        if (response.status === 200) {

          setPasswordsMatch(true);
          setsuccessUI(current => !current);
          toast.success('Password Changed. You can now sign in', {
            icon: '✅',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
        } else {
          toast.error('Error updating password. Please reload the page.', {
            icon: '😰',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
        }
      } catch (error) {
        toast.error('Error updating password.' + error, {
          icon: '😰',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        console.error(error);
      }
    } else {
      // Passwords don't match, show an error message
      setPasswordsMatch(false);
    }
  };


  const [loader, setloader] = useState(false);

  const handleClick = () => {
    setloader(true);

    // Simulate a delay of 4 seconds before hiding the spinner
    setTimeout(() => {
      setloader(false);
    }, 4000);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to mimic content loading
    setTimeout(() => {
      setLoading(false); // Set loading to false when content is loaded
    }, 2000); // Adjust the delay as needed
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>


<div>
      <div>
        {loading ? (
          <div className="flex items-center justify-center h-screen gap-2">
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          </div>
        ) : (
          // Your page content goes here
          <div>
            <main class="mt-0 transition-all duration-200 ease-in-out">
              <section>
                <div class="relative flex items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
                  <div class="container z-1">
                    <div class="flex flex-wrap -mx-3">
                      <div class="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
                        
                      {successUI ? (
                        <div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 rounded-2xl bg-clip-border">
                          <div class="p-6 pb-0 mb-0">


                            <h4 class="font-bold flex flex-row items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-green-400">
  <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
</svg>
Password Changed</h4>
                            <p class="mb-0">
                              You can now go back and login.
                            </p>
                          </div>
                          <div class="p-6">
                            <div class="border-black/12.5 rounded-b-2xl border-t-0 border-solid text-start pt-0 px-1 mt-4">

                                <Link
                                  to="/"
                                  class="btn bg-primary text-white font-semibold bg-clip-text bg-gradient-to-tl from-blue-500 to-violet-500"
                                >
                                  Go Back <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
</svg>

                                </Link>

                            </div>


                          </div>
                        </div>
      ) : 
      

      <div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 rounded-2xl bg-clip-border">
                          <div class="p-6 pb-0 mb-0">

                            <h4 class="font-bold">Update Password</h4>
                            <p class="mb-0">
                              Enter new password
                            </p>
                          </div>
                          <div class="flex-auto p-6">
                          <form role="form" onSubmit={handleSubmit}>
                    <div className="mb-4 relative"> {/* Add a relative class to create a relative positioning context */}
                      <input
                        type={showPassword ? "text" : "password"} // Toggle input type
                        name="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter New Password"
                        className="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                      />
                      {/* Password visibility toggle icon */}
                      <span
                        className="cursor-pointer absolute top-0 right-2 mt-3 text-gray-600"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? "👁️" : "🙈"}
                      </span>
                    </div>
                    <div className="mb-4 relative"> {/* Add a relative class to create a relative positioning context */}
                      <input
                        type={showPassword ? "text" : "password"} // Toggle input type
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm New Password"
                        className="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                      />
                      {/* Password visibility toggle icon */}
                      <span
                        className="cursor-pointer absolute top-0 right-2 mt-3 text-gray-600"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? "👁️" : "🙈"}
                      </span>
                    </div>
                    {!passwordsMatch && (
                      <div className="text-red-600">
                        Passwords do not match. Please try again.
                      </div>
        )}
                    <div className="text-center">
                      <button
                        type="submit"
                        onClick={handleClick}
                        disabled={loading}
                        className="inline-block w-full px-16 py-3.5 mt-6 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-primary border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25"
                      >
                        {loader ? (
                          <div className="spinner-border text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Update Password"
                        )}
                      </button>
                    </div>
                  </form>

                            <div class="border-black/12.5 rounded-b-2xl border-t-0 border-solid p-6 text-center pt-0 px-1 sm:px-6 mt-4">
                              <p class="mx-auto mb-6 leading-normal text-sm">
                                Back to{" "}
                                <Link
                                  to="/"
                                  class="font-semibold text-transparent bg-clip-text bg-gradient-to-tl from-blue-500 to-violet-500"
                                >
                                  Sign In
                                </Link>
                              </p>
                            </div>

                            {message && <div>

                                <div className="flex shadow-md gap-6 rounded-lg overflow-hidden divide-x max-w-2xl bg-gray-50 text-gray-800 divide-gray-300">
	<div className="flex flex-1 flex-col p-4 border-l-8 border-violet-600">
		<span className="text-2xl">Message</span>
		<span className="text-xs text-gray-600">{message}.</span>
	</div>
</div>
                            
                            </div>}
                          </div>
                        </div>
      
      }
                        




                      </div>
                      <div class="absolute top-0 right-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
                        <div class="relative flex flex-col justify-center h-full bg-cover px-24 m-4 overflow-hidden bg-[url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg')] rounded-xl ">
                          <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-blue-500 to-violet-500 opacity-60"></span>
                          <img
                            src="https://res.cloudinary.com/ahbranding/image/upload/v1697018743/AHbranding/Asset_5_sw4io5.png"
                            className="w-1/2 mb-4 mx-auto"
                          />
                          <h4 class="z-20 mt-12 font-bold text-white">
                            Welcome to CampusConnect
                          </h4>
                          <p class="z-20 text-white ">
                            The hub for LUMS society leaders. Streamline your
                            event approvals, manage your roster, and champion
                            your society's growth with ease.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        )}
      </div>

      <div style={{ zIndex: 999999999999999}}>
        <Toaster position="bottom-center" />
      </div>
    </div>


      {/* <form>
        <div>
          <label>New Password:</label>
          <input
            type="text"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <button type="submit">Update Password</button>
      </form>
      {message && <p>{message}</p>} */}
    </div>
  );
};

export default UpdatePasswordPage;
