import React, { useState, useEffect, useContext } from "react";

import { Link, Navigate, Redirect } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SocietySidebar from "./SocietySidebar";
import MobileBottom from "./MobileBottom";
import SocietyStats from "./SocietyStats";
import SocietyEventsWidget from "./SocietyEventsWidget";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MembersList from "./MembersList";
import HierarchyMap from "./HierarchyMap";
import RepoList from "./RepoList";
const Repository = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  // initalize thread and posts component state
  const [profile, setProfile] = useState();

  useEffect(() => {
    const getProfile = async () => {
      if (user !== null) {
        let userID = user["user_id"];
        let profileID = profile?.name;
        const response = await fetch(`/api/profile/${userID}`);

        // parse the data in json
        let data = await response.json();

        setProfile(data);
      }
    };
    getProfile();

    fetch("./society_events.json")
      .then((response) => response.json())
      .then((jsonData) => setData(jsonData))
      .catch((error) => console.log(""));
  }, [user]);

  let userID = user["user_id"];
  if (user["role"] === "Student") {
    window.location.replace(`/student/${userID}`);
  } else if (user == null) {
    window.location.replace("/login");
  }

  const [dateState, setDateState] = useState(new Date());
  const changeDate = (e) => {
    setDateState(e);
  };

  let role = "";
  if (profile?.status == "2") {
    role = "Student";
  }

  const [data, setData] = useState([]);

  return (
    <div>
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}

      {/* Static sidebar for desktop */}
      <SocietySidebar />

      <div className="md:pl-64 flex flex-col flex-1 h-screen" >
        <MobileBottom />
        <main className="flex-1">
          <div className="">
            <header
              aria-label="Page Header"
              className="bg-white"
              style={{ position: "sticky", top: "0" }}
            >
              <div
                className="mx-auto max-w-screen-xl px-4 py-6 sm:px-6 sm:py-6 lg:px-8"
                style={{ boxShadow: "0px 10px 13px -13px rgba(0,0,0,0.1)" }}
              >
                <div className="sm:flex sm:items-center sm:justify-between headerMob">
                  <div className="text-center sm:text-left">
                    <h1 className="text-xl font-bold text-gray-900 sm:text-xl">
                      Repository
                    </h1>
                    <p className="mt-1.5 text-sm text-gray-500">
                      Overview of Repository
                    </p>
                  </div>

                  <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
                    {/* {(() => {
        if (totalScore<500) {
          return (
            <img className='w-20 h-20' src="https://res.cloudinary.com/ahbranding/image/upload/v1684394475/AHbranding/Group_34784162_xvvthl.png"/>
          )
        } else if (totalScore>=500 && totalScore<1500) {
          return (
            <img className='w-20 h-20' alt='avatar' src="https://res.cloudinary.com/ahbranding/image/upload/v1684394476/AHbranding/Group_34784161_rtgryo.png" />
          )
        } else if (totalScore>=1599 && totalScore<3000) {
          return (
            <img className='w-20 h-20' alt='avatar' src="https://res.cloudinary.com/ahbranding/image/upload/v1684394477/AHbranding/Group_34784163_vtidop.png" />
          )
        }
      })()}*/}

                    <button
                      type="button"
                      class="group flex shrink-0 items-center rounded-lg transition dropdown dropdown-bottom dropdown-end gap-2"
                      tabIndex={0}
                    >
                      <span class="sr-only">Menu</span>

                      <img
                        className="w-12 h-12"
                        src={profile?.avatar}
                        style={{
                          border: "1px solid #00000033",
                          borderRadius: "100px",
                        }}
                      />
                      {/* {(() => {
        if (totalScore<500) {
          return (
            <img class="h-10 w-10 rounded-full object-cover" src="https://res.cloudinary.com/ahbranding/image/upload/v1684394475/AHbranding/Group_34784162_xvvthl.png"/>
          )
        } else if (totalScore>=500 && totalScore<1500) {
          return (
            <img class="h-10 w-10 rounded-full object-cover" alt='avatar' src="https://res.cloudinary.com/ahbranding/image/upload/v1684394476/AHbranding/Group_34784161_rtgryo.png" />
          )
        } else if (totalScore>=1599 && totalScore<3000) {
          return (
            <img class="h-10 w-10 rounded-full object-cover" alt='avatar' src="https://res.cloudinary.com/ahbranding/image/upload/v1684394477/AHbranding/Group_34784163_vtidop.png" />
          )
        }
      })()}*/}

                      <p class="ms-2 hidden text-left text-xs sm:block">
                        <strong class="block text-base font-medium">
                          {profile?.firstname} {profile?.lastname}
                        </strong>

                        <span class="text-white font-sm badge badge-primary">
                          {" "}
                          {role}{" "}
                        </span>
                      </p>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="ms-4 hidden h-5 w-5 text-gray-500 transition group-hover:text-gray-700 sm:block"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>

                      <ul
                        tabIndex={0}
                        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                      >
                        <li className="text-sm">
                          <a>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                            Settings
                          </a>
                        </li>
                        <li className="text-sm">
                          <a>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                              />
                            </svg>
                            Profile
                          </a>
                        </li>
                        <li className="text-sm">
                          <a className="text-red-400">
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                              />
                            </svg>
                            Logout
                          </a>
                        </li>
                      </ul>
                    </button>
                  </div>
                </div>
              </div>
            </header>

            <div className="px-2 sm:px-2 md:px-2 NetworkDashboard_leftside w-full">
              <div>
                <div className="flex items-center justify-between gap-8 chartsParent">
                  <section className="text-gray-600 body-font stats_lg w-full">
                    <div className="container px-4 py-4 mx-auto bg-white my-8 rounded-lg">
                      <div className="flex flex-col justify-between text-start w-full mb-6 dash-robbon">
                        <div className="text-center sm:text-left">
                          <h1 className="text-xl font-bold text-gray-900 sm:text-xl">
                            Society Events
                          </h1>
                          <p className="mt-1.5 text-sm text-gray-500">
                            List of all events scheduled in LUMS
                          </p>
                        </div>

                        <RepoList />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default Repository;
