import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import "react-calendar/dist/Calendar.css";
import "react-tabs/style/react-tabs.css";
import { axiosInstance } from "../Axios";
import { toast, Toaster } from 'react-hot-toast';

const MembersList = ({ fetchedMemberList }) => {
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  let USERID = user['user_id'];

  const [societyProfile, setSocietyProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Make an HTTP GET request to fetch the society profile
    axiosInstance.get(`society/profile/${USERID}/`)
      .then((response) => {
        setSocietyProfile(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching society profile:", error);
        setLoading(false);
      });
  }, [USERID]);

 // console.log("ADMIN: "+societyProfile?.ccaadmins)

  // initalize thread and posts component state
  const [memberList, setMemberList] = useState(fetchedMemberList);

  const [selectedMember, setSelectedMember] = useState(null);
  const [newDesignation, setNewDesignation] = useState("");

  const promoteMember = (index) => {
    const member = memberList[index];
    axiosInstance
      .post(`requests/promote/`, {
        member_id: member.id,
        designation: newDesignation,
        name: member.name,
        current_designation: member.designation,
      })
      .then((res) => {
        ////console.log(res);

        let memberName = member.name
        let postedBY = societyProfile?.ccaadmins
        console.log("soc: "+postedBY)
        axiosInstance.post("post_notification_member_promo_req/", { memberName, postedBY });

        toast.success('Request sent to Admin for approval', {
          icon: '⌚',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      })
      .catch((err) => {
        ////console.log(err);
        toast.error('Oh No! Something went wrong', {
          icon: '😓',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      });
    // const newMemberList = [...memberList];
    // newMemberList[index] = {
    //   ...newMemberList[index],
    //   designation: newDesignation,
    //   status: "Active",
    // };
    // setMemberList(newMemberList);
    ////console.log(member.id, newDesignation);
  };

  const suspendMember = (index) => {
    const member = memberList[index];
    axiosInstance
      .post(`requests/suspend/`, {
        member_id: member.id,
        name: member.name,
        current_designation: member.designation,
      })
      .then((res) => {
        ////console.log(res);

        let memberName = member.name
        let postedBY = societyProfile?.ccaadmins
        console.log("soc: "+postedBY)
        axiosInstance.post("post_notification_member_promo_req/", { memberName, postedBY });

        toast.success('Request sent to Admin for approval', {
          icon: '⌚',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      })
      .catch((err) => {
        ////console.log(err);
        toast.error('Oh No! Something went wrong', {
          icon: '😓',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      });
    // const newMemberList = [...memberList];
    // newMemberList[index] = {
    //   ...newMemberList[index],
    //   status: "Suspended",
    //   designation: "Suspended",
    // };
    // setMemberList(newMemberList);
  };

  const handleSubmit = (event) => {
    ////console.log(newDesignation);
  };

 // //console.log(memberList);

  return (
    <div>
      <div className="overflow-x-auto" >
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              <th>Name</th>
              <th>Designation</th>
              <th>School</th>
              <th>Status</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {/* row 1 */}

            {/* row 3 */}
            {memberList &&
              memberList.map((member, index) => {
                //console.log(member);
                return (
                  <tr>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div className="avatar">
                          <div className="mask mask-squircle w-12 h-12">
                          {member.image===null ? (
                            <img
                              src={"https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"}
                            />
                            ) : (
                              <img
                              src={"https://cca.startupearly.com/api" + member.image}
                            />
                            )}
                            
                          </div>
                        </div>
                        <div>
                          <div className="font-bold">{member.name}</div>
                          <div className="text-sm opacity-50">
                            {member.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{member.designation}</td>

                    <td>
                      {member.school}
                      {/* {member.headOf === undefined ? (
                        <p>-</p>
                      ) : (
                        <ul style={{ listStyle: "disc" }}>
                          <li>{member.headOf}</li>
                        </ul>
                      )} */}
                    </td>
                    <td>
                      {" "}
                      <span
                        className={
                          member.status === "Active"
                            ? "badge badge-success"
                            : "badge badge-error"
                        }
                      >
                        {member.status}
                      </span>{" "}
                    </td>
                    <th className="dropdown">
                      <label tabIndex={0} className="btn bg-white m-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                          />
                        </svg>
                      </label>

                      <ul
                        tabIndex={0}
                        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box "
                        style={{ width: "min-content", zIndex: "9999999" }}
                      >
                        <li>
                          <a
                            className="text-success"
                            onClick={() => {
                              window.my_modal_5.showModal();
                              setSelectedMember(index);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                              />
                            </svg>{" "}
                            Promote{" "}
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-error"
                            onClick={() => suspendMember(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>{" "}
                            Suspend{" "}
                          </a>
                        </li>
                      </ul>
                    </th>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle">
          <form method="dialog" className="modal-box">
            <h3 className="font-bold text-lg">Promote Member</h3>

            <div className="form-control w-full max-w-xs mt-4">
              <label className="label">
                <span className="label-text">Select new position</span>
              </label>
              <select
                className="select select-bordered"
                onChange={(e) => setNewDesignation(e.target.value)}
              >
                <option disabled selected>
                  Pick one
                </option>
                <option>Vice President</option>
                <option>Directorate</option>
                <option>Project Head</option>
              </select>
            </div>

            <div className="modal-action">
              {/* if there is a button in form, it will close the modal */}
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleSubmit();
                  promoteMember(selectedMember);
                }}
              >
                Save
              </button>
              <button className="btn">Close</button>
            </div>
          </form>
        </dialog>
      </div>

      <div style={{ zIndex: 999999999999999}}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
};
export default MembersList;
