import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { axiosInstance } from "../Axios";
import AuthContext from "../context/AuthContext";
import SocietySidebar from "../components/SocietySidebar";
import SocietySideInnerSM from "../components/SocietySideInnerSM";
import SocNav from "../components/SocNav";
import { toast, Toaster } from "react-hot-toast";
function AdminProfile() {
  const { user } = useContext(AuthContext);
  const userID = user["user_id"];
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  const [profileData, setProfileData] = useState({
    name: "",
    about_society: "",
    mission: "",
    vision: "",
    objectives: "",
    core_values: "",
    website: "",
    social_fb: "",
    social_ig: "",
    social_li: "",
    social_tw: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  let getendpoint = "";
  let postendpoint = "";

  if (user["role"] === "ccaAdmin") {
    getendpoint = `/admin/profile/${userID}/`;
    postendpoint = `/admin/profile/${userID}/update/`;
  } else if (user["role"] === "manager") {
    getendpoint = `/manager/profile/${userID}/`;
    postendpoint = `/manager/profile/${userID}/update/`;
  } else if (user["role"] === "society") {
    getendpoint = `/society/profile/${userID}/`;
    postendpoint = `/society/profile/${userID}/update/`;
  }

  useEffect(() => {
    axiosInstance
      .get(getendpoint)
      .then((response) => {
        const data = response.data;
        setProfileData({
          name: data.name,
          about_society: data.about_society,
          mission: data.mission,
          objectives: data.objectives,
          vision: data.vision,
          core_values: data.core_values,
          website: data.website,
          social_fb: data.social_fb,
          social_ig: data.social_ig,
          social_li: data.social_li,
          social_tw: data.social_tw,
        });

        setProfilePhotoUrl(data.profile_photo);
        console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = () => {
    console.log("profileData:", profileData); // Log the data to the console
    axiosInstance
      .post(postendpoint, profileData)
      .then((response) => {
        //console.log("Profile updated successfully");
        toast.success("Data saved successfully", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setIsEditing(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Oh No! Something went wrong", {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  // const handleChange = (event) => {
  //     const { name, value, type, checked } = event.target;

  //     // Special handling for checkboxes
  //     const newValue = type === "checkbox" ? checked : value;

  //     // Spread the existing profileData and update the specific field
  //     setProfileData((prevData) => ({
  //         ...prevData,
  //         [name]: newValue,
  //     }));
  // };

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle profile photo input separately
    if (name === "profile_photo" && files.length > 0) {
      setProfilePhoto(files[0]);
    } else {
      // Handle other input fields
      const newValue = type === "checkbox" ? checked : value;
      setProfileData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
  };

  // const handleFileUpload = () => {
  //   const formData = new FormData();
  //   formData.append("profile_photo", profilePhoto);

  //   // Make a POST request to update the profile with the new photo
  //   axiosInstance
  //     .post(postendpoint, formData)
  //     .then((response) => {
  //       //console.log("Profile updated successfully");
  //       toast.success("Photo uploaded successfully", {
  //         icon: "📸",
  //         style: {
  //           borderRadius: "55px",
  //           marginBottom: "15px",
  //         },
  //       });
  //       window.location.reload();
  //       setIsEditing(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       toast.error("Oh No! Something went wrong", {
  //         icon: "😓",
  //         style: {
  //           borderRadius: "55px",
  //           marginBottom: "15px",
  //         },
  //       });
  //     });
  // };

  const uploadProfilePic = async (e) => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      if (!response.ok) {
        toast.error("Error uploading image");
        throw new Error("Image upload failed");
      }
      const sendData = await response.json();
      // toast.success("File uploaded successfully");
      console.warn("URL---", sendData.secure_url);
      setProfilePhoto(sendData.secure_url);

      const formData = new FormData();
      formData.append("profile_photo", sendData.secure_url);

      await axiosInstance.post(postendpoint, formData);
      window.location.reload();

      toast.success("Photo uploaded successfully", {
        icon: "📸",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
      setIsEditing(false);
    } catch (error) {
      console.error(error);
      toast.error("Oh No! Something went wrong", {
        icon: "😓",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    }
  };
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  return (
    <div>
      <div>
        <>
          <div class="absolute w-full bg-primary  min-h-75"></div>

          <SocietySidebar />

          {isSidebarVisible && <SocietySideInnerSM />}

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
            {/* Navbar */}
            <SocNav toggleSidebar={toggleSidebar} />

            {/* end Navbar */}
            {/* cards */}
            <div className="w-full px-6 py-6 mx-auto">
              <div class="relative w-full mx-auto  ">
                <div class="relative flex flex-col flex-auto min-w-0 p-4 mx-6 overflow-hidden break-words bg-white border-0  shadow-3xl rounded-2xl bg-clip-border">
                  <div class="flex flex-wrap -mx-3">
                    <div class="flex-none w-auto max-w-full px-3">
                      <div class="relative inline-flex items-center justify-center text-white transition-all duration-200 ease-in-out text-base h-19 w-19 rounded-xl">
                        {(() => {
                          if (
                            profilePhotoUrl ===
                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                          ) {
                            return (
                              <img
                                src={profilePhotoUrl}
                                alt="profile_image"
                                class="w-full shadow-2xl rounded-xl"
                              />
                            );
                          } else {
                            return (
                              <img
                                src={profilePhotoUrl}
                                alt="profile_image"
                                class="w-full shadow-2xl rounded-xl"
                              />
                            );
                          }
                        })()}
                      </div>
                    </div>
                    <div class="flex-none w-auto max-w-full px-3 my-auto">
                      <div class="h-full">
                        <h5 class="mb-1 ">{profileData?.name}</h5>
                        <p class="mb-0 font-semibold leading-normal   text-sm">
                          @{user?.username}
                        </p>
                      </div>
                    </div>
                    <div class="w-full  px-3 mx-auto mt-4 sm:my-auto sm:mr-0 md:w-1/2 md:flex-none lg:w-4/12 flex gap-4 flex-row items-center ">
                      <select
                        id="statusSelector"
                        className="select select-bordered"
                        name="active_status"
                        value={profileData.active_status}
                        onChange={handleChange}
                      >
                        <option value="Available 😊">Available 😊</option>
                        <option value="Busy ⚒️">Busy ⚒️</option>
                        <option value="In a meeting 📺">In a meeting 📺</option>
                        <option value="Away ✈️">Away ✈️</option>
                      </select>

                      <button
                        type="submit"
                        onClick={handleSaveClick}
                        class="inline-block m-0 px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                      >
                        Update Status
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full p-6 mx-auto">
                <div class="flex flex-wrap -mx-3">
                  <div class="w-full max-w-full px-3 shrink-0 md:w-8/12 md:flex-0">
                    <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                      <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                        <div class="flex items-center">
                          <p class="mb-0 /80">Edit Profile</p>
                          <button
                            type="submit"
                            onClick={handleSaveClick}
                            class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                          >
                            Update Data
                          </button>
                        </div>
                      </div>
                      <div class="flex-auto p-6">
                        <p class="leading-normal uppercase   text-sm">
                          User Information
                        </p>
                        <div class="flex flex-wrap -mx-3">
                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="username"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Full Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                disabled
                                value={profileData.name}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>
                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="email"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Email address
                              </label>
                              <input
                                type="email"
                                disabled
                                name="email"
                                value={user?.email}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>
                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="employeeID"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Mission
                              </label>

                              <textarea
                                type="text"
                                onChange={handleChange}
                                value={profileData.mission}
                                name="mission"
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              ></textarea>
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="employeeID"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Vision
                              </label>

                              <textarea
                                type="text"
                                onChange={handleChange}
                                value={profileData.vision}
                                name="vision"
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              ></textarea>
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="employeeID"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Core Values
                              </label>

                              <textarea
                                type="text"
                                onChange={handleChange}
                                value={profileData.core_values}
                                name="core_values"
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              ></textarea>
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="employeeID"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Objectives
                              </label>

                              <textarea
                                type="text"
                                onChange={handleChange}
                                value={profileData.objectives}
                                name="objectives"
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              ></textarea>
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="employeeID"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Website
                              </label>
                              <input
                                type="url"
                                name="website"
                                value={profileData.website}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="employeeID"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Facebook Page
                              </label>
                              <input
                                type="url"
                                name="social_fb"
                                value={profileData.social_fb}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="employeeID"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Instagram Page
                              </label>
                              <input
                                type="url"
                                name="social_ig"
                                value={profileData.social_ig}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="employeeID"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                LinkedIn Page
                              </label>
                              <input
                                type="url"
                                name="social_li"
                                value={profileData.social_li}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="employeeID"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Twitter Account
                              </label>
                              <input
                                type="url"
                                name="social_tw"
                                value={profileData.social_tw}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>

                        {/* <div>
            
                          <div className="mb-6">
  <label htmlFor="profile_photo" className="block text-sm font-semibold text-gray-600">
    Profile Photo
  </label>
  <input
    type="file"
    name="profile_photo"
    onChange={handleChange}
    className="mt-2 border border-gray-300 py-2 px-3 rounded-lg w-full focus:outline-none focus:ring focus:border-blue-400"
  />
</div>




                          <button
                            type="submit"
                            onClick={handleFileUpload}
                            class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                          >
                            Upload Photo
                          </button>
       
                        </div> */}

                        <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent  " />

                        <p class="leading-normal uppercase   text-sm">
                          About me
                        </p>
                        <div class="flex flex-wrap -mx-3">
                          <div class="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                            <div class="mb-4">
                              <label
                                for="about me"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                About me
                              </label>
                              <textarea
                                type="text"
                                onChange={handleChange}
                                value={profileData.about_society}
                                name="about_society"
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full max-w-full px-3 mt-6 shrink-0 md:w-4/12 md:flex-0 md:mt-0">
                    <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                      <img
                        class="w-full rounded-t-2xl"
                        src="https://lums.edu.pk/themes/contrib/lums/images/no-events.jpg"
                        alt="profile cover image"
                      />
                      <div class="flex flex-wrap justify-center -mx-3">
                        <div class="w-4/12 max-w-full px-3 flex-0 ">
                          <div class="mb-6 -mt-6 lg:mb-0 lg:-mt-16">
                            <a href="javascript:;">
                              {(() => {
                                if (
                                  profilePhotoUrl ===
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                                ) {
                                  return (
                                    <img
                                      src={profilePhotoUrl}
                                      alt="profile_image"
                                      class="w-full shadow-2xl rounded-xl"
                                    />
                                  );
                                } else {
                                  return (
                                    <img
                                      class="h-auto max-w-full border-2 border-white border-solid rounded-circle"
                                      src={profilePhotoUrl}
                                      alt="profile image"
                                    />
                                  );
                                }
                              })()}
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="flex-auto p-6 pt-0">
                        <div class="mt-6 text-center">
                          <h5 class=" ">
                            {profileData?.name}
                            <span class="font-light"></span>
                          </h5>

                          <div>
                            {/* ...Other profile fields... */}
                            <div className="mb-6">
                              <label
                                htmlFor="profile_photo"
                                className="block text-sm font-semibold text-gray-600"
                              >
                                Profile Photo
                              </label>
                              <label className="relative">
                                <input
                                  type="file"
                                  name="profile_photo"
                                  onChange={uploadProfilePic}
                                  className="hidden" // Hide the default input
                                />
                                <div className="bg-gray-100 flex flex-row gap-2 text-black mt-4 py-2 px-4 rounded-lg cursor-pointer">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                                    />
                                  </svg>
                                  Select new
                                </div>
                              </label>
                            </div>

                            {/* ...Other profile fields... */}

                            {/* <button
                              type="submit"
                              // onClick={handleFileUpload}
                              class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                            >
                              Upload Photo
                            </button> */}
                            {/* ...Other buttons... */}
                          </div>

                          <div class="mb-2 font-semibold leading-relaxed text-base /80 text-slate-700">
                            {profileData?.about_society}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      </div>

      {/* <h2>Admin Profile</h2>
            <p><strong>Name:</strong> {isEditing ? <input type="text" name="name" value={profileData.name} onChange={handleChange} /> : profileData.name}</p>
            <p><strong>Can Create Society:</strong> {isEditing ? <input type="checkbox" name="canCreateSociety" checked={profileData.canCreateSociety} onChange={handleChange} /> : profileData.canCreateSociety ? 'Yes' : 'No'}</p>
            {isEditing ? (
                <div>
                    <button onClick={handleSaveClick}>Save</button>
                    <button onClick={handleCancelClick}>Cancel</button>
                </div>
            ) : (
                <button onClick={handleEditClick}>Edit</button>
            )} */}

      <div style={{ zIndex: 999999999999999 }}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
}

export default AdminProfile;