import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../Axios";
import SocNav from "./SocNav";
import SocietySideInnerSM from "./SocietySideInnerSM";
import SocietySidebar from "./SocietySidebar";
import { toast, Toaster } from 'react-hot-toast';
import AuthContext from "../context/AuthContext";

export default function GetAdmins({ type }) {
    const [admins, setAdmins] = useState([]);
  const [societies, setSocieties] = useState([]);
  const [selectedSociety, setSelectedSociety] = useState({});
  const [adminToDelete, setAdminToDelete] = useState(null);

  let { user } = useContext(AuthContext);

  useEffect(() => {
    axiosInstance.get("get_admins/").then((response) => {
      setAdmins(response.data);
    });

    let endpoint = '';

    if (user['role'] === 'ccaAdmin') {
      endpoint = "get_admin_societies/";
    }
    else if (user['role'] === 'manager') {
      endpoint = "get_all_societies/";
    }

    axiosInstance.get(endpoint).then((response) => {
      setSocieties(response.data);
    });
  }, []);

  const handleAssignSociety = (adminId) => {
    const societyId = selectedSociety[adminId];
    const isAlreadyAssignedToOtherAdmin = Object.values(selectedSociety).includes(societyId);

    let confirmationMessage = '';

    let adminName = ''; // Variable to store the admin's name

    if (isAlreadyAssignedToOtherAdmin) {
      // Find the admin's name by their ID
      adminName = admins.find(admin => admin.id === adminId).username;
      const otherAdminName = Object.keys(selectedSociety).find(key => selectedSociety[key] === societyId);
      confirmationMessage = `Do you want to assign this society to ${adminName}? (Previously assigned to ${otherAdminName})`;
    } else {
      adminName = admins.find(admin => admin.id === adminId).username;
      confirmationMessage = `Do you want to assign this society to ${adminName}?`;
    }
   // console.log(`Assigning society to admin: ${adminName}`); // Log the admin's name


    if (isAlreadyAssignedToOtherAdmin) {
      const otherAdminName = Object.keys(selectedSociety).find(key => selectedSociety[key] === societyId);
      confirmationMessage = `Do you want to assign this society?`;
    } else {
      confirmationMessage = "Do you want to assign this society?";
    }

    if (confirmAction(confirmationMessage)) {
      axiosInstance
        .put("assign_society/", { adminId, societyId })
        .then((response) => {
          toast.success('Society assigned successfully', {
            icon: '✅',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });


          axiosInstance.post("post_notification_proxy/", { adminName });
          //console.log("Assigned to: "+adminName)

         window.location.reload();
          // Update the state without a page reload
          setAdmins((prevAdmins) => {
            return prevAdmins.map(admin => {
              if (admin.id === adminId) {
                return { ...admin, society_set: [...admin.society_set, societyId] };
              }
              return admin;
            });
          });
        });
    }
  };

  const handleDeleteAdmin = (adminId) => {

    axiosInstance.put("delete_admin/", { adminId }).then((response) => {
      setAdminToDelete(null);

      // Update the state without a page reload
      setAdmins((prevAdmins) => prevAdmins.filter((admin) => admin.id !== adminId));

      toast.success('Admin removed successfully', {
        icon: '✅',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });

      const adminName = admins.find(admin => admin.id === adminId).username;
          //console.log(`Deleted admin: ${adminName}`); // Log the admin's name
          axiosInstance.post("post_notification_delete_admin/", { adminName });
    });
  };

  const revokeAccess = (adminId) => {
    const societyId = selectedSociety[adminId];
    if (confirmAction("Revoke access for this admin?")) {
      axiosInstance
        .put("revoke_access/", { adminId, societyId })
        .then((response) => {
          toast.success('Access revoked successfully', {
            icon: '✅',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });

          const adminName = admins.find(admin => admin.id === adminId).username;
          //console.log(`Deleted admin: ${adminName}`); // Log the admin's name
          axiosInstance.post("post_notification_revoke_access/", { adminName });
          //window.location.reload();
          // Update the state without a page reload
          setAdmins((prevAdmins) => {
            return prevAdmins.map(admin => {
              if (admin.id === adminId) {
                return { ...admin, society_set: admin.society_set.filter(id => id !== societyId) };
              }
              return admin;
            });
          });
        });
    }
  };

  const handleSelectSociety = (adminId, societyId) => {
    setSelectedSociety((prev) => ({ ...prev, [adminId]: societyId }));
  };

  const confirmAction = (message) => {
    return window.confirm(message);
  };
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  }

  // Rest of your component code...

  return (
    <div>
    <div className="absolute w-full bg-primary min-h-75"></div>
    <SocietySidebar />
    {isSidebarVisible && <SocietySideInnerSM />}
    <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      {/* Navbar */}
      <SocNav toggleSidebar={toggleSidebar} />
      {/* end Navbar */}
      {/* cards */}
      <div className="w-full px-6 py-6 mx-auto">
        <div className="container mx-auto p-4 bg-white rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">CCA Members</th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  {user['role'] === 'ccaAdmin' ? 'Proxy Assigned' : user['role'] === 'manager' ? 'Society Assigned' : 'Default Title'}
                </th>
                <th className="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-300">
              {admins && admins.map((admin, index) => {
                // Filter the admin's societies to include those that are in the <select>
                const matchingSocieties = admin.society_set.filter(
                  (society) => societies.some((selectedSociety) => selectedSociety.id === society.id)
                );

                // Check if the current user's role is 'ccaAdmin'
                if (user.role === 'ccaAdmin' && admin.username === user.username) {
                  // Skip rendering the row for the current user
                  return null;
                }

                return (
                  <tr key={admin.id}>
                    <td className="px-6 py-4 whitespace-no-wrap">{admin.username}</td>
                    <td className="px-6 py-4">
                      <ul style={{ listStyle: 'circle' }}>
                        {matchingSocieties.map((society) => (
                          <li key={society.id}>{society.name}</li>
                        ))}
                      </ul>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center space-x-2">
                        <select
                          value={selectedSociety[admin.id] || ''}
                          onChange={(e) => handleSelectSociety(admin.id, e.target.value)}
                          className="w-36 px-2 py-1 border rounded-md text-gray-600"
                        >
                          <option value="">Select a society</option>
                          {societies.map((society) => (
                            <option key={society.id} value={society.id}>
                              {society.name}
                            </option>
                          ))}
                        </select>
                        <button
                          onClick={() => handleAssignSociety(admin.id)}
                          className="bg-blue-500 text-white px-3 py-2 rounded-md hover-bg-blue-600 focus:outline-none"
                        >
                          Assign Society
                        </button>
                        {user['role'] === 'manager' && (
                          <button
                            onClick={() => setAdminToDelete(admin.id)}
                            className="bg-red-500 text-white px-3 py-2 rounded-md hover-bg-red-600 focus:outline-none hidden"
                          >
                            Delete Member
                          </button>
                        )}
                        <button
                          onClick={() => revokeAccess(admin.id)}
                          className="bg-yellow-500 text-white px-3 py-2 rounded-md hover-bg-yellow-600 focus:outline-none"
                        >
                          Revoke Access
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {adminToDelete && (
            <div className="mt-4">
              <p className="text-red-600">Are you sure you want to delete this admin?</p>
              <button
                onClick={() => handleDeleteAdmin(adminToDelete)}
                className="bg-red-500 text-white px-2 py-1 rounded-md mr-2"
              >
                Yes, delete
              </button>
              <button
                onClick={() => setAdminToDelete(null)}
                className="bg-gray-300 text-gray-600 px-2 py-1 rounded-md"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </main>
    <div style={{ zIndex: 999999999999999 }}>
      <Toaster position="bottom-center" />
    </div>
  </div>
  );
}
