import React,{useEffect} from 'react';
import Modal from 'react-modal';

const CustomModal = ({ isOpen, onRequestClose,children ,title }) => {

  useEffect(() => {
    
    Modal.setAppElement('#root'); // Replace with your root element id (e.g., '#root')
  }, []);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: '1px solid #ccc',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          padding: '20px',
          maxWidth: '400px', // Adjust the maximum width as needed
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the overlay background color and transparency
            zIndex:'999'

        },
      };
    return (
        <Modal
        style={customStyles}
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel={title}
        >
          <h2 className='text-2xl font-semibold mb-5 text-[#344767]'>{title}</h2>
          {children}
          {/* <button onClick={onRequestClose}>Close Modal</button> */}
        </Modal>
      );
    };

export default CustomModal;