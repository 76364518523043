import React from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";

function AdminProtected({ children }) {
  let { user } = useContext(AuthContext);
  if (user.role !== "ccaAdmin" && user.role !== "manager") {
    //console.log(user.role);
    return <Navigate to="/" replace />;
  }
  return children;
}
export default AdminProtected;
