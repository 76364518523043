import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import SocietySidebar from '../components/SocietySidebar';
import SocietySideInner from '../components/SocietySideInner';
import SocNav from '../components/SocNav';
import SocietySideInnerSM from '../components/SocietySideInnerSM';
import { axiosInstance } from '../Axios';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { Link } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
const VenueList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      axiosInstance
        .get('get_venues/')
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }, []);

    const [filterType, setFilterType] = useState(''); // Filter for Indoor or Outdoor
const [filterStatus, setFilterStatus] = useState(''); // Filter for Available or Unavailable

  
    const columns = React.useMemo(
      () => [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ cell: { value } }) => (
            <span className={`type-cell ${value === 'Available' ? 'text-green' : 'text-red'}`}>{value}</span>
          ),
        },
        {
          Header: 'Type',
          accessor: 'type',
        },
        {
          Header: 'Actions',
          accessor: 'id',
          Cell: ({ cell: { value } }) => (
            <div className='flex flex-row items-center gap-4'>
              <Link to={`/edit_venue/${value}`}> <button className='btn btn-sm btn-warning'>Edit</button> </Link>
              <button className='btn btn-sm btn-error' onClick={() => handleDelete(value)}>Delete</button>
            </div>
          ),
        },
      ],
      []
    );
  
    const handleDelete = (id) => {
      axiosInstance
        .delete(`venue/${id}/`)
        .then(() => {
          // Reload the data after successful deletion
          toast.success('Venue Deleted', {
            icon: '🗑️',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          axiosInstance
            .get('get_venues/')
            .then((response) => {
              setData(response.data);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        })
        .catch((error) => {
          console.error('Error deleting venue:', error);
        });
    };



    const [filterOptionsVisible, setFilterOptionsVisible] = useState(false);
    const [filterOptions, setFilterOptions] = useState({
      Indoor: false,
      Outdoor: false,
      Available: false,
      Unavailable: false,
    });
  
    const updateFilterOptions = (option) => {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        [option]: !prevOptions[option],
      }));
    };
    // Function to filter the data based on filter options
    const filteredData = useMemo(() => {
      return data.filter((venue) => {
        const { type, status } = venue;
        return (
          (!filterOptions.Indoor || type === 'Indoor') &&
          (!filterOptions.Outdoor || type === 'Outdoor') &&
          (!filterOptions.Available || status === 'Available') &&
          (!filterOptions.Unavailable || status === 'Unavailable')
        );
      });
    }, [data, filterOptions]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state: { globalFilter, pageIndex },
      setGlobalFilter,
      page,
      canPreviousPage,
      canNextPage,
      previousPage,
      nextPage,
      pageOptions,
  } = useTable(
      {
          columns,
          data: filteredData,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
  );
  
    // const {
    //   getTableProps,
    //   getTableBodyProps,
    //   headerGroups,
    //   rows,
    //   prepareRow,
    //   state,
    //   page,
    //   canPreviousPage,
    //   canNextPage,
    //   previousPage,
    //   nextPage,
    //   pageOptions,
    // } = useTable(
    //   {
    //     columns,
    //     data,
    //   },
    //   useGlobalFilter,
    //   useSortBy,
    //   usePagination
    // );

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  }


  const [isMouseOverDropdown, setIsMouseOverDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const closeDropdown = () => {
    setFilterOptionsVisible(false);
  };

  return (
    <div>
      <div>
        <div>
          <div class="absolute w-full bg-primary min-h-75"></div>
          <SocietySidebar />
          {isSidebarVisible && <SocietySideInnerSM />}
          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
            <SocNav toggleSidebar={toggleSidebar} />
            <div className="w-full px-6 py-6 mx-auto">
              <div className="mt-4 p-4 bg-white rounded-lg">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div>
                    <div className="flex flex-row justify-end mb-4">
                      <Link to="/add_venue">
                        <button className="btn btn-primary">+ Add Venue</button>
                      </Link>
                    </div>



<div className='flex flex-row items-center gap-4'> 

                    <div className="flex flex-row justify-start mb-4 w-max">
        <div className="relative inline-block">
          <button
            className="border rounded-lg p-2 flex flex-row items-center gap-2"
            onClick={() => setFilterOptionsVisible(!filterOptionsVisible)}
          >
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
</svg>
  Filter
          </button>
          {filterOptionsVisible && (
            <div className="absolute flex flex-col gap-4 w-max bg-white border rounded-lg mt-2 p-2">
              <label>
                <input
                class="checkbox checkbox-primary"
                  type="checkbox"
                  checked={filterOptions.Indoor}
                  onChange={() => updateFilterOptions('Indoor')}
                /> Indoor
              </label>
              <label>
                <input
                class="checkbox checkbox-primary"
                  type="checkbox"
                  checked={filterOptions.Outdoor}
                  onChange={() => updateFilterOptions('Outdoor')}
                /> Outdoor
              </label>
              <label>
                <input
                class="checkbox checkbox-primary"
                  type="checkbox"
                  checked={filterOptions.Available}
                  onChange={() => updateFilterOptions('Available')}
                /> Available
              </label>
              <label>
                <input
                class="checkbox checkbox-primary"
                  type="checkbox"
                  checked={filterOptions.Unavailable}
                  onChange={() => updateFilterOptions('Unavailable')}
                /> Unavailable
              </label>
            </div>
          )}
        </div>
      </div>



      <input
    type="text"
    placeholder="Search"
    value={globalFilter || ''}
    onChange={(e) => setGlobalFilter(e.target.value || undefined)}
    className="border rounded-lg p-2 w-full mb-4"
/>


</div>

                    
                    <table {...getTableProps()} className="table-fixed border w-full">
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                className="p-2 bg-gray-200 text-left"
                              >
                                {column.render('Header')}
                                <span>
                                  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                </span>
                              </th>
                            ))}
                          </tr>
                ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} className="hover-bg-gray-100">
                              {row.cells.map((cell) => (
                                <td {...cell.getCellProps()} className="p-2">
                                  {cell.render('Cell')}
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="flex justify-between mt-4">
    <button
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        className={`btn ${!canPreviousPage ? 'btn-disabled' : 'btn-primary'}`}
    >
        Back
    </button>
    <span>
        Page 
        <strong>
            {pageIndex + 1} of {pageOptions.length}
        </strong> 
    </span>
    <button
        onClick={() => nextPage()}
        disabled={!canNextPage}
        className={`btn ${!canNextPage ? 'btn-disabled' : 'btn-primary'}`}
    >
        Next
    </button>
</div>

                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default VenueList;
