import React from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";

function ManagerProtected({ children }) {
  let { user } = useContext(AuthContext);
  if (user && user.role !== "pdc") {
    return <Navigate to="/" replace />;
  }
  return children;
}
export default ManagerProtected;
