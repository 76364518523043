import React, { useState } from "react";
import { axiosInstance } from "../Axios";
import GetAdmins from "../components/GetAdmins";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SocNav from "../components/SocNav";
import SocietySideInnerSM from "../components/SocietySideInnerSM";
import SocietySidebar from "../components/SocietySidebar";
import MyCalendar from "../components/Calendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SocietyStats from "../components/SocietyStats";
import RequestsList from "./RequestsList";
import EventsTable from "../components/AllSocietyEvents";
import FeedbackButton from "./FeedbackButton";
import NotificationComponent from "../components/NotificationComponent";
import PassWarn from "../components/PassWarn";
export default function ManagerDashboard() {
  const [err, setErr] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    name: "",
    canCreateSociety: "yes",
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      alert(
        "Password must be at least 8 characters long, and contain at least one uppercase letter and one lowercase letter."
      );
      return;
    }

    try {
      const response = await axiosInstance.post("create_admin/", formData);
      //console.log(response);
      setErr("Admin created successfully!");
      // Handle success or redirect to another page
    } catch (error) {
      setErr(error.response.data);
      console.error(error);
    }
  };

  const [requests, setRequests] = useState([]);
  const [events, setEvents] = useState([]);
  const [profile, setProfile] = useState();
  let { user } = useContext(AuthContext);
  let { authTokens } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  useEffect(() => {
    const getEvents = async () => {
      if (user !== null) {
        //console.log("mmm");
      }
      axiosInstance
        .get("get_profile/")
        .then((res) => {
          //console.log(res.data);
          setProfile(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    };

    // getProfile();
    getEvents();
  }, [user]);


  useEffect(() => {
    // Fetch the requests from the backend
    axiosInstance
      .get("requests/")
      .then((response) => {
        //console.log(response.data);
        setRequests(response.data);
      })
      .catch((error) => {
        alert(error.response.data);
      });

    axiosInstance
      .get("event/")
      .then((response) => {
        //console.log(response.data);
        setEvents(response.data);
      })
      .catch((error) => {
        //console.log(error.response.data);
      });
  }, []);


  
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

const toggleSidebar = () => {
  setIsSidebarVisible(prevState => !prevState);
}


const [adminData, setadminData] = useState(null);
let userID = user["user_id"];

useEffect(() => {
  // Define the URL of your Django API endpoint

  axiosInstance.get(`manager/profile/${userID}/`)
    .then((response) => {
      setadminData(response.data);
    })
    .catch((error) => {
      console.error('Error fetching society profile:', error);
    });
}, [userID]);
  return (
    <div>


<div><>

<div class="absolute w-full bg-primary  min-h-75"></div>


<SocietySidebar />

{isSidebarVisible && <SocietySideInnerSM/>}

    <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      {/* Navbar */}
      <SocNav toggleSidebar={toggleSidebar} />

      {/* end Navbar */}
      {/* cards */}
      <div className="w-full px-6 py-6 mx-auto">



      <SocietyStats/>
        {/* cards row 2 */}


        {(() => {
        if (adminData?.is_newlogin===1) {
          return (
            <PassWarn/>
          )
        }
      })()}
        
        {/* cards row 3 */}
        <div className="flex flex-wrap mt-6 -mx-3">
          <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
            <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
              <div className="p-4 pb-0 mb-0 rounded-t-4">
                <div className="flex justify-between">
                  <h6 className="mb-2 ">Requests</h6>
                </div>
              </div>
              <div className="overflow-x-auto">
              <Tabs>
    <TabList className="tab-list">
        <Tab className="tab">General Requests</Tab>
        <Tab className="tab">Event Requests</Tab>
    </TabList>

    <TabPanel className="tab-panel">
        <RequestsList />
    </TabPanel>

    <TabPanel className="tab-panel">
        <EventsTable />
    </TabPanel>
</Tabs>
              </div>
            </div>
          </div>
          <div className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none">
            <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
              <div className="p-4 pb-0 rounded-t-4">
                <h6 className="mb-0 ">Calendar</h6>
              </div>
              <div className="flex-auto p-4">
                <MyCalendar events={events} />
              </div>
            </div>
          </div>
        </div>



      </div>
    </main>
  </>
  </div>

<FeedbackButton/>
    
    </div>
  );
}
