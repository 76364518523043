import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../Axios";
import { toast, Toaster } from "react-hot-toast";
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  // check existence of authToken in browser storage
  let authTokenInitState = localStorage.getItem("authTokens")
    ? JSON.parse(localStorage.getItem("authTokens"))
    : null;
  // get the init user state by token
  let userState = localStorage.getItem("authTokens")
    ? jwt_decode(localStorage.getItem("authTokens"))
    : null;

  // set callback function to set the value once in initial load
  let [user, setUser] = useState(() => userState);
  let [authTokens, setAuthTokens] = useState(() => authTokenInitState);
  let [profile, setProfile] = useState();
  const navigate = useNavigate();

  // handle login form from login page
  let loginUser = async (e) => {
    e.preventDefault();
    const credentials = new FormData(e.currentTarget);
    //console.log(credentials);

    let response = axiosInstance
      .post("/token/", {
        username: credentials.get("username"),
        password: credentials.get("password"),
      })
      .then((response) => {
        if (response.status === 200) {
          // update the state of auth tokens from api token
          let data = response.data;
          if (jwt_decode(data.access).status === "suspended") {
            localStorage.removeItem("authTokens");
            // window.location.replace("/");
            //alert("Your account has been suspended!");
            toast.error("We could not find this user", {
              icon: "😰",
              style: {
                borderRadius: "55px",
                marginBottom: "15px",
              },
            });
            return;
          }
          setAuthTokens(data);
          // update user information and decode the user information from token
          setUser(jwt_decode(data.access));
          // store auth token in local storage
          localStorage.setItem("authTokens", JSON.stringify(data));
          axiosInstance.defaults.headers["Authorization"] =
            "Bearer " + JSON.parse(localStorage.getItem("authTokens")).access;

          navigate("/home");
        } else {
          toast.error("Unstable internet connection", {
            icon: "🪐s",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //alert("Invalid credentials or Your account is suspended");
          toast.error(
            "Credentials error. Please recheck your email & password",
            {
              icon: "🚨",
              style: {
                borderRadius: "55px",
                marginBottom: "15px",
              },
            }
          );
        }
      });
  };

  // handle the registration
  let registerUser = async (e) => {
    e.preventDefault();
    const credentials = new FormData(e.currentTarget);
    //console.log(credentials);

    const response = await axiosInstance.post("register/", {
      username: credentials.get("username"),
      email: credentials.get("email"),
      password: credentials.get("password"),
      password2: credentials.get("password2"),
    });

    //console.log(response);

    if (response.status === 201) {
      navigate("/");
    } else {
      alert("Something went wrong!");
    }
  };

  // handle logout
  let logoutUser = async () => {
    const response = await axiosInstance.post("logout/", {
      refresh: JSON.parse(localStorage.getItem("authTokens")).refresh,
    });
    // clear user and authToken state
    setAuthTokens(null);
    setUser(null);

    // remove authToken in browser
    localStorage.removeItem("authTokens");

    // redirect the user
    navigate("/");
  };

  // extract the decoded data
  let contextData = {
    user: user,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
    registerUser: registerUser,
    profile: profile,
  };

  return (
    <div>
      <AuthContext.Provider value={contextData}>
        {children}
      </AuthContext.Provider>
      <div style={{ zIndex: 999999999999999 }}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
};
