import { useState } from "react";
import MessageItem from "./ChatMessageItem";
export default function SendMessage({ chat, onSendMessage }) {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    onSendMessage(chat.chat_id, message);
    setMessage("");
  };

  // const handleKeyPress = (e) => {
  //   if (!e.shiftKey && e.key === "Enter") {
  //     e.preventDefault(); // Prevent the default Enter behavior (new line)
  //     handleSend(); // Send the message
  //   }
  // };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.ctrlKey || e.altKey || e.shiftKey) {
        setMessage((prevMessage) => prevMessage + "\n"); // Handle new line
      } else {
        e.preventDefault(); // Prevent the default Enter behavior (new line)
        handleSend(); // Send the message
      }
    }
  };
  

  return (
    <div className="flex flex-row justify-between">
      <div className="flex-1 mx-4">
        <textarea
          className="w-full border rounded px-2 py-2"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyPress} // Listen for key press events
          placeholder="Type a message • Press Enter to send"
        ></textarea>
      </div>
      <div
        className="bg-primary text-white text-center flex w-max items-center p-2 rounded-full"
        style={{ cursor: "pointer" }}
        onClick={handleSend}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
        </svg>
      </div>
    </div>
  );
}