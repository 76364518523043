import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { axiosInstance } from "../Axios";
import moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
import AuthContext from "../context/AuthContext";

const NotificationComponent = () => {
  const [notifications, setNotifications] = useState([]);
  let { user } = useContext(AuthContext);

  // Define a ref for the audio element
  const audioRef = useRef(null);

  const fetchNotifications = () => {
    axiosInstance
      .get("get_notifications/") // Adjust the URL according to your project structure
      .then((response) => {
        setNotifications(response.data);

        // Play a notification sound when new notifications arrive
        if (response.data.length > notifications.length) {
          playNotificationSound();
        }
      })
      .catch((error) => {
        console.error("Error fetching notifications", error);
      });
  };

  const playNotificationSound = () => {
    // Ensure the audio element is loaded
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  useEffect(() => {
    fetchNotifications();

    // Poll for notifications every 5 seconds (adjust the interval as needed).
    const intervalId = setInterval(fetchNotifications, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const makeTextBeforeFirstSpaceBold = (text) => {
    const updatedText = user?.username
      ? text.replace(new RegExp(`\\b${user.username}\\b`, "gi"), "You")
      : text;
    const parts = updatedText.split(" ");
    if (parts.length > 1) {
      return (
        <span>
          <strong>{parts[0]}</strong> {parts.slice(1).join(" ")}
        </span>
      );
    }
    return updatedText;
  };

  return (
    <div className="max-h-64 overflow-y-auto">
      {/* <audio ref={audioRef} src="../assets/audio/notif.mp3" /> */}
      
      <h6 className="p-2">
        Notifications • <small className="opacity-2">{notifications.length}</small>
      </h6>

      <ul>
        {notifications.map((notification, index) => (
          <li key={index} className="bg-gray-50 rounded-lg my-2">
            <div className="flex flex-row justify-between">
              <span className="text-sm text-gray-600">
                {makeTextBeforeFirstSpaceBold(notification.message)}
              </span>
              <span className="text-xs text-gray-400">
                <Moment fromNow tz="Asia/Karachi">{notification.timestamp}</Moment>
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotificationComponent;
