import moment from "moment";

export default function MessageItem({ message, isRequestUser }) {
  // Check if the message text is "Initiating chat"
  const isInitiatingChat = message.text === "Initiating chat";

  // If it's an "Initiating chat" message, return null to skip rendering
  if (isInitiatingChat) {
    return null;
  }

  return (
    <div className={`chat ${isRequestUser ? "chat-end" : "chat-start"}`}>
      <div className="">
        <p className="text-sm mt-1 chat-bubble">
          {message.text} <br />{" "}
          <span className="text-xs opacity-50">
            {moment(message.sent_at).fromNow()}
          </span>
        </p>
      </div>
    </div>
  );
}