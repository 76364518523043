import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../Axios";

import { Link, Navigate, Redirect } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SocietySidebar from "./SocietySidebar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SocNav from "./SocNav";
import SocietySideInnerSM from "./SocietySideInnerSM";
import SkeletonLoader from "./SkeletonLoader";
import { toast, Toaster } from "react-hot-toast";
import { Routes, Route, useParams } from "react-router-dom";

const AnnouncementEdit = () => {
  const [profile, setProfile] = useState();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { id } = useParams();
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const [repData, setRepData] = useState({
    title: "",
    description: "",
    posted_by: "",
    category: "",
    target: "",
  });

  useEffect(() => {
    axiosInstance.get(`get_repo/${id}/`).then((response) => {
      const data = response.data;
      setRepData({
        title: data.title,
        description: data.description,
        posted_by: data.posted_by,
        category: data.category,
        target: data.target,
      });
    });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRepData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    const dataToSend = {
      ...repData,
      posted_by: id,
    };
    axiosInstance
      .post(`edit_repos/${id}/`, dataToSend)
      .then((response) => {
        toast.success("Repository Edit Successfully", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((err) => {
        console.log("Error:  ", err);
      });
  };

  return (
    <div>
      <>
        <div class="absolute w-full bg-primary  min-h-75"></div>

        <SocietySidebar />

        {isSidebarVisible && <SocietySideInnerSM />}

        <main className="relative h-full max-h-screen  transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
          {/* Navbar */}
          <SocNav toggleSidebar={toggleSidebar} />

          {/* end Navbar */}
          {/* cards */}
          <div className="w-full px-6 py-6 mx-auto">
            {/* row 1 */}

            <div class="w-full max-w-full px-3 shrink-0 md:w-12/12 md:flex-0">
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                  <div class="flex items-center">
                    <p class="mb-0 /80">Edit Announcement</p>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div class="flex-auto p-6">
                  <p class="leading-normal uppercase   text-sm">
                    Announcement Information
                  </p>
                  <div class="flex flex-wrap -mx-3">
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="title"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          name="title"
                          value={repData.title}
                          onChange={handleChange}
                          //   disabled={profileData.name !== ""} // Add this line
                          class="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                        />
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="category"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                        >
                          Category
                        </label>

<select
                        className="select select-bordered w-full max-w-xs"
                        class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                        onChange={handleChange}
                          value={repData.category}
                        name="category"
                      >
                        <option  selected>
                          Select One
                        </option>
                        <option value="Policy Document">Policy Document</option>
                        <option value="Forms">Forms</option>
                        <option value="Guide">Guide</option>
                      </select>
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <label
                        for="category"
                        class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                      >
                        Scope
                      </label>
                      <select
                        className="select select-bordered w-full max-w-xs"
                        class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                        onChange={handleChange}
                        value={repData.target}
                        name="target"
                      >
                        <option disabled selected>
                          Select
                        </option>
                        <option value="CCA">CCA</option>
                        <option value="LUMS Community">LUMS Community</option>
                        <option value="Public">Public</option>
                      </select>
                    </div>
                  </div>

                  <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent  " />

                  <div class="flex flex-wrap -mx-3">
                    <div class="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                      <div class="mb-4">
                        <label
                          for="about me"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                        >
                          Description
                        </label>
                        <textarea
                          type="text"
                          onChange={handleChange}
                          //   value={profileData.bio}
                          value={repData.description}
                          name="description"
                          class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div style={{ zIndex: 999999999999999 }}>
          <Toaster position="bottom-center" />
        </div>
      </>
    </div>
  );
};
export default AnnouncementEdit;