import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { axiosInstance } from "../Axios";
import { toast, Toaster } from "react-hot-toast";
import AuthContext from "../context/AuthContext";
const AddScheduleForm = ({ close }) => {
  const { user } = useContext(AuthContext);
  const USERID = user["user_id"];

  const [societyProfile, setSocietyProfile] = useState(null);
  useEffect(() => {
    axiosInstance
      .get(`society/profile/${USERID}/`)
      .then((response) => {
        setSocietyProfile(response.data);
      })
      .catch((error) => {
        console.error("Error fetching society profile:", error);
      });
  }, [USERID]);

  const [formData, setFormData] = useState({
    title: "",
    startDate: new Date(),
    startTime: "",
    endDate: new Date(),
    endTime: "",
    allDay: false,
    repeats: "",
    venue: "",
    attendees: [],
    slots: [],
    society: "",
  });

  useEffect(() => {
    generateSlots();
  }, [formData, formData.repeats]);

  useEffect(() => {
    // Update formData with societyProfile.id when societyProfile changes
    if (societyProfile) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        society: societyProfile.id,
      }));
    }
  }, [societyProfile]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleAttendeesChange = (selectedOptions) => {
    const attendees = selectedOptions.map((option) => ({
      name: option.label,
      email: option.value,
    }));
    setFormData({ ...formData, attendees });
  };

  const generateSlots = () => {
    const { startDate, endDate, repeats } = formData;
    if (repeats && endDate) {
      const slots = [];
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        slots.push(currentDate);
        switch (repeats) {
          case "daily":
            currentDate = new Date(
              currentDate.setDate(currentDate.getDate() + 1)
            );
            break;
          case "weekly":
            currentDate = new Date(
              currentDate.setDate(currentDate.getDate() + 7)
            );
            break;
          case "monthly":
            currentDate = new Date(
              currentDate.setMonth(currentDate.getMonth() + 1)
            );
            break;
          default:
            break;
        }
      }
      setFormData({ ...formData, slots });
    } else {
      setFormData({ ...formData, slots: [] });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formData before submission:", formData);
    axiosInstance
      .post("create_internal_event/", formData)
      .then((res) => {
        toast.success("Event Added Successfully");
        console.log(res.data);
        close();
      })
      .catch((err) => {
        toast.error("Error in adding event");
        console.log(err);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="px-8">
      <div className="grid grid-cols-3 items-baseline mb-6">
        <div className="flex flex-row items-center gap-2 col-span-2">
          <button
            type="button"
            onClick={close}
            className="btn btn-circle btn-ghost"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className="relative w-full ">
            <input
              type="text"
              id="hs-floating-underline-input-email"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="peer py-4 px-0 block w-full bg-transparent border-t-transparent border-b-2 border-x-transparent border-b-gray-200 text-sm placeholder:text-transparent focus:border-t-transparent focus:border-x-transparent focus:border-b-blue-500 focus:ring-0 disabled:opacity-50 disabled:pointer-events-none darki:border-b-neutral-700 darki:text-neutral-400 darki:focus:ring-neutral-600 darki:focus:border-b-neutral-600
    focus:pt-6
    focus:pb-2
    [&:not(:placeholder-shown)]:pt-6
    [&:not(:placeholder-shown)]:pb-2
    autofill:pt-6
    autofill:pb-2"
              placeholder="Add Title"
            />
            <label
              htmlFor="hs-floating-underline-input-email"
              className="absolute top-0 start-0 py-4 px-0 h-full text-lg truncate pointer-events-none transition ease-in-out duration-100 border border-transparent  origin-[0_0] darki:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
peer-focus:scale-90
peer-focus:translate-x-0.5
peer-focus:-translate-y-1.5
peer-focus:text-gray-500 darki:peer-focus:text-neutral-500
peer-[:not(:placeholder-shown)]:scale-90
peer-[:not(:placeholder-shown)]:translate-x-0.5
peer-[:not(:placeholder-shown)]:-translate-y-1.5
peer-[:not(:placeholder-shown)]:text-gray-500 darki:peer-[:not(:placeholder-shown)]:text-neutral-500 darki:text-neutral-500"
            >
              Add Title
            </label>
          </div>
        </div>

        <button type="submit" className="btn btn-primary w-max ml-4">
          Save & Create
        </button>
      </div>

      <div className="max-w-4xl flex flex-col gap-6">
        <div className="grid grid-cols-5 gap-4 items-center">
          <DatePicker
            selected={formData.startDate}
            onChange={(date) => setFormData({ ...formData, startDate: date })}
            className="block w-full border-gray-300 rounded-md mt-1"
          />

          <input
            type="time"
            name="startTime"
            value={formData.startTime}
            onChange={handleInputChange}
            className="block w-full border-gray-300 rounded-md mt-1"
          />

          <span className="text-base font-medium text-center"> to </span>

          <DatePicker
            selected={formData.endDate}
            onChange={(date) => setFormData({ ...formData, endDate: date })}
            className="block w-full border-gray-300 rounded-md mt-1"
          />

          <input
            type="time"
            name="endTime"
            value={formData.endTime}
            onChange={handleInputChange}
            className="block w-full border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="grid grid-cols-2 gap-4 items-center">
          <label className="flex justify-start mb-2">
            <input
              type="checkbox"
              name="allDay"
              checked={formData.allDay}
              onChange={handleInputChange}
              className="checkbox mr-2"
            />
            All Day
          </label>

          <label className="block mb-2">
            Repeats:
            <select
              name="repeats"
              value={formData.repeats}
              onChange={handleInputChange}
              className="select select-bordered w-full"
            >
              <option value="">None</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </label>
        </div>

        <div className="grid grid-cols-2 gap-4 items-center">
          <label className="block mb-2">
            Venue:
            <input
              type="text"
              name="venue"
              value={formData.venue}
              onChange={handleInputChange}
              className="input input-bordered w-full"
            />
          </label>

          <label className="block mb-2">
            Attendees:
            <Select
              isMulti
              options={[
                { label: "John Doe", value: "john.doe@example.com" },
                { label: "Jane Doe", value: "jane.doe@example.com" },
                { label: "Alice Smith", value: "alice.smith@example.com" },
                { label: "Bob Johnson", value: "bob.johnson@example.com" },
              ]}
              onChange={handleAttendeesChange}
            />
          </label>
        </div>
      </div>
    </form>
  );
};

export default AddScheduleForm;
