import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import ReactModal from "react-modal";
import DateRangePicker from "./DateRangePicker";

export default function CustomTuiModal({
  isOpen = false,
  toggle,
  onSubmit,
  submitText = "Save",
  calendars = [],
  attendees = [],
  schedule,
  startDate,
  endDate
}) {
  const [openSelectCalendars, setOpenSelectCalendars] = useState(false);
  const [openSelectAttendees, setOpenSelectAttendees] = useState(false);
  const wrapperSelectCalendarsRef = useRef(null);
  const wrapperSelectAttendeesRef = useRef(null);
  const dateRangePickerRef = useRef(null);
  const subjectRef = useRef(null);

  const [calendarId, setCalendarId] = useState(calendars[0].id);
  const [attendeeId, setAttendeeId] = useState(attendees[0].id);
  const [title, setTitle] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const handleClick = (e) => {
    if (wrapperSelectCalendarsRef.current?.contains(e.target)) {
      // inside click
      return;
    }
    if (wrapperSelectAttendeesRef.current?.contains(e.target)) {
      // inside click
      return;
    }
    setOpenSelectCalendars(false);
    setOpenSelectAttendees(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, false);

    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  });

  // useLayoutEffect(() => {
  //   if (schedule) {
  //     setCalendarId(schedule.calendarId);
  //     setAttendeeId(
  //       attendees.find((element) => schedule.attendees.includes(element.name))
  //         .id
  //     );
  //     setTitle(schedule.title);
  //     setStart(schedule.start.toDate());
  //     setEnd(schedule.end.toDate());
  //     dateRangePickerRef.current.setStartDate(schedule.start.toDate());
  //     dateRangePickerRef.current.setEndDate(schedule.end.toDate());
  //   }
  //   if (startDate && endDate) {
  //     dateRangePickerRef.current.setStartDate(startDate.toDate());
  //     dateRangePickerRef.current.setEndDate(endDate.toDate());
  //   }
  //   return () => {};
  // }, [schedule, startDate, endDate]);



  useLayoutEffect(() => {
    if (schedule) {
      setCalendarId(schedule.calendarId);
      setAttendeeId(
        attendees.find((element) => schedule.attendees.includes(element.name))
          .id
      );
      setTitle(schedule.title);
      setStart(schedule.start.toDate());
      setEnd(schedule.end.toDate());
      if (dateRangePickerRef.current) {
        dateRangePickerRef.current.setStartDate(schedule.start.toDate());
        dateRangePickerRef.current.setEndDate(schedule.end.toDate());
      }
    }
    if (startDate && endDate && dateRangePickerRef.current) {
      dateRangePickerRef.current.setStartDate(startDate.toDate());
      dateRangePickerRef.current.setEndDate(endDate.toDate());
    }
  }, [schedule, startDate, endDate]);
  

  function reset() {
    setCalendarId(calendars[0].id);
    setAttendeeId(attendees[0].id);
    setTitle("");
    setStart(new Date());
    setEnd(new Date());
    dateRangePickerRef.current.setStartDate(new Date());
    dateRangePickerRef.current.setEndDate(new Date());
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        toggle();
        reset();
      }}
      contentLabel="Custom Modal"
      ariaHideApp={false}
      className="tui-full-calendar-popup-container"
      overlayClassName="tui-full-calendar-overlay"
    >
      <div style={{ display: "flex" }}>
        {/* Your content here */}
      </div>
    </ReactModal>
  );
}
