import axios from "axios";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../Axios";

export default function EventLogs(props) {
  const id = props.event.id;
  const [Logs, setLogs] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`get_event_logs/${id}/`)
      .then((res) => {
        setLogs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  console.warn(id);
  return (
    <>
      {/* Timeline */}
      {Logs.map((log) => (
        <div className="my-2">
          <div className="flex gap-x-3">
            {/* Left Content */}
            <div className="w-16 text-end">
              <span className="text-xs text-gray-500 darki:text-gray-400">
                {log?.date} {log?.time.slice(0, 5)}
              </span>
            </div>
            {/* End Left Content */}
            {/* Icon */}
            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 darki:after:bg-gray-700">
              <div className="relative z-10 size-7 flex justify-center items-center">
                <div className="size-2 rounded-full bg-gray-400 darki:bg-gray-600" />
              </div>
            </div>
            {/* End Icon */}
            {/* Right Content */}
            <div className="grow px-6">
              <h3 className="flex gap-x-1.5 text-lg font-semibold text-gray-800 darki:text-white">
                {log?.action}
              </h3>
              <p className="mt-1 mb-0 text-sm text-gray-600 darki:text-gray-400">
                {log?.details}
              </p>
            </div>
            {/* End Right Content */}
          </div>
          {/* End Item */}
        </div>
      ))}
      <button
        className="btn btn-primary absolute bottom-4 right-4"
        onClick={props.closeModal}
      >
        Close
      </button>
      {/* End Timeline */}
    </>
  );
}
