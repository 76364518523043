import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../Axios";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SkeletonLoader from "./SkeletonLoader";
import CryptoJS from "crypto-js";

const EventsTable = () => {
  const [events, setEvents] = useState([]);
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("myEvents");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedField, setSortedField] = useState(null);

  const [fetching, setFetching] = useState(true);

  const role = user ? user["role"] : "";

  useEffect(() => {
    const getEvents = async () => {
      if (user) {
        const userID = user["user_id"];
        const endpoint =
          activeTab === "myEvents" ? "all_events/" : "geteventList_all/";
        const response = await axiosInstance.get(endpoint);
        const data = await response.data;
        setEvents(data);
        setFetching(false);
      }
    };

    getEvents();
  }, [user, activeTab]);

  const sortEvents = (field) => {
    if (sortedField === field) {
      // Toggle sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortOrder("asc");
    }
  };

  const sortedEvents = [...events];

  if (sortedField) {
    sortedEvents.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortedField] > b[sortedField] ? 1 : -1;
      } else {
        return a[sortedField] < b[sortedField] ? 1 : -1;
      }
    });
  }

  const [eventId, setEventId] = useState(null);
  const [event, setEvent] = useState(null);
  const getEvent = (id) => {
    axiosInstance
      .get(`get_event/${id}/`)
      .then((res) => {
        setEvent(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (eventId) {
      getEvent(eventId);
    }
  });

  return (
    <div>
      {fetching ? (
        <div className="flex items-center justify-center h-screen gap-2">
          <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
        </div>
      ) : (
        <div className="overflow-x-auto mt-6 shadow-md">
          <Tabs>
            <TabList className="flex flex-row mb-6">
              {(() => {
                if (user["role"] !== "manager") {
                  return (
                    <Tab
                      onClick={() => setActiveTab("myEvents")}
                      selected={activeTab === "myEvents"}
                      className={`cursor-pointer py-4 eventTab px-6 border-b-2 font-medium text-lg ${
                        activeTab === "myEvents"
                          ? "border-white bg-none text-white"
                          : "border-transparent text-gray-500 bg-none hover:text-gray-700 hover:border-gray-300"
                      }`}
                    >
                      My Events
                    </Tab>
                  );
                }
              })()}

              <Tab
                onClick={() => setActiveTab("allEvents")}
                selected={activeTab === "allEvents"}
                className={`cursor-pointer py-4 px-6 border-b-2 font-medium text-lg ${
                  activeTab === "allEvents"
                    ? "border-white bg-none text-white"
                    : "border-transparent text-gray-500 bg-none hover:text-gray-700 hover:border-gray-300"
                }`}
              >
                All Events
              </Tab>
            </TabList>

            {(() => {
              if (user["role"] !== "manager") {
                return (
                  <TabPanel>
                    <table className="table bg-white shadow-md">
                      <thead>
                        <tr>
                          <th
                            className="cursor-pointer	"
                            onClick={() => sortEvents("start_date")}
                          >
                            Dates{" "}
                            {sortedField === "start_date" &&
                              (sortOrder === "asc" ? "▲" : "▼")}
                          </th>
                          <th
                            className="cursor-pointer	"
                            onClick={() => sortEvents("start_time")}
                          >
                            Time{" "}
                            {sortedField === "start_time" &&
                              (sortOrder === "asc" ? "▲" : "▼")}
                          </th>
                          <th
                            className="cursor-pointer	"
                            onClick={() => sortEvents("event_name")}
                          >
                            Event{" "}
                            {sortedField === "event_name" &&
                              (sortOrder === "asc" ? "▲" : "▼")}
                          </th>
                          <th
                            className="cursor-pointer	"
                            onClick={() => sortEvents("status")}
                          >
                            Status{" "}
                            {sortedField === "status" &&
                              (sortOrder === "asc" ? "▲" : "▼")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {events.length === 0 ? (
                          <tr>
                            <td colSpan="4">
                              <SkeletonLoader />
                            </td>
                          </tr>
                        ) : (
                          sortedEvents.map((event, index) => {
                            const date = new Date(event?.start_date);

                            const key = 20032024;
                            const encryptedId = encodeURIComponent(
                              CryptoJS.AES.encrypt(
                                event.id.toString(),
                                key.toString()
                              ).toString()
                            );
                            const base64EncryptedId = btoa(encryptedId);
                            return (
                              <tr key={index}>
                                <td>{date.toDateString()}</td>
                                <td>{date.toTimeString().slice(0, 5)} PM</td>
                                <td>
                                  {event?.event_name}
                                  <br />
                                  <small className="">{event?.society}</small>
                                </td>
                                <td
                                  className={`${
                                    event?.status === "Approved"
                                      ? "text-green"
                                      : event?.status === "Reviewing"
                                      ? "text-yellow"
                                      : event?.status === "Rejected"
                                      ? "text-red"
                                      : ""
                                  }`}
                                >
                                  {event?.status}
                                </td>

                                <td className="cursor-pointer text-violet-700">
                                  <Link
                                    to={`/event_details/${encryptedId}/`}
                                    // state={{ event: event.event_data }}
                                    state={{ event: event }}
                                  >
                                    <span className="flex gap-2 text-base">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                      </svg>
                                      View
                                    </span>{" "}
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </TabPanel>
                );
              }
            })()}

            <TabPanel>
              <table className="table bg-white shadow-md">
                <thead>
                  <tr>
                    <th
                      className="cursor-pointer	"
                      onClick={() => sortEvents("start_date")}
                    >
                      Dates{" "}
                      {sortedField === "start_date" &&
                        (sortOrder === "asc" ? "▲" : "▼")}
                    </th>
                    <th
                      className="cursor-pointer	"
                      onClick={() => sortEvents("start_time")}
                    >
                      Time{" "}
                      {sortedField === "start_time" &&
                        (sortOrder === "asc" ? "▲" : "▼")}
                    </th>
                    <th
                      className="cursor-pointer	"
                      onClick={() => sortEvents("event_name")}
                    >
                      Event{" "}
                      {sortedField === "event_name" &&
                        (sortOrder === "asc" ? "▲" : "▼")}
                    </th>
                    <th
                      className="cursor-pointer	"
                      onClick={() => sortEvents("status")}
                    >
                      Status{" "}
                      {sortedField === "status" &&
                        (sortOrder === "asc" ? "▲" : "▼")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {events.length === 0 ? (
                    <tr>
                      <td colSpan="4">
                        <SkeletonLoader />
                      </td>
                    </tr>
                  ) : (
                    sortedEvents.map((event, index) => {
                      const date = new Date(event?.start_date);
                      const key = 20032024;
                      const encryptedId = encodeURIComponent(
                        CryptoJS.AES.encrypt(
                          event.id.toString(),
                          key.toString()
                        ).toString()
                      );
                      const base64EncryptedId = btoa(encryptedId);
                      return (
                        <tr key={index}>
                          <td>{date.toDateString()}</td>
                          <td>{date.toTimeString().slice(0, 5)} PM</td>
                          <td>
                            {event?.event_name}
                            <br />
                            <small className="">{event?.society}</small>
                          </td>
                          <td
                            className={`${
                              event?.status === "Approved"
                                ? "text-green"
                                : event?.status === "Reviewing"
                                ? "text-yellow"
                                : event?.status === "Rejected"
                                ? "text-red"
                                : ""
                            }`}
                          >
                            {event?.status}
                          </td>
                          {role !== "society" ? (
                            <td className="cursor-pointer text-violet-700">
                              <Link to={`/event_details/${encryptedId}`}>
                                <span className="flex gap-2 text-base">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                  </svg>
                                  View
                                </span>{" "}
                              </Link>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </TabPanel>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default EventsTable;