import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../Axios";

import { Link, Navigate, Redirect } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SocietySidebar from "./SocietySidebar";
import MobileBottom from "./MobileBottom";
import SocietyStats from "./SocietyStats";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MembersList from "./MembersList";
import HierarchyMap from "./HierarchyMap";
import SocNav from "./SocNav";
import SocietySideInnerSM from "./SocietySideInnerSM";
import SkeletonLoader from "./SkeletonLoader";
import { toast, Toaster } from "react-hot-toast";

const Announcements = () => {
  const [profile, setProfile] = useState();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const [events, setevents] = useState([]);
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("all_events");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedField, setSortedField] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState("all");

  const role = user ? user["role"] : "";
  const userid = user["user_id"];
  useEffect(() => {
    const getevents = async () => {
      if (user) {
        const userID = user["user_id"];

        const response = await axiosInstance.get("all_repo/announcement/");
        const data = await response.data;
        // Filter events based on search query and selected filter
        const filteredEvents = data.filter((event) => {
          const matchesSearch = Object.values(event).some((field) =>
            String(field).toLowerCase().includes(searchQuery.toLowerCase())
          );

          if (filterValue === "all") {
            return matchesSearch;
          } else {
            const categoryMatches =
              event.category.toLowerCase() === filterValue.toLowerCase();
            const targetMatches =
              event.target &&
              event.target.toLowerCase() === filterValue.toLowerCase();

            return matchesSearch && (categoryMatches || targetMatches);
          }
        });

        setevents(filteredEvents);
      }
    };

    getevents();
  }, [user, activeTab, searchQuery, filterValue]);

  const sortevents = (field) => {
    if (sortedField === field) {
      // Toggle sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortOrder("asc");
    }
  };

  const sortedevents = [...events];

  if (sortedField) {
    sortedevents.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortedField] > b[sortedField] ? 1 : -1;
      } else {
        return a[sortedField] < b[sortedField] ? 1 : -1;
      }
    });
  }

  const handleDelete = (id) => {
    axiosInstance
      .delete(`delete_repo/${id}/`)
      .then((res) => {
        toast.success("announcement Deleted Successfully", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        console.log(res);
        console.log(role);
      })
      .catch((err) => {
        toast.error("Error Deleting Announcement", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        console.log(err);
      });
    setevents((prevRep) => prevRep.filter((rep) => rep.id !== id));
  };

  return (
    <div>
      <>
        <div class="absolute w-full bg-primary  min-h-75"></div>

        <SocietySidebar />

        {isSidebarVisible && <SocietySideInnerSM />}

        <main className="relative h-full max-h-screen  transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
          {/* Navbar */}
          <SocNav toggleSidebar={toggleSidebar} />

          {/* end Navbar */}
          {/* cards */}
          <div
            style={{ width: "95%" }}
            className=" px-5 py-5 mx-auto bg-white shadow-md rounded-lg"
          >
            {/* row 1 */}
            <h6>Announcements</h6>

            <div className="w-full flex items-center gap-3 mt-3">
              <div className="flex bg-gray-100 p-2 w-72 space-x-4 rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 opacity-30"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <input
                  className="bg-gray-100 outline-none text-black"
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Article name or keyword..."
                />
              </div>

              <select
                className="bg-gray-100 border-none outline-none p-2 rounded-lg"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              >
<option value="all">All</option>
                <option value="CCA">CCA</option>
                <option value="LUMS Community">LUMS Community</option>
                <option value="Public">Public</option>
              </select>

              {(role === "ccaAdmin" || role === "manager") && (
                <button className="btn btn-outline btn-primary">
                  <Link to="/new_announcement">Create New</Link>
                </button>
              )}
            </div>
            <div className="overflow-x-auto mt-3 bg-transparent rounded-lg">
              <table className="table bg-transparent shadow-lg  border border-gray-300 border-collapse">
                {/* head */}

                <thead className="bg-gray-200 rounded-lg">
                  <tr>
                    <th className="py-2 px-4 text-gray-700 ">Title</th>
                    <th className="py-2 px-4 text-gray-700 ">Desc</th>
                    {/* <th className="py-2 px-4 text-gray-700 ">Category</th>
                    <th className="py-2 px-4 text-gray-700 ">Target</th> */}
                    <th className="py-2 px-4 text-gray-700 ">Target</th>
                    <th className="py-2 px-4 text-gray-700 ">Action</th>

                    {(userid &&
                      events.some((rep) => rep.posted_by === userid)) ||
                    role === "manager" ? (
                      <th className="py-2 px-4 text-gray-700">Edit</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {events?.map((rep, index) => (
                    <tr key={index}>
                      <th>{rep.title}</th>
                      <td>{rep.description}</td>
                      {/* <td>{rep.category}</td> */}
                      {/* <td>{rep.target}</td> */}
                      {/* <td className="capital capitalize">
                        {rep.target === "internal" ? (
                          <span className="bg-red-500 text-white py-1 px-3 rounded-full">
                            Internal
                          </span>
                        ) : (
                          <span className="bg-yellow-500 text-white py-1 px-3 rounded-full">
                            {rep.target}
                          </span>
                        )}
                      </td> */}
                      <td className="capital capitalize">
                        {rep.target === "internal" ? (
                          <span className="bg-red-500 text-white py-1 px-3 rounded-full">
                            Internal
                          </span>
                        ) : (
                          <span className="bg-yellow-500 text-white py-1 px-3 rounded-full">
                            {rep.target}
                          </span>
                        )}
                      </td>

                      <th>
                        <button className="btn btn-ghost btn-xs">
                          <Link to={`/det_repo/${rep.id}`}>Details</Link>
                        </button>
                      </th>
                      <th>
                        {(userid === rep.posted_by || role === "manager") && (
                          <div>
                            <button className="btn btn-outline btn-info">
                              <Link to={`/edit_announcement/${rep.id}`}>
                                Edit
                              </Link>
                            </button>

                            <button
                              className="btn btn-outline btn-error"
                              type="submit"
                              onClick={() => handleDelete(rep.id)}
                              style={{ marginLeft: "10px" }} // Adding left margin for space
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </th>
                    </tr>
                  ))}

                  {/* row 1 */}

                  {/* row 2 */}
                </tbody>
              </table>
            </div>
          </div>
        </main>
        <div style={{ zIndex: 999999999999999 }}>
          <Toaster position="bottom-center" />
        </div>
      </>
    </div>
  );
};
export default Announcements;