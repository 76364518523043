import React, { useState, useEffect, useContext } from "react";

import { Link, Navigate, Redirect } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SocietySidebar from "../components/SocietySidebar";
import MobileBottom from "../components/MobileBottom";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MembersList from "../components/MembersList";
import HierarchyMap from "../components/HierarchyMap";
import SocietyStatusEvents from "./SocietyStatusEvents";
import { axiosInstance } from "../Axios";
import SocNav from "./SocNav";
const SocietyEvents = () => {
  let { user } = useContext(AuthContext);
  const [events, setEvents] = useState();
  const [profile, setProfile] = useState();

  useEffect(() => {
    const getEvents = async () => {
      if (user !== null) {
        // const response = await fetch(`/api/event/${userID}`);
        let response = await axiosInstance.get(`event/`);

        // parse the data in json
        // let data = await response.json();
        let data = await response.data;

        setEvents(data);
      }
    };
    getEvents();
  }, [user]);
  //console.log(events);

  const [dateState, setDateState] = useState(new Date());
  const changeDate = (e) => {
    setDateState(e);
  };

  const [data, setData] = useState([]);

  return (
    <div>
      <SocietySidebar />

      <div className="md:pl-64 flex flex-col flex-1 h-screen">
        <MobileBottom />
        <main className="flex-1">
          <div className="">
            <SocNav/>

            <div className="px-2 sm:px-2 md:px-2 NetworkDashboard_leftside w-full">
              <div>
                <div className="flex items-center justify-between gap-8 chartsParent">
                  <section className="text-gray-600 body-font stats_lg w-full">
                    <div className="container px-4 py-4 mx-auto bg-white my-8 rounded-lg">
                      <div className="flex flex-col justify-between text-start w-full mb-6 dash-robbon">
                        <div className="text-center sm:text-left">
                          <h1 className="text-xl font-bold text-gray-900 sm:text-xl">
                            Society Events
                          </h1>
                          <p className="mt-1.5 text-sm text-gray-500">
                            List of all events for your society
                          </p>
                        </div>

                        <Tabs>
                          <TabList>
                            <Tab> All </Tab>
                            <Tab>Approved</Tab>
                            <Tab>Planned</Tab>
                          </TabList>

                          <TabPanel>
                            <SocietyStatusEvents
                              status={"All"}
                              events={events}
                              name={user.username}
                            />
                          </TabPanel>

                          <TabPanel>
                            <SocietyStatusEvents
                              status={"Approved"}
                              events={events}
                              name={user.username}
                            />
                          </TabPanel>

                          <TabPanel>
                            <SocietyStatusEvents
                              status={"Reviewing"}
                              events={events}
                              name={user.username}
                            />
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default SocietyEvents;
