import React, {useEffect, useState, useContext} from 'react'
import { NavLink,Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import FeedbackButton from '../pages/FeedbackButton'

const SocietySideInner = () => {

    let {user} = useContext(AuthContext)
    let userID = user['user_id']
    ////console.log("role: "+user['role'])

  
  

    return (


      <div>
        <aside class="fixed inset-y-0 flex-wrap items-center justify-between block w-full p-0 my-4 hover:overflow-y-scroll antialiased transition-transform duration-200 -translate-x-full bg-white border-0 shadow-xl  max-w-64 ease-nav-brand z-990 xl:ml-6 rounded-2xl xl:left-0 xl:translate-x-0" aria-expanded="false">
      <div class="h-19">
        <i class="absolute top-0 right-0 p-4 opacity-50 cursor-pointer fas fa-times text-slate-400 xl:hidden" ></i>
        <a class="block px-8 py-6 m-0 text-sm whitespace-nowrap  text-slate-700"  target="_blank">
          <img src="https://res.cloudinary.com/ahbranding/image/upload/v1697018132/AHbranding/Asset_3_vbcjjp.png" class="block mx-auto h-full max-w-full transition-all duration-200  ease-nav-brand max-h-8" alt="main_logo" />
        </a>
      </div>

      <hr class="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent " />

      <div class="items-center block w-auto max-h-screen overflow-auto grow basis-full">
        <ul class="flex flex-col pl-0 mb-0">
        


        {(() => {
        if (user['role']==='ccaAdmin') {
          return (
            <NavLink to={'/admin'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className="py-2.7  text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors">
                    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <i className="relative top-0 text-sm leading-normal text-blue-500 ni ni-tv-2"> </i>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">Dashboard</span>
                  </a>
                </li>
              </NavLink>
          )
        } else if (user['role']==='manager') {
          return (
            <NavLink to={'/manager'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className="py-2.7  text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors">
                    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <i className="relative top-0 text-sm leading-normal text-blue-500 ni ni-tv-2"> </i>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">Dashboard</span>
                  </a>
                </li>
              </NavLink>
          )
        } else if (user['role']==='society') {
          return (
            <NavLink to={`/society`} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className="py-2.7  text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors">
                    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <i className="relative top-0 text-sm leading-normal text-blue-500 ni ni-tv-2"> </i>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">Dashboard</span>
                  </a>
                </li>
              </NavLink>
          )
        }
      })()}


      {(() => {
        if (user['role']==='society' ) {
          return (
            <NavLink to={'/event_form'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
              <i class="relative top-0 text-sm leading-normal text-red-500 ni ni-spaceship"></i>
              </div>
              <div>
              <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
          Event Form
        </span>
    </div>
            </a>
          </li>
          </NavLink>
          )
        }
      })()}

        


          {(() => {
        if (user['role']==='manager' || user['role']==='ccaAdmin') {
          return (
            <NavLink to={'/all_society_details'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                <i class="relative top-0 text-sm leading-normal text-cyan-500 ni ni-app"></i>
              </div>
              <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Societies</span>
            </a>
          </li>
          </NavLink>
          )
        }
      })()}


      {(() => {
        if (user['role']==='society' ) {
          return (
            <NavLink to={'/organisation'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                <i class="relative top-0 text-sm leading-normal text-cyan-500 ni ni-app"></i>
              </div>
              <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Organisation</span>
            </a>
          </li>
          </NavLink>
          )
        }
      })()}



          <NavLink to={'/schedule'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
              <i class="relative top-0 text-sm leading-normal text-orange-500 ni ni-calendar-grid-58"></i>
              </div>
              <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Schedule</span>
            </a>
          </li>
          </NavLink>
         
          <NavLink to={'/chats'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
              <i class="relative top-0 text-sm leading-normal text-green-500 ni ni-chat-round"></i>
              </div>
              <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Chats</span>
            </a>
          </li>
          </NavLink>

          {(() => {
        if (user['role']==='manager') {
          return (
            <NavLink to={'/add_society'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
              <i class="relative top-0 text-sm leading-normal text-yellow-500 ni ni-archive-2"></i>
              </div>
              <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Add Society</span>
            </a>
          </li>
          </NavLink>
          )
        }
      })()}

      {(() => {
        if (user['role']==='manager') {
          return (
            <NavLink to={'/add_admin'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
              <i class="relative top-0 text-sm leading-normal text-indigo-500 ni ni-single-02"></i>
              </div>
              <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Add Staff Members</span>
            </a>
          </li>
          </NavLink>
          )
        }
      })()}



      {(() => {
        if (user['role']==='manager' || user['role']==='ccaAdmin') {
          return (
            <NavLink to={'/set_proxy'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
              <i class="relative top-0 text-sm leading-normal text-red-500 ni ni-spaceship"></i>
              </div>
              <div>
      {user.role === 'ccaAdmin' ? (
        <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
          Set Proxy
        </span>
      ) : (
        <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
          Assign Societies
        </span>
      )}
    </div>
            </a>
          </li>
          </NavLink>
          )
        }
      })()}


      <NavLink to={'/repo'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
              <i class="relative top-0 text-sm leading-normal text-red-500 ni ni-archive-2"></i>
              </div>
              <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Resource Vault</span>
            </a>
          </li>
          </NavLink>
          


            <NavLink
              to={"/announcements"}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active-link" : ""
              }
            >
              <li class="mt-0.5 w-full ">
                <a
                  class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors"
                  href="../pages/virtual-reality.html"
                >
                  <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <i class="relative top-0 text-sm leading-normal text-orange-500 ni ni-bell-55"></i>
                  </div>
                  <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">
                    Announcements
                  </span>
                </a>
              </li>
            </NavLink>


      {(() => {
        if (user['role']==='manager' ) {
          return (
            <NavLink to={'/venues'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
              <i class="relative top-0 text-sm leading-normal text-yellow-500 ni ni-compass-04"></i>
              </div>
              <div>
              <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
          Venues
        </span>
    </div>
            </a>
          </li>
          </NavLink>
          )
        }
      })()}


      


      {(() => {
        if (user['role']==='manager' ) {
          return (
            <NavLink to={'/feedbacks'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active-link" : "" }>
        <li class="mt-0.5 w-full ">
            <a class="   py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="../pages/virtual-reality.html">
              <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
              <i class="relative top-0 text-sm leading-normal text-indigo-500 ni ni-single-copy-04"></i>
              </div>
              <div>
              <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
          Feedbacks
        </span>
    </div>
            </a>
          </li>
          </NavLink>
          )
        }
      })()}




        </ul>
      </div>

      {/* <div class="mx-4 ">

        <p class="invisible hidden text-gray-800 text-red-500 text-red-600 text-blue-500  bg-slate-500 bg-gray-500/30 bg-cyan-500/30 bg-emerald-500/30 bg-orange-500/30 bg-red-500/30 after:bg-gradient-to-tl after:from-zinc-800 after:to-zinc-700  after:from-blue-700 after:to-cyan-500 after:from-orange-500 after:to-yellow-500 after:from-green-600 after:to-lime-400 after:from-red-600 after:to-orange-600 after:from-slate-600 after:to-slate-300 text-emerald-500 text-cyan-500 text-slate-400"></p>
        <div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border" sidenav-card>
          <img class="w-1/2 mx-auto" src="../assets/img/illustrations/icon-documentation.svg" alt="sidebar illustrations" />
          <div class="flex-auto w-full p-4 pt-0 text-center">
            <div class="transition-all duration-200 ease-nav-brand">
              <h6 class="mb-0  text-slate-700">Need help?</h6>
              <p class="mb-0 text-xs font-semibold leading-tight  ">Please check our tutorials</p>
            </div>
          </div>
        </div>
        <a href="" target="_blank" class="inline-block w-full px-8 py-2 mb-4 text-xs font-bold leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-slate-700 bg-150 hover:shadow-xs hover:-translate-y-px">Documentation</a>

        <a class="inline-block w-full px-8 py-2 text-xs font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md select-none bg-150 bg-x-25 hover:shadow-xs hover:-translate-y-px" href="" target="_blank">Video Tutorials</a>
      </div> */}
    </aside>



      </div>


)
}

export default SocietySideInner