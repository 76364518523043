import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Modal from "react-modal";
import AuthContext from "../context/AuthContext";
import { axiosInstance } from "../Axios";

const AddVenue = (props) => {
  const [guests, setGuests] = useState([
    {
      name: "",
      intro: "",
      twitter: "",
      insta: "",
      linkedin: "",
      speech: "",
      org: "",
      role: "",
      consult: "",
      research: "",
      outcome: "",
      file: "",
      filename: "",
      risk: "",
    },
  ]);

  const [riskLevels, setRiskLevels] = useState([]);
  const handleRiskChange = (index, value) => {
    const updatedRiskLevels = [...riskLevels];
    updatedRiskLevels[index] = value;
    setRiskLevels(updatedRiskLevels);
  };

  const [purchase, setPurchase] = useState([
    {
      category: [
        {
          categoryName: "",
          file: "",
          status: "",
        },
      ],
      due_date: "",
      details: "",
      selected_category: "",
    },
  ]);

  const handleGuestFileChange = (e, index) => {
    const newGuests = [...guests];
    newGuests[index]["file"] = e.target.files[0];
    setGuests(newGuests);

    uploadGuestFile(e.target.files[0], index, "guests");
  };

  // const uploadGuestFile = async (file, index, type) => {
  //   const formData = new FormData();
  //   formData.append("document", file);
  //   formData.append("type", type);

  //   try {
  //     const response = await axiosInstance.post("upload_file/", formData);

  //     // Assuming your API response provides the file path and filename
  //     const { file_path, filename } = response.data;
  //     toast.success("File uploaded successfully:");
  //     console.log("File uploaded successfully:", file_path, filename);

  //     // Update state with file path and filename
  //     const newGuests = [...guests];
  //     newGuests[index].file = file_path;
  //     setGuests(newGuests);

  //     // Handle any additional logic after successful upload
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     // Handle error case
  //   }
  // };

  const uploadGuestFile = async (file, index, type) => {
    // const file = e.target.files[0];
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      toast.error("Error uploading image");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      toast.success("File uploaded successfully");
      console.warn("URL---", sendData.secure_url);
      // setPicture(sendData.secure_url);
      const newGuests = [...guests];
      newGuests[index].file = sendData.secure_url;
      setGuests(newGuests);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGuests({
      ...guests,
      [name]: value,
    });
  };
  console.warn("PROPS: ", props.event.id);
  const [type, setType] = useState(props.type);
  console.warn("TYPE:::", props.type);

  const editCategory = (purchaseIndex, categoryIndex, field, value) => {
    const newPurchase = [...purchase];
    newPurchase[purchaseIndex].category[categoryIndex][field] = value;
    setPurchase(newPurchase);
  };

  const deleteCategory = (purchaseIndex, categoryIndex) => {
    const newPurchase = [...purchase];
    newPurchase[purchaseIndex].category.splice(categoryIndex, 1);
    setPurchase(newPurchase);
  };

  const addCategory = (index) => {
    const newPurchase = [...purchase];
    newPurchase[index].category.push({
      categoryName: "",
      file: "",
      status: "P",
    });
    setPurchase(newPurchase);
  };

  // const uploadCategoryFile = async (
  //   file,
  //   purchaseIndex,
  //   categoryIndex,
  //   type
  // ) => {
  //   const formData = new FormData();
  //   formData.append("document", file);
  //   formData.append("type", type);

  //   try {
  //     const response = await axiosInstance.post("upload_file/", formData);

  //     // Assuming your API response provides the file path and filename
  //     const { file_path, filename } = response.data;

  //     toast.success("File uploaded successfully:");
  //     console.log("File uploaded successfully:", file_path, filename);

  //     // Update state with file path and filename
  //     const newPurchase = [...purchase];
  //     newPurchase[purchaseIndex].category[categoryIndex]["file"] = file_path;
  //     setPurchase(newPurchase);

  //     // Handle any additional logic after successful upload
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     // Handle error case
  //   }
  // };

  const uploadCategoryFile = async (
    file,
    purchaseIndex,
    categoryIndex,
    type
  ) => {
    // const file = e.target.files[0];
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      toast.error("Error uploading image");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      toast.success("File uploaded successfully");
      console.warn("URL---", sendData.secure_url);
      // setPicture(sendData.secure_url);
      const newPurchase = [...purchase];
      newPurchase[purchaseIndex].category[categoryIndex]["file"] =
        sendData.secure_url;
      setPurchase(newPurchase);
    }
  };

  const [auditoriums, setAuditoriums] = useState([
    {
      name: "",
      capacity: "",
      venue: "",
      date: "",
      time: "",
      status: "P",
      conditional: "c",
      feedback: "",
    },
  ]);

  const [venues, setVenues] = useState([
    {
      name: "",
      activity: "",
      capacity: "",
      venue: "",
      date: "",
      time: "",
      status: "P",
      conditional: "c",
      feedback: "",
    },
  ]);

  const [data, setData] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("get_venues/")
      .then((response) => {
        setData(response.data);
        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // setLoading(false);
      });
  }, []);

  const updateArr = (index, field, value, type) => {
    switch (type) {
      case "guest":
        const newGuests = [...guests];
        newGuests[index][field] = value;
        setGuests(newGuests);
        break;
      case "purchase":
        const newPurchase = [...purchase];
        newPurchase[index][field] = value;
        setPurchase(newPurchase);
        break;
      case "venue":
        const newVenue = [...venues];
        newVenue[index][field] = value;
        setVenues(newVenue);
        break;
      case "auditorium":
        const newAuditorium = [...auditoriums];
        newAuditorium[index][field] = value;
        setAuditoriums(newAuditorium);
        break;
    }
  };
  const removeArr = (type) => {
    switch (type) {
      case "guest":
        const newGuests = [...guests];
        newGuests.pop();
        setGuests(newGuests);
        break;
      case "purchase":
        const newPurchase = [...purchase];
        newPurchase.pop();
        setPurchase(newPurchase);
        break;
      case "venue":
        const newVenue = [...venues];
        newVenue.pop();
        setVenues(newVenue);
        break;
      case "auditorium":
        const newAuditorium = [...auditoriums];
        newAuditorium.pop();
        setAuditoriums(newAuditorium);
        break;
    }
  };

  const addArr = (type) => {
    switch (type) {
      case "guest":
        setGuests([
          ...guests,
          {
            name: "",
            intro: "",
            twitter: "",
            insta: "",
            linkedin: "",
            speech: "",
            org: "",
            role: "",
            consult: "",
            research: "",
            outcome: "",
            file: "",
            filename: "",
          },
        ]);
        break;
      case "purchase":
        setPurchase([
          ...purchase,
          {
            category: [
              {
                categoryName: "",
                file: "",
                status: "P",
              },
            ],
            due_date: "",
            details: "",
            selected_category: "",
          },
        ]);
        break;

      case "venue":
        setVenues([
          ...venues,
          {
            name: "",
            activity: "",
            capacity: "",
            venue: "",
            date: "",
            time: "",
          },
        ]);
        break;
      case "auditorium":
        setAuditoriums([
          ...auditoriums,
          { name: "", capacity: "", venue: "", date: "", time: "" },
        ]);
        break;
    }
  };

  const handleSubmit = () => {
    if (type === "guest") {
      var sendData = [...guests];
      var url = "update_guest/";
    } else if (type === "purchase") {
      var sendData = [...purchase];
      var url = "update_purchase/";
    } else if (type === "auditorium") {
      var sendData = [...auditoriums];
      var url = "add_venues/";
    } else if (type === "venue") {
      var sendData = [...venues];
      var url = "add_venues/";
    }
    axiosInstance
      .post(url, {
        data: sendData,
        event_id: props.event.id,
        type: type,
      })
      .then((response) => {
        console.log(response.data);
        toast.success("Event Updated Successfully");
        props.onModalClose();
        props.refreshEvent();
        // props.history.push("/events");
      })
      .catch((error) => {
        console.error("Error updating event:", error);
        toast.error("Error updating event");
      });
  };

  return (
    <div>
      <div className="bg-white p-6 rounded ">
        {type === "guest" ? (
          guests.map((guest, index) => (
            <div key={index}>
              <div className="form-control w-full pb-6 border-b-2">
                <label className="label">
                  <span className="label-text">Pick the Risk Level</span>
                </label>
                {/* <select
                  className="select select-bordered"
                  checked={isChecked_Risk}
                  onChange={handleRiskChange}
                > */}
                <select
                  className="select select-bordered"
                  value={riskLevels[index]}
                  onChange={(e) => {
                    handleRiskChange(index, e.target.value);
                    updateArr(index, "risk", e.target.value, "guest");
                  }}
                >
                  <option value="">Select One</option>
                  <option value="No">
                    No | In-House Experts/Members, etc.
                  </option>
                  <option value="Low">Low | Academian/Microfluencer</option>
                  <option value="Medium">
                    Medium | Social influencer, Artist
                  </option>
                  <option value="High">
                    High | Celebrity, Politician, Media personality, etc.
                  </option>
                </select>
              </div>

              <label className="bg-black text-white py-2 px-4 mt-4 block w-max rounded-full">
                {riskLevels[index] === "No"
                  ? "No Risk"
                  : riskLevels[index] === "Low"
                  ? "Low Risk"
                  : riskLevels[index] === "Medium"
                  ? "Medium Risk"
                  : riskLevels[index] === "High"
                  ? "High Risk"
                  : ""}
              </label>
              <div>
                <div>
                  <div className="flex gap-4 flex-col bg mt-6">
                    <p className="font-bold"> Guest Profile </p>
                    <div className="bg-gray-100 rounded-lg p-4">
                      <div className="w-full ">
                        <span className="label-text">Guest Name</span>
                        <input
                          type="text"
                          placeholder="John Doe"
                          className="input input-bordered w-full mb-2"
                          value={guest.name}
                          onChange={(e) =>
                            updateArr(index, "name", e.target.value, "guest")
                          }
                        />
                      </div>
                      <div className="w-full flex flex-col">
                        <span className="label-text">Guest Introduction</span>
                        <textarea
                          className="textarea textarea-bordered"
                          placeholder="Intro text...."
                          value={guest.intro}
                          onChange={(e) =>
                            updateArr(index, "intro", e.target.value, "guest")
                          }
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-4 flex-col bg mt-6">
                    <p className="font-bold"> Social Media Accounts </p>
                    <div className="bg-gray-100 rounded-lg p-4 flex-row gap-4 flex">
                      <div className="w-full ">
                        <span className="label-text">Twitter</span>
                        <input
                          type="url"
                          placeholder="twitter.com/"
                          className="input input-bordered w-full mb-2"
                          value={guest.twitter}
                          onChange={(e) =>
                            updateArr(index, "twitter", e.target.value, "guest")
                          }
                        />
                      </div>
                      <div className="w-full ">
                        <span className="label-text">Instagram</span>
                        <input
                          type="url"
                          placeholder="Instagram.com/"
                          className="input input-bordered w-full mb-2"
                          value={guest.insta}
                          onChange={(e) =>
                            updateArr(index, "insta", e.target.value, "guest")
                          }
                        />
                      </div>
                      <div className="w-full ">
                        <span className="label-text">LinkedIn</span>
                        <input
                          type="url"
                          placeholder="linkedin.com/"
                          className="input input-bordered w-full mb-2"
                          value={guest.linkedin}
                          onChange={(e) =>
                            updateArr(
                              index,
                              "linkedin",
                              e.target.value,
                              "guest"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-4 flex-col bg mt-6">
                    <p className="font-bold"> Speech Content </p>
                    <div className="bg-gray-100 rounded-lg p-4">
                      <div className="w-full flex flex-col">
                        <span className="label-text">Content of speech</span>
                        <textarea
                          className="textarea textarea-bordered"
                          placeholder="content...."
                          value={guest.speech}
                          onChange={(e) =>
                            updateArr(index, "speech", e.target.value, "guest")
                          }
                        ></textarea>
                      </div>
                      <div className="divider"> OR </div>

                      <div className="form-control w-full ">
                        <label className="label">
                          <span className="label-text">Upload PDF/Word</span>
                        </label>
                        <input
                          type="file"
                          className="file-input file-input-bordered w-full "
                          onChange={(e) => handleGuestFileChange(e, index)}
                        />
                      </div>
                    </div>
                  </div>

                  {(() => {
                    if (
                      riskLevels[index] === "High" ||
                      riskLevels[index] === "Medium"
                    ) {
                      return (
                        <div class="flex gap-4 flex-col bg mt-6">
                          <p className="font-bold"> Associated Organisation </p>
                          <div className="bg-gray-100 rounded-lg p-4 flex-row gap-4 flex">
                            <div className="w-full ">
                              <span className="label-text">
                                Organisation Title
                              </span>
                              <input
                                type="text"
                                placeholder="ABC Cops."
                                className="input input-bordered w-full mb-2"
                                value={guest.org}
                                onChange={(e) =>
                                  updateArr(
                                    index,
                                    "org",
                                    e.target.value,
                                    "guest"
                                  )
                                }
                              />
                            </div>
                            <div className="w-full ">
                              <span className="label-text">
                                Role/Designation
                              </span>
                              <input
                                type="url"
                                placeholder="Vice President"
                                className="input input-bordered w-full mb-2"
                                value={guest.role}
                                onChange={(e) =>
                                  updateArr(
                                    index,
                                    "role",
                                    e.target.value,
                                    "guest"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (
                      riskLevels[index] === "High" ||
                      riskLevels[index] === "Medium"
                    ) {
                      return (
                        <div className="flex gap-4 flex-col bg mt-6">
                          <p className="font-bold">
                            {" "}
                            External Consultation Parties for Approval
                          </p>
                          <div className="bg-gray-100 rounded-lg p-4 flex-row gap-4 flex">
                            <div className="w-full flex flex-col">
                              <span className="label-text">
                                Consultation Parties
                              </span>
                              <textarea
                                className="textarea textarea-bordered"
                                placeholder="Write here...."
                                value={guest.consult}
                                onChange={(e) =>
                                  updateArr(
                                    index,
                                    "consult",
                                    e.target.value,
                                    "guest"
                                  )
                                }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (
                      riskLevels[index] === "High" ||
                      riskLevels[index] === "Low" ||
                      riskLevels[index] === "Medium"
                    ) {
                      return (
                        <div className="flex gap-4 flex-col bg mt-6">
                          <p className="font-bold">
                            {" "}
                            Details of Research Undertaken{" "}
                          </p>
                          <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                            <div className="w-full flex flex-col">
                              <span className="label-text">
                                Research Process
                              </span>
                              <textarea
                                className="textarea textarea-bordered"
                                placeholder="Write here...."
                                value={guest.research}
                                onChange={(e) =>
                                  updateArr(
                                    index,
                                    "research",
                                    e.target.value,
                                    "guest"
                                  )
                                }
                              ></textarea>
                            </div>

                            <div className="w-full flex flex-col">
                              <span className="label-text">
                                Research Outcome
                              </span>
                              <textarea
                                className="textarea textarea-bordered"
                                placeholder="Write here...."
                                value={guest.outcome}
                                onChange={(e) =>
                                  updateArr(
                                    index,
                                    "outcome",
                                    e.target.value,
                                    "guest"
                                  )
                                }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>
          ))
        ) : type === "purchase" ? (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Any special purchase requests for the event? 🛒
            </h1>
            <p className="mt-4 text-gray-500">
              We want to make sure your event is a smashing success, and that
              means taking care of all the details. If you have any special
              purchase requests to enhance the event experience, such as
              decorations, equipment, rental service, printing, artist
              performance, or other items, please let us know. We're here to
              help bring your vision to life, so feel free to share your
              purchase requests in the field below.
            </p>

            {purchase?.map((purch, index) => (
              <div>
                <div className="form-control w-full " key={index}>
                  <label className="label">
                    <span className="label-text">Pick the category</span>
                  </label>
                  <select
                    className="select select-bordered"
                    onChange={(e) =>
                      updateArr(
                        index,
                        "selected_category",
                        e.target.value,
                        "purchase"
                      )
                    }
                  >
                    <option value="Service/Rental/Artist">
                      Service/Rental
                    </option>
                    <option value="Artist">Artist</option>
                    <option value="External Material">External Material</option>
                    <option value="Printing">Printing</option>
                  </select>
                </div>

                <div className="flex gap-4 flex-col bg mt-6">
                  <p className="font-bold"> Purchase Details </p>
                  <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                    <div className="w-full flex flex-col">
                      <span className="label-text">Due Date</span>
                      <input
                        type="date"
                        placeholder="Type here"
                        className="input input-bordered w-full "
                        value={purch.due_date}
                        onChange={(e) =>
                          updateArr(
                            index,
                            "due_date",
                            e.target.value,
                            "purchase"
                          )
                        }
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <span className="label-text">
                        Complete List of items/details
                      </span>

                      <div className="w-full flex flex-col">
                        {Array.isArray(purch.category) &&
                          purch.category.map((category, categoryIndex) => (
                            <div
                              className="w-full flex flex-col gap-3"
                              key={categoryIndex}
                            >
                              <label>Name:</label>
                              <input
                                type="text"
                                className="input input-bordered w-full "
                                value={category.categoryName}
                                onChange={(e) =>
                                  editCategory(
                                    index,
                                    categoryIndex,
                                    "categoryName",
                                    e.target.value
                                  )
                                }
                              />

                              <input
                                type="text"
                                className="input input-bordered w-full hidden"
                                value="Pending"
                                onChange={(e) =>
                                  editCategory(
                                    index,
                                    categoryIndex,
                                    "status",
                                    e.target.value
                                  )
                                }
                              />

                              {/* <label>File:</label>
                        <input
                       type="text"
                       value={category.file}
                        onChange={(e) => editCategory(index, categoryIndex, 'file', e.target.value)}
                      /> */}
                              <div className="form-control w-full ">
                                <label className="label">
                                  <span className="label-text">
                                    Upload Sample(s)
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  className="file-input file-input-bordered w-full "
                                  onChange={(e) =>
                                    uploadCategoryFile(
                                      e.target.files[0],
                                      index,
                                      categoryIndex,
                                      "categories"
                                    )
                                  }
                                />
                              </div>
                              <div className="w-full flex items-center justify-end">
                                <button
                                  className="bg-red-500 capitalize  btn btn-outlined btn-sm text-white font-semibold"
                                  onClick={() =>
                                    deleteCategory(index, categoryIndex)
                                  }
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          ))}

                        <div>
                          <button
                            className="btn btn-outlined btn-sm bg-white  font-bold"
                            onClick={() => addCategory(index)}
                          >
                            + Add More
                          </button>
                        </div>
                      </div>

                      {/*<textarea*/}
                      {/*  className="textarea textarea-bordered"*/}
                      {/*  placeholder="Write here...."*/}
                      {/*  value={purch.details}*/}
                      {/*  onChange={(e) =>*/}
                      {/*    updateArr(*/}
                      {/*      index,*/}
                      {/*      "details",*/}
                      {/*      e.target.value,*/}
                      {/*      "purchase"*/}
                      {/*    )*/}
                      {/*  }*/}
                      {/*></textarea>*/}
                    </div>
                    {/* <div className="form-control w-full ">
                      <label className="label">
                        <span className="label-text">Upload Sample(s)</span>
                      </label>
                      <input
                        type="file"
                        className="file-input file-input-bordered w-full "
                        onChange={(e) => handlePurchaseFileChange(e, index)}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            ))}

            <div className="mt-4 flex flex-row gap-2 justify-start">
              <button
                className="btn btn-outlined btn-sm  font-bold"
                onClick={() => addArr("purchase")}
              >
                + Add More
              </button>
              <button
                className="btn btn-outlined  btn-sm  font-bold"
                onClick={() => removeArr("purchase")}
              >
                - Remove{" "}
              </button>
            </div>
          </div>
        ) : type === "auditorium" ? (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Please provide the preferred dates, timings, seating capacity, and
              select your preferred auditorium option for the event. 🏟️
            </h1>
            <p className="mt-4 text-gray-500">
              If you have selected 'Yes' for booking an auditorium for your
              meeting, please provide the preferred dates, timing(s), and select
              your desired auditorium from the options provided.
            </p>

            {auditoriums.map((aud, index) => (
              <div>
                <div className="form-control w-full " key={index}>
                  <div className="flex gap-4 flex-col bg mt-6">
                    <p className="font-bold">
                      {" "}
                      Auditorium Details {index + 1}{" "}
                    </p>
                    <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                      <div className="flex gap-4 flex-row">
                        <div className="w-full">
                          <span className="label-text">Capacity</span>
                          <input
                            type="number"
                            placeholder="No. of participants"
                            className="input input-bordered w-full mb-2"
                            value={aud.capacity}
                            onChange={(e) =>
                              updateArr(
                                index,
                                "capacity",
                                e.target.value,
                                "auditorium"
                              )
                            }
                          />
                        </div>{" "}
                        <div className="w-full">
                          <span className="label-text">Venue</span>
                          <select
                            className="select select-bordered w-full max-w-xs"
                            value={aud.venue}
                            onChange={(e) =>
                              updateArr(
                                index,
                                "venue",
                                e.target.value,
                                "auditorium"
                              )
                            }
                          >
                            <option value="">Select Venue</option>
                            {data
                              .filter((venue) => venue.type === "Indoor")
                              .map((indoorVenue) => (
                                <option
                                  key={indoorVenue.id}
                                  value={indoorVenue.name}
                                >
                                  {indoorVenue.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="flex gap-4 flex-row">
                        <div className="w-full">
                          <span className="label-text">Date</span>
                          <input
                            type="date"
                            className="input input-bordered w-full mb-2"
                            value={aud.date}
                            onChange={(e) =>
                              updateArr(
                                index,
                                "date",
                                e.target.value,
                                "auditorium"
                              )
                            }
                          />
                        </div>
                        <div className="w-full">
                          <span className="label-text">Time</span>
                          <input
                            type="time"
                            className="input input-bordered w-full mb-2"
                            value={aud.time}
                            onChange={(e) =>
                              updateArr(
                                index,
                                "time",
                                e.target.value,
                                "auditorium"
                              )
                            }
                          />
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="mt-4 flex flex-row gap-2 justify-start">
              <button
                className="btn btn-outlined btn-sm  font-bold"
                onClick={() => addArr("auditorium")}
              >
                + Add More
              </button>
              <button
                className="btn btn-outlined  btn-sm  font-bold"
                onClick={() => removeArr("auditorium")}
              >
                - Remove{" "}
              </button>
            </div>
          </div>
        ) : type === "venue" ? (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Please provide the preferred dates, timings, seating capacity, and
              select your preferred outdoor venue 🏞️
            </h1>
            <p className="mt-4 text-gray-500">
              Please provide the preferred dates, timing(s), and select your
              desired auditorium from the options provided.
            </p>

            {venues.map((venue, index) => (
              <div>
                <div className="form-control w-full " key={index}>
                  <div className="flex gap-4 flex-col bg mt-6">
                    <p className="font-bold">
                      {" "}
                      Outside Venue Details {index + 1}{" "}
                    </p>
                    <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                      <div className="flex gap-4 flex-row">
                        {/* <div class="w-full">
                            <span class="label-text">Activity</span>
                            <input
                              type="text"
                              placeholder="Activity Name"
                              class="input input-bordered w-full mb-2"
                              value={venue.activity}
                              onChange={(e) => {
                                updateArr(
                                  index,
                                  "activity",
                                  e.target.value,
                                  "venue"
                                );
                                updateArr(index, "name", e.target.value, "venue");
                              }}
                            />
                          </div> */}
                        <div className="w-full">
                          <span className="label-text">Capacity</span>
                          <input
                            type="number"
                            placeholder="No. of participants"
                            className="input input-bordered w-full mb-2"
                            value={venue.capacity}
                            onChange={(e) =>
                              updateArr(
                                index,
                                "capacity",
                                e.target.value,
                                "venue"
                              )
                            }
                          />
                        </div>{" "}
                        <div className="w-full">
                          <span className="label-text">Venue</span>
                          <select
                            className="select select-bordered w-full max-w-xs"
                            value={venue.venue}
                            onChange={(e) =>
                              updateArr(index, "venue", e.target.value, "venue")
                            }
                          >
                            <option value="">Select Venue</option>
                            {data
                              .filter((venue) => venue.type === "Outdoor")
                              .map((outdoorVenue) => (
                                <option
                                  key={outdoorVenue.id}
                                  value={outdoorVenue.name}
                                >
                                  {outdoorVenue.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="flex gap-4 flex-row">
                        <div className="w-full">
                          <span className="label-text">Date</span>
                          <input
                            type="date"
                            className="input input-bordered w-full mb-2"
                            value={venue.date}
                            onChange={(e) =>
                              updateArr(index, "date", e.target.value, "venue")
                            }
                          />
                        </div>
                        <div className="w-full">
                          <span className="label-text">Time</span>
                          <input
                            type="time"
                            className="input input-bordered w-full mb-2"
                            value={venue.time}
                            onChange={(e) =>
                              updateArr(index, "time", e.target.value, "venue")
                            }
                          />
                        </div>{" "}
                      </div>
                      <div className="w-full">
                        <span className="label-text">Activity Details</span>
                        <textarea
                          type="text"
                          placeholder="Detailed Description"
                          className="input input-bordered w-full mb-2 p-4"
                          value={venue.activity}
                          onChange={(e) => {
                            updateArr(
                              index,
                              "activity",
                              e.target.value,
                              "venue"
                            );
                            updateArr(index, "name", e.target.value, "venue");
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="mt-4 flex flex-row gap-2 justify-start">
              <button
                className="btn btn-outlined btn-sm  font-bold"
                onClick={() => addArr("venue")}
              >
                + Add More
              </button>
              <button
                className="btn btn-outlined  btn-sm  font-bold"
                onClick={() => removeArr("venue")}
              >
                - Remove{" "}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">Error</h1>
          </div>
        )}
      </div>
      <div className="text-right">
        <button
          type="submit"
          className="btn btn-primary mr-2"
          onClick={() => {
            handleSubmit();
            props.onModalClose();
          }}
        >
          Add {type}
        </button>
        <button onClick={props.onModalClose} className="btn btn-ghost">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddVenue;