import React, { useState, useEffect, useContext } from "react";
import { Link, Navigate, Redirect, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import "react-calendar/dist/Calendar.css";
import { axiosInstance } from "../Axios";
import { toast, Toaster } from "react-hot-toast";
import "../Stepper.css";
import SocietySidebar from "../components/SocietySidebar";
import SocietySideInnerSM from "../components/SocietySideInnerSM";
import SocNav from "../components/SocNav";
import EventTabs from "../components/eventform/event-tabs";





const EventReqForm = () => {
  // initalize thread and posts component state
  let { user } = useContext(AuthContext);
  let USERID = user["user_id"];
  const [step, setStep] = useState(1);
  const [society_name, setSocietyName] = useState(user["username"]);
  const [isChecked_EventHead, setIsCheckedEventHead] = useState(false);
  const [riskLevels, setRiskLevels] = useState([]);
  const [societyProfile, setSocietyProfile] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pdc_file, setPdcFile] = useState("");
  const [ground_men, setGroundMen] = useState("");
  const [welder_service, setWelderService] = useState("");
  const [plumber_service, setPlumberService] = useState("");
  const [labour_service, setLabourService] = useState("");
  const [carpenter_service, setCarpenterService] = useState("");
  const [security_service, setSecurityService] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [stepsEvent, setStepsEvent] = useState(1);
  const navigate = useNavigate();
  const [isLoadingIcon, setisLoadingIcon] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [societyId, setSocietyId] = useState(null);

  const [venues, setVenues] = useState([
    {
      name: "",
      activity: "",
      capacity: "",
      venue: "",
      date: "",
      time: "",
      status: "P",
      conditional: "c",
      feedback: "",
    },

  ]);

  const [auditoriums, setAuditoriums] = useState([
    {
      name: "",
      capacity: "",
      venue: "",
      date: "",
      time: "",
      status: "P",
      conditional: "c",
      feedback: "",
    },
  ]);
  const [registrationFilePath, setRegistrationFilePath] = useState("");

  const [guests, setGuests] = useState([
    {
      name: "",
      intro: "",
      twitter: "",
      insta: "",
      linkedin: "",
      speech: "",
      org: "",
      role: "",
      consult: "",
      research: "",
      outcome: "",
      file: "",
      filename: "",
      risk: "",
    },
  ]);

  const [purchase, setPurchase] = useState([
    {
      category: [
        {
          categoryName: "",
          file: "",
          status: "",
        },
      ],
      due_date: "",
      details: "",
      selected_category: "",
    },
  ]);
  const [securityClearanceServices, setSecurityClearanceServices] = useState([
    {
      name: "",
      cnic: "",
      time: "",
      vehicle: "",
    },
  ]);
  const [eventFormData, setEventFormData] = useState({
    society_name: user["username"],
    applicant_name: "",
    applicant_designation: "",
    applicant_contact: "",
    applicant_email: "",
    requestType: "",
    event_name: "",
    meeting_name: "",
    isChecked_EventHead: false,
    event_head_name: "",
    event_head_designation: "",
    event_head_contact: "",
    event_head_email: "",
    start_date: "",
    end_date: "",
    one_day_event: false,
    today_event: false,
    event_description: "",
    services: [
      { label: "Registration Portal", isChecked: false },
      { label: "Guest Speaker", isChecked: false },
      { label: "Purchase", isChecked: false },
      { label: "Outdoor Venue", isChecked: false },
      { label: "Audit", isChecked: false },
      { label: "PDC/EDH", isChecked: false },
      { label: "Security Clearance", isChecked: false },
      { label: "Other Services", isChecked: false },
    ],
  });

  const [errors, setErrors] = useState({
    applicant_name: '',
    applicant_designation: '',
    applicant_contact: '',
    applicant_email: '',
    event_head_name: '',
    event_head_contact: '',
    event_head_email: ''
  });
  


  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [headContactValid, setHeadContactValid] = useState(false);
  const [headEmailValid, setHeadEmailValid] = useState(false);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [headDesignationValid, setHeadDesignationValid] = useState(false); // New state added

  const [showPdf, setShowPdf] = useState(false);
  const pdfFileName = ("https://drive.google.com/file/d/1ZGTSn2d0mrh0I3Ld9M5Z8K9GC6-orZUu/view?usp=drive_link");


  const [showPdf2, setShowPdf2] = useState(false);
  const pdfFileName2 = ("https://drive.google.com/file/d/1Awk3Dh1axRrVYMB6TDhHjc9T4wAcadBY/view?usp=sharing");

  const handleFile = async () => {
    try {
        const response = await fetch(`${process.env.PUBLIC_URL}/Event%20and%20Procurement%20Form.xlsx`);
        const blob = await response.blob();

        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'Event and Procurement Form.xlsx';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);

    } catch (error) {
        console.error('Error fetching or parsing Excel file:', error);
    }
  };

  const [procurements, setProcurements] = useState([
    {
      due_date: '',
      category: [
        {
          categoryName: '',
          status: 'Pending',
          quantity: 0,
          pricePerQuantity: 0,
          totalPrice: 0,
          // other fields
        }
      ],
    }
  ]);
  
  const modifyArray = (index, field, value, type) => {
    const updatedProcurements = [...procurements];
    if (type === "procurement") {
      updatedProcurements[index][field] = value;
    }
    setProcurements(updatedProcurements);
  };
  
  const alterCategory = (procurementIndex, categoryIndex, field, value) => {
    const updatedProcurements = [...procurements];
    const category = updatedProcurements[procurementIndex].category[categoryIndex];
    
    category[field] = value;
  
    // Automatically update totalPrice if quantity or pricePerQuantity is modified
    if (field === 'quantity' || field === 'pricePerQuantity') {
      category.totalPrice = category.quantity * category.pricePerQuantity;
    }
    
    setProcurements(updatedProcurements);
  };
  
  const uploadFileForCategory = (file, procurementIndex, categoryIndex, type) => {
    // handle file upload and update the state
    // you can use a file reader or upload to a server and store the file URL in state
  };
  
  const removeCategory = (procurementIndex, categoryIndex) => {
    const updatedProcurements = [...procurements];
    updatedProcurements[procurementIndex].category.splice(categoryIndex, 1);
    setProcurements(updatedProcurements);
  };
  
  const appendCategory = (procurementIndex) => {
    const updatedProcurements = [...procurements];
    updatedProcurements[procurementIndex].category.push({
      categoryName: '',
      status: 'Pending',
      quantity: 0,
      pricePerQuantity: 0,
      totalPrice: 0,
      // other fields
    });
    setProcurements(updatedProcurements);
  };
  
  const getTotalQuantity = () => {
    return procurements.reduce((total, procurement) => {
      return total + procurement.category.reduce((categoryTotal, category) => {
        return categoryTotal + category.quantity;
      }, 0);
    }, 0);
  };
  
  const getTotalPrice = () => {
    return procurements.reduce((total, procurement) => {
      return total + procurement.category.reduce((categoryTotal, category) => {
        return categoryTotal + category.totalPrice;
      }, 0);
    }, 0);
  };

  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    calculateTotalDays();
  }, [eventFormData.start_date, eventFormData.end_date]);

  const handleDateFieldChange = (field, value) => {
    setEventFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleEventCheckboxChange = (field, value) => {
    setEventFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (field === "today_event" && value) {
      handleDateFieldChange("end_date", "");
    }
  };

  const calculateTotalDays = () => {
    const startDate = new Date(eventFormData.start_date);
    const endDate = new Date(eventFormData.end_date);

    if (!isNaN(startDate) && !isNaN(endDate)) {
      const differenceInTime = endDate.getTime() - startDate.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      setTotalDays(Math.round(differenceInDays) + 1); // Add 1 to include both start and end dates
    } else {
      setTotalDays(0);
    }
  };

  const handleStartDateUpdate = (value) => {
    handleDateFieldChange("start_date", value);
    if (eventFormData.one_day_event || eventFormData.today_event) {
      handleDateFieldChange("end_date", value);
    }
  };

  const handleEndDateUpdate = (value) => {
    handleDateFieldChange("end_date", value);
  };





  useEffect(() => {
    const isStep3FieldsFilled =
      (eventFormData.applicant_name || '').trim().length >= 2 &&
      (eventFormData.applicant_designation || '').trim().length >= 2 &&
      phoneNumberValid &&
      emailValid;
  
    const isStep6FieldsFilled =
      (eventFormData.event_head_name || '').trim().length >= 2 &&
      (eventFormData.event_head_designation || '').trim().length >= 2 && // New condition added
      headContactValid &&
      headEmailValid;
  
    if (step === 3) {
      setAllFieldsFilled(isStep3FieldsFilled);
    } else if (step === 6) {
      setAllFieldsFilled(isStep6FieldsFilled);
    }
  }, [
    eventFormData,
    phoneNumberValid,
    emailValid,
    headContactValid,
    headEmailValid,
    headDesignationValid, // Added dependency
    step,
  ]);
  
  
  const handleFieldChange = (field, value) => {
    setEventFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [field]: value };
      localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));
      return updatedFormData;
    });
  
    let errorMessage = '';
    let isFieldValid = false;
  
    switch (field) {
      case 'applicant_contact':
        isFieldValid = /^(\+92|03)\d{9}$/.test(value);
        errorMessage = isFieldValid ? '' : 'Phone number should start with 03 and having exactly 11 digits.';
        setPhoneNumberValid(isFieldValid);
        break;
      case 'applicant_email':
        isFieldValid = /^[^\s@]+@lums\.edu\.pk$/.test(value);
        errorMessage = isFieldValid ? '' : 'Enter a valid LUMS email address (e.g., username@lums.edu.pk).';
        setEmailValid(isFieldValid);
        break;
      case 'applicant_name':
        isFieldValid = value.trim().length >= 2;
        errorMessage = isFieldValid ? '' : 'Applicant Name is required and should be at least 2 characters long.';
        break;
      case 'applicant_designation':
        isFieldValid = value.trim().length >= 2;
        errorMessage = isFieldValid ? '' : 'Applicant Designation is required and should be at least 2 characters long.';
        break;
      case 'event_head_name':
        isFieldValid = value.trim().length >= 2;
        errorMessage = isFieldValid ? '' : 'Event Head Name is required and should be at least 2 characters long.';
        break;
      case 'event_head_designation':
        isFieldValid = value.trim().length >= 2; // Validation for event_head_designation
        errorMessage = isFieldValid ? '' : 'Event Head Designation is required and should be at least 2 characters long.'; // Error message for event_head_designation
        setHeadDesignationValid(isFieldValid); // Set state for event_head_designation
        break;
      case 'event_head_contact':
        isFieldValid = /^(\+92|03)\d{9}$/.test(value);
        errorMessage = isFieldValid ? '' : 'Phone number should start with 03 and having exactly 11 digits.';
        setHeadContactValid(isFieldValid);
        break;
        case 'event_head_email':
          isFieldValid = /^[^\s@]+@lums\.edu\.pk$/.test(value);
          errorMessage = isFieldValid ? '' : 'Invalid email format. Please enter a valid LUMS email address (e.g., email@lums.edu.pk).';
        setHeadEmailValid(isFieldValid);
        break;
      default:
        break;
    }
  
    setErrors((prevErrors) => ({ ...prevErrors, [field]: errorMessage }));
  };

  


  

  const handleCheckboxChange = (field) => {
    setEventFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [field]: !prevFormData[field],
      };
  
      localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));
  
      return updatedFormData;
    });
  };      

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("eventFormData"));
    if (storedData) {
      validateFields(storedData);
    }
  }, []);



  const validateFields = (data) => {
    const isPhoneValid = /^(\+92|03)\d{9}$/.test(data.applicant_contact);
    const isEmailValid = /^[^\s@]+@lums\.edu\.pk$/.test(data.applicant_email);
    const isNameValid = (data.applicant_name || '').trim().length >= 2;
    const isDesignationValid = (data.applicant_designation || '').trim().length >= 2;
    const isHeadNameValid = (data.event_head_name || '').trim().length >= 2;
    const isHeadContactValid = /^(\+92|03)\d{9}$/.test(data.event_head_contact);
    const isHeadEmailValid = /^[^\s@]+@lums\.edu\.pk$/.test(data.event_head_email);
    const isHeadDesignationValid = (data.event_head_designation || '').trim().length >= 2; // Validation for event_head_designation

    setPhoneNumberValid(isPhoneValid);
    setEmailValid(isEmailValid);
    setHeadContactValid(isHeadContactValid);
    setHeadEmailValid(isHeadEmailValid);
    setHeadDesignationValid(isHeadDesignationValid); // Set state for event_head_designation
    setAllFieldsFilled(
      isNameValid &&
      isDesignationValid &&
      isPhoneValid &&
      isEmailValid &&
      isHeadNameValid &&
      isHeadContactValid &&
      isHeadEmailValid &&
      isHeadDesignationValid // Add the condition for event_head_designation
    );
  };







  

  useEffect(() => {
    if (eventFormData?.requestType === "Meeting") {
      const { event_name, ...newFormData } = eventFormData;
      setEventFormData(newFormData);
      localStorage.setItem("eventFormData", JSON.stringify(newFormData));
    } else if (eventFormData?.requestType === "Event") {
      const { meeting_name, ...newFormData } = eventFormData;
      setEventFormData(newFormData);
      localStorage.setItem("eventFormData", JSON.stringify(newFormData));
    }
  }, [eventFormData?.requestType]);

  useEffect(() => {
    console.log("isCheck is running", eventFormData.isChecked_EventHead);
    if (eventFormData.isChecked_EventHead) {
      const {
        event_head_name,
        event_head_contact,
        event_head_email,
        ...newFormData
      } = eventFormData;
      setEventFormData(newFormData);
      localStorage.setItem("eventFormData", JSON.stringify(newFormData));
    }
  }, [eventFormData.isChecked_EventHead]);

  const handleNext = () => {
      setStep(step + 1);
  };

  useEffect(() => {
    const storedData = localStorage.getItem("eventFormData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setEventFormData(parsedData);

      if (parsedData && parsedData?.all_guests) {
        setGuests(parsedData?.all_guests);
      }
      if (parsedData && parsedData?.all_purchases) {
        setPurchase(parsedData?.all_purchases);
      }
      if (parsedData && parsedData?.venues) {
        setVenues(parsedData?.venues);
      }
      if (parsedData && parsedData?.registrationPortalFile) {
        setRegistrationFilePath(parsedData?.registrationPortalFile);
      }
      if (parsedData && parsedData?.auditoriums) {
        setAuditoriums(parsedData?.auditoriums);
      }
      if (parsedData && parsedData?.pdc_file) {
        setPdcFile(parsedData?.pdc_file);
      }
      if (parsedData && parsedData?.security_clearance_service) {
        setSecurityClearanceServices(parsedData?.security_clearance_service);
      }
      if (parsedData && parsedData?.plumbing_service) {
        setPlumberService(parsedData?.plumbing_service);
      }
      if (parsedData && parsedData?.security_service) {
        setSecurityService(parsedData?.security_service);
      }
      if (parsedData && parsedData?.ground_men_service) {
        setGroundMen(parsedData?.ground_men_service);
      }
      if (parsedData && parsedData?.welder_service) {
        setWelderService(parsedData?.welder_service);
      }
      if (parsedData && parsedData?.labour_service) {
        setLabourService(parsedData?.labour_service);
      }
      if (parsedData && parsedData?.carpenter_service) {
        setCarpenterService(parsedData?.carpenter_service);
      }
    }
  }, []);

  const handlePrevious = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    // Make an HTTP GET request to fetch the society profile
    axiosInstance
      .get(`society/profile/${USERID}/`)
      .then((response) => {
        setSocietyProfile(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching society profile:", error);
        setLoading(false);
      });
  }, [USERID]);

  useEffect(() => {
    axiosInstance
      .get("get_venues/")
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const [isChecked_Purchase, setIsCheckedPurchase] = useState(false);

  const handleCheckPurchaseboxChange = () => {
    setIsCheckedPurchase(!isChecked_Purchase);
  };

  const [isChecked_OutdoorVenue, setIsCheckedOutdoorVenue] = useState(false);

  const handleCheckOutdoorVenueboxChange = () => {
    setIsCheckedOutdoorVenue(!isChecked_OutdoorVenue);
  };

  const [isChecked_Audit, setIsCheckedAudit] = useState(false);

  const handleCheckAuditboxChange = () => {
    setIsCheckedAudit(!isChecked_Audit);
  };

  const [isChecked_PDC, setIsCheckedPDC] = useState(false);

  const handleCheckPDCboxChange = () => {
    setIsCheckedPDC(!isChecked_PDC);
  };

  const [isChecked_Janitor, setIsCheckedJanitor] = useState(false);

  const handleCheckJanitorboxChange = () => {
    setIsCheckedJanitor(!isChecked_Janitor);
  };

  const [isChecked_SecurityStaff, setIsCheckedSecurityStaff] = useState(false);

  const handleCheckSecurityStaffboxChange = () => {
    setIsCheckedSecurityStaff(!isChecked_SecurityStaff);
  };

  const [isChecked_Plumber, setIsCheckedPlumber] = useState(false);

  const handleCheckPlumberboxChange = () => {
    setIsCheckedPlumber(!isChecked_Plumber);
  };

  const [isChecked_Welder, setIsCheckedWelder] = useState(false);

  const handleCheckWelderboxChange = () => {
    setIsCheckedWelder(!isChecked_Welder);
  };

  const [isChecked_SecClearance, setIsCheckedSecClearance] = useState(false);

  const handleCheckSecClearanceboxChange = () => {
    setIsCheckedSecClearance(!isChecked_SecClearance);
  };

  const addMoreService = () => {
    setSecurityClearanceServices((prevServices) => [
      ...prevServices,
      {
        name: "",
        cnic: "",
        time: "",
        vehicle: "",
      },
    ]);
  };

  const removeService = (index) => {
    setSecurityClearanceServices((prevServices) => [
      ...prevServices.slice(0, index),
      ...prevServices.slice(index + 1),
    ]);
  };

  const handleServiceChange = (index, field, value) => {
    setSecurityClearanceServices((prevServices) => {
      const updatedServices = [...prevServices];
      updatedServices[index][field] = value;
      return updatedServices;
    });
  };

  const handleCheckEventHeadboxChange = () => {
    setIsCheckedEventHead(!isChecked_EventHead);
  };
  const [isChecked_Risk, setIsCheckedRisk] = useState(false);

  const handleRiskChange = (index, value) => {
    const updatedRiskLevels = [...riskLevels];
    updatedRiskLevels[index] = value;
    setRiskLevels(updatedRiskLevels);
  };

  const handlePdcFileChange = (e) => {
    uploadPdcFile(e.target.files[0], "pdc");
  };

  // const uploadPdcFile = async (file, type) => {
  //   const formData = new FormData();
  //   formData.append("document", file);
  //   formData.append("type", type);

  //   try {
  //     const response = await axiosInstance.post("upload_file/", formData);

  //     // Assuming your API response provides the file path and filename
  //     const { file_path, filename } = response.data;
  //     toast.success("File uploaded successfully:");
  //     console.log("File uploaded successfully:", file_path, filename);

  //     // Update state with file path and filename
  //     setPdcFile(file_path);

  //     // Handle any additional logic after successful upload
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     // Handle error case
  //   }
  // };
  const [sendingFile, setsendingFile] = useState(false);

  
  const uploadPdcFile = async (file, type) => {
    // const file = e.target.files[0];
    setsendingFile(true);
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      toast.error("Error uploading image");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      toast.success("File uploaded successfully");
      console.warn("URL---", sendData.secure_url);
      setPdcFile(sendData.secure_url);
      setTimeout(() => {
        setsendingFile(false);
      }, 3000); // 3 seconds delay
    }
  };

  //RegistrationFile Upload

  const handleRegistrationFileChange = (e) => {
    uploadRegistrationFile(e.target.files[0], "registrations");
  };

  // const uploadRegistrationFile = async (file, type) => {
  //   const formData = new FormData();
  //   formData.append("document", file);
  //   formData.append("type", type);

  //   try {
  //     const response = await axiosInstance.post("upload_file/", formData);

  //     // Assuming your API response provides the file path and filename
  //     const { file_path, filename } = response.data;
  //     toast.success("File uploaded successfully:");
  //     console.log("File uploaded successfully:", file_path, filename);

  //     // Update state with file path and filename
  //     setRegistrationFilePath(file_path);

  //     // Handle any additional logic after successful upload
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     // Handle error case
  //   }
  // };

  const uploadRegistrationFile = async (file, type) => {
    // const file = e.target.files[0];
    setsendingFile(true);

    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      toast.error("Error uploading image");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      toast.success("File uploaded successfully");
      console.warn("URL---", sendData.secure_url);
      setRegistrationFilePath(sendData.secure_url);

      setTimeout(() => {
        setsendingFile(false);
      }, 3000); // 3 seconds delay
    }
  };




  const handleClick = () => {
    setShowPdf(true);
  };


  //guest file Upload

  const handleGuestFileChange = (e, index) => {
    const newGuests = [...guests];
    newGuests[index]["file"] = e.target.files[0];
    setGuests(newGuests);

    uploadGuestFile(e.target.files[0], index, "guests");
  };

  const uploadGuestFile = async (file, index, type) => {
    // const file = e.target.files[0];
    setsendingFile(true);
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      toast.error("Error uploading image");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      toast.success("File uploaded successfully");
      console.warn("URL---", sendData.secure_url);
      // setPicture(sendData.secure_url);
      const newGuests = [...guests];
      newGuests[index].file = sendData.secure_url;
      setGuests(newGuests);

      setTimeout(() => {
        setsendingFile(false);
      }, 3000); // 3 seconds delay
    }
  };



  const addArr = (type) => {
    switch (type) {
      case "guest":
        setGuests([
          ...guests,
          {
            name: "",
            intro: "",
            twitter: "",
            insta: "",
            linkedin: "",
            speech: "",
            org: "",
            role: "",
            consult: "",
            research: "",
            outcome: "",
            file: "",
            filename: "",
            risk: "",
          },
        ]);
        break;
      case "purchase":
        setPurchase([
          ...purchase,
          {
            category: [
              {
                categoryName: "",
                file: "",
                status: "P",
              },
            ],
            due_date: "",
            details: "",
            selected_category: "",
          },
        ]);
        break;

      case "venue":
        setVenues([
          ...venues,
          {
            name: "",
            activity: "",
            capacity: "",
            venue: "",
            date: "",
            time: "",
          },
        ]);
        break;
      case "auditorium":
        setAuditoriums([
          ...auditoriums,
          { name: "", capacity: "", venue: "", date: "", time: "" },
        ]);
        break;
    }
  };

  const removeArr = (type) => {
    switch (type) {
      case "guest":
        const newGuests = [...guests];
        newGuests.pop();
        setGuests(newGuests);
        break;
      case "purchase":
        const newPurchase = [...purchase];
        newPurchase.pop();
        setPurchase(newPurchase);
        break;
      case "venue":
        const newVenue = [...venues];
        newVenue.pop();
        setVenues(newVenue);
        break;
      case "auditorium":
        const newAuditorium = [...auditoriums];
        newAuditorium.pop();
        setAuditoriums(newAuditorium);
        break;
    }
  };

  const updateArr = (index, field, value, type) => {
    switch (type) {
      case "guest":
        const newGuests = [...guests];
        newGuests[index][field] = value;
        setGuests(newGuests);
        break;
      case "purchase":
        const newPurchase = [...purchase];
        newPurchase[index][field] = value;
        setPurchase(newPurchase);
        break;
      case "venue":
        const newVenue = [...venues];
        newVenue[index][field] = value;
        setVenues(newVenue);
        break;
      case "auditorium":
        const newAuditorium = [...auditoriums];
        newAuditorium[index][field] = value;
        setAuditoriums(newAuditorium);
        break;
    }
  };

  const handleCheckBoxChange = (index) => {
    setEventFormData((prevFormData) => {
      const newServices = [...prevFormData.services];
      newServices[index].isChecked = !newServices[index].isChecked;
  
      const updatedFormData = {
        ...prevFormData,
        services: newServices,
      };
  
      localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));
  
      return updatedFormData;
    });
  };

  const handleCheckAll = () => {
    const allChecked = !selectAll;

    const newServices = eventFormData?.services?.map((service) => ({
      ...service,
      isChecked: allChecked,
    }));

    setEventFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        services: newServices,
      };

      localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));

      return updatedFormData;
    });

    setSelectAll(allChecked);
  };

  //Select or DeSelect Guest  & Store on LocalStorage
  useEffect(() => {
    const isGuestSpeakerChecked = eventFormData?.services?.some(
      (condition) =>
        condition.isChecked === true && condition.label === "Guest Speaker"
    );

    const updateFormData = (key, value) => {
      setEventFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [key]: value,
        };

        localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));

        return updatedFormData;
      });
    };

    if (isGuestSpeakerChecked) {
      updateFormData("all_guests", guests);
    } else {
      updateFormData("all_guests", [
        {
          name: "",
          intro: "",
          twitter: "",
          insta: "",
          linkedin: "",
          speech: "",
          org: "",
          role: "",
          consult: "",
          research: "",
          outcome: "",
          file: "",
          filename: "",
          risk: "",
        },
      ]);
    }

    console.log(isGuestSpeakerChecked);
  }, [guests, eventFormData?.services]);
  //Select or DeSelect Purchase  & Store on LocalStorage
  useEffect(() => {
    const isPurchaseChecked = eventFormData?.services?.some(
      (condition) =>
        condition.isChecked === true && condition.label === "Purchase"
    );

    const updateFormData = (key, value) => {
      setEventFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [key]: value,
        };

        localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));

        return updatedFormData;
      });
    };

    if (isPurchaseChecked) {
      updateFormData("all_purchases", purchase);
    } else {
      updateFormData("all_purchases", [
        {
          category: [
            {
              categoryName: "",
              file: "",
              status: "P",
            },
          ],
          due_date: "",
          details: "",
          selected_category: "",
        },
      ]);
    }
  }, [purchase, eventFormData?.services]);
  //Select or DeSelect Outdoor Venue  & Store on LocalStorage
  useEffect(() => {
    const isVenueChecked = eventFormData?.services?.some(
      (condition) =>
        condition.isChecked === true && condition.label === "Outdoor Venue"
    );

    const updateFormData = (key, value) => {
      setEventFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [key]: value,
        };

        localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));

        return updatedFormData;
      });
    };

    if (isVenueChecked) {
      updateFormData("venues", venues);
    } else {
      updateFormData("venues", [
        {
          name: "",
          activity: "",
          capacity: "",
          venue: "",
          date: "",
          time: "",
          status: "P",
        },
      ]);
    }
  }, [venues, eventFormData?.services]);
  //Select or DeSelect RegistrationFile  & Store on LocalStorage
  useEffect(() => {
    const isRegistrationChecked = eventFormData?.services?.some(
      (condition) =>
        condition.isChecked === true &&
        condition.label === "Registration Portal"
    );

    const updateFormData = (key, value) => {
      setEventFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [key]: value,
        };

        localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));

        return updatedFormData;
      });
    };

    if (isRegistrationChecked) {
      updateFormData("registrationPortalFile", registrationFilePath);
    } else {
      updateFormData("venues", "");
    }
  }, [registrationFilePath, eventFormData?.services]);
  //Select or DeSelect auditoriums  & Store on LocalStorage
  useEffect(() => {
    const isAuditChecked = eventFormData?.services?.some(
      (condition) => condition.isChecked === true && condition.label === "Audit"
    );

    const updateFormData = (key, value) => {
      setEventFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [key]: value,
        };

        localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));

        return updatedFormData;
      });
    };

    if (isAuditChecked) {
      updateFormData("auditoriums", auditoriums);
    } else {
      updateFormData("auditoriums", [
        {
          name: "",
          capacity: "",
          venue: "",
          date: "",
          time: "",
          status: "P",
        },
      ]);
    }
  }, [auditoriums, eventFormData?.services]);
  //Select or DeSelect pdc_file  & Store on LocalStorage
  useEffect(() => {
    const isPdcChecked = eventFormData?.services?.some(
      (condition) =>
        condition.isChecked === true && condition.label === "PDC/EDH"
    );

    const updateFormData = (key, value) => {
      setEventFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [key]: value,
        };

        localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));

        return updatedFormData;
      });
    };

    if (isPdcChecked) {
      updateFormData("pdc_file", pdc_file);
    } else {
      updateFormData("pdc_file", "");
    }
  }, [pdc_file, eventFormData?.services]);
  //Select or DeSelect securityClearanceServices  & Store on LocalStorage
  useEffect(() => {
    const isSCChecked = eventFormData?.services?.some(
      (condition) =>
        condition.isChecked === true && condition.label === "Security Clearance"
    );

    const updateFormData = (key, value) => {
      setEventFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [key]: value,
        };

        localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));

        return updatedFormData;
      });
    };

    if (isSCChecked) {
      updateFormData("security_clearance_service", securityClearanceServices);
    } else {
      updateFormData("security_clearance_service", [
        {
          name: "",
          cnic: "",
          time: "",
          vehicle: "",
        },
      ]);
    }
  }, [securityClearanceServices, eventFormData?.services]);
  //Select or DeSelect Other Services  & Store on LocalStorage
  useEffect(() => {
    const isOSChecked = eventFormData?.services?.some(
      (condition) =>
        condition.isChecked === true && condition.label === "Other Services"
    );

    const updateFormData = (key, value) => {
      setEventFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [key]: value,
        };

        localStorage.setItem("eventFormData", JSON.stringify(updatedFormData));

        return updatedFormData;
      });
    };

    if (isOSChecked) {
      updateFormData("plumbing_service", plumber_service);
      updateFormData("security_service", security_service);
      updateFormData("ground_men_service", ground_men);
      updateFormData("welder_service", welder_service);
      updateFormData("labour_service", labour_service);
      updateFormData("carpenter_service", carpenter_service);
    } else {
      updateFormData("plumbing_service", "");
      updateFormData("security_service", "");
      updateFormData("ground_men_service", "");
      updateFormData("welder_service", "");
      updateFormData("labour_service", "");
      updateFormData("carpenter_service", "");
    }
  }, [
    plumber_service,
    security_service,
    ground_men,
    welder_service,
    labour_service,
    carpenter_service,
    eventFormData?.services,
  ]);

  const handleNextStep = () => {
    if(!phoneNumberValid && !emailValid){
        return
    }
    else{
      setStepsEvent(stepsEvent + 1)
    }
  };

  const handleBackStep = () => {
    setStepsEvent(stepsEvent - 1);
  };
 
  const addCategory = (index) => {
    const newPurchase = [...purchase];
    newPurchase[index].category.push({
      categoryName: "",
      file: "",
      status: "P",
    });
    setPurchase(newPurchase);
  };

  const deleteCategory = (purchaseIndex, categoryIndex) => {
    const newPurchase = [...purchase];
    newPurchase[purchaseIndex].category.splice(categoryIndex, 1);
    setPurchase(newPurchase);
  };

  const editCategory = (purchaseIndex, categoryIndex, field, value) => {
    const newPurchase = [...purchase];
    newPurchase[purchaseIndex].category[categoryIndex][field] = value;
    setPurchase(newPurchase);
  };

  // const uploadCategoryFile = async (
  //   file,
  //   purchaseIndex,
  //   categoryIndex,
  //   type
  // ) => {
  //   const formData = new FormData();
  //   formData.append("document", file);
  //   formData.append("type", type);

  //   try {
  //     const response = await axiosInstance.post("upload_file/", formData);

  //     // Assuming your API response provides the file path and filename
  //     const { file_path, filename } = response.data;

  //     toast.success("File uploaded successfully:");
  //     console.log("File uploaded successfully:", file_path, filename);

  //     // Update state with file path and filename
  //     const newPurchase = [...purchase];
  //     newPurchase[purchaseIndex].category[categoryIndex]["file"] = file_path;
  //     setPurchase(newPurchase);

  //     // Handle any additional logic after successful upload
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     // Handle error case
  //   }
  // };

  const uploadCategoryFile = async (
    file,
    purchaseIndex,
    categoryIndex,
    type
  ) => {
    // const file = e.target.files[0];

    setsendingFile(true);

    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      toast.error("Error uploading image");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      toast.success("File uploaded successfully");
      console.warn("URL---", sendData.secure_url);
      // setPicture(sendData.secure_url);
      setTimeout(() => {
        setsendingFile(false);
      }, 3000); // 3 seconds delay
      const newPurchase = [...purchase];
      newPurchase[purchaseIndex].category[categoryIndex]["file"] =
        sendData.secure_url;
      setPurchase(newPurchase);
    }
  };

  const trueConditions = eventFormData?.services?.filter(
    (condition) => condition.isChecked
  );

  const renderStepContent = () => {
    if (trueConditions.length === 0 || stepsEvent > trueConditions.length) {
      return null;
    }

    const currentCondition = trueConditions[stepsEvent - 1];

    // Custom content for each condition
    let content;
    switch (currentCondition.label) {
      case "Registration Portal":
        content = (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Registering with LUMS Portal 🖥️
            </h1>
            <p class="mt-4 text-gray-500">
              To ensure a smooth registration process for the event, we would
              like to know if you plan to use the LUMS registration portal.
            </p>

            


            <div className="mb-4">
              <label className="flex items-center space-x-4 p-4 bg-white shadow rounded-lg cursor-pointer hover:bg-gray-50 transition duration-300">
                      <input 
                          type="checkbox" 
                          className="form-checkbox h-6 w-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" 
                          value="IST Portal" 
                          checked={selectedPayment === 'IST Portal'}
                          onChange={handleCheckboxChanges} 
                      />
                      <span className="ml-2 text-lg font-medium text-gray-700">IST Portal</span>
                  </label>
              </div>
              <div className="mb-4">
                  <label className="flex items-center space-x-4 p-4 bg-white shadow rounded-lg cursor-pointer hover:bg-gray-50 transition duration-300">
                      <input 
                          type="checkbox" 
                          className="form-checkbox h-6 w-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" 
                          value="Card Pay" 
                          checked={selectedPayment === 'Card Pay'}
                          onChange={handleCheckboxChanges} 
                      />
                      <span className="ml-2 text-lg font-medium text-gray-700">Card Pay</span>
                  </label>
              </div>



            



            <div className="form-control w-full mt-6">
              <label className="label">
                <span className="label-text">Pick a file</span>
              </label>
              <input
                type="file"
                className="file-input file-input-bordered w-full"
                onChange={(e) => handleRegistrationFileChange(e)}
              />
            </div>
          </div>
        );
        break;

      case "Guest Speaker":
        content = (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Assess the Risk Level for Guests ⚠️
            </h1>
            <p className="mt-4 text-gray-500">
              Safety first! We want to make sure all attendees have a blast at
              our event without any hiccups. Help us out by assessing the risk
              level associated with inviting the guest for your event. You can
              review our guest speaker approval policy for guidance. Choose from
              the following categories: "No Risk," "Low Risk," "Medium Risk," or
              "High Risk." Your input will help us take appropriate measures to
              ensure a successful and safe event.
            </p>

            <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Link target="_blank" rel="noopener noreferrer" to={pdfFileName2} className="btn btn-warning" >
                View Guest Policy
            </Link>
        </div>







            {guests.map((guest, index) => (
                    <div key={index}>
                      <div className="form-control w-full pb-6 border-b-2">
                        <label className="label">
                          <span className="label-text">Pick the Risk Level</span>
                        </label>
                        <select
                          className="select select-bordered"
                          value={riskLevels[index]}
                          onChange={(e) => {
                            handleRiskChange(index, e.target.value);
                            updateArr(index, "risk", e.target.value, "guest");
                          }}
                        >
                          <option value="">Select One</option>
                          <option value="No">No | In-House Experts/Members, etc.</option>
                          <option value="Low">Low | Academian/Microfluencer</option>
                          <option value="Medium">Medium | Social influencer, Artist</option>
                          <option value="High">High | Celebrity, Politician, Media personality, etc.</option>
                        </select>
                      </div>

                      <label className="bg-black text-white py-2 px-4 mt-4 block w-max rounded-full">
                        {riskLevels[index] === "No"
                          ? "No Risk"
                          : riskLevels[index] === "Low"
                          ? "Low Risk"
                          : riskLevels[index] === "Medium"
                          ? "Medium Risk"
                          : riskLevels[index] === "High"
                          ? "High Risk"
                          : ""}
                      </label>

                      <div className="flex gap-4 flex-col bg mt-6">
                        <p className="font-bold"> Guest Profile </p>
                        <div className="bg-gray-100 rounded-lg p-4">
                          <div className="w-full ">
                            <span className="label-text">Guest Name</span>
                            <input
                              type="text"
                              placeholder="John Doe"
                              className="input input-bordered w-full mb-2"
                              value={guest.name}
                              onChange={(e) =>
                                updateArr(index, "name", e.target.value, "guest")
                              }
                            />
                          </div>
                          <div className="w-full flex flex-col">
                            <span className="label-text">Guest Introduction</span>
                            <textarea
                              className="textarea textarea-bordered"
                              placeholder="Intro text...."
                              value={guest.intro}
                              onChange={(e) =>
                                updateArr(index, "intro", e.target.value, "guest")
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      {riskLevels[index] !== "No" && (
                        <div className="flex gap-4 flex-col bg mt-6">
                          <p className="font-bold"> Speech Content </p>
                          <div className="bg-gray-100 rounded-lg p-4">
                            <div className="w-full flex flex-col">
                              <span className="label-text">Content of speech</span>
                              <textarea
                                className="textarea textarea-bordered"
                                placeholder="content...."
                                value={guest.speech}
                                onChange={(e) =>
                                  updateArr(index, "speech", e.target.value, "guest")
                                }
                              ></textarea>
                            </div>
                            <div className="divider"> OR </div>

                            <div className="form-control w-full ">
                              <label className="label">
                                <span className="label-text">Upload PDF/Word</span>
                              </label>
                              <input
                                type="file"
                                className="file-input file-input-bordered w-full "
                                onChange={(e) => handleGuestFileChange(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {riskLevels[index] !== "No" && riskLevels[index] !== "Low" && (
                        <div className="flex gap-4 flex-col bg mt-6">
                          <p className="font-bold"> Social Media Accounts </p>
                          <div className="bg-gray-100 rounded-lg p-4 flex-row gap-4 flex">
                            <div className="w-full ">
                              <span className="label-text">Twitter</span>
                              <input
                                type="url"
                                placeholder="twitter.com/"
                                className="input input-bordered w-full mb-2"
                                value={guest.twitter}
                                onChange={(e) =>
                                  updateArr(index, "twitter", e.target.value, "guest")
                                }
                              />
                            </div>
                            <div className="w-full ">
                              <span className="label-text">Instagram</span>
                              <input
                                type="url"
                                placeholder="Instagram.com/"
                                className="input input-bordered w-full mb-2"
                                value={guest.insta}
                                onChange={(e) =>
                                  updateArr(index, "insta", e.target.value, "guest")
                                }
                              />
                            </div>
                            <div className="w-full ">
                              <span className="label-text">LinkedIn</span>
                              <input
                                type="url"
                                placeholder="linkedin.com/"
                                className="input input-bordered w-full mb-2"
                                value={guest.linkedin}
                                onChange={(e) =>
                                  updateArr(index, "linkedin", e.target.value, "guest")
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {riskLevels[index] === "High" || riskLevels[index] === "Medium" ? (
                        <>
                          <div className="flex gap-4 flex-col bg mt-6">
                            <p className="font-bold"> Associated Organisation </p>
                            <div className="bg-gray-100 rounded-lg p-4 flex-row gap-4 flex">
                              <div className="w-full ">
                                <span className="label-text">Organisation Title</span>
                                <input
                                  type="text"
                                  placeholder="ABC Cops."
                                  className="input input-bordered w-full mb-2"
                                  value={guest.org}
                                  onChange={(e) =>
                                    updateArr(index, "org", e.target.value, "guest")
                                  }
                                />
                              </div>
                              <div className="w-full ">
                                <span className="label-text">Role/Designation</span>
                                <input
                                  type="text"
                                  placeholder="Vice President"
                                  className="input input-bordered w-full mb-2"
                                  value={guest.role}
                                  onChange={(e) =>
                                    updateArr(index, "role", e.target.value, "guest")
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex gap-4 flex-col bg mt-6">
                            <p className="font-bold">
                              External Consultation Parties for Approval
                            </p>
                            <div className="bg-gray-100 rounded-lg p-4 flex-row gap-4 flex">
                              <div className="w-full flex flex-col">
                                <span className="label-text">Consultation Parties</span>
                                <textarea
                                  className="textarea textarea-bordered"
                                  placeholder="Write here...."
                                  value={guest.consult}
                                  onChange={(e) =>
                                    updateArr(index, "consult", e.target.value, "guest")
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      <div className="flex gap-4 flex-col bg mt-6">
                        <p className="font-bold"> Details of Research Undertaken </p>
                        <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                          <div className="w-full flex flex-col">
                            <span className="label-text">Research Process</span>
                            <textarea
                              className="textarea textarea-bordered"
                              placeholder="Write here...."
                              value={guest.research}
                              onChange={(e) =>
                                updateArr(index, "research", e.target.value, "guest")
                              }
                            ></textarea>
                          </div>

                          <div className="w-full flex flex-col">
                            <span className="label-text">Research Outcome</span>
                            <textarea
                              className="textarea textarea-bordered"
                              placeholder="Write here...."
                              value={guest.outcome}
                              onChange={(e) =>
                                updateArr(index, "outcome", e.target.value, "guest")
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}





            <div className="mt-4 flex flex-row gap-2 justify-start">
              <button
                className="btn btn-outlined btn-sm  font-bold"
                onClick={() => addArr("guest")}
              >
                + Add More
              </button>
              <button
                className="btn btn-outlined  btn-sm  font-bold"
                onClick={() => removeArr("guest")}
              >
                - Remove{" "}
              </button>
            </div>
          </div>
        );

        break;

      case "Purchase":
        content = (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Any special purchase requests for the event? 🛒
            </h1>
            <p className="mt-4 text-gray-500">
              We want to make sure your event is a smashing success, and that
              means taking care of all the details. If you have any special
              purchase requests to enhance the event experience, such as
              decorations, equipment, rental service, printing, artist
              performance, or other items, please let us know. We're here to
              help bring your vision to life, so feel free to share your
              purchase requests in the field below.
            </p>

  


            {purchase?.map((purch, index) => (
              <div>
                <div className="form-control w-full " key={index}>
                  <label className="label">
                    <span className="label-text">Pick the category</span>
                  </label>
                  <select
                    className="select select-bordered"
                    onChange={(e) =>
                      updateArr(
                        index,
                        "selected_category",
                        e.target.value,
                        "purchase"
                      )
                    }
                  >
                    <option value="Service/Rental/Artist">
                      Service/Rental
                    </option>
                    <option value="Artist">Artist</option>
                    <option value="External Material">External Material</option>
                    <option value="Printing">Printing</option>
                  </select>
                </div>

                <div>
    {procurements.map((procurement, procurementIndex) => (
      <div key={procurementIndex} className="flex gap-4 flex-col bg mt-6">
        <p className="font-bold"> Purchase Details </p>
        <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
          <div className="w-full flex flex-col">
            <span className="label-text">Due Date</span>
            <input
              type="date"
              placeholder="Type here"
              className="input input-bordered w-full "
              value={procurement.due_date}
              onChange={(e) =>
                modifyArray(
                  procurementIndex,
                  "due_date",
                  e.target.value,
                  "procurement"
                )
              }
            />
          </div>
          <div className="w-full flex flex-col">
            <span className="label-text">
              Complete List of items/details
            </span>

            <div className="w-full flex flex-col">
              {Array.isArray(procurement.category) &&
                procurement.category.map((category, categoryIndex) => (
                  <div
                    className="w-full flex flex-col gap-3"
                    key={categoryIndex}
                  >
                    <label>Name:</label>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      value={category.categoryName}
                      onChange={(e) =>
                        alterCategory(
                          procurementIndex,
                          categoryIndex,
                          "categoryName",
                          e.target.value
                        )
                      }
                    />
                    <input
                      type="text"
                      className="input input-bordered w-full hidden"
                      value="Pending"
                      onChange={(e) =>
                        alterCategory(
                          procurementIndex,
                          categoryIndex,
                          "status",
                          e.target.value
                        )
                      }
                    />
                    <label>Quantity:</label>
                    <input
                      type="number"
                      placeholder="Enter quantity"
                      className="input input-bordered w-full"
                      value={category.quantity || ''}
                      onChange={(e) =>
                        alterCategory(
                          procurementIndex,
                          categoryIndex,
                          "quantity",
                          parseFloat(e.target.value)
                        )
                      }
                    />

                    <label>Price per Quantity:</label>
                    <input
                      type="number"
                      className="input input-bordered w-full"
                      placeholder="Enter Price"

                      value={category.pricePerQuantity || ''}
                      onChange={(e) =>
                        alterCategory(
                          procurementIndex,
                          categoryIndex,
                          "pricePerQuantity",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                    <label>Total Price Per Item:</label>
                    <input
                      type="number"
                      className="input input-bordered w-full"
                      value={category.totalPrice}
                      readOnly
                    />
                    <div className="form-control w-full ">
                      <label className="label">
                        <span className="label-text">
                          Upload Sample(s)
                        </span>
                      </label>
                      <input
                        type="file"
                        className="file-input file-input-bordered w-full "
                        onChange={(e) =>
                          uploadFileForCategory(
                            e.target.files[0],
                            procurementIndex,
                            categoryIndex,
                            "categories"
                          )
                        }
                      />
                    </div>
                    <div className="w-full flex items-center justify-end">
                      <button
                        className="bg-red-500 capitalize  btn btn-outlined btn-sm text-white font-semibold"
                        onClick={() =>
                          removeCategory(procurementIndex, categoryIndex)
                        }
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              <div>
                <button
                  className="btn btn-outlined btn-sm bg-white  font-bold"
                  onClick={() => appendCategory(procurementIndex)}
                >
                  + Add More
                </button>
              </div>
            </div>
            <div className="mt-4">
                <strong>Total Quantities: {getTotalQuantity()}</strong>
              </div>
              <div className="mt-2">
                <strong>Total Price of All items: {getTotalPrice()}</strong>
            </div>
          </div>
        </div>
      </div>
    ))}
  
  </div>
              </div>
            ))}

            <div className="mt-4 flex flex-row gap-2 justify-start">
              <button
                className="btn btn-outlined btn-sm  font-bold"
                onClick={() => addArr("purchase")}
              >
                + Add More
              </button>
              <button
                className="btn btn-outlined  btn-sm  font-bold"
                onClick={() => removeArr("purchase")}
              >
                - Remove{" "}
              </button>
            </div>
          </div>
        );
        break;

      case "Outdoor Venue":
        content = (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Please provide the preferred dates, timings, seating capacity, and
              select your preferred outdoor venue 🏞️
            </h1>
            <p className="mt-4 text-gray-500">
              Please provide the preferred dates, timing(s), and select your
              desired auditorium from the options provided.
            </p>

            {venues.map((venue, index) => (
              <div>
                <div className="form-control w-full " key={index}>
                  <div className="flex gap-4 flex-col bg mt-6">
                    <p className="font-bold">
                      {" "}
                      Outside Venue Details {index + 1}{" "}
                    </p>
                    <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                      <div className="flex gap-4 flex-row">
                        {/* <div class="w-full">
                            <span class="label-text">Activity</span>
                            <input
                              type="text"
                              placeholder="Activity Name"
                              class="input input-bordered w-full mb-2"
                              value={venue.activity}
                              onChange={(e) => {
                                updateArr(
                                  index,
                                  "activity",
                                  e.target.value,
                                  "venue"
                                );
                                updateArr(index, "name", e.target.value, "venue");
                              }}
                            />
                          </div> */}
                        <div className="w-full">
                          <span className="label-text">Capacity</span>
                          <input
                            type="number"
                            placeholder="No. of participants"
                            className="input input-bordered w-full mb-2"
                            value={venue.capacity}
                            onChange={(e) =>
                              updateArr(
                                index,
                                "capacity",
                                e.target.value,
                                "venue"
                              )
                            }
                          />
                        </div>{" "}
                        <div className="w-full">
                          <span className="label-text">Venue</span>
                          <select
                            className="select select-bordered w-full max-w-xs"
                            value={venue.venue}
                            onChange={(e) =>
                              updateArr(index, "venue", e.target.value, "venue")
                            }
                          >
                            <option value="">Select Venue</option>
                            {data
                              .filter((venue) => venue.type === "Outdoor")
                              .map((outdoorVenue) => (
                                <option
                                  key={outdoorVenue.id}
                                  value={outdoorVenue.name}
                                >
                                  {outdoorVenue.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="flex gap-4 flex-row">
                        <div className="w-full">
                          <span className="label-text">Date</span>
                          <input
                            type="date"
                            className="input input-bordered w-full mb-2"
                            value={venue.date}
                            onChange={(e) =>
                              updateArr(index, "date", e.target.value, "venue")
                            }
                          />
                        </div>
                        <div className="w-full">
                          <span className="label-text">Time</span>
                          <input
                            type="time"
                            className="input input-bordered w-full mb-2"
                            value={venue.time}
                            onChange={(e) =>
                              updateArr(index, "time", e.target.value, "venue")
                            }
                          />
                        </div>{" "}
                      </div>
                      <div className="w-full">
                        <span className="label-text">Activity Details</span>
                        <textarea
                          type="text"
                          placeholder="Detailed Description"
                          className="input input-bordered w-full mb-2 p-4"
                          value={venue.activity}
                          onChange={(e) => {
                            updateArr(
                              index,
                              "activity",
                              e.target.value,
                              "venue"
                            );
                            updateArr(index, "name", e.target.value, "venue");
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="mt-4 flex flex-row gap-2 justify-start">
              <button
                className="btn btn-outlined btn-sm  font-bold"
                onClick={() => addArr("venue")}
              >
                + Add More
              </button>
              <button
                className="btn btn-outlined  btn-sm  font-bold"
                onClick={() => removeArr("venue")}
              >
                - Remove{" "}
              </button>
            </div>
          </div>
        );
        break;

      case "Audit":
        content = (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              Please provide the preferred dates, timings, seating capacity, and
              select your preferred auditorium option for the event. 🏟️
            </h1>
            <p className="mt-4 text-gray-500">
              If you have selected 'Yes' for booking an auditorium for your
              meeting, please provide the preferred dates, timing(s), and select
              your desired auditorium from the options provided.
            </p>

            {auditoriums.map((aud, index) => (
              <div>
                <div className="form-control w-full " key={index}>
                  <div className="flex gap-4 flex-col bg mt-6">
                    <p className="font-bold">
                      {" "}
                      Auditorium Details {index + 1}{" "}
                    </p>
                    <div className="bg-gray-100 rounded-lg p-4 flex-col gap-4 flex">
                      <div className="flex gap-4 flex-row">
                        <div className="w-full">
                          <span className="label-text">Capacity</span>
                          <input
                            type="number"
                            placeholder="No. of participants"
                            className="input input-bordered w-full mb-2"
                            value={aud.capacity}
                            onChange={(e) =>
                              updateArr(
                                index,
                                "capacity",
                                e.target.value,
                                "auditorium"
                              )
                            }
                          />
                        </div>{" "}
                        <div className="w-full">
                          <span className="label-text">Venue</span>
                          <select
                            className="select select-bordered w-full max-w-xs"
                            value={aud.venue}
                            onChange={(e) =>
                              updateArr(
                                index,
                                "venue",
                                e.target.value,
                                "auditorium"
                              )
                            }
                          >
                            <option value="">Select Venue</option>
                            {data
                              .filter((venue) => venue.type === "Indoor")
                              .map((indoorVenue) => (
                                <option
                                  key={indoorVenue.id}
                                  value={indoorVenue.name}
                                >
                                  {indoorVenue.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="flex gap-4 flex-row">
                        <div className="w-full">
                          <span className="label-text">Date</span>
                          <input
                            type="date"
                            className="input input-bordered w-full mb-2"
                            value={aud.date}
                            onChange={(e) =>
                              updateArr(
                                index,
                                "date",
                                e.target.value,
                                "auditorium"
                              )
                            }
                          />
                        </div>
                        <div className="w-full">
                          <span className="label-text">Time</span>
                          <input
                            type="time"
                            className="input input-bordered w-full mb-2"
                            value={aud.time}
                            onChange={(e) =>
                              updateArr(
                                index,
                                "time",
                                e.target.value,
                                "auditorium"
                              )
                            }
                          />
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="mt-4 flex flex-row gap-2 justify-start">
              <button
                className="btn btn-outlined btn-sm  font-bold"
                onClick={() => addArr("auditorium")}
              >
                + Add More
              </button>
              <button
                className="btn btn-outlined  btn-sm  font-bold"
                onClick={() => removeArr("auditorium")}
              >
                - Remove{" "}
              </button>
            </div>
          </div>
        );
        break;

      case "Security Clearance":
         content = (
          <div className="p-8 bg-white rounded-lg shadow-lg relative right-10" style={{width: '120%'}}>
            <h1 className="text-2xl font-bold sm:text-3xl text-center mb-6">
              Security clearance service for your event 🚨
            </h1>
            <p className="mt-4 text-gray-600 text-center mb-8">
              Need security clearance for external arrivals at your event? We've got you covered!
            </p>
        
            {securityClearanceServices.map((service, index) => (
              <div key={index} className="flex gap-4 mb-6 items-end flex-wrap lg:flex-nowrap border-b pb-4">
                <div className="w-full lg:w-1/4">
                  <label className="block text-gray-700 font-semibold mb-2">Guest Name</label>
                  <input
                    type="text"
                    placeholder="John Doe"
                    className="input input-bordered w-full rounded-md shadow-sm"
                    value={service.name}
                    onChange={(e) =>
                      handleServiceChange(index, "name", e.target.value)
                    }
                  />
                </div>
                <div className="w-full lg:w-1/4">
                  <label className="block text-gray-700 font-semibold mb-2">Guest CNIC</label>
                  <input
                    type="number"
                    placeholder="CNIC Number"
                    className="input input-bordered w-full rounded-md shadow-sm"
                    value={service.cnic}
                    onChange={(e) =>
                      handleServiceChange(index, "cnic", e.target.value)
                    }
                  />
                </div>
                <div className="w-full lg:w-1/4">
                  <label className="block text-gray-700 font-semibold mb-2">Time of Entry</label>
                  <input
                    type="time"
                    className="input input-bordered w-full rounded-md shadow-sm"
                    value={service.time}
                    onChange={(e) =>
                      handleServiceChange(index, "time", e.target.value)
                    }
                  />
                </div>
                <div className="w-full lg:w-1/4">
                  <label className="block text-gray-700 font-semibold mb-2">Vehicle Type & Number</label>
                  <input
                    type="text"
                    placeholder="Corolla, LEA-4181"
                    className="input input-bordered w-full rounded-md shadow-sm"
                    value={service.vehicle}
                    onChange={(e) =>
                      handleServiceChange(index, "vehicle", e.target.value)
                    }
                  />
                </div>
                {index > 0 && (
                  <button
                    className="mt-4 lg:mt-0 lg:ml-4 p-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-300 shadow-md"
                    onClick={() => removeService(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            <button
              className="mt-6 p-3 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-300 shadow-md"
              onClick={addMoreService}
            >
              Add More
            </button>
          </div>
        );
        
        

        break;

      case "PDC/EDH":
        content = (
          <div>
            <h1 className="text-2xl font-bold sm:text-3xl">
              PDC or EDH service for your event 🍽️
            </h1>
            <p className="mt-4 text-gray-500">
              We want to ensure that all your event needs are met, so please let
              us know if you require any additional services such as Pepsi
              Dining Center (PDC) or Executive Dining Hall (EDH) services. We're
              here to assist you.{" "}
            </p>


            <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Link target="_blank" rel="noopener noreferrer" to={pdfFileName} className="btn btn-warning" >
                View PDC Request Form
            </Link>
        </div>





            <div className="form-control w-full mt-6">
              <label className="label">
                <span className="label-text">Pick a file</span>
              </label>
              <input
                type="file"
                className="file-input file-input-bordered w-full"
                onChange={(e) => handlePdcFileChange(e)}
              />
            </div>
          </div>
        );
        break;

      case "Other Services":
        content = (
          <div>
            <div className="join join-vertical w-full">
              <div className="collapse collapse-arrow join-item border border-base-300">
                <input type="radio" name="my-accordion-4" id="plumber" />
                <div className="collapse-title text-xl font-medium">
                  Plumb service for your event 🪠
                </div>
                <div className="collapse-content">
                  <p>
                    Need some plumbing work done for your event? We've got you
                    covered! Our sanitation department can provide plumbing
                    services for your setup.
                  </p>
                  <textarea
                    className="textarea textarea-bordered mt-6 w-full"
                    placeholder="Write here .... "
                    value={plumber_service}
                    onChange={(e) => setPlumberService(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="collapse collapse-arrow join-item border border-base-300">
                <input type="radio" name="my-accordion-4" id="plumber" />
                <div className="collapse-title text-xl font-medium">
                  Security service for your event 👮
                </div>
                <div className="collapse-content">
                  <p>Need Security for your event? We've got you covered!.</p>
                  <textarea
                    className="textarea textarea-bordered mt-6 w-full"
                    placeholder="Write here .... "
                    value={security_service}
                    onChange={(e) => setSecurityService(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="collapse collapse-arrow join-item border border-base-300">
                <input type="radio" name="my-accordion-4" id="janitor" />
                <div className="collapse-title text-xl font-medium">
                  Janitorial or Ground Men service for your event 🧹
                </div>
                <div className="collapse-content">
                  <p>
                    We want to make sure your event is clean and
                    well-maintained, so we're checking if you require Janitorial
                    or Ground Men service. This service can help with cleaning,
                    maintenance, and upkeep of the event venue, ensuring a
                    pleasant experience for all attendees.
                  </p>
                  <textarea
                    className="textarea textarea-bordered mt-6 w-full"
                    placeholder="Write here .... "
                    value={ground_men}
                    onChange={(e) => setGroundMen(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="collapse collapse-arrow join-item border border-base-300">
                <input type="radio" name="my-accordion-4" id="welder" />
                <div className="collapse-title text-xl font-medium">
                  Welder service for your event 🥽
                </div>
                <div className="collapse-content">
                  <p>
                    Need some welding work done for your event? We've got you
                    covered! Our GAS department can provide welding services for
                    stage setup, decorations, or any other requirements you may
                    have.
                  </p>
                  <textarea
                    className="textarea textarea-bordered mt-6 w-full"
                    placeholder="Write here .... "
                    value={welder_service}
                    onChange={(e) => setWelderService(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="collapse collapse-arrow join-item border border-base-300">
                <input type="radio" name="my-accordion-4" id="labour" />
                <div className="collapse-title text-xl font-medium">
                  Labour service for your event 👷‍♂️
                </div>
                <div className="collapse-content">
                  <p>
                    Need some labour work done for your event? We've got you
                    covered! Our labour department can provide services for your
                    setup.
                  </p>
                  <textarea
                    className="textarea textarea-bordered mt-6 w-full"
                    placeholder="Write here .... "
                    value={labour_service}
                    onChange={(e) => setLabourService(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="collapse collapse-arrow join-item border border-base-300">
                <input type="radio" name="my-accordion-4" id="carpenter" />
                <div className="collapse-title text-xl font-medium">
                  Carpenter service for your event 🪚
                </div>
                <div className="collapse-content">
                  <p>
                    Need some Carpenter work done for your event? We've got you
                    covered!
                  </p>
                  <textarea
                    className="textarea textarea-bordered mt-6 w-full"
                    placeholder="Write here .... "
                    value={carpenter_service}
                    onChange={(e) => setCarpenterService(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        );
        break;

      default:
        content = (
          <div>
            {/* Default content in case no specific condition is matched */}
            <h2>{currentCondition.label}</h2>
            {/* Render additional content or actions related to this condition */}
          </div>
        );
        break;
    }

    return content;
  };


  const [selectedPayment, setSelectedPayment] = useState('');

  const handleCheckboxChanges = (event) => {
    setSelectedPayment(event.target.value);
};



  const handleSubmit = (e, submit) => {
    e.preventDefault();
    const storedData = localStorage.getItem("eventFormData");
    const parsedData = JSON.parse(storedData);

    setisLoadingIcon(true);

    const formData = new FormData();
    formData.append("society_id", societyProfile?.id);
    formData.append("society_name", parsedData?.society_name || "");
    formData.append("requestType", parsedData?.requestType || "");
    formData.append("isChecked_EventHead", parsedData?.isChecked_EventHead || "");
    formData.append("applicant_name", parsedData?.applicant_name || "");
    formData.append("applicant_email", parsedData?.applicant_email || "");
    formData.append("applicant_contact", parsedData?.applicant_contact || "");
    formData.append("applicant_designation", parsedData?.applicant_designation || "");
    formData.append("event_name", parsedData?.event_name || "");
    formData.append("meeting_name", parsedData?.meeting_name || "");
    formData.append("event_description", parsedData?.event_description || "");
    formData.append("event_head_name", parsedData?.event_head_name || "");
    formData.append("event_head_contact", parsedData?.event_head_contact || "");
    formData.append("event_head_email", parsedData?.event_head_email || "");
    formData.append("start_date", parsedData?.start_date || "");
    formData.append("end_date", parsedData?.end_date || "");
    formData.append("ground_men", parsedData?.ground_men || "");
    formData.append("welder_service", parsedData?.welder_service || "");
    formData.append("plumber_service", parsedData?.plumber_service || "");
    formData.append("labour_service", parsedData?.labour_service || "");
    formData.append("carpenter_service", parsedData?.carpenter_service || "");
    formData.append("security_service", parsedData?.security_service || "");
    formData.append("pdc_file", pdc_file || "");
    formData.append("isChecked_Risk", parsedData?.isChecked_Risk || "");
    formData.append("all_guests", JSON.stringify(parsedData?.all_guests));
    formData.append("venues", JSON.stringify(parsedData?.venues));
    formData.append("auditoriums", JSON.stringify(parsedData?.auditoriums));
    formData.append("all_purchases", JSON.stringify(parsedData?.all_purchases));
    formData.append("security_clearance_service", JSON.stringify(parsedData?.security_clearance_service));
    formData.append("payment_name", selectedPayment); // Add payment name
    formData.append("step", submit);

    // Add one-day event and total number of days to formData
    formData.append("today_event", eventFormData.today_event);
    formData.append("total_days", totalDays);

    axiosInstance.post("event/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      // Handle the success response here.
      let societyName = parsedData?.society_name;
      let eventLabel = parsedData?.event_name;
      let postedFor = societyProfile?.ccaadmins;

      axiosInstance.post("post_notification_event_req/", {
        societyName,
        eventLabel,
        postedFor,
      });

      toast.success("Event Data Saved", {
        icon: "👏",
        style: { borderRadius: "55px", marginBottom: "15px" },
      });

      if (submit === true) {
        localStorage.removeItem("eventFormData");
        setTimeout(() => {
          navigate("/events_all"); // This will take the user to the previous page, similar to history.goBack()
        }, 3000);
        alert("Thank you for your submission!");
      }
    }).catch((err) => {
      // Handle the error response here.
      toast.error("Oh No! Something went wrong", {
        icon: "😓",
        style: { borderRadius: "55px", marginBottom: "15px" },
      });
    });
  };

  






  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const create_event = (e) => {
    axiosInstance
      .post("create_or_get_event/", { society_name, status: false })
      .then((res) => {
        toast.success("Event creation started");
      })
      .catch((err) => {
        toast.error("Oh No! Something went wrong", {
          icon: "��",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div className="absolute w-full bg-primary  min-h-75"></div>

            <SocietySidebar />

            {isSidebarVisible && <SocietySideInnerSM />}

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
              {/* Navbar */}
              <SocNav toggleSidebar={toggleSidebar} />

              {/* end Navbar */}
              {/* cards */}
              <div className="w-full px-6 py-6 mx-auto">
                <div className="stepper-form rounded-lg">
                  <div className="bg-indigo-600 px-4 py-3 text-white sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 opacity-0">
                    <p className="text-center font-medium sm:text-left">
                      📝 Request Form
                    </p>

                    <button
                      className="btn btn-square bg-indigo-700 border-0"
                      // onClick={() => navigate(-1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div
                    className={`max-w-2xl mx-auto mt-4 step ${
                      step === 1 ? "active" : ""
                    }`}
                  >
                    {/* Step 1 content */}

                    <div className="max-w-2xl mx-auto space-y-12 mt-4">
                      <article className="space-y-8 text-gray-900">
                        <div className="space-y-6">
                          <p className="flex-shrink-0 mt-3 text-sm md:mt-0 border-2 rounded-full w-max py-2 px-4">
                            Hey 👋
                          </p>
                          <h1 className="text-3xl font-bold md:tracki md:text-3xl">
                            Welcome to the Society Engagement Approval Request
                            Form!
                          </h1>
                          <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center text-gray-600">
                            <div className="flex items-center md:space-x-2">
                              <img
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1677941506/AHbranding/Group_1_lzsxib.png"
                                alt=""
                                className="w-6 h-6 border rounded-full bg-gray-500 border-gray-300"
                              />
                              <p className="text-sm">CCA • LUMS</p>
                            </div>
                            <p className="hidden flex-shrink-0 mt-3 text-sm md:mt-0">
                              4 min read • 1,570 views
                            </p>
                          </div>
                        </div>
                        <div className="text-gray-800">
                          <p>
                            We sincerely appreciate your interest in seeking
                            event approval. Your effort in providing us with
                            essential event details is crucial in evaluating the
                            suitability of the event and ensuring its alignment
                            with the guidelines established by the Co-Curricular
                            Activities Office (CCA).
                          </p>
                        </div>
                      </article>
                      <div>
                        <div className=" flex flex-wrap py-6 space-x-2 border-t border-dashed border-gray-600"></div>
                        <div className="space-y-2">
                          <h4 className="text-lg font-semibold">Guidelines:</h4>
                          <ul className="ml-4 space-y-1 list-disc">
                            <li>
                              <p className="hover:underline">
                                Kindly complete the form below with accurate and
                                comprehensive information.
                              </p>
                            </li>
                            <li>
                              <p className="hover:underline">
                                Fields marked with an asterisk (*) are
                                mandatory.
                              </p>
                            </li>
                            <li>
                              <p className="hover:underline">
                                Feel free to save your progress and return later
                                to finalize the form.
                              </p>
                            </li>
                          </ul>
                        </div>

                        <div className="space-y-2 mt-6">
                          <h4 className="text-lg font-semibold">
                            Approval Procedure:
                          </h4>
                          <ul className="ml-4 space-y-1 list-disc">
                            <li>
                              <p className="hover:underline">
                                Upon submission, your request will undergo
                                review by your assigned CCA Official.
                              </p>
                            </li>
                            <li>
                              <p className="hover:underline">
                                You will be notified of the request's status
                                following the review.
                              </p>
                            </li>
                            <li>
                              <p className="hover:underline">
                                An email confirmation will be sent, along with
                                updates regarding the request's progress.
                              </p>
                            </li>
                          </ul>
                        </div>

                        <div className="space-y-2 mt-6">
                          <h4 className="text-lg font-semibold">
                            Approval Timelines:{" "}
                          </h4>
                          <ul className="ml-4 space-y-1 list-disc">
                            <li>
                              <p className="hover:underline">
                                1-Day Event: Within 3 days
                              </p>
                            </li>
                            <li>
                              <p className="hover:underline">
                                2-3 Days Event: Within 7 days
                              </p>
                            </li>
                            <li>
                              <p className="hover:underline">
                                Event with Accommodation: Within 15 days
                              </p>
                            </li>
                            <li>
                              <p className="hover:underline">
                                Meeting Requests: Within 2 days
                              </p>
                            </li>
                            <li>
                              <p className="hover:underline">
                                External Events with Petitions: Within 7 days
                              </p>
                            </li>
                            <li>
                              <p className="hover:underline">
                                Trips: Within 15 days
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* ... your step 1 form fields */}
                  </div>
                  {/* Step 2 content */}
                  <div
                    className={`max-w-2xl mx-auto mt-4 step ${
                      step === 2 ? "active" : ""
                    }`}
                  >
                    {/* Step 2 content */}
                    <h1 className="text-2xl font-bold sm:text-3xl">
                      What is the name of your Awesome Society? 👀{" "}
                    </h1>

                    <p className="mt-4 text-gray-500">
                      Greetings! We're curious to know which society you
                      represent. Kindly select your society's name from the
                      drop-down list below. If your society's name is not
                      listed, please select "Other" and provide the name in the
                      text field that appears.
                    </p>

                    <div className="w-full mt-8">
                      <span className="label-text">Society Name</span>
                      <input
                        type="text"
                        placeholder="eg: LUMS Entrepreneur Society "
                        className="input input-bordered w-full mb-2"
                        value={eventFormData.society_name}
                        onChange={(e) => {
                          handleFieldChange("society_name", society_name);
                          create_event(e.target.value);
                        }}
                        disabled
                      />
                    </div>

                    {/* ... your step 2 form fields */}
                  </div>
                  {/* Step 3 content */}
                  <div
                    className={`max-w-2xl mx-auto mt-4 step ${
                      step === 3 ? "active" : ""
                    }`}
                  >
                    {/* Step 3 content */}

                    <h1 className="text-2xl font-bold sm:text-3xl">
                      May I kindly ask for your information? ✍️
                    </h1>

                    <p className="mt-4 text-gray-500">
                      Greetings! To better assist you, could you provide us with
                      your full name, e-mail address and mobile number?
                    </p>

                    <div className="flex gap-4 flex-row">
                      <div className="w-full mt-8">
                        <span className="label-text">Applicant Name</span>
                        <input
                          type="text"
                          placeholder="John Doe"
                          className="input input-bordered w-full mb-2"
                          value={eventFormData.applicant_name}
                          required
                          onChange={(e) => handleFieldChange("applicant_name", e.target.value)}
                        />
                        {errors.applicant_name && (
                          <p className="text-red-500 text-sm mt-1">{errors.applicant_name}</p>
                        )}
                      </div>
                      <div className="w-full mt-8">
                        <span className="label-text">Applicant Designation</span>
                        <input
                          type="text"
                          placeholder="eg: Vice President"
                          className="input input-bordered w-full mb-2"
                          value={eventFormData.applicant_designation}
                          required
                          onChange={(e) => handleFieldChange("applicant_designation", e.target.value)}
                        />
                        {errors.applicant_designation && (
                          <p className="text-red-500 text-sm mt-1">{errors.applicant_designation}</p>
                        )}
                      </div>
                    </div>

                    <div className="flex gap-4 flex-row">
                      <div className="w-full mt-8">
                        <span className="label-text">Mobile no.</span>
                        <input
                          type="text"
                          placeholder="0300-123456789"
                          className="input input-bordered w-full mb-2"
                          value={eventFormData.applicant_contact}
                          required
                          onChange={(e) => handleFieldChange("applicant_contact", e.target.value)}
                        />
                        {errors.applicant_contact && (
                          <p className="text-red-500 text-sm mt-1">{errors.applicant_contact}</p>
                        )}
                      </div>
                      <div className="w-full mt-8">
                        <span className="label-text">Applicant Email</span>
                        <input
                          type="email"
                          placeholder="eg: email@lums.edu.pk"
                          className="input input-bordered w-full mb-2"
                          value={eventFormData.applicant_email}
                          required
                          onChange={(e) => handleFieldChange("applicant_email", e.target.value)}
                        />
                        {errors.applicant_email && (
                          <p className="text-red-500 text-sm mt-1">{errors.applicant_email}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Step 4 content */}
                  <div
                    className={`max-w-2xl mx-auto mt-4 step ${
                      step === 4 ? "active" : ""
                    }`}
                  >
                    {/* Step 4 content */}

                    <h1 className="text-2xl font-bold sm:text-3xl">
                      Let's dive in! What category does your event fall under?
                      Please select from the options below. 🧑‍🚀
                    </h1>

                    <p className="mt-4 text-gray-500">
                      We're excited to learn about your event's theme. Is it a
                      dynamic concert, an exhilarating competition, a
                      captivating cultural showcase, or perhaps an internal
                      meeting, a participation in a competition, or an exciting
                      trip? Make your choice from the drop-down list to best
                      capture your event's essence.
                    </p>

                    <div className="mt-8">
                      <span className="label-text">Select Type:</span>

                      <select
                        id="status"
                        name="project"
                        className="input input-bordered w-full select"
                        value={eventFormData?.requestType}
                        onChange={(e) =>
                          handleFieldChange("requestType", e.target.value)
                        }
                      >
                        <option value="null">Request Type</option>
                        <option value="Event">Event</option>
                        <option value="External Competition" disabled>
                          External Competition
                        </option>
                        <option value="Meeting">Meeting</option>
                        <option value="Trip" disabled>
                          Trip
                        </option>
                      </select>
                    </div>
                  </div>
                  {/* Step 5 content */}
                  <div
                    className={`max-w-2xl mx-auto mt-4 step ${
                      step === 5 ? "active" : ""
                    }`}
                  >
                    {eventFormData?.requestType === "Event" && (
                      <div>
                        <h1 className="text-2xl font-bold sm:text-3xl">
                          What is the name of your event? 📛
                        </h1>
                        <p className="mt-4 text-gray-500">
                          Your event deserves a name that truly captures its
                          essence. Kindly provide the title of your event in the
                          field below. This name will serve as an introduction
                          to the excitement that awaits attendees.
                        </p>

                        <input
                          type="text"
                          placeholder="Event Name Here..."
                          className="input input-bordered w-full mt-6"
                          value={eventFormData?.event_name}
                          onChange={(e) =>
                            handleFieldChange("event_name", e.target.value)
                          }
                        />
                      </div>
                    )}

                    {eventFormData?.requestType === "Meeting" && (
                      <div>
                        <h1 className="text-2xl font-bold sm:text-3xl">
                          What is the name of your meeting? 📛
                        </h1>
                        <p className="mt-4 text-gray-500">
                          Kindly provide the title of your meeting in the field
                          below.
                        </p>

                        <input
                          type="text"
                          placeholder="Meeting Title Here..."
                          className="input input-bordered w-full mt-6"
                          value={eventFormData?.meeting_name}
                          onChange={(e) =>
                            handleFieldChange("meeting_name", e.target.value)
                          }
                        />
                      </div>
                    )}
                  </div>
                  {/* Step 6 content */}
                  <div
                    className={`max-w-2xl mx-auto mt-4 step ${
                      step === 6 ? "active" : ""
                    }`}
                  >
                    {eventFormData?.requestType === "Event" && (
                      <div>
                        <h1 className="text-2xl font-bold sm:text-3xl">
                          Who will be leading this event? Please share their
                          details with us. 🔰
                        </h1>
                        <p className="mt-4 text-gray-500">
                          We'd like to get to know the individual who will be at
                          the helm of this event. Kindly provide their name,
                          contact number, and email address in the fields
                          provided above. This information will help us stay
                          connected and ensure a smooth communication process
                          throughout the event planning and execution phases.
                        </p>

                        <div className="form-control mt-6 bg-gray-100 rounded-xl px-4">
  <label className="cursor-pointer label">
    <input
      type="checkbox"
      checked={eventFormData.isChecked_EventHead}
      onChange={() => handleCheckboxChange("isChecked_EventHead")}
      className="checkbox checkbox-accent"
    />
    <span className="label-text">Same as Applicant</span>
  </label>
</div>



                        {!eventFormData?.isChecked_EventHead && (
                          <div className="flex gap-4 flex-row">
                            <div className="w-full mt-8">
                              <span className="label-text">Event Head Name</span>
                              <input
                                type="text"
                                placeholder="John Doe"
                                className="input input-bordered w-full mb-2"
                                value={eventFormData?.event_head_name}
                                onChange={(e) =>
                                  handleFieldChange("event_head_name", e.target.value)
                                }
                              />
                              {errors.event_head_name && (
                                <p className="text-red-500 text-sm">{errors.event_head_name}</p>
                              )}
                            </div>

                            <div className="w-full mt-8">
                              <span className="label-text">Designation</span>
                              <input
                                type="email"
                                placeholder="Vice President"
                                className="input input-bordered w-full mb-2"
                                value={eventFormData?.event_head_designation}
                                onChange={(e) =>
                                  handleFieldChange("event_head_designation", e.target.value)
                                }
                              />
                              {errors.event_head_designation && (
                                <p className="text-red-500 text-sm">{errors.event_head_designation}</p>
                              )}
                            </div>






                            <div className="w-full mt-8 ">
                              <span className="label-text">Event Head Contact</span>
                              <input
                                type="number"
                                placeholder="03xx-xxxxxxx"
                                className="input input-bordered w-full mb-2"
                                value={eventFormData?.event_head_contact}
                                onChange={(e) =>
                                  handleFieldChange("event_head_contact", e.target.value)
                                }
                              />
                              {errors.event_head_contact && (
                                <p className="text-red-500 text-sm">{errors.event_head_contact}</p>
                              )}
                            </div>
                            <div className="w-full mt-8">
                              <span className="label-text">Event Head Email</span>
                              <input
                                type="email"
                                placeholder="email@lums.edu.pk"
                                className="input input-bordered w-full mb-2"
                                value={eventFormData?.event_head_email}
                                onChange={(e) =>
                                  handleFieldChange("event_head_email", e.target.value)
                                }
                              />
                              {errors.event_head_email && (
                                <p className="text-red-500 text-sm">{errors.event_head_email}</p>
                              )}
                            </div>



                          </div>

                        )}
                      </div>
                    )}

{eventFormData?.requestType === "Meeting" && (
  <div>
    <h1 className="text-2xl font-bold sm:text-3xl">
      Who will be leading this meeting? Please share their details with us. 🔰
    </h1>
    <p className="mt-4 text-gray-500">
      We'd like to get to know the individual who will be managing this meeting. Kindly provide their name, contact number, and email address in the fields provided above. This information will help us stay connected and ensure a smooth communication process throughout the planning and execution phases.
    </p>

    <div className="form-control mt-6 bg-gray-100 rounded-xl px-4">
      <label className="cursor-pointer label">
        <input
          type="checkbox"
          checked={isChecked_EventHead}
          onChange={handleCheckEventHeadboxChange}
          className="checkbox checkbox-accent"
        />
        <span className="label-text">Same as Applicant</span>
      </label>
    </div>

    {!isChecked_EventHead && (
      <div className="flex gap-4 flex-row">
        <div className="w-full mt-8">
          <span className="label-text">POC Name</span>
          <input
            type="text"
            placeholder="John Doe"
            className="input input-bordered w-full mb-2"
            value={eventFormData?.event_head_name}
            onChange={(e) => handleFieldChange("event_head_name", e.target.value)}
          />
        </div>
        <div className="w-full mt-8">
          <span className="label-text">POC Contact</span>
          <input
            type="number"
            placeholder="03xx-xxxxxxx"
            className="input input-bordered w-full mb-2"
            value={eventFormData?.event_head_contact}
            onChange={(e) => handleFieldChange("event_head_contact", e.target.value)}
          />
        </div>
        <div className="w-full mt-8">
          <span className="label-text">POC Email</span>
          <input
            type="email"
            placeholder="email@lums.edu.pk"
            className="input input-bordered w-full mb-2"
            value={eventFormData?.event_head_email}
            onChange={(e) => handleFieldChange("event_head_email", e.target.value)}
          />
        </div>
      </div>
    )}
    </div>
  )}


                  </div>


                  {/* Step 7 content */}
                  <div
                    className={`max-w-2xl mx-auto mt-4 step ${
                      step === 7 ? "active" : ""
                    }`}
                  >
                    {eventFormData?.requestType === "Event" && (
                      <div>
                        <h1 className="text-2xl font-bold sm:text-3xl">
                          When will your event take place? 📅
                        </h1>
                        <p className="mt-4 text-gray-500">
                          Timing is everything! Please let us know the dates
                          when your event is scheduled to take place. If your
                          event spans multiple days, kindly provide the start
                          and end dates. This information is essential for us to
                          ensure proper planning and coordination.
                        </p>

                        <div className="flex gap-4 flex-col">
                        <div className="w-full mt-2 flex items-center">
                            <span className="text-lg font-bold">Is it a one day event?</span>
                            <input
                              type="checkbox"
                              className="ml-2 rounded-full border-2 border-blue-500 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                              checked={eventFormData.today_event}
                              onChange={(e) =>
                                handleEventCheckboxChange("today_event", e.target.checked)
                              }
                            />
                          </div>

                            
                              
                              <div className="flex gap-4 flex-row">
                                <div className="w-full">
                                  <span className="label-text text-lg font-bold">Start Date</span>
                                  <input
                                    type="datetime-local"
                                    className="input input-bordered w-full mb-2"
                                    value={eventFormData.start_date}
                                    onChange={(e) => handleStartDateUpdate(e.target.value)}
                                  />
                                </div>
                                {!eventFormData.today_event ? (
                                      <div className="w-full flex flex-col">
                                        <div className="w-full">
                                          <span className="label-text text-lg font-bold">End Date</span>
                                          <input
                                            type="datetime-local"
                                            className="input input-bordered w-full mb-2"
                                            value={eventFormData.end_date}
                                            onChange={(e) => handleEndDateUpdate(e.target.value)}
                                          />
                                        </div>

                                        <div className="w-full mt-4">
                                          <span className="label-text text-lg font-bold">Total number of days: {totalDays}</span>
                                        </div>
                                      </div>
                                    ) : null}

                              </div>
                            </div>
                          </div>

                    )}

                    {eventFormData?.requestType === "Meeting" && (
                      <div>
                        <h1 className="text-2xl font-bold sm:text-3xl">
                          When will your meeting take place? 📅
                        </h1>
                        <p className="mt-4 text-gray-500">
                          Please let us know the dates when your meeting is
                          scheduled to take place. If your meeting spans
                          multiple days, kindly provide the start and end dates.
                          This information is essential for us to ensure proper
                          planning and coordination.
                        </p>

                        <div className="flex gap-4 flex-row">
                          <div className="w-full mt-8">
                            <span className="label-text">Start Date</span>
                            <input
                              type="datetime-local"
                              className="input input-bordered w-full mb-2"
                              value={eventFormData?.start_date}
                              onChange={(e) =>
                                handleFieldChange("start_date", e.target.value)
                              }
                            />
                          </div>
                          <div className="w-full mt-8">
                            <span className="label-text">End Date</span>
                            <input
                              type="datetime-local"
                              className="input input-bordered w-full mb-2"
                              value={eventFormData?.end_date}
                              onChange={(e) =>
                                handleFieldChange("end_date", e.target.value)
                              }
                            />
                          </div>{" "}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Step 8 content */}
                  <div
                    className={`max-w-2xl mx-auto mt-4 step ${
                      step === 8 ? "active" : ""
                    }`}
                  >
                    {eventFormData?.requestType === "Event" && (
                      <div>
                        <h1 className="text-2xl font-bold sm:text-3xl">
                          Could you please share the purpose and objectives of
                          your event? 🎯
                        </h1>
                        <p className="mt-4 text-gray-500">
                          We're eager to understand the essence of your event.
                          Kindly elaborate on the purpose and objectives you
                          have in mind. What do you hope to achieve with this
                          event? Your insights will guide us in ensuring that
                          your goals are met successfully.
                        </p>

                        <textarea
                          className="textarea textarea-bordered w-full mt-8 border-gray-400 h-32"
                          placeholder="Event Purpose"
                          value={eventFormData?.event_description}
                          onChange={(e) =>
                            handleFieldChange(
                              "event_description",
                              e.target.value
                            )
                          }
                        ></textarea>
                      </div>
                    )}

                    {eventFormData?.requestType === "Meeting" && (
                      <div>
                        <h1 className="text-2xl font-bold sm:text-3xl">
                          Could you please share the purpose and objectives of
                          your Meeting? 🎯
                        </h1>
                        <p className="mt-4 text-gray-500">
                          Kindly elaborate on the purpose and objectives you
                          have in mind.
                        </p>

                        <textarea
                          className="textarea textarea-bordered w-full mt-8 border-gray-400 h-32"
                          placeholder="Meeting Purpose"
                          value={eventFormData?.event_description}
                          onChange={(e) =>
                            handleFieldChange(
                              "event_description",
                              e.target.value
                            )
                          }
                        ></textarea>
                      </div>
                    )}
                  </div>
                  {/* Step 9 content */}
                  <div
                    className={`max-w-2xl mx-auto mt-4 step ${
                      step === 9 ? "active" : ""
                    }`}
                  >
                    {eventFormData?.requestType === "Event" && (
                      <div>
                        <h1 className="text-2xl font-bold sm:text-3xl">
                          Please select all the items you might need from the
                          options below. 📦
                        </h1>
                        <p className="mt-4 text-gray-500">
                          To assist you effectively, please indicate the items
                          you anticipate requiring for your event. You can
                          select multiple items that align with your event's
                          requirements from the provided list.
                        </p>

                        <div className="form-control mt-6 bg-gray-100 rounded-xl px-4">
                          <div className="">
                            <label className="cursor-pointer label">
                              <span className="label-text text-lg text-bold">
                                Select All
                              </span>
                              <input
                                type="checkbox"
                                className="checkbox checkbox-accent"
                                checked={selectAll}
                                onChange={handleCheckAll}
                              />
                            </label>
                          </div>
                          {eventFormData?.services?.map((item, index) => (
                            <div className="" key={index}>
                              <label className="cursor-pointer label">
                                <span className="label-text text-lg text-bold">
                                  {item.label}
                                </span>
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-accent"
                                  checked={item.isChecked}
                                  onChange={() => handleCheckBoxChange(index)}
                                />
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {eventFormData?.requestType === "Meeting" && (
                      <div>
                        <h1 class="text-2xl font-bold sm:text-3xl">
                          Please select all the items you might need from the
                          options below. 📦
                        </h1>
                        <p class="mt-4 text-gray-500">
                          To assist you effectively, please indicate the items
                          you anticipate requiring for your meeting. You can
                          select multiple items that align with your meeting's
                          requirements from the provided list.
                        </p>

                        <div className="form-control mt-6 bg-gray-100 rounded-xl px-4">
                          <label className="cursor-pointer label">
                            <span className="label-text">
                              Purchase Requisition
                            </span>
                            <input
                              type="checkbox"
                              checked={isChecked_Purchase}
                              onChange={handleCheckPurchaseboxChange}
                              className="checkbox checkbox-accent"
                            />
                          </label>
                        </div>

                        <div className="form-control mt-6 bg-gray-100 rounded-xl px-4">
                          <label className="cursor-pointer label">
                            <span className="label-text">Outdoor Venue</span>
                            <input
                              type="checkbox"
                              checked={isChecked_OutdoorVenue}
                              onChange={handleCheckOutdoorVenueboxChange}
                              className="checkbox checkbox-accent"
                            />
                          </label>
                        </div>

                        <div className="form-control mt-6 bg-gray-100 rounded-xl px-4">
                          <label className="cursor-pointer label">
                            <span className="label-text">
                              Auditorium Bookings
                            </span>
                            <input
                              type="checkbox"
                              checked={isChecked_Audit}
                              onChange={handleCheckAuditboxChange}
                              className="checkbox checkbox-accent"
                            />
                          </label>
                        </div>

                        <div className="form-control mt-6 bg-gray-100 rounded-xl px-4">
                          <label className="cursor-pointer label">
                            <span className="label-text">PDC/EDH Services</span>
                            <input
                              type="checkbox"
                              checked={isChecked_PDC}
                              onChange={handleCheckPDCboxChange}
                              className="checkbox checkbox-accent"
                            />
                          </label>
                        </div>

                        <div className="form-control mt-6 bg-gray-100 rounded-xl px-4">
                          <label className="cursor-pointer label">
                            <span className="label-text">
                              Campus Security Clearance
                            </span>
                            <input
                              type="checkbox"
                              checked={isChecked_SecClearance}
                              onChange={handleCheckSecClearanceboxChange}
                              className="checkbox checkbox-accent"
                            />
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Step 10 content */}
                  <div className={`step ${step === 10 ? "active" : ""}`}>
                    <div>
                      {trueConditions?.length === 0 ? (
                        <p className="text-center mt-12 ">
                          <b>
                            Are you sure you don't want any additional service?
                          </b>{" "}
                          <br /> You can still go back and select required items
                        </p>
                      ) : (
                        <div>
                          <div className={`max-w-2xl mx-auto mt-4 step active`}>
                            {renderStepContent()}
                          </div>

                          <div className="max-w-2xl mx-auto mt-4 stepActions flex flex-row items-center gap-2 justify-end">
                            <div>
                              {stepsEvent > 1 && (
                                <button
                                  className="btn btn-circle bg-primary text-white"
                                  onClick={handleBackStep}
                                >
                                  ‹
                                </button>
                              )}
                            </div>
                            <div>
                              {stepsEvent < trueConditions.length && (
                                <button
                                  className="btn btn-circle bg-primary text-white"
                                  onClick={handleNextStep}
                                >
                                  ›
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Step 11 content */}
                  <div
                    className={`max-w-2xl mx-auto mt-4 step ${
                      step === 11 ? "active" : ""
                    }`}
                  >
                    <div className="h-full">
                      <EventTabs data={eventFormData} />
                    </div>
                    <div className="bg-white ">
                      <div className="p-6 flex justify-center flex-col md:mx-auto rounded-xl shadow-xl ">
                        <svg
                          viewBox="0 0 24 24"
                          className="text-green-600 w-16 h-16 mx-auto my-6"
                        >
                          <path
                            fill="currentColor"
                            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                          ></path>
                        </svg>
                        <div className="text-center">
                          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                            You're all set!
                          </h3>
                          <p className="text-gray-600 my-2">
                            Press submit to save the form
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-2xl mx-auto stepActions">
                    <div className="button-container items-center px-6">
                      {step > 1 && (
                        <button
                          className="btn btn-outline border-primary text-primary hover:bg-primary"
                          onClick={handlePrevious}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                            />
                          </svg>{" "}
                          Back
                        </button>
                      )}

                      <span className="text-gray-500">
                        Step: <b>{step}</b> of <b>11</b>
                      </span>
                      <div className="flex flex-row gap-2">
                        {step < 11 ? (
                            <button
                            className="btn bg-primary text-white"
                            onClick={(e) => {
                              handleNext();
                              handleSubmit(e,step === 11);
                            }}
                            disabled={
                              (step === 3 && !allFieldsFilled) ||
                              (step === 6 && (!allFieldsFilled && !eventFormData?.isChecked_EventHead))
                            }                            
                            >
                            Next
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                              />
                            </svg>
                          </button>
                        ) : (
                          <div>
                            {isLoadingIcon ? (
                              <button className="btn">
                                <span className="loading loading-spinner"></span>
                                loading
                              </button>
                            ) : (
                              <button
                                className="btn bg-primary text-white"
                                onClick={(e) => {
                                  // setste
                                  handleSubmit(e, true);
                                }}
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      <div style={{ zIndex: 999999999999999 }}>
        <Toaster position="bottom-center" />
      </div>

      {sendingFile && (
        <div className="toast toast-end">
          <div className="alert alert-success">
            <span>Please wait. File is uploading</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default EventReqForm;
