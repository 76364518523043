import React from "react";

export default function SocietyStatusEvents({ status, events, name }) {
  let filteredEvents = [];
  if (events !== undefined) {
    filteredEvents = events;
  }
  let society_name = name.toUpperCase();
  if (status !== "All") {
    filteredEvents = events.filter((event) => event.status === status);
  }

  return (
    <div className="overflow-x-auto mt-6">
      {events && filteredEvents.length === 0 ? (
        <span>No records found</span>
      ) : (
        <div className="overflow-x-auto mt-6">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>Head</th>
                <th>Venue</th>
              </tr>
            </thead>
            <tbody>
              {events &&
                filteredEvents.map((event) => {
                  let event_head = event.applicant_name;
                  if (event.is_event_head === "false") {
                    //might cuase bugs
                    event_head = event.event_heads;
                  }
                  let venue = "TBA";
                  if (event.status === "Approved") {
                    venue = event.approved_venue;
                  } else if (event.status === "Rejected") {
                    venue = "-";
                  }

                  return (
                    <tr key={event.id}>
                      <td>
                        {event.event_name}{" "}
                        <small className="badge badge-primary float-right">
                          {society_name}
                        </small>
                      </td>
                      <td>Apr. 29th to 30th</td>
                      <td>11:00 to 3:00 PM</td>
                      <td>{event_head}</td>
                      <td>{venue}</td>
                    </tr>
                  );
                })}

              {/* <tr>
                <td>
                  Business Event{" "}
                  <small className="badge badge-primary">YLES</small>
                </td>
                <td>Apr. 29th to 30th</td>
                <td>11:00 to 3:00 PM</td>
                <td>Ahmad Imran</td>
                <td>Auditorium 1</td>
              </tr>

              <tr>
                <td>
                  Business Event{" "}
                  <small className="badge badge-primary">YLES</small>
                </td>
                <td>Apr. 29th to 30th</td>
                <td>11:00 to 3:00 PM</td>
                <td>Ahmad Imran</td>
                <td>Auditorium 1</td>
              </tr>

              <tr>
                <td>
                  Business Event{" "}
                  <small className="badge badge-primary">YLES</small>
                </td>
                <td>Apr. 29th to 30th</td>
                <td>11:00 to 3:00 PM</td>
                <td>Ahmad Imran</td>
                <td>Auditorium 1</td>
              </tr>

              <tr>
                <td>
                  Business Event{" "}
                  <small className="badge badge-primary">YLES</small>
                </td>
                <td>Apr. 29th to 30th</td>
                <td>11:00 to 3:00 PM</td>
                <td>Ahmad Imran</td>
                <td>Auditorium 1</td>
              </tr>

              <tr>
                <td>
                  Business Event{" "}
                  <small className="badge badge-primary">YLES</small>
                </td>
                <td>Apr. 29th to 30th</td>
                <td>11:00 to 3:00 PM</td>
                <td>Ahmad Imran</td>
                <td>Auditorium 1</td>
              </tr>

              <tr>
                <td>
                  Business Event{" "}
                  <small className="badge badge-primary">YLES</small>
                </td>
                <td>Apr. 29th to 30th</td>
                <td>11:00 to 3:00 PM</td>
                <td>Ahmad Imran</td>
                <td>Auditorium 1</td>
              </tr>

              <tr>
                <td>
                  Business Event{" "}
                  <small className="badge badge-primary">YLES</small>
                </td>
                <td>Apr. 29th to 30th</td>
                <td>11:00 to 3:00 PM</td>
                <td>Ahmad Imran</td>
                <td>Auditorium 1</td>
              </tr>

              <tr>
                <td>
                  Business Event{" "}
                  <small className="badge badge-primary">YLES</small>
                </td>
                <td>Apr. 29th to 30th</td>
                <td>11:00 to 3:00 PM</td>
                <td>Ahmad Imran</td>
                <td>Auditorium 1</td>
              </tr>

              <tr>
                <td>
                  Business Event{" "}
                  <small className="badge badge-primary">YLES</small>
                </td>
                <td>Apr. 29th to 30th</td>
                <td>11:00 to 3:00 PM</td>
                <td>Ahmad Imran</td>
                <td>Auditorium 1</td>
              </tr>

              <tr>
                <td>
                  Business Event{" "}
                  <small className="badge badge-primary">YLES</small>
                </td>
                <td>Apr. 29th to 30th</td>
                <td>11:00 to 3:00 PM</td>
                <td>Ahmad Imran</td>
                <td>Auditorium 1</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
