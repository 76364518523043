import React, { useContext, useEffect, useState } from "react";
import { axiosInstance } from "../Axios";
import { Link } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast';
import AuthContext from "../context/AuthContext";
function AddSocietyMember() {

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  let USERID = user['user_id'];

  const [societyProfile, setSocietyProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Make an HTTP GET request to fetch the society profile
    axiosInstance.get(`society/profile/${USERID}/`)
      .then((response) => {
        setSocietyProfile(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching society profile:", error);
        setLoading(false);
      });
  }, [USERID]);

  const [members, setMembers] = useState([
    {
      name: "",
      contact: "",
      email: "",
      designation: "",
      headOf: "",
      status: "Active",
      school: "",
      image: null,
    },
  ]);

  const addMember = () => {
    setMembers([
      ...members,
      {
        name: "",
        contact: "",
        email: "",
        designation: "",
        headOf: "",
        status: "Active",
        school: "",
        image: null,
      },
    ]);
  };

  const [msg, setMsg] = useState(null);

  const updateMember = (index, field, value) => {
    const newMembers = [...members];
    newMembers[index][field] = value;
    setMembers(newMembers);
  };

  const handleImageChange = (e, index) => {
    const newMembers = [...members];
    newMembers[index]["image"] = e.target.files[0];
    setMembers(newMembers);
  };

  const [membersFile, setMembersFile] = useState(null);

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileType = selectedFile;
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
    if (fileExtension === "csv") {
      setMembersFile(selectedFile);
    } else if (fileExtension === "xlsx") {
      alert("Please convert your xlsx file to a csv");
      setMembersFile(null);
    } else {
      alert("Please select a valid CSV file");
      setMembersFile(null);
    }
  };

  const onUpload = async () => {
    if (membersFile === null) {
      setMsg("Please select a file");
      return;
    }
    const formData = new FormData();
    formData.append("csv", membersFile);

    axiosInstance
      .post("member_csv_upload/", formData)
      .then((res) => {
        ////console.log(res);
        
        let postedFor = societyProfile?.ccaadmins
        axiosInstance.post("post_notification_member_add_req/", { postedFor });

        toast.success('File Uploaded and being reviewed by admin', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        setMsg("File Uploaded");
      })
      .catch((err) => {
        if (err.response.status === 406) {
          setMsg(err.response.data);
        } else {
          ////console.log(err);
          toast.error('Oh No! Something went wrong', {
            icon: '😓',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          setMsg("Error while uploading file");
        }
      });
  };

  // const validateFile = (file) => {
  //   const fileType = file.type;
  //   const fileExtension = file.name.split(".").pop().toLowerCase();
  //   //console.log(fileType, fileExtension);

  //   return fileExtension === "csv" ;
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create an array of promises
    const promises = members.map(async (member) => {
      // Create a new FormData object
      const formData = new FormData();

      // Append each field to the FormData object
      for (const field in member) {
        formData.append(field, member[field]);
      }
      axiosInstance.get("get_profile/").then((res) => {
        ////console.log("custom refresh");
        axiosInstance
          .post("create_member/", formData)
          .then((res) => {
            ////console.log(res.data);
            let postedFor = societyProfile?.ccaadmins
            axiosInstance.post("post_notification_member_add_req/", { postedFor });
            toast.success('Member details are being reviewed by admin', {
              icon: '👏',
              style: {
                borderRadius: '55px',
                marginBottom: '15px',
              },
            });
            setMembers([
              {
                name: "",
                contact: "",
                email: "",
                designation: "",
                headOf: "",
                status: "Active",
                school: "",
                image: null,
              },
            ]);
          })
          .catch((err) => {
            // //console.log(err);
            toast.error('Oh No! Something went wrong', {
              icon: '😓',
              style: {
                borderRadius: '55px',
                marginBottom: '15px',
              },
            });
          });
      });
      // Return a promise that resolves to the response data
    });

    // Wait for all promises to resolve
    // const allData = await Promise.all(promises);
  };

  return (
    <section className="p-6 bg-white text-gray-900">
      <form
        onSubmit={handleSubmit}
        className="container flex flex-col mx-auto space-y-12"
      >
        {members.map((member, index) => (
          <fieldset className="grid grid-cols-4 gap-6 p-6 rounded-md shadow-sm bg-gray-50">
            <div className="space-y-2 col-span-full lg:col-span-1">
              <p className="font-medium">Member(s) Information</p>
              <p className="badge badge-primary"> Member {index + 1} </p>
            </div>
            <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
              <div className="col-span-full sm:col-span-2">
                <label for="firstname" className="text-sm">
                  Full name
                </label>
                <input
                  id="firstname"
                  value={member.name}
                  onChange={(e) => updateMember(index, "name", e.target.value)}
                  type="text"
                  placeholder="John Doe"
                  className="input input-bordered w-full rounded-md focus:ring focus:ri focus:ri border-gray-300 text-gray-900"
                />
              </div>

              <div className="col-span-full sm:col-span-2">
                <label for="email" className="text-sm">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  value={member.email}
                  onChange={(e) => updateMember(index, "email", e.target.value)}
                  placeholder="email@lums.edu.pk"
                  className="input input-bordered w-full rounded-md focus:ring focus:ri focus:ri border-gray-300 text-gray-900"
                />
              </div>

              <div className="col-span-full sm:col-span-2">
                <label for="city" className="text-sm">
                  Designation
                </label>
                <input
                  type="text"
                  value={member.designation}
                  onChange={(e) =>
                    updateMember(index, "designation", e.target.value)
                  }
                  placeholder="Vice President"
                  className="input input-bordered w-full rounded-md focus:ring focus:ri focus:ri border-gray-300 text-gray-900"
                />
              </div>
              <div className="col-span-full sm:col-span-2">
                <label for="state" className="text-sm">
                  Contact No.
                </label>
                <input
                  id="state"
                  type="number"
                  value={member.contact}
                  onChange={(e) =>
                    updateMember(index, "contact", e.target.value)
                  }
                  placeholder="0340456789"
                  className="input input-bordered w-full rounded-md focus:ring focus:ri focus:ri border-gray-300 text-gray-900"
                />
              </div>
              <div className="col-span-full sm:col-span-2">
                <label for="zip" className="text-sm">
                  School
                </label>
                <select
                  className="select select-bordered w-full max-w-xs"
                  value={member.school}
                  onChange={(e) =>
                    updateMember(index, "school", e.target.value)
                  }
                >
                  <option value="-" selected>
                    Pick One
                  </option>
                  <option value="SBASEE">SBASEE</option>
                  <option value="SDSB">SDSB</option>
                  <option value="MGHSS">MGHSS</option>
                  <option value="SAHSOL">SAHSOL</option>
                </select>
              </div>

              <div className="col-span-full sm:col-span-2">
                <label for="zip" className="text-sm">
                  Is this an EC member?
                </label>
                <select
                  className="select select-bordered w-full max-w-xs"
                  value={member.headOf}
                  onChange={(e) =>
                    updateMember(index, "headOf", e.target.value)
                  }
                >
                  <option value="-" selected>
                    Pick One
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div className="col-span-full sm:col-span-3">
                <fieldset className="w-full space-y-1 text-gray-800">
                  <label for="files" className="block text-sm font-medium">
                    Attachments
                  </label>
                  <div className="flex">
                    <input
                      type="file"
                      name="files"
                      onChange={(e) => handleImageChange(e, index)}
                      id="files"
                      className="px-8 py-12 border-2 border-dashed rounded-md border-gray-300 text-gray-600 bg-gray-100"
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </fieldset>
        ))}

        <div className="flex flex-row gap-4 w-full">
          <button className="btn bg-primary text-white" type="submit">
            Submit
          </button>
          <button type="button" className="btn btn-outline" onClick={addMember}>
            + Add More
          </button>
        </div>
      </form>

<div className="divider"> OR </div>
      <div className="rounded-md shadow-sm p-6  bg-gray-50 mt-8">

      <div className="col-span-full sm:col-span-3">
  <fieldset className="w-full space-y-1 text-gray-800">
    <label htmlFor="files" className="block text-sm font-medium">
      Upload members via CSV.
      <a
        href="https://drive.google.com/file/d/1P0_qKP6GQswJ79ZvyUEF1qXGZtGIak3X/view?usp=sharing"
        className="text-primary"
        download="sample.csv"
      >
        Download Sample CSV
      </a>
    </label>
    <div className="flex">
      <input
        type="file"
        name="files"
        onChange={onFileChange}
        id="files"
        className="px-8 py-12 border-2 border-dashed rounded-md border-gray-300 text-gray-600 bg-gray-100"
      />
    </div>
  </fieldset>
</div>


        <button className="btn bg-primary text-white mt-4" onClick={onUpload}>Upload File</button>
        <h1>{msg}</h1>
      </div>
      <div style={{ zIndex: 999999999999999}}>
        <Toaster position="bottom-center" />
      </div>
    </section>
  );
}

export default AddSocietyMember;
