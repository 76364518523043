import React, { useState, useEffect, useContext } from "react";
import SocNav from "../components/SocNav";
import { Link, Navigate, Redirect } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SocietySidebar from "../components/SocietySidebar";
import MobileBottom from "../components/MobileBottom";
import SocietyStats from "../components/SocietyStats";
import SocietyRequestTable from "../components/SocietyRequestTable";
import SocietyEventsWidget from "../components/SocietyEventsWidget";
import Calendar from "../components/Calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { axiosInstance } from "../Axios";
import MyCalendar from "../components/Calendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SocietySideInnerSM from "../components/SocietySideInnerSM";
import RequestsList from "./RequestsList";
import EventsTable from "../components/AllSocietyEvents";
import FeedbackButton from "./FeedbackButton";
import PassWarn from "../components/PassWarn";
const SocietyDashboard = () => {
  const [events, setEvents] = useState();

  let { user } = useContext(AuthContext);
  let { authTokens } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  const [profile, setProfile] = useState();
  let userID = user["user_id"];
  // initalize thread and posts component state

  const [societyData, setSocietyData] = useState(null);

  useEffect(() => {
    // Define the URL of your Django API endpoint

    axiosInstance.get(`society/profile/${userID}/`)
      .then((response) => {
        setSocietyData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching society profile:', error);
      });
  }, [userID]);

  useEffect(() => {
    // const getProfile = async () => {
    //   if (user !== null) {
    //     let userID = user["user_id"];
    //     let profileID = profile?.name;
    //     const response = await fetch(`/api/profile/${userID}`);

    //     // parse the data in json
    //     let data = await response.json();

    //     setProfile(data);
    //   }
    // };
    const getEvents = async () => {
      if (user !== null) {
        

        // const response = await fetch(`/api/event/${userID}`);
        let response = await axiosInstance.get(`event/`);

        // parse the data in json
        // let data = await response.json();
        let data = await response.data;

        setEvents(data);
      }
    };

    // getProfile();
    getEvents();
  }, [user]);
  // //console.log(events);


  if (user["user_id"] === null) {
    window.location.replace(`/`);
  }
  if (user["role"] === "Student") {
    window.location.replace(`/student/${userID}`);
  } else if (user == null) {
    window.location.replace("/login");
  }

  let role = "";

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  }

  return (
    <div>


    <div><>
    
    <div class="absolute w-full bg-primary  min-h-75"></div>
    
    
    <SocietySidebar />
    
    {isSidebarVisible && <SocietySideInnerSM/>}
    
        <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
          {/* Navbar */}
          <SocNav toggleSidebar={toggleSidebar} />
    
          {/* end Navbar */}
          {/* cards */}
          <div className="w-full px-6 py-6 mx-auto">
    
    
    
          <SocietyStats/>


          {(() => {
        if (societyData?.is_newlogin===1) {
          return (
            <PassWarn/>
          )
        }
      })()}

          
            {/* cards row 2 */}
            
            {/* cards row 3 */}
            <div className="flex flex-wrap mt-6 -mx-3">
              <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
                <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
                  <div className="p-4 pb-0 mb-0 rounded-t-4">
                    <div className="flex justify-between">
                      <h6 className="mb-2 ">Requests</h6>
                    </div>
                  </div>
                  <div className="overflow-x-auto">
                  <Tabs>
        <TabList className="tab-list">
            <Tab className="tab">General Requests</Tab>
            <Tab className="tab">Event Requests</Tab>
        </TabList>
    
        <TabPanel className="tab-panel">
            <RequestsList />
        </TabPanel>
    
        <TabPanel className="tab-panel">
            <EventsTable />
        </TabPanel>
    </Tabs>
                  </div>
                </div>
              </div>
              <div className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none">
                <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                  <div className="p-4 pb-0 rounded-t-4">
                    <h6 className="mb-0 ">Calendar</h6>
                  </div>
                  <div className="flex-auto p-4">
                    <MyCalendar events={events} />
                  </div>
                </div>
              </div>
            </div>
    
    
          </div>
        </main>
      </>
      </div>

      <FeedbackButton/>
      </div>
  );
};
export default SocietyDashboard;
