import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../Axios";

import { Link, Navigate, Redirect } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SocietySidebar from "./SocietySidebar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SocNav from "./SocNav";
import SocietySideInnerSM from "./SocietySideInnerSM";
import SkeletonLoader from "./SkeletonLoader";
import { toast, Toaster } from "react-hot-toast";
import { Routes, Route, useParams } from "react-router-dom";

const AnnouncementDetails = () => {
  const [profile, setProfile] = useState();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { id } = useParams();
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const [repData, setRepData] = useState({
    title: "",
    description: "",
    posted_by: "",
    category: "",
    target: "",
  });

  useEffect(() => {
    axiosInstance.get(`get_repo/${id}/`).then((response) => {
      const data = response.data;
      setRepData({
        title: data.title,
        description: data.description,
        posted_by: data.posted_by,
        category: data.category,
        target: data.target,
      });
    });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRepData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    const dataToSend = {
      ...repData,
      posted_by: id,
    };
    axiosInstance
      .post(`edit_repos/${id}/`, dataToSend)
      .then((response) => {
        toast.success("Repository Edit Successfully", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((err) => {
        console.log("Error:  ", err);
      });
  };

  return (
    <div>
      <>
        <div class="absolute w-full bg-primary  min-h-75"></div>

        <SocietySidebar />

        {isSidebarVisible && <SocietySideInnerSM />}

        <main className="relative h-full max-h-screen  transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
          {/* Navbar */}
          <SocNav toggleSidebar={toggleSidebar} />

          {/* end Navbar */}
          {/* cards */}
          <div className="w-full px-6 py-6 mx-auto">
            {/* row 1 */}

            <div class="w-full max-w-full px-3 shrink-0 md:w-12/12 md:flex-0">
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                  <div class="flex items-center"></div>
                </div>
                <div class="flex-auto p-6">
                  <p class="leading-normal uppercase   text-sm">
                    Repository Information
                  </p>
                  <div class="flex flex-wrap -mx-3">
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="title"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                        >
                          Title
                        </label>
                        <h2>{repData.title}</h2>
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="category"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                        >
                          Category
                        </label>
                        <h4>{repData.category}</h4>
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <label
                        for="category"
                        class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                      >
                        Target
                      </label>
                      <h6>{repData.target}</h6>
                    </div>
                  </div>

                  <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent  " />

                  <div class="flex flex-wrap -mx-3">
                    <div class="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                      <div class="mb-4">
                        <label
                          for="about me"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                        >
                          Description
                        </label>
                        <h5>{repData.description}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div style={{ zIndex: 999999999999999 }}>
          <Toaster position="bottom-center" />
        </div>
      </>
    </div>
  );
};
export default AnnouncementDetails;