import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import ProfileEditForm from "../components/ProfileEditForm";

const Profile = () => {
  let { user } = useContext(AuthContext);

  // extract profile id
  let params = useParams();
  let profileID = params.id;

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(`/api/profile/${profileID}`);

        // parse the data in json
        let data = await response.json();

        setProfile(data);
      } catch (err) {
        //console.log("The requested profile does not exists.");
      }
    };

    getProfile();
  }, [profileID]);

  //console.log("this is profile ", profile);
  const [showProfile, setshowProfile] = useState(true);
  const [showForm, setshowForm] = useState(false);

  const handle_showProfile = (event) => {
    setshowProfile((current) => !current);
    setshowForm(false);
  };

  const handle_showForm = (event) => {
    setshowForm((current) => !current);
    setshowProfile(false);
  };

  let { logoutUser } = useContext(AuthContext);
  const sum_lead =
    profile?.lead_activity *
      profile?.lead_duration *
      profile?.lead_scope *
      profile?.lead_service +
    profile?.lead_activity2 *
      profile?.lead_duration2 *
      profile?.lead_scope2 *
      profile?.lead_service2 +
    profile?.lead_activity3 *
      profile?.lead_duration3 *
      profile?.lead_scope3 *
      profile?.lead_service3;

  const sum_society =
    profile?.society_name *
      profile?.society_duration *
      profile?.society_role *
      profile?.society_service +
    profile?.society_name2 *
      profile?.society_duration2 *
      profile?.society_role2 *
      profile?.society_service2 +
    profile?.society_name3 *
      profile?.society_duration3 *
      profile?.society_role3 *
      profile?.society_service3;

  const sum_social =
    profile?.social_name *
      profile?.social_role *
      profile?.social_rank *
      profile?.social_service +
    profile?.social_name2 *
      profile?.social_role2 *
      profile?.social_rank2 *
      profile?.social_service2 +
    profile?.social_name3 *
      profile?.social_role3 *
      profile?.social_rank3 *
      profile?.social_service3;

  const sum_award =
    profile?.award_name * profile?.award_rank * profile?.award_scope +
    profile?.award_name2 * profile?.award_rank2 * profile?.award_scope2 +
    profile?.award_name3 * profile?.award_rank3 * profile?.award_scope3;

  const round_event_management_result = 0;
  const sum_sports =
    profile?.sports_name *
      profile?.sports_role *
      profile?.sports_scope *
      profile?.sports_service *
      profile?.sports_duration +
    profile?.sports_name2 *
      profile?.sports_role2 *
      profile?.sports_scope2 *
      profile?.sports_service2 *
      profile?.sports_duration2 +
    profile?.sports_name3 *
      profile?.sports_role3 *
      profile?.sports_scope3 *
      profile?.sports_service3 *
      profile?.sports_duration3;

  const sum_intern =
    profile?.intern_name * profile?.intern_duration +
    profile?.intern_name2 * profile?.intern_duration2 +
    profile?.intern_name3 * profile?.intern_duration3;

  const sum_cert =
    profile?.cert_type + profile?.cert_type2 + profile?.cert_type3;

  const round_lead_result = Math.floor(sum_lead);
  const round_society_result = Math.floor(sum_society);
  const round_social_result = Math.floor(sum_social);
  const round_award_result = Math.floor(sum_award);
  const round_sports_result = Math.floor(sum_sports);
  const round_intern_result = Math.floor(sum_intern);
  const round_cert_result = Math.floor(sum_cert);

  const round_lead_duration = profile?.lead_duration * 10;
  const round_lead_duration2 = profile?.lead_duration2 * 10;
  const round_lead_duration3 = profile?.lead_duration3 * 10;

  const round_society_duration = profile?.society_duration * 10;
  const round_society_duration2 = profile?.society_duration2 * 10;
  const round_society_duration3 = profile?.society_duration3 * 10;

  const round_social_duration = profile?.social_duration * 10;
  const round_social_duration2 = profile?.social_duration2 * 10;
  const round_social_duration3 = profile?.social_duration3 * 10;

  const totalScore =
    (round_lead_result +
      round_society_result +
      round_social_result +
      round_award_result +
      round_sports_result +
      round_cert_result +
      round_intern_result) *
    15;
  //const totalScore = 2500;

  let parsed_hobboes = profile?.hobbies;

  //console.log(profile?.lead_activity);

  {
    /* Competency Calculation */
  }

  let comp_lead_tech = 0;
  let comp_lead_tech2 = 0;
  let comp_lead_tech3 = 0;

  let comp_lead_lead = 0;
  let comp_lead_lead2 = 0;
  let comp_lead_lead3 = 0;

  let comp_lead_ips = 0;
  let comp_lead_ips2 = 0;
  let comp_lead_ips3 = 0;

  let comp_lead_ct = 0;
  let comp_lead_ct2 = 0;
  let comp_lead_ct3 = 0;

  let comp_lead_social = 0;
  let comp_lead_social2 = 0;
  let comp_lead_social3 = 0;

  let comp_lead_physical = 0;
  let comp_lead_physical2 = 0;
  let comp_lead_physical3 = 0;

  {
    /*
                      <option value="8.04">Captain Sports Team</option>
                      <option value="7">Class Monitor</option>
                      <option value="10">Head Student</option>
                      <option value="0.9">Student Council General Secretary</option>
                      <option value="8.01">Deputy Head Student</option>
                      <option value="8.02">House Captain</option>
                      <option value="8.03">Class Prefect</option>
                      */
  }

  {
    /* Activity: leadership | Comp: leadership*/
  }

  if (
    profile?.lead_activity == 10.0 ||
    profile?.lead_activity == 9.0 ||
    profile?.lead_activity == 8.02 ||
    profile?.lead_activity == 8.03
  ) {
    comp_lead_lead = 3;
  }

  if (
    profile?.lead_activity == 8.01 ||
    profile?.lead_activity == 7.0 ||
    profile?.lead_activity == 8.04
  ) {
    comp_lead_lead = 2;
  }

  if (
    profile?.lead_activity2 == 10.0 ||
    profile?.lead_activity2 == 9.0 ||
    profile?.lead_activity2 == 8.02 ||
    profile?.lead_activity2 == 8.03
  ) {
    comp_lead_lead2 = 3;
  }

  if (
    profile?.lead_activity3 == 8.01 ||
    profile?.lead_activity3 == 7.0 ||
    profile?.lead_activity3 == 8.04
  ) {
    comp_lead_lead2 = 2;
  }

  if (
    profile?.lead_activity3 == 10.0 ||
    profile?.lead_activity3 == 9.0 ||
    profile?.lead_activity3 == 8.02 ||
    profile?.lead_activity3 == 8.03
  ) {
    comp_lead_lead3 = 3;
  }

  if (
    profile?.lead_activity3 == 8.01 ||
    profile?.lead_activity3 == 7.0 ||
    profile?.lead_activity3 == 8.04
  ) {
    comp_lead_lead3 = 2;
  }

  {
    /* Activity: leadership | Comp: IPS */
  }

  if (
    profile?.lead_activity == 10.0 ||
    profile?.lead_activity == 9.0 ||
    profile?.lead_activity == 8.02
  ) {
    comp_lead_ips = 3;
  }

  if (
    profile?.lead_activity == 8.01 ||
    profile?.lead_activity == 7.0 ||
    profile?.lead_activity == 8.04 ||
    profile?.lead_activity == 8.03
  ) {
    comp_lead_ips = 2;
  }

  if (
    profile?.lead_activity2 == 10.0 ||
    profile?.lead_activity2 == 9.0 ||
    profile?.lead_activity2 == 8.02
  ) {
    comp_lead_ips2 = 3;
  }

  if (
    profile?.lead_activity3 == 8.01 ||
    profile?.lead_activity3 == 7.0 ||
    profile?.lead_activity3 == 8.04 ||
    profile?.lead_activity2 == 8.03
  ) {
    comp_lead_ips2 = 2;
  }

  if (
    profile?.lead_activity3 == 10.0 ||
    profile?.lead_activity3 == 9.0 ||
    profile?.lead_activity3 == 8.02
  ) {
    comp_lead_ips3 = 3;
  }

  if (
    profile?.lead_activity3 == 8.01 ||
    profile?.lead_activity3 == 7.0 ||
    profile?.lead_activity3 == 8.04 ||
    profile?.lead_activity3 == 8.03
  ) {
    comp_lead_ips3 = 2;
  }

  {
    /* Activity: leadership | Comp: Critical Thinking */
  }

  if (profile?.lead_activity == 10.0 || profile?.lead_activity == 9.0) {
    comp_lead_ct = 3;
  }

  if (
    profile?.lead_activity == 8.01 ||
    profile?.lead_activity == 7.0 ||
    profile?.lead_activity == 8.04 ||
    profile?.lead_activity == 8.03 ||
    profile?.lead_activity == 8.02
  ) {
    comp_lead_ct = 2;
  }

  if (profile?.lead_activity2 == 10.0 || profile?.lead_activity2 == 9.0) {
    comp_lead_ct2 = 3;
  }

  if (
    profile?.lead_activity3 == 8.01 ||
    profile?.lead_activity3 == 7.0 ||
    profile?.lead_activity3 == 8.04 ||
    profile?.lead_activity2 == 8.03 ||
    profile?.lead_activity2 == 8.02
  ) {
    comp_lead_ct2 = 2;
  }

  if (profile?.lead_activity3 == 10.0 || profile?.lead_activity3 == 9.0) {
    comp_lead_ct3 = 3;
  }

  if (
    profile?.lead_activity3 == 8.01 ||
    profile?.lead_activity3 == 7.0 ||
    profile?.lead_activity3 == 8.04 ||
    profile?.lead_activity3 == 8.03 ||
    profile?.lead_activity3 == 8.02
  ) {
    comp_lead_ct3 = 2;
  }

  {
    /* Activity: leadership | Comp: Social */
  }

  {
    /* Activity: leadership | Comp: Physcial */
  }

  if (profile?.lead_activity == 8.04) {
    comp_lead_physical = 2;
  }

  if (profile?.lead_activity2 == 8.04) {
    comp_lead_physical2 = 2;
  }

  if (profile?.lead_activity3 == 8.04) {
    comp_lead_physical3 = 2;
  }

  var total_tech_Competencies1 =
    comp_lead_tech + comp_lead_tech2 + comp_lead_tech3;
  //var total_tech_Competencies1 = 20
  var total_leadership_Competencies1 =
    comp_lead_lead + comp_lead_lead2 + comp_lead_lead3;
  var total_ips_Competencies1 = comp_lead_ips + comp_lead_ips2 + comp_lead_ips3;
  var total_ct_Competencies1 = comp_lead_ct + comp_lead_ct2 + comp_lead_ct3;
  var total_social_Competencies1 =
    comp_lead_social + comp_lead_social2 + comp_lead_social3;
  var total_physical_Competencies1 =
    comp_lead_physical + comp_lead_physical2 + comp_lead_physical3;

  {
    /* Competency Calculation */
  }

  let hobbies_arr = profile?.hobbies;
  try {
    hobbies_arr = profile?.hobbies.substring(1, profile?.hobbies.length - 1);
    const stringArray = hobbies_arr.replace(/[{}]/g, "").split(", ");
    // //console.log(hobbies_arr);

    // Extract values from each string element
    hobbies_arr = stringArray.map((element) => {
      const keyValuePairs = element.split(", ");
      const valuePairs = keyValuePairs.map((pair) => pair.split(": ")[1]);
      return valuePairs;
    });
    hobbies_arr = hobbies_arr.flat();
  } catch (err) {
    //console.log(err);
  }
  hobbies_arr = [...new Set(hobbies_arr)];

  const EventManagementRow = ({ name, rank, scope, grade }) => {
    //console.log(typeof rank);
    let event_name = (name) => {
      switch (name) {
        case "9":
          return "Executive Council";
          break;
        case "8":
          return "Logistics Team";
          break;
        case "8.1":
          return "Registrations Team";
          break;
        case "8.2":
          return "Socials Team";
          break;
        case "8.3":
          return "Publications Team";
          break;
        case "8.4":
          return "Finance Team";
          break;
        case "8.5":
          return "Promotions Team";
          break;
        case "8.6":
          return "Communications Team";
          break;
        case "8.7":
          return "IT Team";
          break;
        case "8.8":
          return "Security Team";
          break;
        case "8.9":
          return "Operations Team";
          break;
        default:
          return "Unknown option";
          break;
      }
    };
    let event_rank = (rank) => {
      if (rank === 1.5) {
        return "Head";
      }
      if (rank === 1.3) {
        return "Team Member";
      } else {
        return "-";
      }
    };
    let event_scope = (scope) => {
      if (scope === 1) {
        return "School";
      }
      if (scope === 1.5) {
        return "Local";
      }
      if (scope === 1.3) {
        return "International";
      } else {
        return "-";
      }
    };

    let content = (
      <div className="infoRow flex-wrap">
        <div>
          <p className="text-sm text-gray-600"> Name </p>
          <h5> {event_name(name)} </h5>
        </div>

        <div>
          <p className="text-sm text-gray-600"> Grade </p>
          <h5> {grade} </h5>
        </div>

        <div>
          <p className="text-sm text-gray-600"> Rank </p>
          <h5> {event_rank(rank)} </h5>
        </div>

        <div>
          <p className="text-sm text-gray-600"> Scope </p>
          <h5> {event_scope(scope)} </h5>
        </div>
      </div>
    );

    return content;
  };

  return (
    <div>
    </div>
  );
};

export default Profile;