import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../Axios";
import ChatWindow from "../components/ChatWindow";
import SendMessage from "../components/SendMessage";
import moment from "moment";
import AdminNav from "./AdminNav";
import SocNav from "../components/SocNav";
import AuthContext from "../context/AuthContext";
import SocietySidebar from "../components/SocietySidebar";
import SocietySideInnerSM from "../components/SocietySideInnerSM";
import Select from "react-select"; // Import the Select component from react-select
import { toast, Toaster } from "react-hot-toast";

function ChatItem({ chat, onClick, currentUser }) {
  function hasUnreadMessages(chat, currentUser) {
    const lastMessage = chat?.messages?.[chat.messages.length - 1];
    return (
      lastMessage &&
      !lastMessage.seen_by?.find((user) => user?.id === currentUser?.id)
    );
  }

  let { user } = useContext(AuthContext);
  const lastMessage = chat?.messages?.[chat.messages.length - 1];
  const chatMoment = lastMessage ? lastMessage.sent_at : chat.sent_at;
  const isUnread = hasUnreadMessages(chat, currentUser);
  const chatName = chat.users
    ?.filter((user) => user.username !== chat.me)
    ?.map((user) => user.username)
    ?.join(", ");

  return (
    <div key={chat.id} onClick={() => onClick(chat)}>
      <div
        className={`bg-white px-3 flex items-center hover:bg-gray-100 cursor-pointer ${
          isUnread ? "bg-blue-500" : ""
        }`}
      >
        <div>{isUnread ? "" : "unread"}</div>
        <div>
          <img
            className="h-12 w-12 rounded-full"
            src="https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
          />
        </div>
        <div className="ml-4 flex-1 border-b border-grey-lighter py-2">
          <div className="flex items-bottom justify-between">
            <p className="text-grey-darkest font-medium m-0 m-none">
              {chatName}
            </p>
            <p className="text-xs text-grey-darkest">
              {moment(chatMoment).fromNow()}
            </p>
          </div>
          <p className="text-grey-dark mt-1 text-sm m-0 m-none">
            {chat.messages?.[chat.messages.length - 1]?.text}
          </p>
        </div>
      </div>
    </div>
  );
}

export default function ChatList() {
  const [chats, setChats] = useState([]);
  const [orginalChats, setOrginalChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [receivers, setReceivers] = useState([]);
  const [selectedReceivers, setSelectedReceivers] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  // useEffect(() => {
  //   const fetchChats = async () => {
  //     try {
  //       const response = await axiosInstance.get("get_chats/");
  //       setChats(response.data);
  //     } catch (error) {
  //       console.error("Error fetching chats:", error);
  //     }
  //   };

  //   const fetchUsers = async () => {
  //     try {
  //       const res = await axiosInstance.get("get_users/");
  //       setReceivers(res.data);
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     }
  //   };

  //   // Call once immediately
  //   fetchChats();
  //   fetchUsers();

  //   // Then set up an interval to keep fetching
  //   const intervalId = setInterval(() => {
  //     fetchChats();
  //     fetchUsers();
  //   }, 1000);

  //   // Clean up the interval when component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    console.log(selectedChat);
  }, [selectedChat]);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await axiosInstance.get("get_chats/");
        setChats(response.data);
        setOrginalChats(response.data);
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const res = await axiosInstance.get("get_users/");
        setReceivers(res.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    // Call once immediately
    fetchChats();
    fetchUsers();

    // Then set up an interval to keep fetching
    const intervalId = setInterval(() => {
      fetchChats();
      fetchUsers();
    }, 10000);

    // Clean up the interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");
  const userID = user["user_id"];
  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  let getendpoint = "";
  let postendpoint = "";

  if (user["role"] === "ccaAdmin") {
    getendpoint = `/admin/profile/${userID}/`;
    postendpoint = `/admin/profile/${userID}/update/`;
  } else if (user["role"] === "manager") {
    getendpoint = `/manager/profile/${userID}/`;
    postendpoint = `/manager/profile/${userID}/update/`;
  } else if (user["role"] === "society") {
    getendpoint = `/society/profile/${userID}/`;
    postendpoint = `/society/profile/${userID}/update/`;
  }

  // useEffect(() => {
  //   axiosInstance
  //     .get(getendpoint)
  //     .then((response) => {
  //       const data = response.data;
  //       setProfileData({
  //         name: data.name,
  //         dob: data.dob,
  //         gender: data.gender,
  //         contact: data.contact,
  //         bio: data.bio,
  //         active_status: data.active_status,
  //         employeeID: data.employeeID,
  //       });

  //       setProfilePhotoUrl(data.profile_photo);
  //       //console.log("Profile Photo URL: ", data.profile_photo);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [userID]);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axiosInstance.get(getendpoint);
        const data = response.data;
        setProfileData({
          name: data.name,
          dob: data.dob,
          gender: data.gender,
          contact: data.contact,
          bio: data.bio,
          active_status: data.active_status,
          employeeID: data.employeeID,
        });
        setProfilePhotoUrl(data.profile_photo);
      } catch (error) {
        console.error(error);
      }
    };

    // Call once immediately
    fetchProfileData();

    // Then set up an interval to keep fetching
    const intervalId = setInterval(fetchProfileData, 1000);

    // Clean up the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [userID]);

  const handleReceiverChange = (e) => {
    console.log(e.target.selectedOptions);
    const selectedOptions = Array.from(e.target.selectedOptions).map((option) =>
      parseInt(option.value)
    );
    const selectedUsers = receivers?.filter((user) =>
      selectedOptions.includes(user.id)
    );

    // Set the selected receivers
    setSelectedReceivers(selectedUsers);
  };

  const sortedChats = [...chats].sort((a, b) => {
    const lastMessageA = a.messages?.[a.messages.length - 1];
    const lastMessageB = b.messages?.[b.messages.length - 1];
    if (lastMessageA && lastMessageB) {
      return (
        moment(lastMessageB.sent_at).unix() -
        moment(lastMessageA.sent_at).unix()
      );
    }
    // Handle cases where last messages are not available
    return 0;
  });

  const sendNewChat = () => {
    let _recievers = JSON.stringify(selectedReceivers);
    axiosInstance
      .post("new_chat/", {
        message: newMessage,
        receivers: _recievers,
      })
      .then((res) => {
        toast.success("Message sent", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setNewMessage("");
      })
      .catch((err) => {
        toast.error("Oh No! Something went wrong", {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  const initiateNewChat = async () => {
    try {
      const response = await axiosInstance.post("new_chat/", {
        message: "Initiating chat",
        receivers: JSON.stringify(selectedReceivers),
      });

      if (response.data && response.data.id) {
        // Update the state with the new chat
        setChats((prevChats) => [...prevChats, response.data]);

        // Set the selected chat to the newly created chat
        setSelectedChat(response.data);

        toast.success("Message sent!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      } else {
        toast.error("Starting Chat.. ", {
          icon: "🕧",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      }
    } catch (error) {
      console.error("Error initiating chat:", error);

      toast.error("Oh No! Something went wrong", {
        icon: "😓",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    }
  };

  useEffect(() => {
    if (selectedReceivers.length > 0) {
      const existingChat = chats.find((chat) =>
        chat.users.some((user) =>
          selectedReceivers.map((r) => r.id).includes(user.id)
        )
      );

      if (!existingChat) {
        initiateNewChat();
      } else {
        setSelectedChat(existingChat);
      }
    }
  }, [selectedReceivers]);

  const handleChatClick = (chat) => {
    console.log("Selected Chat:", chat);
    setSelectedChat(chat);
  };
  const handleSendMessage = async (chatId, message) => {
    try {
      // Send the chat message
      const response = await axiosInstance.post("send_chat/", {
        chatId,
        message,
      });
      toast.success("Message sent!", {
        icon: "👏",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });

      // Get the updated chats
      // const chatsResponse = await axiosInstance.get("get_chats/");
      // setChats(chatsResponse.data);
      // const selectedChat = chatsResponse.data.find((chat) => chat.chat_id === chatId);
      // setSelectedChat(selectedChat);

      // // Log the selectedChat for debugging
      // console.log('USER: ', selectedChat);

      // // Assuming that selectedChat.users[1] is the receiver
      // const receiver = selectedChat.users[1];
      // if (receiver) {
      //   // Post a notification using the receiver's username
      //   await axiosInstance.post("post_notification_chat/", { others: receiver.username });
      // }

      const chatsResponse = await axiosInstance.get("get_chats/");
      setChats(chatsResponse.data);
      const selectedChat = chatsResponse.data.find(
        (chat) => chat.chat_id === chatId
      );
      setSelectedChat(selectedChat);

      // Log the selectedChat for debugging
      console.log("USER: ", selectedChat);

      // Determine the receiver by comparing with the 'me' variable
      const receiver = selectedChat.users.find(
        (user) => user.username !== selectedChat.me
      );

      if (receiver) {
        // Post a notification using the receiver's username
        await axiosInstance.post("post_notification_chat/", {
          others: receiver.username,
        });
      }
    } catch (err) {
      toast.error("Oh No! Something went wrong", {
        icon: "😓",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    }
  };

  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const handleRecipientSelect = (recipient) => {
    setSelectedRecipient(recipient);
    alert("Render - selectedRecipient: " + selectedRecipient);
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  return (
    <div>
      <>
        <div class="absolute w-full bg-primary  min-h-75"></div>

        <SocietySidebar />

        {isSidebarVisible && <SocietySideInnerSM />}

        <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
          {/* Navbar */}
          <SocNav toggleSidebar={toggleSidebar} />

          {/* end Navbar */}
          {/* cards */}
          <div className="w-full px-6 py-6 mx-auto">
            <div className="px-6">
              <div>
                <div className="w-full h-32" />
                <div
                  className="container mx-auto"
                  style={{ marginTop: "-128px" }}
                >
                  <div className="py-6 h-screen">
                    <div className="flex border border-grey rounded shadow-lg h-full">
                      <div className="w-1/3 border flex flex-col">
                        <div className="py-2 px-3 bg-blue-100 text-white flex flex-row justify-between items-center hidden">
                          <div>
                            <img
                              className="w-10 h-10 rounded-full"
                              src="http://andressantibanez.com/res/avatar.png"
                            />
                          </div>
                          <div className="flex">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width={24}
                                height={24}
                              >
                                {/* ... (path data) */}
                              </svg>
                            </div>
                            {/* ... (other icons) */}
                          </div>
                        </div>
                        <div className="py-2 px-2 bg-white">
                          <select
                            className="select select-bordered w-full"
                            value={selectedReceivers?.map((user) => user?.id)} // Use user IDs as values
                            onChange={handleReceiverChange}
                          >
                            {receivers.map((user) => (
                              <option key={user.id} value={user.id}>
                                {(() => {
                                  if (
                                    user.first_name === "" ||
                                    user.first_name === null ||
                                    user.first_name === 0 ||
                                    user.last_name === "" ||
                                    user.last_name === null ||
                                    user.last_name === 0
                                  ) {
                                    return <div>{user.username}</div>;
                                  } else {
                                    return (
                                      <div>
                                        {user.first_name} {user.last_name}
                                      </div>
                                    );
                                  }
                                })()}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="flex-1 overflow-auto p-2 bg-white">
                          {sortedChats.map((chat) => {
                            console.log(chat, "=====");
                            return (
                              <ChatItem
                                key={chat.chat_id}
                                chat={chat}
                                currentUser={user}
                                onClick={handleChatClick}
                                name={profileData.name} // Pass the user's name as a prop
                                image={profilePhotoUrl} // Pass the user's image URL as a prop
                              />
                            );
                          })}
                        </div>
                      </div>

                      <div className="w-2/3 border flex flex-col">
                        <div
                          className="flex-1 overflow-auto "
                          style={{ backgroundColor: "#DAD3CC" }}
                        >
                          {selectedChat && (
                            <div className="bg-white p-6 sticky">
                              {selectedChat.users &&
                                selectedChat.users.length > 0 &&
                                selectedChat.users.map((chatuser, index) => {
                                  // Check if chatuser's username is the same as the current user's username
                                  const isCurrentUser =
                                    chatuser.username === user.username;

                                  return (
                                    !isCurrentUser && (
                                      <span key={chatuser.id}>
                                        {chatuser.first_name ||
                                        chatuser.last_name
                                          ? `${chatuser.first_name} ${
                                              chatuser.last_name ||
                                              chatuser.username
                                            }`
                                          : chatuser.username}
                                      </span>
                                    )
                                  );
                                })}
                            </div>
                          )}

                          <div className="py-2 px-3">
                            {selectedChat && (
                              <ChatWindow
                                chat={selectedChat}
                                onSendMessage={handleSendMessage}
                              />
                            )}
                          </div>
                        </div>
                        <div className="bg-white px-4 py-4 block items-center w-full">
                          <SendMessage
                            chat={selectedChat}
                            onSendMessage={handleSendMessage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>

      <div style={{ zIndex: 999999999999999 }}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
}
