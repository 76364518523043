import React, { useState, useEffect, useContext } from "react";

import { Link, Navigate, Redirect } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SocietySidebar from "./SocietySidebar";
import MobileBottom from "./MobileBottom";
import SocietyStats from "./SocietyStats";
import SocietyEventsWidget from "./SocietyEventsWidget";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MembersList from "./MembersList";
import HierarchyMap from "./HierarchyMap";
import AllSocietyEvents from "./AllSocietyEvents";
import SocNav from "./SocNav";
import SocietySideInnerSM from "./SocietySideInnerSM";
const AllEvents = () => {
  const [profile, setProfile] = useState();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  return (
    <div>
      <>
        <div class="absolute w-full bg-primary  min-h-75"></div>

        <SocietySidebar />

        {isSidebarVisible && <SocietySideInnerSM />}

        <main className="relative h-full max-h-screen  transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
          {/* Navbar */}
          <SocNav toggleSidebar={toggleSidebar} />

          {/* end Navbar */}
          {/* cards */}
          <div className="w-full px-6 py-6 mx-auto">
            {/* row 1 */}

            <AllSocietyEvents />
          </div>
        </main>
      </>
    </div>
  );
};
export default AllEvents;
