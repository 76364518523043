// components/ManagerRegistration.js
import React, { useState } from "react";
import axios from "axios";
import { axiosInstance } from "../Axios";

const ManagerRegistration = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("create_manager/", {
        username,
        password,
        email,
        name,
      });

      if (response.status === 200) {
        // Manager registration successful, you can redirect or show a success message here
        console.log("Manager created successfully");
      }
    } catch (error) {
      setErrorMessage(error.response.data);
    }
  };

  return (
    <div>
      <h2>Manager Registration</h2>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <br />
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <br />
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <br />
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <br />
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default ManagerRegistration;
