import React, {useState, useEffect, useContext} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { CalendarIcon } from '@heroicons/react/solid';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';
import { axiosInstance } from '../Axios';





const PostEventReport = ({event}) => {



  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const [formData, setFormData] = useState({

    event: event,
    delegates_local: '',
    delegates_international: '',
    sponsors: [{ name: '', price: '' }],
    inKindSponsors: [{ name: '', price: '' }],
    revenue: '',
    expense: '',
    profit: '',
    name: '',
    company: '',
    email: '',
    venue: '',
    highlightsLink: '',
    highlightsMedia: [],
    banner: null,
    EventReport: [],
    MoUswithSponsor: [],
    feedbackComplaints: '',
    milestones: '',
    learnings: '',
    improvement: '',
    otherFeedback: '',
  });
  
  
  const [highlightsMedia, setHighlightsMedia] = useState([]);
  const [submittedPayload, setSubmittedPayload] = useState(null);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      event: event,
      eventPurpose: formData.eventPurpose,
      delegates_local: formData.delegates_local,
      delegates_international: formData.delegates_international,
      startDate,
      endDate,
      sponsors: formData.sponsors.map(({ name, price }) => ({ name, price })),
      inKindSponsors: formData.inKindSponsors.map(({ name, price }) => ({ name, price })),
      revenue: formData.revenue,
      expense: formData.expense,
      profit: formData.profit,
      name: formData.name,
      company: formData.company,
      email: formData.email,
      venue: formData.venue,
      highlightsLink: formData.highlightsLink,
      highlightsMedia: formData.highlightsMedia,
      banner: formData.banner,
      EventReport: formData.EventReport,
      MoUswithSponsor: formData.MoUswithSponsor      ,
      feedbackComplaints: formData.feedbackComplaints,
      milestones: formData.milestones,
      learnings: formData.learnings,
      improvement: formData.improvement,
      other_feedback: formData.otherFeedback,
    };
    setSubmittedPayload(payload); 
  
    // Make API call
    axiosInstance.post("post_event_report/", payload)
      .then((res) => {
        console.log("Form submitted successfully:", res.data);
        toast.success("Form submitted successfully!");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error("Error submitting form!");
      });
  };

  
    const [sponsorSummary, setSponsorSummary] = useState({ count: 0, total: 0 });
    const [inKindSponsorSummary, setInKindSponsorSummary] = useState({ count: 0, total: 0 });
  


    useEffect(() => {
      const totalSponsors = formData.sponsors.length;
      const totalInKindSponsors = formData.inKindSponsors.length;
  
      const totalSponsorsPrice = formData.sponsors.reduce((sum, sponsor) => sum + (parseFloat(sponsor.price) || 0), 0);
      const totalInKindSponsorsPrice = formData.inKindSponsors.reduce((sum, sponsor) => sum + (parseFloat(sponsor.price) || 0), 0);
  
      setSponsorSummary({ count: totalSponsors, total: totalSponsorsPrice });
      setInKindSponsorSummary({ count: totalInKindSponsors, total: totalInKindSponsorsPrice });
    }, [formData.sponsors, formData.inKindSponsors]);
  
 
  
    
    const [uploading, setUploading] = useState(false);
  
    const handleFileChange = async (e) => {
      setUploading(true); // Start uploading, set uploading status to true
      const newFiles = Array.from(e.target.files);
      const inputName = e.target.name;
  
      const uploadedFiles = await Promise.all(newFiles.map(async (file) => {
        const data = new FormData();
        data.append("file", file);
        data.append("upload_preset", "sda7kxdr");
        data.append("cloud_name", "doxi2odzm");
  
        try {
          const response = await fetch(
            "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
            {
              method: "POST",
              body: data,
            }
          );
          if (!response.ok) {
            toast.error("Error uploading image");
            throw new Error("Image upload failed");
          }
          const sendData = await response.json();
          return { name: file.name, url: sendData.secure_url };
        } catch (error) {
          console.error(error);
          return null;
        }
      }));
  
      setFormData((prevData) => ({
        ...prevData,
        [inputName]: [...(Array.isArray(prevData[inputName]) ? prevData[inputName] : []), ...uploadedFiles.filter(Boolean)],
      }));
      
      setUploading(false);
    };
    
    

    
    function handleRemoveFile(index, category) {
      setFormData((prevData) => {
        const newFiles = [...prevData[category]];
        newFiles.splice(index, 1);
        return {
          ...prevData,
          [category]: newFiles,
        };
      });
    }
    
    
    
    
    const handleSponsorChange = (index, e, type) => {
      const { name, value } = e.target;
      const updatedSponsors = [...formData[type]];
      updatedSponsors[index][name] = value;
      setFormData({ ...formData, [type]: updatedSponsors });
    };
  
    const handleAddSponsor = (type) => {
      setFormData({ ...formData, [type]: [...formData[type], { name: '', price: '' }] });
    };
  
    const handleRemoveSponsor = (index, type) => {
      const updatedSponsors = formData[type].filter((_, i) => i !== index);
      setFormData({ ...formData, [type]: updatedSponsors });
    };
  



  
  
    return (
      <section className="bg-white darki:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
          <form onSubmit={handleSubmit}>
          <div className="sm:col-span-2">

            <div className="flex flex-col gap-6">
              
  
  
              <div className="sm:col-span-2">
                <h2 className="text-lg font-medium text-gray-900 darki:text-white">Number of Delegates</h2>
                <div className="flex flex-wrap -mx-2">
                  <div className="flex-grow px-2">
                    <label htmlFor="delegates_local" className="block mb-2 text-sm font-medium text-gray-900 darki:text-white">National/Local</label>
                    <input
                      type="number"
                      name="delegates_local"
                      id="delegates_local"
                      value={formData.delegates_local}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500"
                      placeholder="National/Local"
                      required
                    />
                  </div>
                  <div className="flex-grow px-2">
                    <label htmlFor="delegates_international" className="block mb-2 text-sm font-medium text-gray-900 darki:text-white">delegates_international</label>
                    <input
                      type="number"
                      name="delegates_international"
                      id="delegates_international"
                      value={formData.delegates_international}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500"
                      placeholder="delegates_international"
                      required
                    />
                  </div>
                </div>
              </div>
  
              <div className="sm:col-span-2 h-auto grid">
                <h2 className="text-lg font-medium text-gray-900 darki:text-white">Sponsors</h2>
                {formData.sponsors.map((sponsor, index) => (
                  <div key={index} className="flex items-center mb-4">
                    <input
                      type="text"
                      name="name"
                      value={sponsor.name}
                      onChange={(e) => handleSponsorChange(index, e, 'sponsors')}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 mt-1"
                      placeholder="Name"
                      required
                    />
                    <input
                      type="number"
                      name="price"
                      value={sponsor.price}
                      onChange={(e) => handleSponsorChange(index, e, 'sponsors')}
                      className="bg-gray-50 ml-5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 mt-1"
                      placeholder="Price"
                      required
                    />
                    {formData.sponsors.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveSponsor(index, 'sponsors')}
                        className="ml-5 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 darki:focus:ring-red-800 shadow-lg shadow-red-500/50 darki:shadow-lg darki:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}

                <button 
                  type="button" 
                  onClick={() => handleAddSponsor('sponsors')}
                  class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 darki:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 w-64">
                  Add
                  </button>





                <p className="text-gray-900 darki:text-white">Number of sponsors: {sponsorSummary.count}</p>
                <p className="text-gray-900 darki:text-white">Total price: {sponsorSummary.total}</p>
              </div>

              <div className="sm:col-span-2">
                <h2 className="text-lg font-medium text-gray-900 darki:text-white">In-Kind Sponsors</h2>
                {formData.inKindSponsors.map((sponsor, index) => (
                  <div key={index} className="flex items-center mb-4">
                    <input
                      type="text"
                      name="name"
                      value={sponsor.name}
                      onChange={(e) => handleSponsorChange(index, e, 'inKindSponsors')}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 mt-1"
                      placeholder="Name"
                      required
                    />
                    <input
                      type="number"
                      name="price"
                      value={sponsor.price}
                      onChange={(e) => handleSponsorChange(index, e, 'inKindSponsors')}
                      className="bg-gray-50 ml-5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 mt-1"
                      placeholder="Price"
                      required
                    />
                    {formData.inKindSponsors.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveSponsor(index, 'inKindSponsors')}
                        className="ml-5 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 darki:focus:ring-red-800 shadow-lg shadow-red-500/50 darki:shadow-lg darki:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => handleAddSponsor('inKindSponsors')}
                  class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 darki:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 w-64">
                  Add
                </button>
                <p className="text-gray-900 darki:text-white">Number of in-kind sponsors: {inKindSponsorSummary.count}</p>
                <p className="text-gray-900 darki:text-white">Total price: {inKindSponsorSummary.total}</p>
              </div>



    <div className=" justify-center items-center">
        <div className="rounded-lg flex space-x-4">
          <div className="flex flex-col">
            <label htmlFor="revenue" className="text-gray-700 font-medium">Revenue from Tickets</label>
            <input
              type="number"
              id="revenue"
              name="revenue"
              value={formData.revenue}
              onChange={handleInputChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 mt-1 w-54"
              placeholder="Revenue from Tickets"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="expense" className="text-gray-700 font-medium">Total Expense/Total Cost</label>
            <input
                type="number"
                id="expense"
                name="expense"
                value={formData.expense}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 mt-1 w-54"
                placeholder="Expense"          
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="profit" className="text-gray-700 font-medium">Profit Amount</label>
            <input
                type="number"
                id="profit"
                name="profit"
                value={formData.profit}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 mt-1 w-54"
                placeholder="Profit"        
            />
          </div>
      </div>
    </div> 





  {/* Important Highlights Link/Media */}
  <div>
  <h3 className="text-lg font-medium text-gray-900 darki:text-white">Important Highlights</h3>
  <div className="flex flex-col space-y-4">
    <input
      type="file"
      name="highlightsMedia"
      multiple
      onChange={handleFileChange}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 w-full"
      placeholder="Upload Media"
    />
    {uploading && <div className="loader">Uploading...</div>}


    {formData.highlightsMedia.length > 0 && (
      <div className="bg-gray-100 p-4 rounded-lg shadow-inner space-y-2">
        <h4 className="text-md font-medium text-gray-900 darki:text-white">Uploaded Media Files:</h4>
        <ul className="list-disc list-inside text-gray-700 darki:text-white">
          {formData.highlightsMedia.map((file, index) => (
            <li key={index} className="flex justify-between items-center">
              <span>{file.name}</span>
              <button
                type="button"
                onClick={() => handleRemoveFile(index, 'highlightsMedia')}
                className="text-red-500 hover:text-red-700"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
  </div>





  {/* Feedback */}
    <h3 className="text-lg font-medium text-gray-900 darki:text-white">Feedback (no. of complaints received)</h3>
    <textarea
      name="feedbackComplaints"
      value={formData.feedbackComplaints}
      onChange={handleInputChange}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 w-full"
      placeholder="Feedback"
      rows="3"
    />

  {/* Additional Feedback Sections */}
    <h3 className="text-lg font-medium text-gray-900 darki:text-white">Additional Feedback</h3>
    <div className="space-y-4">
      <textarea
        name="milestones"
        value={formData.milestones}
        onChange={handleInputChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 w-full"
        placeholder="Milestones"
        rows="2"
      />
      <textarea
        name="learnings"
        value={formData.learnings}
        onChange={handleInputChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 w-full"
        placeholder="Key learnings from event experience"
        rows="2"
      />
      <textarea
        name="improvement"
        value={formData.improvement}
        onChange={handleInputChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 w-full"
        placeholder="Areas of improvement"
        rows="2"
      />
      <textarea
        name="otherFeedback"
        value={formData.otherFeedback}
        onChange={handleInputChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 w-full"
        placeholder="Other Feedback"
        rows="2"
      />
    </div>

              


<div>
  <h3 className="text-lg font-medium text-gray-900 darki:text-white">Event Report</h3>
  <div className="flex flex-col space-y-4">
    <input
      type="file"
      name="EventReport"
      multiple
      onChange={handleFileChange}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 w-full"
      placeholder="Upload Gallery Images"
    />

    {uploading && <div className="loader">Uploading...</div>}

    {formData.EventReport.length > 0 && (
      <div className="bg-gray-100 p-4 rounded-lg shadow-inner space-y-2">
        <h4 className="text-md font-medium text-gray-900 darki:text-white">Uploaded Media: </h4>
        <ul className="list-disc list-inside text-gray-700 darki:text-white">
          {formData.EventReport.map((file, index) => (
            <li key={index} className="flex justify-between items-center">
              <span>{file.name}</span>
              <button
                type="button"
                onClick={() => handleRemoveFile(index, 'EventReport')}
                className="text-red-500 hover:text-red-700"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>
    )} 
  </div>
</div>



<div>
  <h3 className="text-lg font-medium text-gray-900 darki:text-white">MoUs with Sponsor</h3>
  <div className="flex flex-col space-y-4">
    <input
      type="file"
      name="MoUswithSponsor"
      multiple
      onChange={handleFileChange}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 darki:bg-gray-700 darki:border-gray-600 darki:placeholder-gray-400 darki:text-white darki:focus:ring-primary-500 darki:focus:border-primary-500 w-full"
      placeholder="Upload Document Images"
    />
    {uploading && <div className="loader">Uploading...</div>}

    {formData.MoUswithSponsor.length > 0 && (
      <div className="bg-gray-100 p-4 rounded-lg shadow-inner space-y-2">
        <h4 className="text-md font-medium text-gray-900 darki:text-white">Uploaded Media:</h4>
        <ul className="list-disc list-inside text-gray-700 darki:text-white">
          {formData.MoUswithSponsor.map((file, index) => (
            <li key={index} className="flex justify-between items-center">
              <span>{file.name}</span>
              <button
                type="button"
                onClick={() => handleRemoveFile(index, 'MoUswithSponsor')}
                className="text-red-500 hover:text-red-700"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
</div>

        

      </div>
        <button
                    type="submit"
                    className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 darki:focus:ring-lime-800 shadow-lg shadow-lime-500/50 darki:shadow-lg darki:shadow-lime-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 w-full mt-10"
                >
                    Submit
        </button>
      </div>

        


          



          </form>
        </div>
      </section>
    );
  };
  
  export default PostEventReport;