import React, { useContext, useState, useEffect } from "react";
import { axiosInstance } from "../Axios";
import SocNav from "./SocNav";
import SocietySideInnerSM from "./SocietySideInnerSM";
import SocietySideInner from "./SocietySideInner";
import AuthContext from "../context/AuthContext";
import { toast, Toaster } from "react-hot-toast";
import ReactModal from "react-modal";

export default function AddAdmin() {
  const [err, setErr] = useState("");
  let { user } = useContext(AuthContext);
  const [formData, setFormData] = useState([
    {
      username: "",
      email: "",
      password: "",
      name: "",
      employeeID: "",
      canCreateSociety: "no",
      is_intern: 0,
    },
  ]);

  const [editFormData, setEditFormData] = useState({
    username: "",
    email: "",
    password: "",
    name: "",
    employeeID: "",
    is_intern: 0,
  });

  const [ccaAdmins, setCcaAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [editEventId, setEditEventId] = useState(null);

  const handleEditClick = (event) => {
    console.warn(event);
    setEditEventId(event.id);
    setShowEditModal(true);
    setSelectedAdmin(event);
  };

  useEffect(() => {
    if (selectedAdmin) {
      const { name, username, email, employeeID, password } = selectedAdmin;
      setEditFormData({
        name,
        username,
        email,
        employeeID,
        is_intern: 0,
        password: "",
      });
    }
  }, [selectedAdmin]);

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Add a new state to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Add a new state to handle the confirmation input
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // Load CCA Admins when the component mounts
    loadCcaAdmins();
  }, []);

  const loadCcaAdmins = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get("get_admins/");
      setCcaAdmins(response.data); // Assuming the response is an array of CCA admins
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    // if (!passwordRegex.test(formData.password)) {
    //   alert(
    //     "Password must be at least 8 characters long and contain at least one uppercase letter and one lowercase letter."
    //   );
    //   return;
    // }
    setLoading(true);
    try {
      const response = await axiosInstance.post("create_admin/", formData);
      setErr("Admin(s) created successfully!");

      // Extract the society name from the form data
      const admin_name = formData[0].name;

      // Post a notification when a society is created, including the society name
      await axiosInstance.post("post_notification_admin_create/", {
        admin_name,
      });

      toast.success("CCA Member Added!", {
        icon: "👏",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
      // Reset the form
      setFormData([
        {
          username: "",
          email: "",
          password: "",
          name: "",
          employeeID: "",
          is_intern: 0,
          canCreateSociety: "no",
        },
      ]);
      loadCcaAdmins();
      // Hide the message after 3 seconds
      // setTimeout(() => {
      //   setErr("");
      // }, 1500);
    } catch (error) {
      toast.error("Error!", {
        icon: "",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
      // setErr(error.response.data);
      console.error("ERROR: ", error);
    } finally {
      setLoading(false);
    }
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleChange = (event, memberIndex) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
  
    setFormData((prevData) => {
      const updatedFormData = [...prevData];
      updatedFormData[memberIndex] = {
        ...updatedFormData[memberIndex],
        [name]: newValue,
      };
      console.warn(`DATA`, updatedFormData)
      return updatedFormData;
    });
  };
  
  

  const handleConfirmChanges = () => {
    handleEdit(editEventId);
    setShowEditModal(false);
  };

  const handleEdit = (user_id) => {
    axiosInstance
      .put("update_admin/", { user_id, data: editFormData })
      .then((response) => {
        loadCcaAdmins();
        toast.success("Admin updated successfully", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((error) => {
        toast.error("Error!", {
          icon: "",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        console.error("ERROR: ", error);
      });
  };

  const addArr = () => {
    setFormData([
      ...formData,
      {
        username: "",
        email: "",
        password: "",
        name: "",
        employeeID: "",
        is_intern: 0,
        canCreateSociety: "no",
      },
    ]);
  };

  const removeArr = (index) => {
    const newData = [...formData];
    newData.splice(index, 1);
    setFormData(newData);
  };

  const handleDeleteAdmin = (adminId) => {
    // Show a prompt for confirmation
    const userConfirmation = prompt(
      "To delete this admin, please type 'DELETE' in capital letters:"
    );

    if (userConfirmation === "DELETE") {
      // Delete the admin if the confirmation is correct
      axiosInstance.put("delete_admin/", { adminId }).then((response) => {
        // Update the admin list after a successful deletion
        setCcaAdmins((prevAdmins) =>
          prevAdmins.filter((admin) => admin.id !== adminId)
        );

        toast.success("Member removed successfully", {
          icon: "✅",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });

        // Temporarily hide the error message
        // setTimeout(() => {
        //   setErr("");
        // }, 1500);
      });
    } else {
      alert("Deletion confirmation incorrect. Admin was not deleted.");
    }
  };

  return (
    <div>
      <div class="absolute w-full bg-primary min-h-75"></div>
      <SocietySideInner />
      {isSidebarVisible && <SocietySideInnerSM />}
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
        <SocNav toggleSidebar={toggleSidebar} />
        <div className="w-full px-6 py-6 mx-auto">
          <div className="min-h-screen flex items-center justify-center">
            <div className="bg-white p-8 rounded shadow-md">
              <div className="mb-4">
                <button
                  onClick={() => setActiveTab(1)}
                  className={`${
                    activeTab === 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300 text-gray-600"
                  } py-2 px-4 mr-4 rounded-full`}
                >
                  All
                </button>
                <button
                  onClick={() => setActiveTab(2)}
                  className={`${
                    activeTab === 2
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300 text-gray-600"
                  } py-2 px-4 rounded-full`}
                >
                  Add Member
                </button>
              </div>
              {activeTab === 1 ? (
                <div>
                  <h2 className="text-2xl font-semibold mb-4">
                    All CCA Team Members
                  </h2>
                  {isLoading ? (
                    <p>Loading...</p>
                  ) : (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ccaAdmins.map((admin) => (
                          <tr key={admin.id}>
                          
                            <td><strong>{admin.name}</strong>
                            <br/>
                            {admin.username}
                            
                            <br/>
                            {(() => {
        if (admin.is_intern===1) {
          return (
            <div className="badge badge-primary">Intern</div>
          )
        }
      })()}
                           
                            </td>
                            <td>{admin.email}</td>
                            <td>
                              {user["role"] === "manager" && (
                                <div>
                                  <button
                                    // onClick={() => handleEditClick(event)}
                                    onClick={() => handleEditClick(admin)}
                                    className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      class="w-5 h-5"
                                    >
                                      <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                                    </svg>
                                  </button>

                                  <button
                                    onClick={() => handleDeleteAdmin(admin.id)}
                                    className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      class="w-5 h-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              ) : (
                //         <div>
                //   <h2 className="text-2xl font-semibold mb-4">Add CCA Team Members</h2>
                //   {formData.map((member, index) => (
                //     <form key={index} onSubmit={handleSubmit}>
                //       <div className="mb-4">
                //         <label htmlFor={`username-${index}`} className="block text-gray-600">
                //           Username:
                //         </label>
                //         <input
                //           type="text"
                //           name={`username-${index}`}
                //           value={member.username}
                //           onChange={(e) => handleChange(e, index)}
                //           className="w-full input input-bordered"
                //           placeholder="eg: john.doe"
                //           required
                //         />
                //         {/* ... (similarly, add other input fields with unique names) */}
                //       </div>
                //       <div className="mt-4 flex flex-row gap-2 justify-start">
                //         {formData.length > 1 && (
                //           <button
                //             className="btn btn-outlined btn-sm font-bold"
                //             onClick={() => removeArr(index)}
                //           >
                //             - Remove
                //           </button>
                //         )}
                //       </div>
                //       {/* ... (submit button for each member, if needed) */}
                //     </form>
                //   ))}
                //   <div className="mt-4 flex flex-row gap-2 justify-start">
                //     <button
                //       className="btn btn-outlined btn-sm font-bold"
                //       onClick={addArr}
                //     >
                //       + Add More
                //     </button>
                //   </div>
                // </div>

                <div>
                  <h2 className="text-2xl font-semibold mb-4">
                    Add CCA Team Members
                  </h2>
                  {formData.map((member, index) => (
                    <form key={index}>
                      <div className="mb-4">
                        <label
                          htmlFor={`username-${index}`}
                          className="block text-gray-600"
                        >
                          Username:
                        </label>
                        <input
                          type="text"
                          name={`username`}
                          value={member.username}
                          onChange={(e) => handleChange(e, index)}
                          className="w-full input input-bordered"
                          placeholder="eg: john.doe"
                          required
                        />

                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-600"
                          >
                            Email:
                          </label>
                          <input
                            type="email"
                            name={`email`}
                            value={member.email}
                            onChange={(e) => handleChange(e, index)}
                            className="w-full input input-bordered"
                            placeholder="eg: email@lums.edu.pk"
                            required
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="password"
                            className="block text-gray-600"
                          >
                            Set Password:
                          </label>
                          <div className="flex">
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={member.password}
                              onChange={(e) => handleChange(e, index)}
                              className="w-full input input-bordered"
                              placeholder="Case sensitive. Must contain Letters, Numbers and Special Chars"
                              required
                            />
                            <button
                              onClick={handleShowPassword}
                              type="button"
                              className="ml-2 px-3 py-1 bg-gray-300 text-gray-600 rounded-full focus:outline-none"
                            >
                              {showPassword ? "Hide" : "Show"}
                            </button>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="password"
                            className="block text-gray-600"
                          >
                            Employee ID:
                          </label>
                          <input
                            type="text"
                            name="employeeID"
                            value={member.employeeID}
                            onChange={(e) => handleChange(e, index)}
                            className="w-full input input-bordered"
                            placeholder="ABC1234"
                            required
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="name" className="block text-gray-600">
                            Name:
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={member.name}
                            onChange={(e) => handleChange(e, index)}
                            className="w-full input input-bordered"
                            placeholder="John Doe"
                            required
                          />
                        </div>

                        <div className="mb-4 flex flex-row items-center justify-between">
  <label htmlFor="intern" className="block text-gray-600 ">
    Check if this user is an intern
  </label>
  <div className="flex items-end">
    <input
      type="checkbox"
      id="intern"
      checked={member.is_intern} // Use member.is_intern to determine checked state
      onChange={(e) => handleChange(e, index)}
      name="is_intern" // Change name to match the state property
      className="checkbox checkbox-primary mt-2 "
    />
    <label htmlFor="intern" className="ml-2 text-gray-700">Yes</label>
  </div>
</div>


                        
                      </div>
                      <div className="mt-4 flex flex-row gap-2 justify-start">
                        {formData.length > 1 && (
                          <button
                            className="btn btn-ghost"
                            onClick={() => removeArr(index)}
                          >
                            - Remove
                          </button>
                        )}
                      </div>
                    </form>
                  ))}
                  <div className="my-4 flex flex-row gap-2 justify-start">
                    <button
                      className="btn btn-ghost "
                      onClick={addArr}
                    >
                      + Add More
                    </button>
                  </div>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full"
                    onClick={handleSubmit}
                  >
                    {loading && (
                      <div className="loader absolute inset-0 flex items-center justify-center">
                        {/* SVG Loader */}
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.22a4 4 0 10-4 0v-.001zM12 20.528a8.001 8.001 0 01-5.657-13.456l1.415 1.414A5.989 5.989 0 0012 10.828v9.7zm6.364-6.364l-1.415-1.414a5.99 5.99 0 00-1.414-1.414l1.414-1.415a7.963 7.963 0 011.415 1.415l-1.415 1.414zM16.472 12H19.7a5.989 5.989 0 00-1.414-1.415l-1.415 1.415zM4.472 12A7.963 7.963 0 015.887 9.586l1.415 1.415A5.989 5.989 0 004.472 12zm8.658 7.172A7.963 7.963 0 0112 19.7v-3.228a4 4 0 104 0v.001z"
                          ></path>
                        </svg>
                        {/* End of SVG Loader */}
                      </div>
                    )}
                    {!loading && "Add Member"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <ReactModal
        className={`w-5/12 h-min m-auto mt-8 rounded-xl bg-white transform transition-all duration-300 ${
          showEditModal ? "opacity-100 scale-100" : "opacity-0 scale-95"
        }`}
        overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-50 transition-opacity duration-300"
        isOpen={showEditModal}
        onRequestClose={() => setShowEditModal(false)}
      >
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Edit Admin</h2>
            <button
              className="btn btn-error btn-circle"
              onClick={() => setShowEditModal(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="mb-4">
            <label htmlFor={`username-${1}`} className="block text-gray-600">
              Username:
            </label>
            <input
              type="text"
              name={`username`}
              value={editFormData?.username}
              onChange={(e) => handleEditChange(e)}
              className="w-full input input-bordered"
              placeholder="eg: john.doe"
              required
            />

            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-600">
                Email:
              </label>
              <input
                type="email"
                name={`email`}
                value={editFormData?.email}
                onChange={(e) => handleEditChange(e)}
                className="w-full input input-bordered"
                placeholder="eg: email@lums.edu.pk"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-600">
                Set Password:
              </label>
              <div className="flex">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={(e) => handleEditChange(e)}
                  className="w-full input input-bordered"
                  placeholder="Case sensitive. Must contain Letters, Numbers and Special Chars"
                  required
                />
                <button
                  onClick={handleShowPassword}
                  type="button"
                  className="ml-2 px-3 py-1 bg-gray-300 text-gray-600 rounded-full focus:outline-none"
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-600">
                Employee ID:
              </label>
              <input
                type="text"
                name="employeeID"
                value={editFormData?.employeeID}
                onChange={(e) => handleEditChange(e)}
                className="w-full input input-bordered"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-600">
                Name:
              </label>
              <input
                type="text"
                name="name"
                value={editFormData?.name}
                onChange={(e) => handleEditChange(e)}
                className="w-full input input-bordered"
                placeholder="John Doe"
                required
              />
            </div>
          </div>
          <button
            onClick={handleConfirmChanges}
            className="btn btn-primary mt-6"
          >
            Confirm Changes
          </button>
        </div>
      </ReactModal>
    </div>
  );
}
