import React, { useState, useEffect, useContext } from "react";

import { Link, Navigate, Redirect } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const Home = () => {
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  let role = "";
  useEffect(() => {
    const getProfile = async () => {
      if (user !== null) {
        let userID = user["user_id"];
        role = user["role"];
        const response = await fetch(`/api/society_profile/${userID}`);
        // parse the data in json
        let data = await response.json();

        setProfile(data);
      }
    };
    getProfile();
  }, [user]);

  //console.log("here", user);
  let userID = user["user_id"];
  if (userID !== undefined) {
    if (user["role"] && user["role"] === "ccaAdmin") {
      window.location.replace(`/admin`);
    } else if (user["role"] && user["role"] === "manager") {
      window.location.replace(`/manager`);
    } else if (user["role"] && user["role"] === "society") {
      //console.log("society");
      window.location.replace(`/society`);
    } else if (user["role"] && user["role"] === "pdc") {
      window.location.replace(`/pdc_dashboard`);
    }
  } else if (user == null) {
    window.location.replace("/login");
  }

  return (
    <div>
      <div className="flex justify-center gap-4 h-screen place-items-center">
        <div className="w-4 h-4 rounded-full animate-pulse bg-blue-800"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-blue-800"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-blue-800"></div>
      </div>
    </div>
  );
};
export default Home;
