import React from 'react';

function Navbar() {
  return (
    <header className="bg-blue-500 p-4 text-white">
      {/* Navbar content */}
      <h1>Dashboard Navbar</h1>
    </header>
  );
}

export default Navbar;