import React, { useEffect } from 'react';
import { axiosInstance } from '../Axios';
import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom

function EventApproval() {
    const { event_id } = useParams(); // Use useParams to get the event_id from the URL parameter

    useEffect(() => {
        // Make a POST request to the Django API to approve the event
        axiosInstance
            .post(`approve-event/${event_id}/`) // Use .post to simplify the request
            .then((response) => {
                if (response.status === 200) {
                    console.log('Event approved successfully');
                } else if (response.status === 404) {
                    console.error('Event not found');
                } else {
                    console.error('Event approval failed');
                }
            })
            .catch((error) => {
                console.error('Error approving event:', error);
            });
    }, [event_id]);

    console.log('Params: ' + event_id);

    return (
        <div>
            <h1>Event Request Received</h1>
        </div>
    );
}

export default EventApproval;
