import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { axiosInstance } from "../Axios";
import SocNav from "../components/SocNav";
import SocietySidebar from "../components/SocietySidebar";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useRef } from "react";
import { useLayoutEffect } from "react";
import AuthContext from "../context/AuthContext";
import { MentionsInput, Mention } from "react-mentions";
import SocietySideInnerSM from "../components/SocietySideInnerSM";
import { toast, Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";
// import Modal from "../components/Modal";
import CustomModal from "../components/Modal";
import Modal from "react-modal";
import AddVenue from "../components/AddVenue";
import EditDetails from "./EditDetails";
import EventLogs from "./EventLogs";
import CryptoJS from "crypto-js";
import PostEventReport from "./PostEventReport";
import EventReportTable from "./EventReportTable";

const EventDetails = () => {
  // const location = useLocation();
  // const { event } = location.state || {};
  let { user } = useContext(AuthContext);

  // const { event } = location.state;

  const { id } = useParams();
  const key = 20032024;
  const decryptedId = decodeURIComponent(
    CryptoJS.AES.decrypt(id.toString(), key.toString()).toString(
      CryptoJS.enc.Utf8
    )
  );
  console.warn("DECRYPTED ID----", decryptedId);

  const [event, setEvent] = useState(null);
  const [eventRepStatus, setEventRepStatus] = useState(false);
  const [PER_Data, setPER_Data] = useState(false);

  const getEvent = async () => {
    axiosInstance
      .get(`get_event/${decryptedId}/`)
      .then((response) => {
        console.log(response.data);
        setEvent(response.data);
      })
      .catch((error) => {
        console.error("Error getting event details:", error);
      });
  };


  const fetchEventReport = async () => {
    try {
      const response = await axiosInstance.get(`get_event_report/${event.id}/`); // Await the response
      let data = response.data;
      setEventRepStatus(true);
      setPER_Data(data); // Update the state with the response data
    } catch (error) {
      console.error('Error fetching the event report:', error);
      setEventRepStatus(false);
    }
  };

  useEffect(() => {
    getEvent();
  }, [decryptedId]);

  useEffect(() => {
    if (event && event.id) {
      fetchEventReport(event.id);
    }
  }, [event]);


  console.warn("EVENT----", event);

  let USERID = user["user_id"];

  const [societyProfile, setSocietyProfile] = useState(null);
  const [adminProfile, setadminProfile] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [timelineModal, settimelineModal] = useState(false);
  const [eventId, setEventId] = useState(null);
  const openEditModal = () => {
    setEditModal(true);
  };
  const closeEditModal = () => {
    setEditModal(false);
  };

  const openTimelineModal = () => {
    settimelineModal(true);
  };

  const closeTimelineModal = () => {
    settimelineModal(false);
  };

  const openAddModal = () => {
    setModalIsOpen(true);
  };

  const closeAddModal = () => {
    setModalIsOpen(false);
  };

  const [type, setType] = useState("");

  const handleDelete = (index, type) => {
    axiosInstance
      .post("delete_items/", { type: type, id: index, event_id: event?.id }) // Ensure `type` and `id` are sent in the request body
      .then((response) => {
        console.log(response.data);
        toast.success(`${type} Deleted Successfully`);
        getEvent();
        // props.history.push("/events");
      })
      .catch((error) => {
        console.error("Error deleting event:", error);
        toast.error(`${type} deleting event`);
      });
  };

  useEffect(() => {
    axiosInstance
      .get(`society/profile/${USERID}/`)
      .then((response) => {
        setSocietyProfile(response.data);
        console.log("ID---", USERID);
      })
      .catch((error) => {
        console.error("Error fetching society profile:", error);
        console.log("ID---", event);
      });
  }, [USERID]);

  useEffect(() => {
    axiosInstance
      .get(`admin/profile/${USERID}/`)
      .then((response) => {
        setadminProfile(response.data);
        console.log("ID---", USERID);
      })
      .catch((error) => {
        console.error("Error fetching admin profile:", error);
        console.log("ID---", event);
      });
  }, [USERID]);

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [status, setStatus] = useState(event?.status);
  const [category, setCategory] = useState("all");
  const [openEscaltePopUp, setOpenEscaltePopUp] = useState(false);
  const [openEscaltePopUpGuest, setOpenEscaltePopUpGuest] = useState(false);
  const [procId, setProcId] = useState();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailModalIndex, setEmailModalIndex] = useState(null);
  const [isEmailModalOpenOutSideVenu, setIsEmailModalOpenOutSideVenu] =
    useState(false);
  const [emailModalOutSideVenuIndex, setEmailModalOutSideVenuIndex] =
    useState(null);
  const [isEmailModalOpen1, setIsEmailModalOpen1] = useState(false);
  const [emailModalIndex1, setEmailModalIndex1] = useState(null);
  const [isEmailModalOpen2, setIsEmailModalOpen2] = useState(false);
  const [emailModalIndex2, setEmailModalIndex2] = useState(null);
  const [serviceName, setServiceName] = useState("");
  useEffect(() => {
    axiosInstance.get(`event_comments/${event?.id}`).then((res) => {
      setComments(res.data);
    });
  }, []);

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`event_comments/${event?.id}`, {
        comment: newComment,
        category,
      })
      .then((res) => {
        axiosInstance.get(`event_comments/${event?.id}`).then((res) => {
          setComments(res.data);
        });

        if (user["role"] === "society") {
          let postedFor = societyProfile?.ccaadmins;
          let eventLabel = event?.event_name;
          let societyName = event?.society_name;
          axiosInstance.post("post_notification_comment/", {
            eventLabel,
            postedFor,
            societyName,
          });
        } else if (user["role"] === "ccaAdmin" || user["role"] === "manager") {
          let eventLabel = event?.event_name;
          let societyName = event?.society_name;
          axiosInstance.post("post_notification_comment/", {
            eventLabel,
            societyName,
          });
        }

        toast.success("Comment Posted", {
          icon: "📨",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  const handleCommentChange = (event, newValue) => {
    setNewComment(newValue);
  };

  const openModal = () => {
    setOpenEscaltePopUp(true);
  };

  const closeModal = () => {
    setOpenEscaltePopUp(false);
    setRecipientEmailForPro("");
  };

  const openGuestModal = () => {
    setOpenEscaltePopUpGuest(true);
  };

  const closeGuestModal = () => {
    setOpenEscaltePopUpGuest(false);
    setRecipientEmailForGuest("");
  };

  const openAudiModal = () => {
    setIsEmailModalOpen(true);
  };

  const closeAudiModal = () => {
    setIsEmailModalOpen(false);
    setEmailModalIndex(null);
  };
  const mentionableItems = [
    { id: 1, display: "Applicant", url: "ggg" },
    { id: 2, display: "Request", url: "ggg" },
    { id: 3, display: "Venues", url: "ggg" },
    { id: 4, display: "Guests/Vigilance", url: "ggg" },
    { id: 5, display: "Procurements", url: "ggg" },
    { id: 6, display: "Services", url: "ggg" },
  ];

  const handleEventRequest = (status) => {
    axiosInstance
      .put(`event_approval/`, {
        status: status,
        event_id: event?.id,
      })
      .then((res) => {
        if (res.data === "Event status changed") {
          setStatus(status);
        }

        let societyName = event?.society_name;
        let eventLabel = event?.event_name;
        // axiosInstance.post("post_notification_event/", {
        //   status,
        //   societyName,
        //   eventLabel,
        // });
        // setStatus(status);

        toast.success("Approval Status Changed", {
          icon: "✅",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        // window.location.reload();
      });
  };

  const localStorageKey_Venue = "approvalStatus_venue";
  const localStorageKey_Audit = "approvalStatus_audit";

  const initialApprovalStatus_Venue =
    JSON.parse(localStorage.getItem(localStorageKey_Venue)) ||
    (event?.venues ? Array(event?.venues.length).fill(null) : []);
  const initialApprovalStatus_Audit =
    JSON.parse(localStorage.getItem(localStorageKey_Audit)) ||
    (event?.auditoriums ? Array(event?.auditoriums.length).fill(null) : []);

  const [approvalStatus_Venue, setApprovalStatus_Venue] = useState(
    initialApprovalStatus_Venue
  );
  const [approvalStatus_Audit, setApprovalStatus_Audit] = useState(
    initialApprovalStatus_Audit
  );

  useEffect(() => {
    localStorage.setItem(
      localStorageKey_Venue,
      JSON.stringify(approvalStatus_Venue)
    );
  }, [approvalStatus_Venue]);

  useEffect(() => {
    localStorage.setItem(
      localStorageKey_Audit,
      JSON.stringify(approvalStatus_Audit)
    );
  }, [approvalStatus_Audit]);

  console.log("LOCAL: " + localStorageKey_Audit);
  console.log("Initial: " + initialApprovalStatus_Audit);
  console.log("status: " + approvalStatus_Audit);

  const handleApprove = (type, index) => {
    axiosInstance
      .put(`event_service_approval/`, {
        approve: true,
        id: index,
        type: type,
        event_id: event?.id,
      })

      .then((res) => {
        // Update the approval status based on the type
        if (type === "venue") {
          const newStatus = [...approvalStatus_Venue];
          newStatus[index] = "Approved";
          setApprovalStatus_Venue(newStatus);
        } else if (type === "auditorium") {
          const newStatus = [...approvalStatus_Audit];
          newStatus[index] = "Approved";
          setApprovalStatus_Audit(newStatus);
        }

        let statusTemp = "Approved";
        let societyName = event?.society_name;
        let eventLabel = event?.event_name;
        let reqType = type;
        axiosInstance.post("post_notification_event_service_req/", {
          societyName,
          eventLabel,
          statusTemp,
          reqType,
        });

        console.log("Type:", type);
        console.log("Index:", index);

        toast.success("Approval Status Changed", {
          icon: "✅",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((err) => {
        toast.error("Something went wrong. Try opening this page again", {
          icon: "🔃",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  const handleReject = (type, index) => {
    axiosInstance
      .put(`event_service_approval/`, {
        approve: false,
        id: index,
        type: type,
        event_id: event?.id,
      })
      .then((res) => {
        // Update the approval status based on the type
        if (type === "venue") {
          const newStatus = [...approvalStatus_Venue];
          newStatus[index] = "Rejected";
          setApprovalStatus_Venue(newStatus);
        } else if (type === "auditorium") {
          const newStatus = [...approvalStatus_Audit];
          newStatus[index] = "Rejected";
          setApprovalStatus_Audit(newStatus);
        }

        let statusTemp = "Rejected";
        let societyName = event?.society_name;
        let eventLabel = event?.event_name;
        let reqType = type;
        axiosInstance.post("post_notification_event_service_req/", {
          societyName,
          eventLabel,
          statusTemp,
          reqType,
        });
        toast.success("Approval Status Changed", {
          icon: "✅",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((err) => {
        // Handle errors
      });
  };

  const handleEscalate = (purchaseId) => {
    setOpenEscaltePopUp(true);
    setProcId(purchaseId);
  };

  const handleEscalateGuest = (Id) => {
    setOpenEscaltePopUpGuest(true);
  };

  const ExportButton = () => {
    const baseUrl = "";
    const updatedData = event?.all_purchases?.map((item) => ({
      ...item,
      file: item?.file ? baseUrl + item.file : " ",
      // file: baseUrl + (item?.file || ""),
    }));
    exportToExcel(updatedData);
  };

  const ExportEventButton = () => {
    if (!event) {
      console.error("Event data is null or undefined");
      return null;
    }

    // Convert nested object values to strings and include them in the 'event' object
    const eventWithNestedObjects = {
      ...event,
      all_guests: JSON.stringify(event?.all_guests),
      all_purchases: JSON.stringify(event?.all_purchases),
      venues: JSON.stringify(event?.venues),
      auditoriums: JSON.stringify(event?.auditoriums),
    };

    // Export the updated 'event' object with nested objects as strings
    exportToExcel([eventWithNestedObjects]);
    return null;
  };

  const exportToExcel = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error("Invalid data format");
      return;
    }

    // Convert the data to a worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a workbook with a single sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the workbook as an Excel file
    XLSX.writeFile(wb, "exported_data.xlsx");
  };

  // const exportToExcel = (data) => {
  //   if (!data || !Array.isArray(data) || data.length === 0) {
  //     console.error("Invalid or empty data received for export");
  //     return; // Or any other suitable action based on your requirements
  //   }

  //   // Extract headers (column names) from the first object in the array
  //   const headers = Object.keys(data[0]);

  //   // Prepare an array of arrays for each row of data
  //   const rows = data.map((item) => {
  //     return headers.map((header) => item[header]);
  //   });

  //   // Insert headers at the beginning of the rows array
  //   rows.unshift(headers);

  //   // Create a worksheet from the rows array
  //   const ws = XLSX.utils.aoa_to_sheet(rows);

  //   // Create a workbook and append the worksheet
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   // Save the workbook as an Excel file
  //   XLSX.writeFile(wb, "exported_data.xlsx");
  // };

  const scrollableRef = useRef(null);

  useLayoutEffect(() => {
    // Scroll to the bottom of the div when comments are added or updated
    scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
  }, [comments]);

  const handleMentionClick = (id, display, url) => {
    //const mention = `<a href=${url}>${display}</a>`;
    const mention = `${display}`;
    setNewComment((prevComment) => prevComment + mention + " ");
  };

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleUsernameClick = (clickedUsername) => {
    // console.log("Selected: " + clickedUsername);

    if (clickedUsername === "Applicant") {
      setSelectedTabIndex(0);
    } else if (clickedUsername === "Request") {
      setSelectedTabIndex(1);
    } else if (clickedUsername === "Venues") {
      setSelectedTabIndex(2);
    } else if (clickedUsername === "Guests/Vigilance") {
      setSelectedTabIndex(3);
    } else if (clickedUsername === "Procurements") {
      setSelectedTabIndex(4);
    } else if (clickedUsername === "Services") {
      setSelectedTabIndex(5);
    }
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState("");
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [recipientEmailForPro, setRecipientEmailForPro] = useState("");
  const [recipientEmailForGuest, setRecipientEmailForGuest] = useState("");

  const [emailPopups, setEmailPopups] = useState({});

  const openEmailPopup = (purchaseId) => {
    setEmailPopups((prevState) => ({
      ...prevState,
      [purchaseId]: true,
    }));
  };

  const closeEmailPopup = (purchaseId) => {
    setEmailPopups((prevState) => ({
      ...prevState,
      [purchaseId]: false,
    }));
  };

  const handleSubmitEmail = (purchaseId, type) => {
    setShowEmailPopup(false);
    handleSendEmail(recipientEmail, purchaseId, type);
  };

  const handleProcumentEmailSend = (recipientEmailForPro, procId, type) => {
    handleSendEmail(recipientEmailForPro, procId, "special_purchases");
  };
  const handleGuestEmailSend = (recipientEmailForGuest, guestId, type) => {
    handleSendEmail(recipientEmailForGuest, guestId, type);
  };

  const handleSendEmail = (recipientEmail, id, type) => {
    const payload = {
      email: recipientEmail,
      id: id,
      type: type,
      event_id: event?.id,
    };

    axiosInstance
      .post("send_purchase_details_email/", payload)
      .then((response) => {
        if (response.status === 200) {
          console.log(
            "Email sent successfully to: " +
              recipientEmail +
              "Cat: " +
              category +
              "ID: " +
              id
          );

          let authority = recipientEmail;
          let societyName = event?.society_name;
          let eventLabel = event?.event_name;
          let reqType = "Procurement";
          axiosInstance.post("post_notification_esclate_req/", {
            societyName,
            eventLabel,
            authority,
            reqType,
          });
          toast.success("Email sent successfully!", {
            icon: "✅",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
          closeModal();
          closeGuestModal();
        } else {
          console.error("Error sending email.");
          // Handle errors or show an error message to the user
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [venueIndex, setVenueIndex] = useState("");

  // Define the keywords to filter by
  const keywordsToFilter = [
    "Applicant",
    "Request",
    "Venue",
    "Guests/Vigilance",
    "Procurement",
    "Services",
  ];

  // Handler to toggle selected keywords
  const handleKeywordClick = (keyword) => {
    setSelectedKeywords((prevSelected) => {
      if (prevSelected.includes(keyword)) {
        return prevSelected.filter((k) => k !== keyword);
      } else {
        return [...prevSelected, keyword];
      }
    });
  };

  // Clear the filter and show all comments
  const handleShowAllClick = () => {
    setSelectedKeywords([]);
  };

  const [isFormSubmit, setIsFormSubmit] = useState(event?.form_status);
  useEffect(() => {
    // Update isFormSubmit when event?.form_status changes
    setIsFormSubmit(event?.form_status);
  }, [event?.form_status]);
  const handleFormSubmit = () => {
    axiosInstance
      .post(`submit_event/${event?.id}/`)
      .then((response) => {
        toast.success("Form submitted successfully!");
        setIsFormSubmit(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Error submitting form!");
      });
  };

  // Filter comments based on selected keywords

  const filteredComments =
    comments && Array.isArray(comments)
      ? comments.filter((comment) => {
          if (selectedKeywords.length === 0) {
            return true; // Show all comments if no keywords selected
          } else {
            return selectedKeywords.some((keyword) =>
              comment.comment.includes(keyword)
            );
          }
        })
      : [];

  const [modal, setModal] = useState(false);

let is_intern =0;



const [postEventModal, setpostEventModal] = useState(false);


const openPERModal = () => {
  setpostEventModal(true);
};

const closePERModal = () => {
  setpostEventModal(false);
};




  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-primary  min-h-75"></div>

            <SocietySidebar />

            {isSidebarVisible && <SocietySideInnerSM />}

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
              {/* Navbar */}
              <SocNav toggleSidebar={toggleSidebar} />

              {/* end Navbar */}
              {/* cards */}
              <div className="w-full px-6 py-6 mx-auto">
                <section className="py-6 mx-5 px-5 rounded-lg mt-4 bg-white text-gray-900">
                  <div className="container mx-auto flex flex-col justify-between p-4 text-center lg:flex-row">
                    <div className="flex flex-col justify-center lg:text-left">
                      <p className="mb-1 text-sm font-medium tracki uppercase text-violet-600">
                        {event?.type} REQUEST
                      </p>
                      <h1 className="py-2 text-3xl font-medium leadi title-font">
                        {event?.event_name}{" "}
                        {/* <Link to="/export" state={{ event: event?.event_data }}>
                        <small className="text-sm badge badge-primary ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                            />
                          </svg>
                          Export Data
                        </small>{" "}
                      </Link> */}
                        <button
                          className="btn btn-sm btn-ghost mb-8"
                          onClick={ExportEventButton}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                            />
                          </svg>
                          Export event
                        </button>
                        <button
                          className="btn btn-sm btn-ghost mb-8"
                          onClick={openTimelineModal}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                          Timeline
                        </button>
                      </h1>
                    </div>

                    <div className="flex flex-row items-center gap-4 flex-wrap">
                      <div className="flex flex-col items-center justify-center flex-shrink-0 mt-6 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 lg:ml-4 lg:mt-0 lg:justify-end">
                        <p className="flex flex-row gap-2 items-center m-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          {moment(event?.request_date).fromNow()}{" "}
                        </p>

                        {(() => {
                          if (user[`role`] === "society") {
                            return (
                              <>
                                {isFormSubmit === true ? (
                                  <p
                                    className={`text-lg font-medium 
  ${
    status === "Reviewing"
      ? "text-yellow-500"
      : status === "Approved"
      ? "text-green-500"
      : status === "Rejected"
      ? "text-red-400"
      : "text-violet-600 text-gray-50"
  }`}
                                  >
                                    {status}
                                  </p>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleFormSubmit}
                                  >
                                    Complete Submission
                                  </button>
                                )}
                              </>
                            );
                          } else {
                            return (
                              <>
                                <p
                                  className={`text-lg font-medium 
  ${
    status === "Reviewing"
      ? "text-yellow-500"
      : status === "Approved"
      ? "text-green-500"
      : status === "Rejected"
      ? "text-red-400"
      : "text-violet-600 text-gray-50"
  }`}
                                >
                                  {status}
                                </p>
                              </>
                            );
                          }
                        })()}
                      </div>

                      {(user["role"] === "ccaAdmin" &&
                        is_intern === 0) ||
                      user["role"] === "manager" ? (
                        <div className="flex flex-row items-center gap-4 border-l-2 pl-4">
                          <button
                            className="btn btn-success"
                            onClick={() => handleEventRequest("Approved")}
                          >
                            {/* SVG for Approve */}
                            Approve
                          </button>
                          <button
                            className="btn btn-error"
                            onClick={() => handleEventRequest("Rejected")}
                          >
                            {/* SVG for Reject */}
                            Reject
                          </button>
                        </div>
                      ) : user["role"] === "society" ? (
                        <div></div>
                      ) : null}



{eventRepStatus? (<button className="btn btn-primary" onClick={openPERModal}>View Post Event Report</button>):(
  <button className="btn btn-primary" onClick={openPERModal}>+ Add Post Event Report</button>

)}

{postEventModal? (
  <Modal

  isOpen={openPERModal}

        onRequestClose={closePERModal}
      >
      {eventRepStatus?(<EventReportTable eventReport={PER_Data}/>):(<PostEventReport event={event.id}/>)}
      
      
      </Modal>
):null}
                    </div>
                  </div>
                </section>

                <div className="flex flex-wrap mt-6 -mx-3 bg-white rounded-lg">
                  <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-5/12 lg:flex-none">
                    <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
                      <div className="p-4 pb-0 mb-0 rounded-t-4">
                        <div className="flex justify-between">
                          <h6 className="mb-2 ">Comments</h6>
                        </div>
                      </div>

                      <div className="h-max">
                        <Tabs className="p-4">
                          <TabList
                            className="w-full flex flex-row items-center "
                            style={{ overflowX: "scroll" }}
                          >
                            <Tab value="all" className="hidden" onClick={() => setCategory("all")}>
                              General
                            </Tab>
                     
                          </TabList>

                          <TabPanel>
                            <div
                              className="h-96"
                              style={{ overflowY: "scroll" }}
                              ref={scrollableRef}
                            >
                              <div>
                                <div
                                  className="flex flex-row gap-2 pb-4"
                                  style={{ overflowX: "scroll" }}
                                >
                                  <button
                                    className="btn btn-sm btn-pill rounded-full bg-indigo-300"
                                    onClick={handleShowAllClick}
                                  >
                                    Show All
                                  </button>
                                  {keywordsToFilter.map((keyword) => (
                                    <button
                                      key={keyword}
                                      className={`btn btn-sm btn-pill rounded-full ${
                                        selectedKeywords.includes(keyword)
                                          ? "activePill"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleKeywordClick(keyword)
                                      }
                                    >
                                      {keyword}
                                    </button>
                                  ))}
                                </div>

                                {filteredComments.map((comment, index) => (
                                  <div
                                    className="bg-gray-100 p-2 rounded-lg mx-2 my-4"
                                    key={index}
                                  >
                                    <h4 className="text-xs">
                                      <span className="text-primary font-bold">
                                        {comment.username}
                                      </span>{" "}
                                      • {moment(comment.time).fromNow()}
                                    </h4>
                                    <p className="mt-2">
                                      {comment.comment
                                        .split(" ")
                                        .map((word, wordIndex) => {
                                          if (word.startsWith("@")) {
                                            return (
                                              <span
                                                key={wordIndex}
                                                className="text-primary font-bold"
                                                onClick={() =>
                                                  handleUsernameClick(
                                                    word.substring(1)
                                                  )
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {word}{" "}
                                              </span>
                                            );
                                          }
                                          return (
                                            <span key={wordIndex}>{word} </span>
                                          );
                                        })}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </TabPanel>

                          <TabPanel>
                            <div
                              className="h-96"
                              style={{ overflowY: "scroll" }}
                              ref={scrollableRef}
                            >
                              {comments &&
                                comments
                                  .filter(
                                    (comment) => comment?.category === "pdc"
                                  )
                                  .map((comment, index) => (
                                    <div
                                      className="bg-gray-100 p-2 rounded-lg mx-2 my-4"
                                      key={index}
                                    >
                                      <h4 className="text-xs">
                                        <span className="text-primary font-bold">
                                          {comment.username}
                                        </span>{" "}
                                        • {moment(comment.time).fromNow()}
                                      </h4>
                                      <p className="mt-2">
                                        {comment.comment
                                          .split(" ")
                                          .map((word, wordIndex) => {
                                            if (word.startsWith("@")) {
                                              return (
                                                <span
                                                  key={wordIndex}
                                                  className="text-primary font-bold"
                                                  onClick={() =>
                                                    handleUsernameClick(
                                                      word.substring(1)
                                                    )
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {word}{" "}
                                                </span>
                                              );
                                            }
                                            return (
                                              <span key={wordIndex}>
                                                {word}{" "}
                                              </span>
                                            );
                                          })}
                                      </p>
                                    </div>
                                  ))}
                            </div>
                          </TabPanel>

                          <TabPanel> Working on it... </TabPanel>
                          <TabPanel> Working on it... </TabPanel>
                          <TabPanel> Working on it... </TabPanel>
                          <TabPanel> Working on it... </TabPanel>
                          <TabPanel> Working on it... </TabPanel>
                        </Tabs>

                        <form
                          onSubmit={handleCommentSubmit}
                          className="mt-4 border-t-2 p-8 "
                        >
                          <div className="mb-4">
                            <MentionsInput
                              value={newComment}
                              onChange={handleCommentChange}
                              placeholder="Add new comment"
                              className="textarea textarea-bordered h-20 p-8 w-full"
                            >
                              <Mention
                                markup="@__display__ "
                                trigger="@"
                                data={mentionableItems}
                                renderSuggestion={(
                                  suggestion,
                                  search,
                                  highlightedDisplay
                                ) => (
                                  <div
                                    onClick={() =>
                                      handleMentionClick(
                                        suggestion.id,
                                        suggestion.display,
                                        suggestion.url
                                      )
                                    }
                                  >
                                    {highlightedDisplay}
                                  </div>
                                )}
                                renderSuggestionList={(suggestions) => (
                                  <ul className="mention-suggestion-list p-4">
                                    {suggestions}
                                  </ul>
                                )}
                              />
                            </MentionsInput>
                          </div>
                          <button
                            className="btn bg-primary text-white mt-4"
                            type="submit"
                          >
                            Add Comment{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 ml-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                              />
                            </svg>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="w-full max-w-full px-3 mt-0  lg:w-7/12 lg:flex-none">
                    <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                      <div className="p-4 pb-0 rounded-t-4">
                        <h6 className="mb-0 ">Request Details</h6>
                      </div>

                      <Tabs
                        selectedIndex={selectedTabIndex}
                        onSelect={(index) => setSelectedTabIndex(index)}
                        className="p-6"
                      >
                        <TabList>
                          <Tab>Applicant</Tab>
                          <Tab>Request</Tab>
                          <Tab>Venues</Tab>
                          <Tab>Guests</Tab>
                          <Tab>Procurements</Tab>
                          <Tab>Services</Tab>
                        </TabList>

                        <TabPanel>
                          <div className="">
                            <div className="flow-root rounded-lg border border-gray-100 py-3 shadow-sm">
                              <dl className="-my-3 divide-y divide-gray-100 text-sm">
                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    Applicant Name
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2">
                                    {event?.applicant_name}{" "}
                                  </dd>
                                </div>

                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    Contact Details
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2 flex flex-row gap-4 items-center">
                                    <div className="flex flex-row gap-2 items-center">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-4 h-4"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                        />
                                      </svg>

                                      {event?.applicant_email}
                                    </div>{" "}
                                    •
                                    <div className="flex flex-row gap-2 items-center">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-4 h-4"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                        />
                                      </svg>

                                      {event?.applicant_contact}
                                    </div>
                                  </dd>
                                </div>

                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    Applicant Designation
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2">
                                    {event?.applicant_designation}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="">
                            <div className="flow-root rounded-lg border border-gray-100 py-3 shadow-sm">
                              <dl className="-my-3 divide-y divide-gray-100 text-sm">
                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    Request Type
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2">
                                    {event?.type}{" "}
                                  </dd>
                                </div>

                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    Request Title
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2">
                                    {event?.event_name} •{" "}
                                    <span className="badge badge-primary">
                                      {" "}
                                      {event?.status}{" "}
                                    </span>
                                  </dd>
                                </div>

                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    Posted
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2">
                                    {moment(event?.request_time).format(
                                      "MMMM DD, YYYY h:mm A"
                                    )}
                                  </dd>
                                </div>

                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    Starts at
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2">
                                    {moment(event?.start_date).format(
                                      "MMMM DD, YYYY h:mm A"
                                    )}
                                  </dd>
                                </div>

                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    Ends at
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2">
                                    {moment(event?.end_date).format(
                                      "MMMM DD, YYYY h:mm A"
                                    )}
                                  </dd>
                                </div>
                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    IST Portal Payment
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2">
                                    {event?.portal_required ? ('Requried'):(`Not Required`)}{" "}
                                  </dd>
                                </div>
                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    Event details
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2">
                                    {event?.event_description}{" "}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="">
                            <div className="flow-root rounded-lg border border-gray-100 py-3 shadow-sm">
                              <dl className="-my-3 divide-y divide-gray-100 text-sm">
                                {event?.all_auditoriums &&
                                  event?.all_auditoriums.length > 0 && (
                                    <div>
                                      <h4 className="my-4"> Auditoriums </h4>

                                      <div className="min-w-full divide-y divide-gray-200 overflow-x-auto mt-4">
                                        <table className="min-w-full divide-y divide-gray-300">
                                          <thead>
                                            <tr>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Venue
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Capacity
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Date
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Time
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Status
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Actions
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="bg-white divide-y divide-gray-300">
                                            {event?.all_auditoriums?.map(
                                              (aud, index) => (
                                                <tr key={index}>
                                                  <td className="px-6 py-4 whitespace-nowrap">
                                                    {aud.venue}
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap">
                                                    {aud.capacity}
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap">
                                                    {aud.date}
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap">
                                                    {aud.time}
                                                  </td>

                                                  <td>
                                                    {aud.approval === true ? (
                                                      <p className="text-success">
                                                        Approved{" "}
                                                      </p>
                                                    ) : aud.approval ===
                                                      false ? (
                                                      <p className="text-error">
                                                        Rejected{" "}
                                                      </p>
                                                    ) : (
                                                      <p className="text-warning">
                                                        Pending{" "}
                                                      </p>
                                                    )}
                                                  </td>
                                                  {(user["role"] ===
                                                    "ccaAdmin" &&
                                                    is_intern ===
                                                      0) ||
                                                  user["role"] === "manager" ||
                                                  user["role"] === "society" ? (
                                                    <div className="flex flex-row items-center gap-4 border-l-2 pl-4">
                                                      <button
                                                        className="btn btn-sm btn-error"
                                                        onClick={() => {
                                                          handleDelete(
                                                            aud.id,
                                                            "venue"
                                                          );
                                                        }}
                                                      >
                                                        Delete
                                                      </button>

                                                      <button
                                                        className="btn btn-sm btn-secondary"
                                                        onClick={() => {
                                                          openEditModal();
                                                          setType("auditorium");
                                                          setEventId(aud.id);
                                                        }}
                                                      >
                                                        Edit
                                                      </button>
                                                    </div>
                                                  ) : user["role"] ===
                                                    "society" ? (
                                                    <div></div>
                                                  ) : null}

                                                  <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    {(user["role"] ===
                                                      "ccaAdmin" &&
                                                      is_intern ===
                                                        0) ||
                                                    user["role"] ===
                                                      "manager" ? (
                                                      <>
                                                        <button
                                                          className="btn btn-sm btn-success mx-1"
                                                          onClick={() =>
                                                            handleApprove(
                                                              "auditorium",
                                                              index
                                                            )
                                                          }
                                                        >
                                                          Approve
                                                        </button>
                                                        <button
                                                          className="btn btn-sm btn-error mx-1"
                                                          onClick={() =>
                                                            handleReject(
                                                              "auditorium",
                                                              index
                                                            )
                                                          }
                                                        >
                                                          Reject
                                                        </button>
                                                        <button
                                                          className="btn btn-sm btn-primary"
                                                          onClick={() => {
                                                            openEmailPopup(
                                                              event?.id
                                                            );
                                                            setVenueIndex(
                                                              index
                                                            );
                                                            setIsEmailModalOpen(
                                                              true
                                                            );
                                                            setEmailModalIndex(
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          Escalate
                                                        </button>
                                                      </>
                                                    ) : null}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>

                                      <CustomModal
                                        isOpen={
                                          isEmailModalOpen &&
                                          emailModalIndex !== null
                                        }
                                        onRequestClose={() => {
                                          setIsEmailModalOpen(false);
                                          setEmailModalIndex(null);
                                          setRecipientEmail("");
                                        }}
                                        title="Auditorium Email Popup"
                                      >
                                        <div className="w-full flex items-center gap-2">
                                          <input
                                            type="email"
                                            className="input input-bordered"
                                            placeholder="Recipient Email"
                                            value={recipientEmail}
                                            onChange={(e) =>
                                              setRecipientEmail(e.target.value)
                                            }
                                          />
                                          <button
                                            onClick={() => {
                                              handleSubmitEmail(
                                                venueIndex,
                                                "Auditorium"
                                              );
                                              setIsEmailModalOpen(false);
                                              setEmailModalIndex(null);
                                              setRecipientEmail("");
                                            }}
                                            className="btn btn-primary "
                                          >
                                            Send
                                          </button>
                                        </div>
                                      </CustomModal>
                                    </div>
                                  )}

                                {(user["role"] === "ccaAdmin" &&
                                  is_intern === 0) ||
                                user["role"] === "manager" ||
                                user["role"] === "society" ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        openAddModal();
                                        setType("auditorium");
                                      }}
                                    >
                                      Add Auditorium
                                    </button>
                                  </>
                                ) : null}

                                {event?.all_venues &&
                                  event?.all_venues.length > 0 && (
                                    <>
                                      <h4 className="my-4"> Outside Venues </h4>

                                      <div className="min-w-full divide-y divide-gray-200 overflow-x-auto mt-4">
                                        <table className="min-w-full divide-y divide-gray-300">
                                          <thead>
                                            <tr>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Venue
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Capacity
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Date
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Time
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Activity
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Status
                                              </th>
                                              <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Actions
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="bg-white divide-y divide-gray-300">
                                            {event?.all_venues?.map(
                                              (venue_os, index) => (
                                                <tr key={index}>
                                                  <td className="px-6 py-4 whitespace-nowrap">
                                                    {venue_os.venue}
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap">
                                                    {venue_os.capacity}
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap">
                                                    {venue_os.date}
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap">
                                                    {venue_os.time}
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap">
                                                    {venue_os.activity}
                                                  </td>

                                                  <td>
                                                    {venue_os.approval ===
                                                    true ? (
                                                      <p className="text-success">
                                                        Approved{" "}
                                                      </p>
                                                    ) : venue_os.approval ===
                                                      false ? (
                                                      <p className="text-error">
                                                        Rejected{" "}
                                                      </p>
                                                    ) : (
                                                      <p className="text-warning">
                                                        Pending{" "}
                                                      </p>
                                                    )}
                                                  </td>

                                                  {(user["role"] ===
                                                    "ccaAdmin" &&
                                                    is_intern ===
                                                      0) ||
                                                  user["role"] === "manager" ||
                                                  user["role"] === "society" ? (
                                                    <>
                                                      <button
                                                        className="btn btn-sm btn-error"
                                                        onClick={() => {
                                                          handleDelete(
                                                            venue_os.id,
                                                            "venue"
                                                          );
                                                        }}
                                                      >
                                                        Delete
                                                      </button>

                                                      <button
                                                        className="btn btn-sm btn-secondary"
                                                        onClick={() => {
                                                          openEditModal();
                                                          setType("venue");
                                                          setEventId(
                                                            venue_os.id
                                                          );
                                                        }}
                                                      >
                                                        Edit
                                                      </button>
                                                    </>
                                                  ) : null}

                                                  <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    {(user["role"] ===
                                                      "ccaAdmin" &&
                                                      is_intern ===
                                                        0) ||
                                                    user["role"] ===
                                                      "manager" ? (
                                                      <>
                                                        <button
                                                          className="btn btn-sm btn-success mx-1"
                                                          onClick={() =>
                                                            handleApprove(
                                                              "venue",
                                                              index
                                                            )
                                                          }
                                                        >
                                                          Approve
                                                        </button>
                                                        <button
                                                          className="btn btn-sm btn-error mx-1"
                                                          onClick={() =>
                                                            handleReject(
                                                              "venue",
                                                              index
                                                            )
                                                          }
                                                        >
                                                          Reject
                                                        </button>
                                                        <button
                                                          className="btn btn-sm btn-primary"
                                                          onClick={() => {
                                                            openEmailPopup(
                                                              event?.id
                                                            );
                                                            setVenueIndex(
                                                              index
                                                            );
                                                            setIsEmailModalOpenOutSideVenu(
                                                              true
                                                            );
                                                            setEmailModalOutSideVenuIndex(
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          Escalate
                                                        </button>
                                                      </>
                                                    ) : null}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>

                                        <CustomModal
                                          isOpen={
                                            isEmailModalOpenOutSideVenu &&
                                            emailModalOutSideVenuIndex !== null
                                          }
                                          onRequestClose={() => {
                                            setIsEmailModalOpenOutSideVenu(
                                              false
                                            );
                                            setEmailModalOutSideVenuIndex(null);
                                            setRecipientEmail("");
                                          }}
                                          title="Venue Email Popup"
                                        >
                                          <div className="w-full flex items-center gap-2">
                                            <input
                                              type="email"
                                              className="input input-bordered"
                                              placeholder="Recipient Email"
                                              value={recipientEmail}
                                              onChange={(e) =>
                                                setRecipientEmail(
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <button
                                              onClick={() => {
                                                handleSubmitEmail(
                                                  venueIndex,
                                                  "venue"
                                                );
                                                setIsEmailModalOpenOutSideVenu(
                                                  false
                                                );
                                                setEmailModalOutSideVenuIndex(
                                                  null
                                                );
                                                setRecipientEmail("");
                                              }}
                                              className="btn btn-primary "
                                            >
                                              Send
                                            </button>
                                          </div>
                                        </CustomModal>
                                      </div>
                                    </>
                                  )}
                              </dl>
                            </div>
                          </div>
                          {(user["role"] === "ccaAdmin" &&
                            is_intern === 0) ||
                          user["role"] === "manager" ||
                          user["role"] === "society" ? (
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                openAddModal();
                                setType("venue");
                              }}
                            >
                              Add venue
                            </button>
                          ) : null}
                        </TabPanel>
                        <TabPanel>
                          <div className="">
                            <div className="flow-root rounded-lg border border-gray-100 py-3 shadow-sm">
                              <dl className="-my-3 divide-y divide-gray-100 text-sm">
                                <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                                  <dt className="font-medium text-gray-900">
                                    Guests
                                  </dt>
                                  <dd className="text-gray-700 sm:col-span-2">
                                    {event?.all_guests?.map((guest, index) => (
                                      <div key={index} className="guest my-4">
                                        <h3>
                                          <b>{guest.name}</b>
                                        </h3>
                                        <p>
                                          Introduction: {guest.introduction}
                                        </p>
                                        <p>
                                          Twitter:{" "}
                                          <a
                                            className="link link-primary"
                                            href={guest.twitter_link}
                                          >
                                            {" "}
                                            Link{" "}
                                          </a>{" "}
                                        </p>
                                        <p>
                                          Instagram:{" "}
                                          <a
                                            className="link link-primary"
                                            href={guest.instagram_link}
                                          >
                                            {" "}
                                            Link{" "}
                                          </a>
                                        </p>
                                        <p>
                                          Facebook:{" "}
                                          <a
                                            className="link link-primary"
                                            href={guest.facebook_link}
                                          >
                                            {" "}
                                            Link{" "}
                                          </a>
                                        </p>
                                        <p>Org: {guest.org}</p>
                                        <p>Role: {guest.role}</p>
                                        <p>Research: {guest.research}</p>
                                        <p>Outcome: {guest.outcome}</p>

                                        {guest.approval === true ? (
                                          <p className="text-success">
                                            Status: Approved{" "}
                                          </p>
                                        ) : guest.approval === false ? (
                                          <p className="text-error">
                                            Status: Rejected{" "}
                                          </p>
                                        ) : (
                                          <p className="text-warning">
                                            Status: Pending{" "}
                                          </p>
                                        )}

                                        {(user["role"] === "ccaAdmin" &&
                                          is_intern === 0) ||
                                        user["role"] === "manager" ||
                                        user["role"] === "society" ? (
                                          <>
                                            <button
                                              className="btn btn-sm btn-error"
                                              onClick={() => {
                                                handleDelete(guest.id, "guest");
                                              }}
                                            >
                                              Delete
                                            </button>

                                            <button
                                              className="btn btn-sm btn-secondary"
                                              onClick={() => {
                                                openEditModal();
                                                setType("guest");
                                                setEventId(guest.id);
                                              }}
                                            >
                                              Edit
                                            </button>
                                          </>
                                        ) : null}

                                        {guest.file && (
                                          <a
                                            className="link link-primary"
                                            href={`${guest.file}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            View File
                                          </a>
                                        )}

                                        {(() => {
                                          if (
                                            (user["role"] === "ccaAdmin" &&
                                              is_intern === 0) ||
                                            user["role"] === "manager"
                                          ) {
                                            return (
                                              <div className="flex flex-row items-center gap-2 my-4">
                                                <button
                                                  className="btn btn-sm btn-success"
                                                  onClick={() =>
                                                    handleApprove(
                                                      "guest",
                                                      guest.id
                                                    )
                                                  }
                                                >
                                                  Approve
                                                </button>
                                                <button
                                                  className="btn btn-sm btn-error"
                                                  onClick={() =>
                                                    handleReject(
                                                      "guest",
                                                      guest?.id
                                                    )
                                                  }
                                                >
                                                  Reject
                                                </button>{" "}
                                                <button
                                                  className="btn btn-sm btn-primary"
                                                  onClick={() =>
                                                    handleEscalateGuest(
                                                      guest?.id
                                                    )
                                                  }
                                                >
                                                  Escalate
                                                </button>
                                              </div>
                                            );
                                          }
                                        })()}
                                        <CustomModal
                                          isOpen={openEscaltePopUpGuest}
                                          onRequestClose={closeGuestModal}
                                          title="Esculate Email Guest"
                                        >
                                          <div className="w-full flex items-center gap-2">
                                            <input
                                              style={{ width: "100%" }}
                                              type="email"
                                              className="input input-bordered"
                                              placeholder="Recipient Email"
                                              value={recipientEmailForGuest}
                                              onChange={(e) =>
                                                setRecipientEmailForGuest(
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <button
                                              onClick={() =>
                                                handleGuestEmailSend(
                                                  recipientEmailForGuest,
                                                  guest?.id,
                                                  "guest"
                                                )
                                              }
                                              className="btn btn-primary "
                                            >
                                              Send
                                            </button>
                                          </div>
                                        </CustomModal>
                                      </div>
                                    ))}
                                  </dd>
                                </div>
                              </dl>
                            </div>

                            {(user["role"] === "ccaAdmin" &&
                              is_intern === 0) ||
                            user["role"] === "manager" ||
                            user["role"] === "society" ? (
                              <>
                                <button
                                  className="btn btn-sm btn-primary"
                                  onClick={() => {
                                    openAddModal();
                                    setType("guest");
                                  }}
                                >
                                  Add Guest
                                </button>
                              </>
                            ) : null}
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="min-w-full divide-y divide-gray-200 overflow-x-auto mt-4">
                            <button
                              className="btn btn-sm btn-ghost mb-8"
                              onClick={ExportButton}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                                />
                              </svg>
                              Export Table
                            </button>

                            <table className="min-w-full divide-y divide-gray-300">
                              <thead>
                                <tr>
                                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Procurement
                                  </th>
                                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Details
                                  </th>
                                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Due Date
                                  </th>
                                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    File
                                  </th>
                                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                  </th>
                                  <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-300">
                                {event?.all_purchases?.map(
                                  (purchase, index) => (
                                    <tr key={index}>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        {purchase.category}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        {purchase.details}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        {purchase.due_date}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <button className="btn btn-ghost btn-xs">
                                          <Link to={`${purchase.file}`}>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="currentColor"
                                              class="w-6 h-6"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                              />
                                            </svg>
                                            file
                                          </Link>
                                        </button>
                                      </td>

                                      <td
                                        className={`px-6 py-4 whitespace-nowrap ${
                                          purchase.approval === true
                                            ? "text-green-500"
                                            : purchase.approval === false
                                            ? "text-red-500"
                                            : "text-yellow-500"
                                        }`}
                                      >
                                        {purchase.approval === true
                                          ? "Approved"
                                          : purchase.approval === false
                                          ? "Rejected"
                                          : "Pending"}
                                      </td>

                                      {(user["role"] === "ccaAdmin" &&
                                        is_intern === 0) ||
                                      user["role"] === "manager" ||
                                      user["role"] === "society" ? (
                                        <>
                                          <td>
                                            <button
                                              className="btn btn-sm btn-error"
                                              onClick={() => {
                                                handleDelete(
                                                  purchase.id,
                                                  "purchase"
                                                );
                                              }}
                                            >
                                              Delete
                                            </button>

                                            <button
                                              className="btn btn-sm btn-secondary"
                                              onClick={() => {
                                                openEditModal();
                                                setType("purchase");
                                                setEventId(purchase.id);
                                              }}
                                            >
                                              Edit
                                            </button>
                                          </td>
                                        </>
                                      ) : null}

                                      <td className="px-6 py-4 whitespace-nowrap text-center">
                                        {(user["role"] === "ccaAdmin" &&
                                          is_intern === 0) ||
                                        user["role"] === "manager" ? (
                                          <>
                                            <button
                                              className="btn btn-sm btn-success mx-1"
                                              onClick={() =>
                                                handleApprove(
                                                  "special_purchases",
                                                  purchase.id
                                                )
                                              }
                                            >
                                              Approve
                                            </button>
                                            <button
                                              className="btn btn-sm btn-error mx-1"
                                              onClick={() =>
                                                handleReject(
                                                  "special_purchases",
                                                  purchase.id
                                                )
                                              }
                                            >
                                              Reject
                                            </button>
                                            <button
                                              className="btn btn-sm btn-primary"
                                              onClick={() =>
                                                handleEscalate(purchase.id)
                                              }
                                            >
                                              Escalate
                                            </button>
                                          </>
                                        ) : null}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>

                            <CustomModal
                              isOpen={openEscaltePopUp}
                              onRequestClose={closeModal}
                              title="Esculate Email Procument"
                            >
                              <div className="w-full flex items-center gap-2">
                                <input
                                  style={{ width: "100%" }}
                                  type="email"
                                  className="input input-bordered"
                                  placeholder="Recipient Email"
                                  value={recipientEmailForPro}
                                  onChange={(e) =>
                                    setRecipientEmailForPro(e.target.value)
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleProcumentEmailSend(
                                      recipientEmailForPro,
                                      procId,
                                      "special_purchases"
                                    )
                                  }
                                  className="btn btn-primary "
                                >
                                  Send
                                </button>
                              </div>
                            </CustomModal>
                          </div>

                          {(user["role"] === "ccaAdmin" &&
                            is_intern === 0) ||
                          user["role"] === "manager" ||
                          user["role"] === "society" ? (
                            <>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  openAddModal();
                                  setType("purchase");
                                }}
                              >
                                Add Purchase
                              </button>
                            </>
                          ) : null}
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      <div style={{ zIndex: 999999999999999 }}>
        <Toaster position="bottom-center" />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeAddModal}
        contentLabel="Example Modal"
      >
        <AddVenue
          onModalClose={closeAddModal}
          event={event}
          type={type}
          refreshEvent={getEvent}
        />
      </Modal>

      <Modal
        isOpen={editModal}
        onRequestClose={closeEditModal}
        contentLabel="Example Modal"
      >
        <EditDetails
          onModalClose={closeEditModal}
          id={eventId}
          event={event}
          type={type}
          refreshEvent={getEvent}
        />
      </Modal>

      <Modal
        isOpen={timelineModal}
        onRequestClose={closeTimelineModal}
        contentLabel="Example Modal Timeline"
      >
        <EventLogs
          closeModal={closeTimelineModal}
          id={eventId}
          event={event}
          type={type}
        />
      </Modal>


      <Modal
        isOpen={timelineModal}
        onRequestClose={closeTimelineModal}
        contentLabel="Example Modal Timeline"
      >
        <EventLogs
          closeModal={closeTimelineModal}
          id={eventId}
          event={event}
          type={type}
        />
      </Modal>
    </div>
  );
};
export default EventDetails;

// <TabPanel>
//   <div>
//     {event?.pdc_file && (
//       <div className="collapse collapse-plus bg-base-200 my-4">
//         <input
//           type="radio"
//           name="my-accordion-3"
//           id="pdc-file"
//           checked="checked"
//         />
//         <div className="collapse-title text-xl font-medium">
//           View PDC File
//         </div>
//         <div className="collapse-content">
//           <a
//             className="link link-primary"
//             href={`https://cca.startupearly.com/${event?.pdc_file}`}
//             target="_blank"
//             rel="noopener noreferrer"
//             download={event?.pdc_file}
//           >
//             PDC File Content
//           </a>

//           {(() => {
//             if (
//               user["role"] === "ccaAdmin" ||
//               user["role"] === "manager"
//             ) {
//               return (
//                 <div>
//                   <div className="flex flex-row gap-2 mt-2">
//                     <button
//                       className="btn btn-success"
//                       onClick={() =>
//                         handleApprove("pdc_file", -1)
//                       }
//                     >
//                       Approve
//                     </button>
//                     <button
//                       className="btn btn-error"
//                       onClick={() =>
//                         handleReject("pdc_file", -1)
//                       }
//                     >
//                       Reject
//                     </button>{" "}
//                   </div>
//                 </div>
//               );
//             }
//           })()}
//         </div>
//       </div>
//     )}

//     {[
//       "ground_men_service",
//       "security_service",
//       "electrical_service",
//       "plumbing_service",
//       "welder_service",
//       "carpenter_service",
//       "labour_service",
//       "security_clearance_service",
//     ].map((service, index) => {
//       if (service === "security_clearance_service") {
//         // Check if the event[service] is a JSON string
//         // if (typeof event[service] === "string") {
//         let serviceData =
//           event?.security_clearance_service;
//         // console.log("Clearance:" +event[service]);

//         return (
//           <div
//             key={service}
//             className="collapse collapse-plus bg-base-200 my-4"
//           >
//             <input
//               type="radio"
//               name="my-accordion-3"
//               id={`service-${index}`}
//             />
//             <div className="collapse-title text-xl font-medium">
//               {service.replace("_", " ").toUpperCase()}{" "}
//               Service
//             </div>
//             <div className="collapse-content">
//               {serviceData?.map(
//                 (serviceData, index) => (
//                   <div className="my-4 border-b border-2-black">
//                     <p>Name: {serviceData.name}</p>
//                     <p>
//                       Vehicle: {serviceData.vehicle}
//                     </p>
//                     <p>CNIC: {serviceData.cnic}</p>
//                     <p>Time: {serviceData.time}</p>

//                     {(() => {
//                       if (
//                         user["role"] === "ccaAdmin" ||
//                         user["role"] === "manager"
//                       ) {
//                         return (
//                           <div className="flex flex-row gap-4">
//                             <button
//                               className="btn btn-sm btn-success"
//                               onClick={() =>
//                                 handleApprove(
//                                   `${service}`,
//                                   -1
//                                 )
//                               }
//                             >
//                               Approve
//                             </button>
//                             <button
//                               className="btn btn-sm btn-error"
//                               onClick={() =>
//                                 handleReject(
//                                   `${service}`,
//                                   -1
//                                 )
//                               }
//                             >
//                               Reject
//                             </button>
//                             <button
//                               className="btn btn-sm btn-primary"
//                               onClick={() => {
//                                 openEmailPopup(-1);
//                                 setIsEmailModalOpen1(
//                                   true
//                                 );
//                                 setEmailModalIndex1(
//                                   index
//                                 );
//                                 setServiceName(service);
//                               }}
//                             >
//                               Escalate
//                             </button>
//                           </div>
//                         );
//                       }
//                     })()}

//                     <CustomModal
//                       isOpen={
//                         isEmailModalOpen1 &&
//                         emailModalIndex1 !== null
//                       }
//                       onRequestClose={() => {
//                         setIsEmailModalOpen1(false);
//                         setEmailModalIndex1(null);
//                         setRecipientEmail("");
//                       }}
//                       title={
//                         serviceName
//                           .replace(/_/g, " ")
//                           ?.replace(/\b\w/g, (match) =>
//                             match?.toUpperCase()
//                           ) + " Email"
//                       }
//                     >
//                       <div className="w-full flex items-center gap-2">
//                         <input
//                           type="email"
//                           className="input input-bordered"
//                           placeholder="Recipient Email"
//                           value={recipientEmail}
//                           onChange={(e) =>
//                             setRecipientEmail(
//                               e.target.value
//                             )
//                           }
//                         />
//                         <button
//                           onClick={() => {
//                             handleSubmitEmail(
//                               -11,
//                               serviceName
//                             );
//                             setIsEmailModalOpen1(false);
//                             setEmailModalIndex1(null);
//                             setRecipientEmail("");
//                           }}
//                           className="btn btn-primary "
//                         >
//                           Send
//                         </button>
//                       </div>
//                     </CustomModal>

//                     {/* {emailPopups[1] && (
//                         <div className="email-popup bg-gray-200 w-min shadow-md p-4 rounded-lg">
//                           <input
//                             type="email"
//                             className="input input-bordered"
//                             placeholder="Recipient Email"
//                             value={recipientEmail}
//                             onChange={(e) =>
//                               setRecipientEmail(
//                                 e.target.value
//                               )
//                             }
//                           />
//                           <button
//                             onClick={() =>
//                               handleSubmitEmail(
//                                 -11,
//                                 `${service}`
//                               )
//                             }
//                             className="btn btn-primary mt-2"
//                           >
//                             Send
//                           </button>
//                         </div>
//                       )} */}
//                   </div>
//                 )
//               )}
//             </div>
//           </div>
//         );
//       }

//       return (
//         <div
//           key={service}
//           className="collapse collapse-plus bg-base-200 my-4"
//         >
//           <input
//             type="radio"
//             name="my-accordion-3"
//             id={`service-${index}`}
//           />
//           <div className="collapse-title text-xl font-medium">
//             {service.replace("_", " ").toUpperCase()}{" "}
//             Service
//           </div>
//           <div className="collapse-content">
//             {/* <p>{event[service]}</p> */}
//             {(() => {
//               if (
//                 user["role"] === "ccaAdmin" ||
//                 user["role"] === "manager"
//               ) {
//                 return (
//                   <div className="flex flex-row gap-4">
//                     <button
//                       className="btn btn-sm btn-success"
//                       onClick={() =>
//                         handleApprove(`${service}`, -1)
//                       }
//                     >
//                       Approve
//                     </button>
//                     <button
//                       className="btn btn-sm btn-error"
//                       onClick={() =>
//                         handleReject(`${service}`, -1)
//                       }
//                     >
//                       Reject
//                     </button>
//                     <button
//                       className="btn btn-sm btn-primary"
//                       onClick={() => {
//                         openEmailPopup(-1);
//                         setIsEmailModalOpen1(true);
//                         setEmailModalIndex1(index);
//                         setServiceName(service);
//                       }}
//                     >
//                       Escalate
//                     </button>
//                   </div>
//                 );
//               }
//             })()}
//             <CustomModal
//               isOpen={
//                 isEmailModalOpen1 &&
//                 emailModalIndex1 !== null
//               }
//               onRequestClose={() => {
//                 setIsEmailModalOpen1(false);
//                 setEmailModalIndex1(null);
//                 setRecipientEmail("");
//               }}
//               title={
//                 serviceName
//                   .replace(/_/g, " ")
//                   ?.replace(/\b\w/g, (match) =>
//                     match?.toUpperCase()
//                   ) + " Email"
//               }
//             >
//               <div className="w-full flex items-center gap-2">
//                 <input
//                   type="email"
//                   className="input input-bordered"
//                   placeholder="Recipient Email"
//                   value={recipientEmail}
//                   onChange={(e) =>
//                     setRecipientEmail(e.target.value)
//                   }
//                 />
//                 <button
//                   onClick={() => {
//                     handleSubmitEmail(-1, serviceName);
//                     setIsEmailModalOpen1(false);
//                     setEmailModalIndex1(null);
//                     setRecipientEmail("");
//                   }}
//                   className="btn btn-primary "
//                 >
//                   Send
//                 </button>
//               </div>
//             </CustomModal>
//           </div>
//         </div>
//       );
//     })}
//   </div>
