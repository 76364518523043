import React, { useState } from "react";
import Calendar from "react-calendar";
import "./Calendar.css";

function MyCalendar({ events }) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const eventDates = new Set(
    events?.map((event) => new Date(event.start_date).toDateString()) || []
  );

  const eventsForSelectedDate = events?.filter(
    (event) =>
      new Date(event.start_date).toDateString() === selectedDate.toDateString()
  );

  const tileClassName = ({ date, view }) => {
    if (view === "month" && eventDates.has(date.toDateString())) {
      return "highlight";
    }
  };

  return (
    <div className="space-y-4">
      <Calendar
        onChange={setSelectedDate}
        value={selectedDate}
        tileClassName={tileClassName}
      />

      <div className="space-y-4">
        {events && eventsForSelectedDate.length > 0 ? (
          eventsForSelectedDate.map((event, i) => {
            let start_date = new Date(event.start_date).toLocaleDateString();
            let time = new Date(event.start_date).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
            return (
              <div key={i} className="p-4 border rounded-lg shadow">
                <div className="flex justify-between flex-col">
                  <div className="flex flex-row items-center justify-start gap-4">
                    <h3 className="text-xl font-bold text-violet-900 m-0">
                      {event.event_name}
                    </h3>
                    <p className="text-sm text-gray-500 m-0">
                      {event.society_name} Society
                    </p>
                  </div>

                  <div>
                    <div className="flex flex-row justify-between">
                      <button className="btn px-0 bg-transparent hover:bg-white border-0 btn-transparent no-animation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-gray-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                          />
                        </svg>

                        {start_date}
                      </button>

                      <button className="btn px-0 bg-transparent hover:bg-white border-0 btn-transparent no-animation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-gray-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>

                        {time}
                      </button>
                    </div>
                  </div>

                  <div className="divider">
                    <button className="btn px-0 bg-transparent hover:bg-white border-0 btn-transparent no-animation">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 text-gray-400"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                        />
                      </svg>
                      Indoor Venues
                    </button>
                  </div>

                  <div className="flex flex-col text-start justify-start items-start gap-2">
                    <ul className="block p-4" style={{ listStyle: "circle" }}>
                      {event?.auditoriums?.map((auditorium, index) => (
                        <li key={index}>
                          {auditorium.venue}{" "}
                          <span className="badge badge-ghost">
                            {auditorium.date}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="divider">
                    <button className="btn px-0 bg-transparent hover:bg-white border-0 btn-transparent no-animation">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 text-gray-400"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                        />
                      </svg>
                      Outdoor Venues
                    </button>
                  </div>

                  <div className="flex flex-col text-start justify-start items-start gap-2">
                    <ul className="block p-4" style={{ listStyle: "circle" }}>
                      {event?.venues?.map((venue, index) => (
                        <li key={index}>
                          {venue.venue}{" "}
                          <span className="badge badge-ghost">
                            {venue.date}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="p-4 border rounded-lg shadow">
            <h3 className="text-center text-xl font-medium text-violet-900">
              No events for this date
            </h3>
          </div>
        )}
      </div>

      <div className="flex gap-2 justify-center">
        <span
          className="badge text-xs text-white"
          style={{ background: "#006edc" }}
        >
          Selected
        </span>
        <span className="badge text-xs" style={{ background: "#ffff76" }}>
          Current Date
        </span>
        <span className="badge text-xs" style={{ background: "#c4b5fd" }}>
          Scheduled Event
        </span>
      </div>
    </div>
  );
}

export default MyCalendar;
