import React, { useState, useEffect, useContext } from "react";

import { Link, Navigate, Redirect } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SocietySidebar from "../components/SocietySidebar";
import MobileBottom from "../components/MobileBottom";
import SocietyStats from "../components/SocietyStats";
import SocietyEventsWidget from "../components/SocietyEventsWidget";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MembersList from "../components/MembersList";
import HierarchyMap from "../components/HierarchyMap";
import AddSocietyMember from "../components/AddSocietyMember";
import { axiosInstance } from "../Axios";
import SocNav from "../components/SocNav";
import SocietySideInnerSM from "../components/SocietySideInnerSM";
import EditSocietyMember from "../components/EditSocietyMember";
const Organisation = () => {
  const [members, setMembers] = useState();

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  // initalize thread and posts component state
  const [profile, setProfile] = useState();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  useEffect(() => {
    const getMembers = async () => {
      if (user !== null) {
        let response = await axiosInstance.get(`get_members/`);
        let data = await response.data;
        setMembers(data);
      }
    };
    getMembers();
  }, [user]);

  let memberList = [
    {
      name: "Areeb Arif",
      email: "23100024@lums.edu.pk.pk",
      designation: "President",
      headOf: ["SWESS", "YLES"],
      status: "Active",
      // img: "https://res.cloudinary.com/ahbranding/image/upload/v1688547602/AHbranding/CCA%20img/Mask_group-1_j1vbcl.png",
      img: "https://cca.startupearly.com/api/media/profile_pics/spdrman.jpg",
    },
    {
      name: "Kumail Halai",
      email: "24100112@lums.edu.pk.pk",
      designation: "Vice President",
      headOf: ["SOP Chap."],
      status: "Active",
      img: "https://res.cloudinary.com/ahbranding/image/upload/v1688547602/AHbranding/CCA%20img/Mask_group-3_gz52ft.png",
    },
    {
      name: "Rameen Zafar",
      email: "2501084@lums.edu.pk.pk",
      designation: "General Secretary",
      headOf: [],
      status: "Suspended",
      img: "https://res.cloudinary.com/ahbranding/image/upload/v1688547602/AHbranding/CCA%20img/Mask_group_qhpbty.png",
    },
    {
      name: "Waleed zubai",
      email: "2500007@lums.edu.pk.pk",
      designation: "Treasurer",
      headOf: [],
      status: "Active",
      img: "https://res.cloudinary.com/ahbranding/image/upload/v1688547602/AHbranding/CCA%20img/Mask_group-4_tfkckv.png",
    },
    {
      name: "Hadia Khan",
      email: "26100043@lums.edu.pk.pk",
      designation: "PR executive",
      headOf: [],
      status: "Active",
      img: "https://res.cloudinary.com/ahbranding/image/upload/v1688547602/AHbranding/CCA%20img/Mask_group-2_t4sgzh.png",
    },
  ];

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-primary  min-h-75"></div>

            <SocietySidebar />

            {isSidebarVisible && <SocietySideInnerSM />}

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
              {/* Navbar */}
              <SocNav toggleSidebar={toggleSidebar} />

              {/* end Navbar */}
              {/* cards */}
              <div className="w-full px-6 py-6 mx-auto">
                <div className="bg-white p-6 rounded-lg">
                  <Tabs>
                    <TabList>
                      <Tab>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                          />
                        </svg>
                        Society Members{" "}
                      </Tab>

                      <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                          />
                        </svg>
                        Society Hierarchy
                      </Tab>
                      <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        Add Member
                      </Tab>

                      {/* <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                        Update Member
                      </Tab> */}
                    </TabList>

                    {members && (
                      <TabPanel>
                        <MembersList fetchedMemberList={members} />
                      </TabPanel>
                    )}
                    <TabPanel>
                      <HierarchyMap
                        members={members}
                        fetchedMemberList={memberList}
                      />
                    </TabPanel>
                    <TabPanel>
                      <AddSocietyMember />
                    </TabPanel>

                    <TabPanel>
                      <EditSocietyMember />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};
export default Organisation;
